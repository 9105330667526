import {
  faStethoscope,
  faHospitalUser,
  faClinicMedical,
  faStarOfLife,
  faGavel,
  faHeartbeat,
} from "@fortawesome/free-solid-svg-icons";

const getIconByRole = (roleId: number) => {
  switch (roleId) {
    case 1: {
      return faStarOfLife; // Admin / Green
    }
    case 2: {
      return faStethoscope; // Radiologist
    }
    case 3: {
      return faHeartbeat; // Technologist / Red
    }
    case 4: {
      return faHospitalUser; // Hospital Admin / Orange
    }
    case 5: {
      return faClinicMedical; // Office Manager / Yellow
    }
    case 6: {
      return faGavel; // Judge / Blue
    }
    default: {
      return faStethoscope;
    }
  }
};

export default getIconByRole;
