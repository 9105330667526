import React from "react";
import { FieldProps, getIn } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";

const FormikPasswordField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, variant, margin, ...rest } = props;

  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      fullWidth
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      margin={margin ?? "normal"}
      variant={variant ?? "outlined"}
      type="password"
      {...rest} // Material UI Props
      {...field} // Formik Props
    />
  );
};

export default FormikPasswordField;
