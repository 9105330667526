import React from "react";
import { Box, CardActionArea, Grid, Typography } from "@mui/material";
import Schedule from "../../types/schedule";
import Moment from "react-moment";
import { setUserHospital } from "../../slices/schedule";
import { useDispatch } from "../../store";

interface OwnProps {
  schedule: Schedule[];
  handleClaimShift: (id: number) => void;
}

type Props = OwnProps;

const WelcomeModal: React.FC<Props> = React.memo((props) => {
  const { schedule, handleClaimShift } = props;
  const dispatch = useDispatch();

  const claimShift = (schedule: Schedule) => {
    dispatch(setUserHospital(schedule.hospital));
    handleClaimShift(schedule.id);
  };
  return (
    <React.Fragment>
      <Grid margin={1}>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="h6">Claim a shift</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Hmm. It doesn't look like you're currently scheduled to be working. Click a shift below to claim it.
            </Typography>
          </Grid>
          {schedule &&
            schedule.map((s) => {
              return (
                <Grid item xs={12} key={s.id}>
                  <CardActionArea style={{ borderRadius: 5 }} onClick={() => claimShift(s)}>
                    <Box sx={{ margin: 1 }}>
                      <Typography>
                        {s.taskName} | <Moment format="MMM Do HH:mm">{s.startTime}</Moment> -{" "}
                        <Moment format="MMM Do HH:mm">{s.endTime}</Moment>
                      </Typography>
                      <Typography>
                        {s.claimedUser !== null ? s.claimedUser?.value : s.firstName + " " + s.lastName}
                      </Typography>
                    </Box>
                  </CardActionArea>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default WelcomeModal;
