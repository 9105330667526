import React, { useEffect } from "react";
import type { FC } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import { Add, Delete, EditRounded, Search, Download, Save } from "@mui/icons-material";
import { deleteHospital, fetchHospitals, updateHospitalOrder, updateHospitals } from "../../slices/hospital";
import Chip from "../base/Chip";
import HospitalSummary from "../../types/hospitalSummary";
import { getReport } from "../../slices/report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { arrayMoveImmutable } from "array-move";
import theme from "../../theme";
import StyledTable from "../base/StyledTable";

interface DeleteModal {
  visible: boolean;
  id: null | number;
}

const HospitalTable: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { hospitals, status } = useSelector((store) => store.hospital);
  const { reportStatus } = useSelector((store) => store.reports);

  const [filteredHospitals, setFilteredHospitals] = React.useState<HospitalSummary[] | null>(null);
  const [deleteModal, setDeleteModal] = React.useState<DeleteModal>({
    visible: false,
    id: null,
  });

  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleFilterHospitals = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilteredHospitals(
      hospitals?.filter((hospital) => {
        return hospital.name.toLowerCase().includes(event.target.value.toLowerCase());
      }) || []
    );
  };

  const handleOpenDeleteModal = (id: number) =>
    setDeleteModal({
      visible: true,
      id: id,
    });

  const handleCloseDeleteModal = () => {
    setDeleteModal((prevState) => {
      return {
        ...prevState,
        visible: false,
      };
    });
    setTimeout(() => {
      setDeleteModal((prevState) => {
        return {
          ...prevState,
          id: null,
        };
      });
    }, 250);
  };

  const handleDeleteHospital = async () => {
    handleCloseDeleteModal();
    await dispatch(deleteHospital(deleteModal?.id || 0));
    await dispatch(fetchHospitals());
  };

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  useEffect(() => {
    try {
      if (status === "loading") return;
      if (!!hospitals) setFilteredHospitals([...hospitals]);
    } catch (e) {}
  }, [hospitals, status]);

  const handleDownload = (reportUrl: string) => {
    dispatch(getReport({ reportUrl: reportUrl }));
  };

  const saveOrdering = () => {
    dispatch(updateHospitalOrder(hospitals));
  };

  const headers = ["Hospital", "Badge", "Phone", "Actions", "Display Order"];

  const rows =
    (rowsPerPage > 0
      ? filteredHospitals?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : filteredHospitals
    )
      ?.sort((a, b) => {
        return a.order! - b.order!;
      })
      .map((hospital, index) => {
        return {
          hospital: (
            <Box>
              <Typography color="inherit" variant="subtitle1">
                {hospital.name}
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {hospital.location}
              </Typography>
            </Box>
          ),
          badge: <Chip label={hospital?.shortName} color={hospital?.badgeColor} type="hospital" />,
          phone: (
            <>
              {hospital && hospital.phone
                ? hospital.phone.split("")[0] === "1"
                  ? "(" +
                    hospital.phone.substring(1, 4) +
                    ")-" +
                    hospital.phone.substring(4, 7) +
                    "-" +
                    hospital.phone.substring(7, hospital.phone.length)
                  : hospital.phone
                : ""}
            </>
          ),
          actions: (
            <>
              <Tooltip title={<Typography variant="button">Edit</Typography>} placement="top">
                <IconButton
                  onClick={() =>
                    navigate("/app/hospital", {
                      state: {
                        id: hospital.id,
                      },
                    })
                  }
                  color="inherit"
                >
                  <EditRounded />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Typography variant="button">Delete</Typography>} placement="top">
                <IconButton onClick={() => handleOpenDeleteModal(hospital.id)} color="inherit">
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          ),
          displayOrder: (
            <Box display="flex" flexDirection="row" justifyContent="space-evenly" gap={2}>
              <Box>{hospital.order || index + 1}</Box>
              <Box sx={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faArrowUp}
                  // size="sm"
                  onClick={() => {
                    const newArray = arrayMoveImmutable(hospitals, index, index - 1);
                    dispatch(updateHospitals(newArray));
                  }}
                />
              </Box>
              <Box sx={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  // size="sm"
                  onClick={() => {
                    const newArray = arrayMoveImmutable(hospitals, index, index + 1);
                    dispatch(updateHospitals(newArray));
                  }}
                />
              </Box>
            </Box>
          ),
        };
      }) || [];

  return (
    <React.Fragment>
      <Modal open={deleteModal.visible} onClose={handleCloseDeleteModal}>
        <Fade in={deleteModal.visible}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid margin={2}>
                <Typography>
                  Are you sure you would like to delete "
                  {(() => hospitals?.find((hospital) => deleteModal.id === hospital.id)?.name)()}"?
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={handleCloseDeleteModal}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleDeleteHospital}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>

      <Paper>
        <Grid container padding="15px">
          <Grid item xs={11} padding={1}>
            <Typography variant={"h6"}>Add/Edit Hospitals</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={12} padding={2}>
            <Box
              display="flex"
              flexDirection="row"
              gap={5}
              justifyContent="space-around"
              sx={{
                flexWrap: "wrap",
                width: "100%",
                marginBottom: 5,
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  onChange={handleFilterHospitals}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Hospitals"
                  variant="outlined"
                />
              </Box>
              <Box sx={{}}>
                <Button
                  onClick={() =>
                    navigate("/app/hospital", {
                      state: {
                        id: 0,
                      },
                    })
                  }
                  color="primary"
                  startIcon={<Add fontSize="small" />}
                  variant="contained"
                >
                  New Hospital
                </Button>
              </Box>
              <Box sx={{}}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleDownload("reports/hospitals");
                  }}
                  startIcon={
                    reportStatus === "loading" ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <Download fontSize="small" />
                    )
                  }
                >
                  Download
                </Button>
              </Box>
              <Box sx={{}}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={saveOrdering}
                  startIcon={
                    status === "loading" ? <CircularProgress size={20} color="inherit" /> : <Save fontSize="small" />
                  }
                >
                  Save Order
                </Button>
              </Box>
            </Box>
            <Box>
              <StyledTable headers={headers} rows={rows} useObjects />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default HospitalTable;
