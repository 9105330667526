import React, { useEffect, useState } from "react";
import { Box, FormControl, Grid, Typography, useMediaQuery } from "@mui/material";
import SiteLogosType from "../../types/siteLogos";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import theme from "../../theme";

interface Props {
  onSubmit: (result: SiteLogosType) => void;
  siteLogos?: SiteLogosType;
}

const SiteLogos: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [model, setModel] = useState<SiteLogosType>({
    siteLogo: [],
    siteLogoFile: "",
    reportLogo: [],
    reportLogoFile: "",
  });
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let siteLogoFile = props.siteLogos?.siteLogoFile;
    let reportLogoFile = props.siteLogos?.reportLogoFile;

    if (siteLogoFile != null && siteLogoFile.length > 0) {
      siteLogoFile = `data:image/png;base64,${siteLogoFile}`;
    }

    if (reportLogoFile != null && reportLogoFile.length > 0) {
      reportLogoFile = `data:image/png;base64,${reportLogoFile}`;
    }

    setModel({ ...props.siteLogos, siteLogoFile: siteLogoFile, reportLogoFile: reportLogoFile });
  }, [props.siteLogos]);

  const handleSubmit = (model: SiteLogosType) => {
    setLoading(true);
    props.onSubmit(model);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({});

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={model}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form noValidate>
            <Grid container>
              <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
                <Typography style={{ marginBottom: "5px" }}>Site Logo</Typography>
                <FormControl>
                  <Field
                    name="siteLogo"
                    label="Site Logo"
                    setFieldValue={setFieldValue}
                    required
                    component={Inputs.Dropzone}
                  />
                  {model?.siteLogoFile && <img src={model?.siteLogoFile} width={sm ? 275 : "inherit"} alt="siteLogo" />}
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={12} style={{ textAlign: "center" }}>
                <Typography style={{ marginBottom: "5px" }}>Report Logo</Typography>
                <FormControl>
                  <Field
                    name="reportLogo"
                    label="Report Logo"
                    setFieldValue={setFieldValue}
                    required
                    component={Inputs.Dropzone}
                  />
                  {model?.reportLogoFile && (
                    <img src={model?.reportLogoFile} width={sm ? 275 : "inherit"} alt="reportLogo" />
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Inputs.Submit text="Save" isSubmitting={loading} />
            </Box>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default SiteLogos;
