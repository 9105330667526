import React, { ChangeEvent } from "react";
import { FieldProps, getIn } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface Props {
  onBlur?: () => void;
}

const FormikNumberField: React.FC<FieldProps & TextFieldProps & Props> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, variant, margin, required, onBlur, onChange, ...rest } = props;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    field.onChange(event);
    onChange?.(event);
  };

  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      fullWidth
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      type="number"
      onKeyPress={(e) => {
        if (e.key === "e") {
          e.preventDefault();
        }
      }}
      margin={margin ?? "normal"}
      variant={variant ?? "outlined"}
      required={required ?? false}
      sx={{
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
          display: "none",
        },
      }}
      {...rest} // Material UI Props
      {...field} // Formik Props
      onBlur={props.onBlur ? props.onBlur : () => {}}
      onChange={handleChange}
      inputProps={{
        spellCheck: "true",
      }}
    />
  );
};

export default FormikNumberField;
