import React, { useEffect, useState } from "react";
import { Box, Button, Fade, Grid, IconButton, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import Schedule from "../../types/schedule";
import theme from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import StyledTable from "../base/StyledTable";
import Moment from "react-moment";
import { useDispatch, useSelector } from "../../store";
import { fetchClaimedSchedules, removeClaimedSchedule } from "../../slices/schedule";
import SnackBarToast from "../base/SnackbarToast";
import { handleErrorToastState, setErrorMessage } from "../../slices/toast";

interface Props {}

const ClaimedSchedule: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { claimedSchedules } = useSelector((store) => store.schedule);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule>();
  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(fetchClaimedSchedules());
  }, [dispatch]);

  const handleRemove = () => {
    setLoading(true);
    if (selectedSchedule) {
      dispatch(removeClaimedSchedule(selectedSchedule.id)).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          dispatch(fetchClaimedSchedules());
          setLoading(false);
          setSuccess(true);
        } else {
          dispatch(setErrorMessage("Could not remove user from schedule"));
          dispatch(handleErrorToastState(true));
        }
      });
      handleCloseModal();
    }
  };

  const handleOpenModal = (schedule: Schedule) => {
    setSelectedSchedule(schedule);
    setModal(true);
  };

  const handleCloseModal = () => {
    setSelectedSchedule(undefined);
    setModal(false);
  };

  const headers = ["Remove", "Claimed By", "Schedule", "Date", "Start Time", "End Time", "Original Schedule"];

  const rows =
    claimedSchedules?.map((s: Schedule) => {
      return {
        remove: (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <IconButton
              disabled={loading}
              sx={{ color: theme.palette.secondary.main }}
              onClick={() => {
                handleOpenModal(s);
              }}
              color="inherit"
            >
              <FontAwesomeIcon icon={faMinusCircle} size="sm" />
            </IconButton>
          </div>
        ),
        claimedBy: s.claimedUser?.value,
        task: s.taskName,
        date: s.startTime && <Moment format="MM/DD/YYYY">{s.startTime!}</Moment>,
        startTime: s.startTime && <Moment format="HH:mm">{s.startTime!}</Moment>,
        endTime: s.endTime && <Moment format="HH:mm">{s.endTime!}</Moment>,
        originalSchedule: s.user?.value || "Locum",
      };
    }) || [];

  return (
    <React.Fragment>
      <Box sx={{ overflow: "auto", fontSize: `${theme.typography.caption}` }}>
        <Grid container>
          {claimedSchedules && claimedSchedules.length > 0 ? (
            <Grid xs={12}>
              <StyledTable rows={rows} paging={false} headers={headers} useObjects />
            </Grid>
          ) : (
            <>No Current Claimed Schedules</>
          )}
        </Grid>
      </Box>
      <SnackBarToast
        message="Claimed Schedule Removed Successfully"
        type="success"
        open={success}
        handleClose={() => {
          setSuccess(false);
        }}
      />
      <Modal open={modal} onClose={handleCloseModal}>
        <Fade in={modal}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid margin={2}>
                <Typography>
                  Are you sure you would like to remove {selectedSchedule?.claimedUser?.value} from this scheduled
                  shift?
                </Typography>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleRemove}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default ClaimedSchedule;
