import React, { useEffect, useRef, useState } from "react";
import { AutocompleteChangeReason, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import Moment from "react-moment";
import { Inputs } from "../forms";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import User from "../../types/user";
import { useDispatch, useSelector } from "../../store";
import { logBreak } from "../../slices/users";
import moment from "moment";
import { debounce } from "lodash";
import HospitalSummary from "../../types/hospitalSummary";
import { fetchLatestSchedule, setUserHospital } from "../../slices/schedule";
import BreakOption from "../../types/breakOption";

interface Props {
  user: User;
  save?: (values: { details?: string; hospital?: HospitalSummary; time?: number }) => void;
  isModal?: boolean;
  handleClose?: () => void;
}

const UserBreakMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const { breakTime } = useSelector((store) => store.users);
  const { hospitals } = useSelector((store) => store.hospital);
  const { breakDetails } = useSelector((store) => store.userBreaks);
  const { latestSchedule, userHospital } = useSelector((store) => store.schedule);
  const { loggedInUser } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(fetchLatestSchedule());
  }, [dispatch]);

  useEffect(() => {
    const hospital = latestSchedule?.find(
      (e) => e.email === loggedInUser?.email || e.claimedUser?.id === loggedInUser?.id
    )?.hospital;

    if (hospital) {
      dispatch(setUserHospital(hospital));
    }
  }, [dispatch, latestSchedule, loggedInUser, hospitals]);

  const initialValues = { details: breakTime?.details, hospital: userHospital, time: breakDetails.breakTime };

  const getFilteredHospitals = () => {
    let filteredHospitals = hospitals.filter((h) => h.name.toLowerCase() !== "sjghir");
    let otherOption = {
      id: 0,
      name: "Other",
    } as HospitalSummary;
    filteredHospitals.push(otherOption);
    return filteredHospitals;
  };

  const handleClick = (minutes: number) => {
    dispatch(
      logBreak({
        ...breakTime,
        userId: props.user?.id,
        returnTime: moment(breakTime?.returnTime).utc().add(minutes, "minutes").toDate(),
        watching: false,
      })
    );
  };

  const handleSelected = (event: React.SyntheticEvent, value: any | Array<any>) => {
    if (!props.isModal) {
      dispatch(logBreak({ ...breakTime!, userId: props.user?.id, hospital: value }));
    }
  };

  const validationSchema = Yup.object().shape({
    details: Yup.mixed().nullable().required("Please enter break details"),
    hospital: Yup.object().nullable().required("Please select a hospital"),
    time: Yup.number().nullable(),
  });

  const handleChange = debounce((details: string) => {
    if (!props.isModal) {
      dispatch(
        logBreak({
          ...breakTime,
          userId: props.user?.id,
          details: details,
          returnTime: moment(breakTime?.returnTime).utc().toDate(),
          watching: false,
        })
      );
    }
  }, 500);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!props.isModal ? (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              gap: 1,
            }}
          >
            <Typography>
              Returning at <Moment format="HH:mm">{breakTime?.returnTime}</Moment>
            </Typography>
            <IconButton size="small" color="inherit" ref={anchorRef} onClick={() => handleClick(1)}>
              +1
            </IconButton>
            <IconButton size="small" color="inherit" ref={anchorRef} onClick={() => handleClick(5)}>
              +5
            </IconButton>
            <IconButton size="small" color="inherit" ref={anchorRef} onClick={() => handleClick(30)}>
              +30
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Typography>
            Please enter break details and hospital. Break will not be saved until the submit button is clicked.
          </Typography>
        </Box>
      )}
      {props.isModal && (
        <>
          <Formik
            enableReinitialize
            initialValues={
              !!breakTime
                ? { details: breakTime.details, hospital: breakTime.hospital, time: breakDetails.breakTime }
                : initialValues
            }
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              setLoading(true);
              if (props.isModal && props.save && props.handleClose) {
                props.save(values);
                props.handleClose();
              } else {
                await dispatch(
                  logBreak({
                    ...breakTime,
                    userId: props.user?.id,
                    details: values.details,
                    hospital: values.hospital,
                    returnTime: moment(breakTime?.returnTime).utc().toDate(),
                    watching: false,
                  })
                );
              }
              setLoading(false);
            }}
          >
            {({ setFieldValue }) => (
              <Form noValidate>
                <Box sx={{ marginTop: "10px" }}>
                  <Field
                    name="details"
                    required
                    label="Add Details"
                    component={Inputs.Dropdown}
                    options={breakDetails.options}
                    onChange={handleChange}
                    freeSolo={true}
                    onSelected={(event: React.SyntheticEvent, value: BreakOption, reason: AutocompleteChangeReason) => {
                      if (reason === "selectOption") {
                        setFieldValue("details", value?.value || value);
                        setFieldValue("time", value.customTime);
                      } else if (reason === "clear") {
                        setFieldValue("time", breakDetails.breakTime);
                      }
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <Field
                    name="hospital"
                    label="Link to Hospital"
                    onSelected={handleSelected}
                    component={Inputs.Dropdown}
                    options={getFilteredHospitals() || []}
                    required={true}
                  />
                </Box>
                {props.isModal && (
                  <Grid container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item xs={6}>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="secondary"
                          fullWidth
                          size="large"
                          type="button"
                          variant="contained"
                          onClick={props.handleClose}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{}}>
                        <Inputs.Submit isSubmitting={loading} />
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        </>
      )}
    </Box>
  );
};

export default UserBreakMenu;
