import { AmplifyS3Image } from "@aws-amplify/ui-react";
import React, { useState } from "react";

interface Props {
  firstName: string;
  lastName: string;
  imageId: number;
  height: string;
}

const S3Avatar: React.FC<Props> = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImgLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <AmplifyS3Image
        handleOnLoad={handleImgLoad}
        style={{ display: imageLoaded ? "flex" : "none", height: props.height }}
        imgKey={`avatars/${props.imageId}`}
      />
      {!imageLoaded && (
        <>
          {props.firstName?.[0]}
          {props.lastName?.[0]}
        </>
      )}
    </>
  );
};

export default S3Avatar;
