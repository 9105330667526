import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import NewsItem from "../../types/newsItem";
import { useSelector } from "../../store";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Props {
  newsItem: NewsItem;
}

const News: React.FC<Props> = (props) => {
  const handleClick = () => {
    window.open(props.newsItem.link);
  };
  const smallBreak = useMediaQuery("(min-width:1081px)");

  const { loggedInUser } = useSelector((state) => state.auth);

  return (
    <div style={{ cursor: "pointer" }} onClick={handleClick}>
      <Box
        display="flex"
        flexDirection={smallBreak ? "row" : "column"}
        alignItems="center"
        alignContent="center"
        justifyContent="space-evenly"
        sx={{ height: loggedInUser?.status?.id! > 2 ? "204px" : "129px" }}
        gap={1}
      >
        <img
          height={smallBreak ? "125" : "62.5"}
          src={props.newsItem.key}
          alt="news cover"
          style={{ borderRadius: "15px" }}
        />
        <Tooltip title={props.newsItem.title.length > 45 ? props.newsItem.title : ""}>
          <Typography
            alignContent="center"
            justifyContent="center"
            justifyItems="center"
            textAlign="center"
            sx={{
              maxHeight: "124px",
              fontSize: "1rem",
              fontWeight: 300,
              whiteSpace: "break-spaces",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.newsItem.title.length < 45
              ? props.newsItem.title
              : props.newsItem.title.split(" ")[0] +
                " " +
                props.newsItem.title.split(" ")[1] +
                " " +
                props.newsItem.title.split(" ")[2] +
                "..."}
          </Typography>
        </Tooltip>
      </Box>
    </div>
  );
};

export default News;
