import React, { useEffect, useState } from "react";
import { Box, Button, FormControlLabel, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
import { useDispatch, useSelector } from "../../store";
import { Inputs } from "../forms";
import User from "../../types/user";
import * as Yup from "yup";
import { fetchHospitals } from "../../slices/hospital";
import Option from "../../types/option";
import getUserRoleId from "../../helpers/getUserRoleId";
import Avatar from "../base/Avatar";
import EditAvatar from "react-avatar-edit";
import { setAvatarForm } from "../../slices/addedituser";
import theme from "../../theme";

interface Props {
  user: User;
  onSubmit: (model: User) => void;
  isSubmitting: boolean;
  navigate: (...params: any) => void;
  cancel: () => void;
}

// TECH ONLY TECH
// JUDGE ONLY JUDGE
// RADS CAN BE JUDGE AND ADMIN

const roleOptions: Option[] = [
  {
    id: 1,
    value: "Admin",
  },
  {
    id: 2,
    value: "Radiologist",
  },
  {
    id: 3,
    value: "Technologist",
  },
  {
    id: 4,
    value: "Hospital Admin",
  },
  {
    id: 5,
    value: "Office Manager",
  },
  {
    id: 6,
    value: "Judge",
  },
  {
    id: 7,
    value: "IR Studies",
  },
];

const EditAddUser: React.FC<Props> = (props) => {
  const { user: model, onSubmit, isSubmitting } = props;
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { user } = useSelector((store) => store.addEditUser);
  const { hospitals } = useSelector((store) => store.hospital);
  const fixedModel = (() => {
    if (!model?.roles)
      return {
        ...model,
        roles: [],
      };
    return model;
  })();
  const [avatarState, setAvatarState] = useState({
    preview: "",
    userAvatar: model.avatar,
  });

  const [textState, setTextState] = useState(model.textMessages);
  const [emailState, setEmailState] = useState(model.receiveEmails);
  const [sensitiveDataState, setSensitiveDataState] = useState(model.allowSensitiveData);
  const [textOptInState, setTextOptInState] = useState(model.textMessageOptIn);

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string().required("Please enter your first name"),
      lastName: Yup.string().required("Please enter your last name"),
      email: Yup.string().email("Must be a valid email").required("Please enter your email address"),
      phone: Yup.string().nullable().matches(phoneRegExp, "Phone number is not valid"),
      textMessages: Yup.boolean(),
      receiveEmails: Yup.boolean(),
      allowSensitiveData: Yup.boolean(),
      textMessageOptIn: Yup.boolean(),
      hospitals: Yup.array()
        .nullable()
        .when("roles", {
          is: (roles: any[]) => roles && !!roles.find((r) => r.id === 2 || r.id === 3),
          then: Yup.array().required("Please select at least one hospital"),
        }),
      password: Yup.string()
        .nullable()
        .min(8)
        .max(128)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number"
        ),
    },
    [["roles", "hospitals"]]
  );

  const secondaryRoleFilter = (roleId: number): Option[] => {
    if (roleId === 1) return [{ ...roleOptions[6] }];
    if (roleId === 2) return [{ ...roleOptions[0] }, { ...roleOptions[5] }, { ...roleOptions[6] }];
    return [];
  };

  function dataURLtoFile(dataurl: string, filename: string) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const onCrop = (preview: string) => {
    setAvatarState({ ...avatarState, preview: preview });
    var file = dataURLtoFile(preview, `${model.firstName}-${model.lastName}-avatar.jpg`);
    dispatch(setAvatarForm(file));
  };

  const onClose = () => {
    setAvatarState({ userAvatar: "", preview: "" });
    dispatch(setAvatarForm(undefined));
  };

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Formik initialValues={fixedModel} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => {
          return (
            <Form autoComplete="off" noValidate>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ paddingLeft: sm ? 70 : 100, justifyContent: "center", textAlign: "center" }}
                >
                  {!user.avatar ? (
                    <EditAvatar height={250} width={250} onCrop={onCrop} cropRadius={125} onClose={onClose} />
                  ) : (
                    <EditAvatar
                      src={`data:image/png;base64,${avatarState.userAvatar}`}
                      height={250}
                      width={250}
                      onCrop={onCrop}
                      cropRadius={125}
                      onClose={onClose}
                    />
                  )}
                </Grid>
                {(!!avatarState.preview || !sm) && (
                  <Grid item xs={12} sm={6} style={{ paddingLeft: sm ? 60 : 20, textAlign: "center" }}>
                    <Avatar
                      roleId={getUserRoleId(fixedModel)}
                      src={avatarState.preview === "" ? undefined : avatarState.preview}
                      sx={{
                        height: 250,
                        width: 250,
                      }}
                    />
                  </Grid>
                )}
                <Grid container spacing={sm ? 1 : 3} style={{ paddingLeft: 25, textAlign: "center" }}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      name="email"
                      label="Email"
                      id="abc123"
                      autoComplete="abc123"
                      required
                      component={Inputs.Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field name="firstName" label="First Name" required component={Inputs.Text} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field name="lastName" label="Last Name" required component={Inputs.Text} />
                  </Grid>
                </Grid>
                <Grid container spacing={sm ? 1 : 3} style={{ paddingLeft: 25, textAlign: "center" }}>
                  <Grid item xs={12} sm={4}>
                    <Field name="phone" label="Phone Number" color component={Inputs.Phone} />
                  </Grid>
                  {loggedInUser?.id !== fixedModel?.id && (
                    <Tooltip
                      title="Password must be a minimum of 8 characters
                            and contain one uppercase letter, one lowercase letter, and one number."
                    >
                      <Grid item xs={12} sm={4}>
                        <Field
                          name="password"
                          id="new-password"
                          autoComplete="new-password"
                          label="Temporary Password"
                          component={Inputs.Password}
                        />
                      </Grid>
                    </Tooltip>
                  )}
                  {getUserRoleId(values) === 2 ? (
                    <Grid item xs={12} sm={4}>
                      <Field name="specialty" label="Specialty" component={Inputs.Text} />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid container spacing={sm ? 1 : 3} style={{ paddingLeft: 25 }}>
                  <Grid item xs={12} sm={4} sx={{ marginTop: "15px" }}>
                    <Field
                      name="hospitals"
                      label="Hospitals"
                      multiple
                      component={Inputs.Dropdown}
                      options={hospitals || []}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {loggedInUser?.roles.find((role) => role?.id === 1) && (
                      <>
                        <Typography variant="subtitle1">Role</Typography>
                        <FieldArray
                          name="roles"
                          render={() => (
                            <React.Fragment>
                              <FormControlLabel
                                label="Admin"
                                control={
                                  <Field
                                    name="roles"
                                    value={values.roles.length === 1 && values.roles[0]?.id === 1 ? "true" : "false"}
                                    checked={!!values.roles.find((role) => role?.id === 1)}
                                    onChange={(e: React.ChangeEvent) =>
                                      setFieldValue("roles", [{ id: 1, value: "Admin" }])
                                    }
                                    component={Inputs.Radio}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Radiologist"
                                control={
                                  <Field
                                    name="roles"
                                    checked={!!values.roles.find((role) => role?.id === 2)}
                                    onChange={(e: React.ChangeEvent) =>
                                      setFieldValue("roles", [{ id: 2, value: "Radiologist" }])
                                    }
                                    component={Inputs.Radio}
                                  />
                                }
                              />
                              <FormControlLabel
                                label="Technologist"
                                control={
                                  <Field
                                    name="roles"
                                    checked={!!values.roles.find((role) => role?.id === 3)}
                                    onChange={(e: React.ChangeEvent) =>
                                      setFieldValue("roles", [{ id: 3, value: "Technologist" }])
                                    }
                                    component={Inputs.Radio}
                                  />
                                }
                              />
                            </React.Fragment>
                          )}
                        />
                        <Grid item xs={12}>
                          <FieldArray
                            name="roles"
                            render={(arrayHelpers) =>
                              secondaryRoleFilter(getUserRoleId({ ...values })).map((role) => (
                                <Field
                                  key={role?.id}
                                  name="roles"
                                  value={role}
                                  checked={!!values.roles.find((role2) => role2?.id === role?.id)}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.checked) return arrayHelpers.push({ ...role });
                                    const foundIndex = values.roles.findIndex((role2) => role2?.id === role?.id);
                                    if (!!foundIndex) arrayHelpers.remove(foundIndex);
                                  }}
                                  component={Inputs.Checkbox}
                                  label={role.value}
                                />
                              ))
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginTop: "25px", textAlign: "center" }}>
                    {(values?.roles.find((role) => role?.id === 1) || values?.roles.find((role) => role?.id === 2)) && (
                      <Grid item xs={12}>
                        {loggedInUser?.id === values?.id! && (
                          <Grid item xs={12} sx={{ textAlign: "left", marginLeft: "75px" }}>
                            <Field
                              name="textMessageOptIn"
                              label="Receive Text Messages"
                              required
                              checked={textOptInState}
                              component={Inputs.Checkbox}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("textMessageOptIn", e.target.checked);
                                setTextOptInState(e.target.checked);
                              }}
                            />
                            <Box display="flex" flexDirection="row" marginX="50px" fontSize="12px">
                              Opt-in to text messaging to receive alerts when an important announcement or QA case is
                              created. Message and data rates may apply. Uncheck the box to stop messages at any time.
                            </Box>
                          </Grid>
                        )}
                        <Grid item xs={12} sx={{ textAlign: "left", marginLeft: "75px" }}>
                          {values?.roles.find((role) => role?.id === 2) && loggedInUser?.id !== values?.id! && (
                            <Field
                              name="allowSensitiveData"
                              label="Show Sensitive Data"
                              required
                              checked={sensitiveDataState}
                              component={Inputs.Checkbox}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("allowSensitiveData", e.target.checked);
                                setSensitiveDataState(e.target.checked);
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "left", marginLeft: "75px" }}>
                          {loggedInUser?.id !== values?.id! && (
                            <Field
                              name="textMessages"
                              label="Send Text Messages"
                              required
                              checked={textState}
                              component={Inputs.Checkbox}
                              tooltip="Users will only receive text messages if they have opted in to allow them."
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("textMessages", e.target.checked);
                                setTextState(e.target.checked);
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "left", marginLeft: "75px" }}>
                          {loggedInUser?.id !== values?.id! && (
                            <Field
                              name="receiveEmails"
                              label="Send Emails"
                              required
                              checked={emailState}
                              component={Inputs.Checkbox}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue("receiveEmails", e.target.checked);
                                setEmailState(e.target.checked);
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="secondary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={props.cancel}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Inputs.Submit text={"Save"} isSubmitting={isSubmitting} />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default EditAddUser;
