import theme from "../theme";

const pillColor = (status?: number | undefined) => {
  if (status === 1) return theme.palette.tertiary.main; // stroke
  if (status === 2) return theme.palette.warning.main; // critical
  if (status === 3) return theme.palette.quinary.main; // routine
  if (status === 4) return theme.palette.secondary.main; // trauma
  return theme.palette.quinary.main;
};

export default pillColor;
