import { Formik, Form, Field } from "formik";
import React, { useState } from "react";
import { useSelector } from "../../store";
import { Inputs } from "../forms";
import * as Yup from "yup";
import Comment from "../../types/comment";
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import Option from "../../types/option";

interface Props {
  caseId: number;
  onSave: (
    titleSwitch: string,
    comment: Comment,
    judgementId?: number,
    sendEmail?: boolean,
    sendText?: boolean
  ) => void;
  description: string;
  onClose: () => void;
  required?: boolean;
  title: string;
  type: "comment" | "finding";
  showAdjudicate?: boolean;
  judge?: boolean;
}

const QaAction: React.FC<Props> = (props) => {
  const { loggedInUser } = useSelector((store) => store.auth);
  const { judgeQuery, judgeResults } = useSelector((store) => store.qa);
  const [busy, setBusy] = useState(false);

  const initialValues = {
    caseId: props.caseId,
    user: loggedInUser,
    commentType: { id: props.type === "comment" ? 1 : 2, value: "" },
    commentText: "",
    sendEmail: false,
    sendText: false,
  };

  const handleSave = (values: any) => {
    props.onSave(props.title, values, selectedOption?.id!, values.sendEmail!, values.sendText!);
    props.onClose();
    setBusy(false);
  };

  const validationSchema = Yup.object().shape({
    commentText: Yup.string().required("Please enter a comment"),
  });

  const [selectedOption, setSelectedOption] = useState<Option>({ id: 0, value: "" });

  return (
    <div>
      <Box padding={1}>{props.description}</Box>
      <Formik
        initialValues={initialValues}
        validationSchema={props.required && validationSchema}
        onSubmit={async (values, actions) => {
          setBusy(true);
          handleSave(values);
        }}
      >
        <Form noValidate>
          {props.showAdjudicate && (
            <RadioGroup
              name="judgeOptions"
              value={selectedOption.id}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedOption(
                  (props.judge ? judgeResults : judgeQuery).find((o: Option) => o.id === +event.target.value)!
                );
              }}
            >
              {(props.judge ? judgeResults : judgeQuery).map((option) => (
                <FormControlLabel key={option.id} value={option.id} control={<Radio />} label={option.value} />
              ))}
            </RadioGroup>
          )}
          <Field name="commentText" label="Add Comment" required={props.required} component={Inputs.Text} />
          <Grid container>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Field name="sendEmail" label="Send Urgent Email" required component={Inputs.Checkbox} />
            </Grid>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Field name="sendText" label="Send Urgent Text" required component={Inputs.Checkbox} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="secondary"
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={props.onClose}
                >
                  Close
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Box>
                <Inputs.Submit text="Save" isSubmitting={busy} />
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};

export default QaAction;
