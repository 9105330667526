import React, { useEffect, useState } from "react";
import { Box, Drawer, ListItemText, MenuItem, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ActionButtons from "../base/ActionButtons";
import { Menu } from "@mui/icons-material";
import { useDispatch, useSelector } from "../../store";
import { fetchLinks } from "../../slices/links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faEllipsisH, faFileContract } from "@fortawesome/free-solid-svg-icons";

interface Props {}

const LinkMenuDrawer: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { visibleLinks } = useSelector((store) => store.links);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchLinks());
  }, [dispatch]);

  const handleClose = (): void => {
    setOpen(false);
  };

  return visibleLinks ? (
    <>
      <ActionButtons
        label="Quick Links"
        icon={<FontAwesomeIcon icon={faFileContract} size="sm" />}
        onClick={() => setOpen(!open)}
      />
      <Drawer
        anchor="top"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 2,
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            mt: 10,
            px: 1.5,
          }}
        >
          {visibleLinks.map((radioLink) => {
            return (
              <MenuItem
                key={radioLink.id}
                onClick={handleClose}
                component={"a"}
                href={radioLink.permalink.includes("//") ? `${radioLink.permalink}` : `//${radioLink.permalink}`}
                target="_blank"
              >
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="subtitle2">
                      {radioLink.name}
                    </Typography>
                  }
                />
              </MenuItem>
            );
          })}
        </Box>
      </Drawer>
    </>
  ) : (
    <></>
  );
};

export default LinkMenuDrawer;
