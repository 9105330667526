import { createSlice } from "@reduxjs/toolkit";

interface KioskState {
  kiosk: boolean;
}

const initialState: KioskState = {
  kiosk: false
};

const kioskSlice = createSlice({
  name: "kiosk",
  initialState,
  reducers: {
    setKiosk(state, action) {
      state.kiosk = action.payload;
    }
  },
});

export default kioskSlice.reducer;
export const { setKiosk } = kioskSlice.actions