import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import IrStudy from "../types/irStudy";
import SearchResults from "../types/searchResults";
import SearchParams from "../types/searchParams";
import helpers from "../helpers";
import Option from "../types/option";

interface IrStudyState {
  value: string;
  status: "idle" | "loading" | "failed";
  unreadStudies?: number;
  studies?: SearchResults;
  study: IrStudy;
  findings: Option[];
  statuses: Option[];
  rerouteString: string;
}

const initialState: IrStudyState = {
  value: "",
  status: "idle",
  study: {
    id: 0,
    mrNumber: undefined,
    hospital: undefined,
    modality: undefined,
    findings: "",
    comments: "",
  },
  findings: [],
  statuses: [],
  rerouteString: "",
};

export const getIrStudies = createAsyncThunk<SearchResults, SearchParams>(
  "study/search",
  async (model: SearchParams) => {
    let qs = helpers.getSearchQueryString(model);
    const response = await http.get<SearchResults>(`/study/search?${qs}`);
    return response.data;
  }
);

export const getIrStudyId = createAsyncThunk<IrStudy, number>("study/id/get", async (id: number) => {
  const response = await http.get<IrStudy>(`/study/${id}`);
  return response.data;
});

export const getUnreadStudies = createAsyncThunk<number>("study/unread", async () => {
  const response = await http.get(`/study/unread`);
  return response.data;
});

export const postIrStudy = createAsyncThunk<IrStudy, IrStudy>("study/post", async (model: IrStudy) => {
  const response = await http.post("/study", model);
  return response.data;
});

export const updateIrStudy = createAsyncThunk<IrStudy, IrStudy>("study/put", async (model: IrStudy) => {
  const response = await http.put(`/study/${model.id}`, model);
  return response.data;
});

export const readIrStudy = createAsyncThunk<null, number>("study/read", async (id: number) => {
  const response = await http.patch(`/study/${id}`);
  return response.data;
});

export const getFindings = createAsyncThunk<Option[]>("study/findings", async () => {
  const response = await http.get(`/study/findings`);
  return response.data;
});

export const getStatusOptions = createAsyncThunk<Option[]>("study/status", async () => {
  const response = await http.get(`/study/status`);
  return response.data;
});

export const deleteIrStudy = createAsyncThunk<null, number>("study/id/delete", async (id: number) => {
  const response = await http.delete(`/study/${id}`);
  return response.data;
});

const irStudySlice = createSlice({
  name: "irStudy",
  initialState,
  reducers: {
    resetIrStudy(state) {
      state.study = { ...initialState.study };
    },
    setRerouteString(state, action) {
      state.rerouteString = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIrStudies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIrStudies.fulfilled, (state, action) => {
        state.status = "idle";
        state.studies = action.payload;
      })
      .addCase(getIrStudies.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getIrStudyId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIrStudyId.fulfilled, (state, action) => {
        state.status = "idle";
        state.study = action.payload;
      })
      .addCase(getIrStudyId.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUnreadStudies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUnreadStudies.fulfilled, (state, action) => {
        state.status = "idle";
        state.unreadStudies = action.payload;
      })
      .addCase(getUnreadStudies.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(postIrStudy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postIrStudy.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(postIrStudy.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateIrStudy.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateIrStudy.fulfilled, (state, action) => {
        state.status = "idle";
        state.study = action.payload;
      })
      .addCase(updateIrStudy.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getFindings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFindings.fulfilled, (state, action) => {
        state.status = "idle";
        state.findings = action.payload;
      })
      .addCase(getFindings.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getStatusOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStatusOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.statuses = action.payload;
      })
      .addCase(getStatusOptions.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { resetIrStudy, setRerouteString } = irStudySlice.actions;

export default irStudySlice.reducer;
