import { createSlice } from "@reduxjs/toolkit";

interface hubState {
}

const initialState: hubState = {
};

const hubSlice = createSlice({
  name: "hub",
  initialState,
  reducers: {
    startConnecting() {}
  },
});

export default hubSlice.reducer;
export const hubActions = hubSlice.actions;