import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import NewsItem from "../types/newsItem";

interface NewsState {
  news: NewsItem[];
  dismissed: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: NewsState = {
  news: [],
  dismissed: false,
  status: "idle",
};

export const getNews = createAsyncThunk<NewsItem[]>(`news/fetch`, async () => {
  const response = await http.get<NewsItem[]>(`news`);
  return response.data;
});

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    toggleNews(state, action) {
      state.dismissed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNews.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.status = "idle";
        state.news = action.payload;
      })
      .addCase(getNews.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default newsSlice.reducer;
export const { toggleNews } = newsSlice.actions;
