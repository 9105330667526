import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import ActionButtons from "../base/ActionButtons";
import { useDispatch, useSelector } from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyringe } from "@fortawesome/free-solid-svg-icons";
import { getUnreadStudies } from "../../slices/irStudy";
import { handleSuccessToastState } from "../../slices/toast";
import SnackBarToast from "../base/SnackbarToast";

interface Props {
  setIrModal: any;
}

const IrMenu: React.FC<Props> = (props) => {
  const { setIrModal } = props;

  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { unreadStudies } = useSelector((store) => store.irStudy);

  const { successToastOpen } = useSelector((store) => store.toast);

  useEffect(() => {
    if (loggedInUser?.roles.find((role) => role.id === 7)) {
      dispatch(getUnreadStudies());
    }
  }, [dispatch, loggedInUser]);

  const closeToast = () => {
    dispatch(handleSuccessToastState(false));
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item>
          <ActionButtons
            label={loggedInUser?.roles.find((role) => role.id === 7) ? "View IR Studies" : "New IR Study"}
            icon={<FontAwesomeIcon icon={faSyringe} size="1x" />}
            badgeNumber={loggedInUser?.roles.find((role) => role.id === 7) ? unreadStudies : undefined}
            onClick={setIrModal}
          />
        </Grid>
      </Grid>

      <SnackBarToast message="IR Study Added" type="success" open={successToastOpen} handleClose={closeToast} />
    </React.Fragment>
  );
};

export default IrMenu;
