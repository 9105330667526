import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import Case from "../../types/case";

const Root = styled.div`
  .container {
    width: 100%;
    height: fit-content;
    background-color: white;
  }

  .center-elements {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .exams-container {
    border: 1px solid dimgray;
  }

  table {
    width: 100%;
  }

  table,
  th,
  td {
    padding: 2.5px;
    border: 1px solid dimgray;
    border-collapse: collapse;
    color: black;
    font-size: 12.5px;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: black;
  }

  h2 {
    margin: 0;
  }

  h4 {
    margin: 2px;
  }
`;

const CaseReport: React.FC<{ model: Case; width: number; image: string | undefined }> = (props) => {
  const {
    firstName,
    lastName,
    hospital,
    patientId,
    completedByUser,
    created,
    examTypes,
    comments,
    completed,
    hospitalDepartment,
  } = props.model;
  return (
    <Root>
      <div id="caseReportId" className="container" style={{ height: props.width * 1.41421 }}>
        <div style={{ marginRight: 30, marginLeft: 30 }}>
          <div className="text-right">
            <img style={{ height: 70, objectFit: "cover", marginTop: 25 }} src={props.image} alt="" />
          </div>
          <div className="text-center" style={{ backgroundColor: "dimgray" }}>
            <h2 style={{ color: "white" }}>Preliminary Report</h2>
          </div>
          <br />
          <div className="text-center">
            <h3 style={{ fontWeight: 300, margin: 0 }}>{hospital?.name || ""}</h3>
            <h4 style={{ fontWeight: 200 }}>
              Contact regarding this report: {completedByUser?.firstName || ""} {completedByUser?.lastName || ""}{" "}
              {completedByUser?.phone &&
                "(" +
                  completedByUser?.phone.substring(1, 4) +
                  ")-" +
                  completedByUser?.phone.substring(4, 7) +
                  "-" +
                  completedByUser?.phone.substring(7, completedByUser?.phone.length)}
            </h4>
            {!!completedByUser?.phone && completedByUser.phone !== "" && (
              <h4 style={{ fontWeight: 200 }}>This listed number is only to be used on the date of this report</h4>
            )}
            <h4 style={{ fontWeight: 200 }}>
              Report Created: <Moment format="MM/DD/YYYY HH:mm">{created}</Moment>
            </h4>
          </div>
          <br />
          <table>
            <tr>
              <td className="text-right">Patient Name:</td>
              <th className="text-left">
                {lastName}, {firstName}
              </th>

              <td className="text-right">Patient ID:</td>
              <th className="text-left">{patientId}</th>

              <td className="text-right">Patient Location:</td>
              <th className="text-left">
                {hospitalDepartment?.name || ""}{" "}
                {hospitalDepartment?.phoneNumber &&
                  "(" +
                    hospitalDepartment?.phoneNumber.substring(1, 4) +
                    ")-" +
                    hospitalDepartment?.phoneNumber.substring(4, 7) +
                    "-" +
                    hospitalDepartment?.phoneNumber.substring(7, hospitalDepartment?.phoneNumber.length)}
              </th>
            </tr>
            <tr>
              <td className="text-right">Submitted:</td>
              <th className="text-left">
                <Moment format="MM/DD/YYYY HH:mm">{created}</Moment>
              </th>

              <td className="text-right">Completed:</td>
              <th className="text-left">{!!completed && <Moment format="MM/DD/YYYY HH:mm">{completed}</Moment>}</th>

              <td className="text-right">Radiologist:</td>
              <th className="text-left">
                {completedByUser?.firstName || ""} {completedByUser?.lastName || ""}
              </th>
            </tr>
          </table>

          <br />

          <div className="exams-container">
            <h3 style={{ margin: 5, fontWeight: 600 }}>
              Exam Type:{" "}
              {examTypes.map((exam, index) =>
                exam.id === examTypes.slice(-1)[0]?.id ? `${exam.value || ""}` : `${exam.value || ""}, `
              )}
            </h3>
          </div>

          <br />

          <h3 style={{ margin: 0 }}>Findings:</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {comments
              ?.filter((c) => c.commentType?.id === 2)
              ?.map((find) => (
                <React.Fragment>
                  <div style={{ marginRight: 10 }}>
                    <h4 style={{ fontWeight: 500, fontSize: 12.5 }}>{find.commentText}</h4>
                    <h4 style={{ fontWeight: 500, fontStyle: "italic", fontSize: 12.5 }}>
                      by {find.user?.firstName || ""} {find.user?.lastName || ""}, on{" "}
                      <Moment format="MM/DD/YYYY HH:mm">{find.created}</Moment>
                    </h4>
                  </div>
                </React.Fragment>
              ))}
          </div>

          <br />

          <h3 style={{ margin: 0 }}>Addendum:</h3>
          {([] as any[])?.map((find) => (
            <React.Fragment>
              <h4 style={{ fontWeight: 500, fontSize: 12.5 }}>{find.commentText}</h4>
              <h4 style={{ fontWeight: 500, fontStyle: "italic", fontSize: 12.5 }}>
                by {find.user?.firstName || ""} {find.user?.lastName || ""}, on{" "}
                <Moment format="MM/DD/YYYY HH:mm">{find.created}</Moment>
              </h4>
            </React.Fragment>
          ))}

          <br />

          {completed && completedByUser && (
            <div style={{ border: "2px solid dimgray", padding: 0 }}>
              <div
                className="text-center"
                style={{ backgroundColor: "darkgray", padding: 0, borderBottom: "2px solid dimgray" }}
              >
                <h4 style={{ margin: 0 }}>
                  Dictated by: {completedByUser?.firstName || ""} {completedByUser?.lastName || ""}
                </h4>
              </div>
              <div>
                <p style={{ margin: 0, fontSize: 12.5 }}>
                  ***This report constitutes a preliminary interpretation only. Non acute findings felt to be unrelated
                  to the clinical presentation may not be discussed in this report. The study will be interpreted and a
                  final report will be generated by the local Radiologist the following shift.
                </p>
                <hr style={{ margin: 0 }} />
                <p style={{ margin: 0, fontSize: 12.5 }}>
                  ***INTERPRETING RADIOLOGIST: If there is a significant discrepancy between this report and your
                  interpretation please fill out and send the RADS, Inc. Discrepancy form.
                </p>
              </div>
            </div>
          )}
          <br />
          <div>
            <h2>Confidential</h2>
            <p style={{ margin: 0, fontSize: 12.5 }}>
              This transmission contains information that is confidential and/or legally privileged. This information is
              intended only for the user of the individual or entity named on this transmission sheet. If you are not
              the intended recipient, you are hereby notified that any disclosure, copying, distribution or the taking
              of any action in reliance on the contents of this telecopy information is strictly prohibited, and that
              the documents should be returned to this Hospital immediately. In this regard, if you have received this
              telecopy in error, please notify us by telephone immediately, so that we can arrange for the return of the
              documents to us.
            </p>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default CaseReport;
