import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

interface Props {
  name: string;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteConfirmation: React.FC<Props> = (props) => {
  return (
    <Box>
      <Grid margin={1}>
        <Typography variant="h6" justifyContent="center" textAlign="center">
          Are you sure you would like to delete {props.name}?
        </Typography>
        <Grid container justifyContent="center" alignItems="center" spacing={2} mt={2}>
          <Grid item xs={6}>
            <Box>
              <Button
                color="secondary"
                fullWidth
                size="large"
                type="button"
                variant="contained"
                onClick={props.handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={props.handleDelete}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeleteConfirmation;
