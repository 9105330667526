import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "../../store";
import CaseColumn from "./CaseColumn";
import AnnouncementCard from "./AnnouncementCard";
import getUserRoleId from "../../helpers/getUserRoleId";
import SharepointCard from "./SharepointCard";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import BreakCard from "./BreakCard";
import theme from "../../theme";
import Modal from "../base/Modal";

interface Props {
  kiosk: boolean;
  collapsed: boolean;
  windowWidth: number;
}

const Cases: React.FC<Props> = (props) => {
  const { kiosk, collapsed, windowWidth } = props;
  const { dismissed } = useSelector((store) => store.news);
  const { loggedInUser, selectedHospital } = useSelector((store) => store.auth);
  const { breakTime } = useSelector((store) => store.userBreaks);

  const {
    pendingCases,
    inProgressCases,
    pendingStatus,
    userProgressStatus,
    teamProgressStatus,
    recentCases,
    blockedCases,
    blockedStatus,
  } = useSelector((store) => store.cases);
  const { unreadAnnouncements } = useSelector((store) => store.announcements);

  const [hospital, setHospital] = useState(loggedInUser?.hospital);
  const [mobileAnnouncementMenu, setMobileAnnouncementMenu] = useState(false);
  const [sharepointMenu, setSharepointMenu] = useState(false);

  const userProgress = inProgressCases?.filter((c) => c.currentUser?.id === loggedInUser?.id);
  const teamProgress = inProgressCases?.filter((c) => c.currentUser?.id !== loggedInUser?.id);
  let hospitalIds = loggedInUser?.hospitals?.map((h) => h.id);
  const pending =
    getUserRoleId(loggedInUser) === 3
      ? pendingCases?.filter((c) => hospitalIds?.includes(c.hospital?.id!))
      : pendingCases;
  const blocked =
    getUserRoleId(loggedInUser) === 3
      ? blockedCases?.filter((c) => hospitalIds?.includes(c.hospital?.id!))
      : blockedCases;

  const techPending = pendingCases?.concat(inProgressCases!);

  const sm = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedHospital) {
      setHospital(selectedHospital);
    } else {
      setHospital(loggedInUser?.hospital);
    }
  }, [selectedHospital, loggedInUser?.hospital, loggedInUser?.hospital?.isOpen]);

  const newAnnouncements = (unreadAnnouncements || []).filter(
    (a) => new Date(a.created!) >= new Date(loggedInUser?.firstSession || "")
  );

  useEffect(() => {
    if (mobileAnnouncementMenu && !newAnnouncements.length) {
      setMobileAnnouncementMenu(false);
    }
  }, [mobileAnnouncementMenu, newAnnouncements]);

  if (!loggedInUser) return <React.Fragment></React.Fragment>;

  const filteredBlockedCases =
    getUserRoleId(loggedInUser) === 3
      ? (blockedCases || []).filter((model) => (model.hospital?.id || 0) === (hospital?.id || 0))
      : blockedCases || [];

  if (getUserRoleId(loggedInUser) === 3)
    return (
      <React.Fragment>
        <Box
          sx={{
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            px: { xs: "5px", md: 2.5 },
            py: 2,
          }}
        >
          <Box
            sx={{
              width: { xs: windowWidth, md: !!collapsed ? windowWidth - 210 : windowWidth - 385 },
              height: "100%",
            }}
          >
            <SimpleBar style={{ height: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: { xs: windowWidth - 10, md: !!collapsed ? windowWidth - 210 : windowWidth - 385 },
                    marginTop: { xs: 10, md: "18px" },
                    display: "flex",
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "100%",
                  }}
                >
                  <Grid container spacing={1} flexDirection="row">
                    <Grid item xs>
                      <CaseColumn
                        kiosk={kiosk}
                        userId={loggedInUser?.id || 0}
                        columnHeader="Pending Cases"
                        caseArrayOne={techPending?.filter((c) => c.hospital?.id === hospital?.id) || []}
                        loadingOne={blockedStatus === "loading"}
                        rowHeaderTwo="Corrective Action Needed"
                        caseArrayTwo={filteredBlockedCases}
                        loadingTwo={pendingStatus === "loading"}
                        rowHeaderThree="Completed Cases"
                        caseArrayThree={recentCases?.filter((c) => c.hospital?.id === hospital?.id) || []}
                        loadingThree={pendingStatus === "loading"}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </SimpleBar>
          </Box>
        </Box>
      </React.Fragment>
    );

  const renderAnnouncements = () => {
    return (
      <>
        {newAnnouncements.map((announcement) => (
          <Box key={announcement.id} sx={{ marginY: "5px" }}>
            <AnnouncementCard
              id={announcement.id!}
              title={announcement.title!}
              body={announcement.body}
              created={announcement.created!}
              createdByUser={announcement.createdByUser!}
            />
          </Box>
        ))}
      </>
    );
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          px: { xs: 0, md: 2.5 },
          py: 2,
        }}
      >
        <Box
          style={{
            position: "sticky",
            zIndex: 1,
            top: 35,
            width: collapsed ? windowWidth - 210 : windowWidth - 385,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
              marginLeft: { xs: "-8px", md: "8px" },
            }}
          >
            {breakTime !== null && loggedInUser.status?.id! > 2 ? (
              <Box sx={{ mr: "10px" }}>
                <BreakCard />
              </Box>
            ) : newAnnouncements.length > 0 ? (
              <React.Fragment>
                {!sm ? (
                  <>{renderAnnouncements()}</>
                ) : (
                  <Card
                    sx={{ border: `3px solid ${theme.palette.secondary.main}`, mr: "10px" }}
                    onClick={() => setMobileAnnouncementMenu(true)}
                  >
                    <Box
                      sx={{
                        height: 128,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography textAlign={"center"}>
                        {newAnnouncements.length} unread announcement(s). Click here to view.
                      </Typography>
                    </Box>
                  </Card>
                )}
              </React.Fragment>
            ) : !dismissed ? (
              <>
                {!sm ? (
                  <React.Fragment>
                    <Box>
                      <SharepointCard />
                    </Box>
                  </React.Fragment>
                ) : (
                  <Card
                    sx={{ border: `3px solid ${theme.palette.secondary.main}`, mr: "10px" }}
                    onClick={() => setSharepointMenu(true)}
                  >
                    <Box
                      sx={{
                        height: 128,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography textAlign={"center"}>Click here to view Sharepoint</Typography>
                    </Box>
                  </Card>
                )}
              </>
            ) : (
              <></>
            )}
            {/* {newAnnouncements.length > 0 ? (
              <React.Fragment>
                {newAnnouncements.map((announcement) => (
                  <Box key={announcement.id} sx={{ marginY: "5px" }}>
                    <AnnouncementCard
                      id={announcement.id!}
                      title={announcement.title!}
                      body={announcement.body}
                      created={announcement.created!}
                      createdByUser={announcement.createdByUser!}
                    />
                  </Box>
                ))}
              </React.Fragment>
            ) : !dismissed ? (
              <React.Fragment>
                <Box>
                  <SharepointCard />
                </Box>
              </React.Fragment>
            ) : (
              <></>
            )} */}
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: windowWidth, md: collapsed ? windowWidth - 210 : windowWidth - 385 },
            height: "100%",
          }}
        >
          <SimpleBar style={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: { xs: windowWidth - 10, md: !!collapsed ? windowWidth - 210 : windowWidth - 385 },
                  marginTop: "4px",
                  display: "flex",
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: "100%",
                }}
              >
                <Grid container spacing={1} flexDirection="row" marginLeft={0}>
                  <Grid item md={6} xs={12}>
                    <CaseColumn
                      userId={loggedInUser?.id || 0}
                      columnHeader="Pending"
                      caseArrayOne={pending || []}
                      loadingOne={pendingStatus === "loading"}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CaseColumn
                      userId={loggedInUser?.id || 0}
                      columnHeader="My Cases"
                      caseArrayOne={userProgress || []}
                      loadingOne={userProgressStatus === "loading"}
                      rowHeaderTwo="Team Cases"
                      caseArrayTwo={teamProgress || []}
                      loadingTwo={teamProgressStatus === "loading"}
                      rowHeaderThree="Corrective Action Needed"
                      caseArrayThree={blocked}
                      loadingThree={blockedStatus === "loading"}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </SimpleBar>
        </Box>
      </Box>
      <Modal
        border={`4px solid ${theme.palette.error.main}`}
        background={theme.palette.background.paper}
        title="Sharepoint"
        open={sharepointMenu}
        handleClose={() => {
          setSharepointMenu(false);
        }}
        showClose
      >
        <Box sx={{ maxHeight: { xs: 350, sm: 600 }, overflow: "scroll" }}>
          <SharepointCard />
        </Box>
      </Modal>
      <Modal
        border={`4px solid ${theme.palette.error.main}`}
        background={theme.palette.background.paper}
        title="Announcements"
        open={mobileAnnouncementMenu}
        handleClose={() => {
          setMobileAnnouncementMenu(false);
        }}
        showClose
      >
        <Box sx={{ maxHeight: 350, overflow: "scroll" }}>{renderAnnouncements()}</Box>
      </Modal>
    </React.Fragment>
  );
};

export default Cases;
