import React from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../forms";
import { useNavigate } from "react-router-dom";
import { changeExpiredPassword, changePassword, check, login } from "../../slices/auth";
import { handleErrorToastState, setErrorMessage } from "../../slices/toast";
import AuthLogo from "../base/AuthLogo";

interface Props {}

const ChangePassword: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, awsUser, passwordExpired } = useSelector((store) => store.auth);

  const initialValues = { password: "", confirmPassword: "" };

  const validationSchema = Yup.object().shape(
    {
      password: Yup.string()
        .min(8)
        .max(128)
        .required("Please enter your new password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number"
        ),
      confirmPassword: Yup.string().when("password", {
        is: (val: string) => val && val.length > 0,
        then: Yup.string()
          .required("Please confirm password")
          .oneOf([Yup.ref("password")], "Passwords must match"),
      }),
    },
    [["password", "confirmPassword"]]
  );

  const handleChangePassword = (password: string) => {
    let newValues = {
      password: password,
      user: awsUser,
      username: awsUser?.username,
    };
    if (passwordExpired) {
      dispatch(login({ username: awsUser?.username!, password: password, rememberMe: false })).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          dispatch(handleErrorToastState(true));
          dispatch(setErrorMessage("New password cannot be the same as old password"));
        } else {
          dispatch(changeExpiredPassword({ username: awsUser?.username!, password: password, rememberMe: false })).then(
            (result) => {
              dispatch(check(true));
              navigate("/app/dashboard");
            }
          );
        }
      });
    } else {
      dispatch(changePassword(newValues)).then((result) => {
        dispatch(check(true)).then((response) => {
          if (response && response.payload) {
            navigate("/app/dashboard");
          }
        });
      });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: { xs: "100%", md: "100vh" },
        }}
      >
        <Container maxWidth="sm" sx={{ py: { md: "80px", xs: "0px" }, pb: { xs: "20px", md: "0px" } }}>
          <AuthLogo />
          <Card sx={{ mt: "15px" }}>
            <CardContent>
              <Box>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    handleChangePassword(values.password);
                  }}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form noValidate style={{ color: "textSecondary" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          {passwordExpired ? (
                            <Typography variant="h6" align="center">
                              Your password has expired and needs to be changed before continuing. Password cannot be
                              the same as your previous password and must be a minimum of 8 characters and contain one
                              uppercase letter, one lowercase letter, and one number.
                            </Typography>
                          ) : (
                            <Typography variant="h6" align="center">
                              Please change your password before continuing. Password must be a minimum of 8 characters
                              and contain one uppercase letter, one lowercase letter, and one number.
                            </Typography>
                          )}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          <Field name="password" required label="New Password" component={Inputs.Password} />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          <Field name="confirmPassword" required label="Confirm Password" component={Inputs.Password} />
                        </Box>
                        <Box
                          sx={{
                            mt: "12px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            <Inputs.Submit text={"Reset"} isSubmitting={status === "loading"} />
                          </Box>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
};

export default ChangePassword;
