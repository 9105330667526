import React from "react";
import StudyTable from "../views/StudyTable";
import { useParams } from "react-router-dom";

interface Props {}

const StudyPage: React.FC<Props> = (props) => {
  const { id } = useParams();
  return (
    <>
      <StudyTable id={+id!} />
    </>
  );
};

export default StudyPage;
