import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Root = styled.div<{ color?: string }>`
  .chip {
    user-select: none;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    background-color: #000;
    padding: 0px 10px;
    width: fit-content;
  }

  & .chip-modality {
    border: 2px solid #fff;
  }

  & .chip-exam {
    background-color: ${(props) => props.color || "#000"};
    color: #000;
  }

  & .chip-hospital {
    text-transform: uppercase;
    background-color: ${(props) => props.color || "#000"};
  }

  & .chip-notification {
    text-transform: uppercase;
    background-color: ${(props) => props.color || "#000"};
  }
`;

interface Props {
  label: string;
  type: "modality" | "exam" | "hospital" | "notification";
  color?: string;
}

const Chip: React.FC<Props> = (props) => {
  const { label, type, color } = props;

  return (
    <Root color={color!}>
      <div className={`chip chip-${type} h5`}>
        <Typography
          sx={{
            marginRight: 0,
            fontSize:
              type === "exam" ? "16px" : type === "notification" ? "15px" : type === "hospital" ? "18px" : "20px",
            fontWeight: 500,
          }}
        >
          {label}
        </Typography>
      </div>
    </Root>
  );
};

export default Chip;
