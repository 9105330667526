import React, { useState } from "react";
import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import * as Yup from "yup";
import ActionButtons from "../base/ActionButtons";
import { InsertEmoticon } from "@mui/icons-material";
import Emojis from "../base/Emojis";
import RadioLink from "../../types/radioLink";

interface Props {
  editLink?: RadioLink;
  onSubmit: (model: RadioLink) => void;
  isSubmitting: boolean;
  handleClose: () => void;
}

const AddEditLink: React.FC<Props> = (props) => {
  const [radioLink, setRadioLink] = useState<RadioLink>(
    props.editLink || {
      id: 0,
      name: "",
      permalink: "",
      visible: true,
    }
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    permalink: Yup.string().required("Required"),
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={radioLink}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          props.onSubmit(values);
        }}
      >
        {() => (
          <Form noValidate>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Field name="name" label="Name" component={Inputs.Text} />
              </Grid>
              <Grid item xs={12}>
                <Field name="permalink" label="Link" component={Inputs.Text} />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ mt: 2 }}>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={props.handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Inputs.Submit text={props.editLink ? "Save" : "Add"} isSubmitting={props.isSubmitting} />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default AddEditLink;
