import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import HospitalCard from "./HospitalCard";
import { useDispatch, useSelector } from "../../store";
import { setTechCollapsed, setUsersCollapsed } from "../../slices/users";
import { groupBy } from "lodash";
import { PushPinRounded } from "@mui/icons-material";
import ActionButtons from "../base/ActionButtons";
import getUserRoleId from "../../helpers/getUserRoleId";
import theme from "../../theme";
import { fetchChatList, fetchUserSchedule } from "../../slices/schedule";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import TechChat from "./TechChat";

interface Props {
  kiosk: boolean;
  windowWidth: number;
}

const StatusPanel: React.FC<Props> = (props) => {
  const { kiosk, windowWidth } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserSchedule());
    dispatch(fetchChatList());
  }, [dispatch]);

  const [panelHeight, setPanelHeight] = React.useState(0);
  const { scheduledBreak, techCollapsed, usersCollapsed } = useSelector((state) => state.users);
  const { loggedInUser, selectedHospital } = useSelector((state) => state.auth);
  const { conversations } = useSelector((state) => state.chat);

  const { scheduledUsers, scheduledTechUsers } = useSelector((state) => state.schedule);
  const [hospital, setHospital] = useState(loggedInUser?.hospital);
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (selectedHospital) {
      setHospital(selectedHospital);
    } else {
      setHospital(loggedInUser?.hospital);
    }
  }, [selectedHospital, loggedInUser?.hospital, loggedInUser?.hospital?.isOpen]);

  const hospitals = groupBy(
    scheduledUsers
      .filter((u) => {
        const defaultFilter = getUserRoleId(u) !== 3;

        if (!!kiosk || getUserRoleId(loggedInUser) === 3) {
          return (
            defaultFilter &&
            (getUserRoleId(u) === 2 || getUserRoleId(u) === 0) &&
            getUserRoleId(u) !== 3 &&
            (u.hospital?.id === hospital?.id || !u.hospital?.hospitalLocation)
          );
        }
        return defaultFilter;
      })
      .sort((u) => {
        if (getUserRoleId(u) === 2) return 1;
        return 0;
      }),
    (user) => {
      return user.hospital?.id || 0;
    }
  );

  const techChatList = groupBy(
    scheduledTechUsers
      .filter((u) => {
        const defaultFilter = getUserRoleId(u) !== 3;

        if (!!kiosk || getUserRoleId(loggedInUser) === 3) {
          return (
            defaultFilter &&
            (getUserRoleId(u) === 2 || getUserRoleId(u) === 0) &&
            getUserRoleId(u) !== 3 &&
            (u.hospital?.id === hospital?.id || !u.hospital?.hospitalLocation)
          );
        }
        return defaultFilter;
      })
      .sort((u) => {
        if (getUserRoleId(u) === 2) return 1;
        return 0;
      }),
    (user) => {
      return user.hospital?.id || 0;
    }
  );

  const hospitalArray = (() => {
    const keysToArray = [...Object.keys(hospitals!).map((h) => hospitals[h])];
    let hArray = [];
    let nonHArray = [];
    let noLocationArray = [];

    for (var h of keysToArray) {
      if (!h[0].hospital?.hospitalLocation && h[0].hospital?.shortName?.length! > 0) {
        nonHArray.push(h);
        continue;
      } else if (!h[0].hospital?.hospitalLocation) {
        noLocationArray.push(h);
        continue;
      } else {
        hArray.push(h);
      }
    }

    nonHArray.sort((a, b) => (a[0].hospital?.shortName! < b[0].hospital?.shortName! ? -1 : 1));
    hArray.sort((a, b) => (a[0].hospital?.order! < b[0].hospital?.order! ? -1 : 1));

    const fullList = [...hArray, ...nonHArray, ...noLocationArray];

    return fullList;
  })();

  const techChatArray = (() => {
    const keysToArray = [...Object.keys(techChatList!).map((h) => techChatList[h])];
    let hArray = [];
    let nonHArray = [];
    let noLocationArray = [];

    for (var h of keysToArray) {
      if (!h[0].hospital?.hospitalLocation && h[0].hospital?.shortName?.length! > 0) {
        nonHArray.push(h);
        continue;
      } else if (!h[0].hospital?.hospitalLocation) {
        noLocationArray.push(h);
        continue;
      } else {
        hArray.push(h);
      }
    }

    nonHArray.sort((a, b) => (a[0].hospital?.shortName! < b[0].hospital?.shortName! ? -1 : 1));
    hArray.sort((a, b) => (a[0].hospital?.order! < b[0].hospital?.order! ? -1 : 1));

    const fullList = [...hArray, ...nonHArray, ...noLocationArray];

    return fullList;
  })();

  useEffect(() => {
    const handlePanelHeight = () =>
      setPanelHeight(
        () =>
          window.innerHeight -
          (getUserRoleId(loggedInUser) === 3
            ? 145
            : usersCollapsed
            ? 226
            : loggedInUser?.status?.id! > 2
            ? 295
            : !!scheduledBreak && !usersCollapsed
            ? 295
            : 310)
      );
    window.addEventListener("resize", handlePanelHeight);
    handlePanelHeight();
    return () => window.removeEventListener("resize", handlePanelHeight);
  }, [loggedInUser, scheduledBreak, usersCollapsed]);

  return (
    <>
      <Box sx={{ marginLeft: "5px", height: { md: panelHeight, xs: usersCollapsed ? "44px" : panelHeight }, pb: 1 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: "15px",
            border: `2px solid ${theme.palette.primary.main}`,
            width: {
              xs: usersCollapsed && getUserRoleId(loggedInUser) !== 3 ? "180px" : windowWidth - 10,
              md: usersCollapsed ? "180px" : "345px",
            },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ justifyContent: "left", display: "flex", flexGrow: 1 }}>
            <ActionButtons
              onClick={() => {
                dispatch(setUsersCollapsed(!usersCollapsed));
              }}
              label="Pin"
              icon={<PushPinRounded fontSize="medium" sx={{ transform: usersCollapsed ? "rotate(-90deg)" : "" }} />}
            />
          </Box>
          {!usersCollapsed ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography variant="h6">Today's Team</Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Typography variant="h6">Team</Typography>
            </Box>
          )}
          <Box sx={{ marginRight: "45px", justifyContent: "center", display: "flex", flexGrow: 1 }}></Box>
        </Box>
        <SimpleBar
          style={{
            maxHeight: sm && usersCollapsed ? "44px" : panelHeight,
            height: "100%",
            width: sm && usersCollapsed ? "0px" : "100%",
          }}
        >
          <Box
            sx={{
              marginTop: "5px",
              display: { xs: usersCollapsed ? "none" : "flex", md: "flex" },
              flexDirection: "column",
              gap: 1,
              overflow: "hidden",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              pb: 1,
              width: { xs: usersCollapsed ? "110px" : windowWidth - 10, md: usersCollapsed ? "180px" : "100%" },
            }}
          >
            {hospitalArray.map((h, index) => {
              return (
                <HospitalCard
                  key={index}
                  conversations={conversations}
                  // techConversations={techConversations}
                  hospital={h[0]?.hospital}
                  users={h}
                  collapsed={usersCollapsed}
                  showHospital={true}
                  loggedInUser={loggedInUser!}
                />
              );
            })}
            {!!loggedInUser?.roles.find((f) => f.id === 2 || f.id === 3) && (
              <TechChat windowWidth={windowWidth} techChatArray={techChatArray} />
            )}
          </Box>
        </SimpleBar>
      </Box>
    </>
  );
};

export default StatusPanel;
