import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import http from "../api/http";
import Option from "../types/option";
import UserBreak from "../types/userBreak";
import BreakDetails from "../types/breakDetails";
import BreakOption from "../types/breakOption";

interface UsersState {
  status: "idle" | "loading" | "failed";
  breakTime?: UserBreak;
  scheduledBreak?: UserBreak;
  breakTypes: Option[];
  breakDetails: BreakDetails;
}

const initialState: UsersState = {
  status: "idle",
  breakTypes: [],
  breakDetails: {
    breakTime: 0,
    options: []
  },
};

export const logBreak = createAsyncThunk<UserBreak, UserBreak>("break/log", async (userBreak: UserBreak) => {
  const response = await http.post(`users/${userBreak.userId}/break`, userBreak);
  return response.data;
});

export const getBreak = createAsyncThunk<UserBreak, number>("break/get", async (userId: number) => {
  const response = await http.get(`users/${userId}/break`);
  return response.data;
});

export const fetchScheduledBreak = createAsyncThunk<UserBreak, number>("break/scheduled", async (userId: number) => {
  const response = await http.get(`users/${userId}/scheduled-break`);
  return response.data;
});

export const fetchBreakOptions = createAsyncThunk<Option[]>("break/options", async () => {
  const response = await http.get(`breaks`);
  return response.data;
});

export const fetchBreakDetails = createAsyncThunk<BreakDetails, number>("break/details", async (typeId: number) => {
  const response = await http.get(`breaks/${typeId}`);
  return response.data;
});

export const updateBreakDetails = createAsyncThunk<BreakDetails, { typeId: number; details: BreakOption[]}>(
  "break/details-update",
  async ({ typeId, details }) => {
    const response = await http.put(`breaks/${typeId}`, details);
    return response.data;
  }
);

const userBreakSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateDetailList(state, action: PayloadAction<BreakOption[]>) {
      state.breakDetails.options = action.payload;
    },
    // updateBreakTime(state, action){
    //   state.breakDetails.breakTime = action.payload
    // },
    clearBreakOptions(state) {
      state.breakDetails.options = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logBreak.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logBreak.fulfilled, (state, action) => {
        state.breakTime = action.payload;
        state.status = "idle";
      })
      .addCase(logBreak.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getBreak.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBreak.fulfilled, (state, action) => {
        state.breakTime = action.payload;
        state.status = "idle";
      })
      .addCase(getBreak.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchScheduledBreak.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchScheduledBreak.fulfilled, (state, action) => {
        state.scheduledBreak = action.payload;
        state.status = "idle";
      })
      .addCase(fetchScheduledBreak.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchBreakOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBreakOptions.fulfilled, (state, action) => {
        state.breakTypes = action.payload;
        state.status = "idle";
      })
      .addCase(fetchBreakOptions.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchBreakDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBreakDetails.fulfilled, (state, action) => {
        state.breakDetails = action.payload;
        state.status = "idle";
      })
      .addCase(fetchBreakDetails.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateBreakDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateBreakDetails.fulfilled, (state, action) => {
        state.breakDetails = action.payload;
        state.status = "idle";
      })
      .addCase(updateBreakDetails.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { updateDetailList, clearBreakOptions } = userBreakSlice.actions;
export default userBreakSlice.reducer;
