import React, { useState } from "react";
import { Box, Fab, Tooltip, Typography } from "@mui/material";
import ActionButtons from "../base/ActionButtons";
import {
  FreeBreakfastRounded,
  GroupsRounded,
  CallRounded,
  MedicalServicesRounded,
  AssignmentRounded,
  TimerRounded,
  ReplayRounded,
  Visibility,
  Description,
} from "@mui/icons-material";
import { changeState } from "../../slices/auth";
import User from "../../types/user";
import { logBreak, setUsersCollapsed, toggleWatching } from "../../slices/users";
import moment from "moment";
import theme from "../../theme";
import { useDispatch, useSelector } from "../../store";
import Modal from "../base/Modal";
import UserBreakMenu from "./UserBreakMenu";
import { fetchBreakDetails } from "../../slices/userBreak";
import HospitalSummary from "../../types/hospitalSummary";

interface Props {
  user: User;
}

enum Action {
  Return,
  Break,
  Meeting,
  Call,
  Procedure,
  Consult,
  Flex,
  Admin,
  Watch,
}

const UserActionMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { breakTime, scheduledBreak, temporaryOpen, usersCollapsed } = useSelector((store) => store.users);
  const { breakDetails } = useSelector((store) => store.userBreaks);

  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState<Action>();

  const handleOpen = (openAction: Action) => {
    setAction(openAction);
    if (openAction === Action.Return || openAction === Action.Watch) {
      handleSave({ details: breakTime?.details!, hospital: breakTime?.hospital! }, openAction);
    } else {
      dispatch(fetchBreakDetails(openAction));
      setModalOpen(true);
    }
  };

  const handleSave = (
    values?: { details?: string; hospital?: HospitalSummary; time?: number },
    openAction?: Action
  ) => {
    switch (openAction ?? action) {
      case Action.Return:
        dispatch(changeState({ id: 1, value: "Online" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            returnTime: moment().toDate(),
            watching: false,
            returned: true,
          })
        );
        if (temporaryOpen) {
          dispatch(setUsersCollapsed(true));
        }
        break;
      case Action.Break:
        dispatch(changeState({ id: 3, value: "Break" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            breakType: { id: 1, value: "" },
            returnTime: moment()
              .add(values?.time && values?.time > 0 ? values.time : breakDetails.breakTime, "minutes")
              .toDate(),
            watching: false,
            details: values?.details,
            hospital: values?.hospital,
          })
        );
        break;
      case Action.Meeting:
        dispatch(changeState({ id: 4, value: "Meeting" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            breakType: { id: 2, value: "" },
            returnTime: moment()
              .add(values?.time && values?.time > 0 ? values.time : breakDetails.breakTime, "minutes")
              .toDate(),
            watching: false,
            details: values?.details,
            hospital: values?.hospital,
          })
        );
        break;
      case Action.Call:
        dispatch(changeState({ id: 5, value: "Call" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            breakType: { id: 3, value: "" },
            returnTime: moment()
              .add(values?.time && values?.time > 0 ? values.time : breakDetails.breakTime, "minutes")
              .toDate(),
            watching: false,
            details: values?.details,
            hospital: values?.hospital,
          })
        );
        break;
      case Action.Procedure:
        dispatch(changeState({ id: 6, value: "Procedure" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            breakType: { id: 4, value: "" },
            returnTime: moment()
              .add(values?.time && values?.time > 0 ? values.time : breakDetails.breakTime, "minutes")
              .toDate(),
            watching: false,
            details: values?.details,
            hospital: values?.hospital,
          })
        );
        break;
      case Action.Consult:
        dispatch(changeState({ id: 7, value: "Consult" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            breakType: { id: 5, value: "" },
            returnTime: moment()
              .add(values?.time && values?.time > 0 ? values.time : breakDetails.breakTime, "minutes")
              .toDate(),
            watching: false,
            details: values?.details,
            hospital: values?.hospital,
          })
        );
        break;
      case Action.Flex:
        dispatch(changeState({ id: 8, value: "Flex" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            breakType: { id: 6, value: "" },
            returnTime: moment()
              .add(values?.time && values?.time > 0 ? values.time : breakDetails.breakTime, "minutes")
              .toDate(),
            watching: false,
            details: values?.details,
            hospital: values?.hospital,
          })
        );
        break;
      case Action.Admin:
        dispatch(changeState({ id: 9, value: "Admin and IT" }));
        dispatch(
          logBreak({
            ...breakTime,
            userId: props.user?.id,
            breakType: { id: 7, value: "" },
            returnTime: moment()
              .add(values?.time && values?.time > 0 ? values.time : breakDetails.breakTime, "minutes")
              .toDate(),
            watching: false,
            details: values?.details,
            hospital: values?.hospital,
          })
        );
        break;
      case Action.Watch:
        dispatch(toggleWatching(props.user?.id));
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{ paddingTop: loggedInUser?.status?.id! > 2 ? "5px" : !!scheduledBreak && !usersCollapsed ? "10px" : "25px" }}
      display="flex"
      alignItems="center"
    >
      <Modal open={modalOpen} handleClose={() => setModalOpen(!modalOpen)} title={Action[action!] || "Break Details"}>
        <Box sx={{ display: "flex", flexDirection: "row", columnGap: "4px" }}>
          <Box
            sx={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              flexGrow: 1,
            }}
          >
            <UserBreakMenu
              user={props.user}
              save={handleSave}
              isModal={true}
              handleClose={() => setModalOpen(!modalOpen)}
            />
          </Box>
        </Box>
      </Modal>
      {loggedInUser?.status?.id! > 2 && (
        <Fab
          sx={{
            backgroundColor: breakTime && breakTime.watching ? theme.palette.success.dark : theme.palette.error.main,
            color: "white",
          }}
          size="small"
          onClick={() => {
            handleOpen(Action.Watch);
          }}
        >
          <Tooltip title={<Typography variant="button">Watching</Typography>}>
            <Visibility sx={{ fontSize: 20 }} />
          </Tooltip>
        </Fab>
      )}
      <ActionButtons
        label="On Break"
        icon={<FreeBreakfastRounded sx={{ fontSize: loggedInUser?.status?.id! > 2 ? 20 : 25 }} />}
        onClick={() => {
          handleOpen(Action.Break);
        }}
        color="error"
      />
      <ActionButtons
        label="In a Meeting"
        icon={<GroupsRounded sx={{ fontSize: loggedInUser?.status?.id! > 2 ? 20 : 25 }} />}
        badgeNumber={!!scheduledBreak && props.user?.status?.id! < 3 ? 1 : undefined}
        onClick={() => {
          handleOpen(Action.Meeting);
        }}
        color="secondary"
      />
      <ActionButtons
        label="On a Call"
        icon={<CallRounded sx={{ fontSize: loggedInUser?.status?.id! > 2 ? 20 : 25 }} />}
        onClick={() => {
          handleOpen(Action.Call);
        }}
        color="warning"
      />
      <ActionButtons
        label="Procedure"
        icon={<MedicalServicesRounded sx={{ fontSize: loggedInUser?.status?.id! > 2 ? 20 : 25 }} />}
        onClick={() => {
          handleOpen(Action.Procedure);
        }}
        color="quarternary"
      />
      <ActionButtons
        label="Consult/Consent"
        icon={<AssignmentRounded sx={{ fontSize: loggedInUser?.status?.id! > 2 ? 20 : 25 }} />}
        onClick={() => {
          handleOpen(Action.Consult);
        }}
        color="info"
      />
      <ActionButtons
        label="Flex Time"
        icon={<TimerRounded sx={{ fontSize: loggedInUser?.status?.id! > 2 ? 20 : 25 }} />}
        onClick={() => {
          handleOpen(Action.Flex);
        }}
        color="tertiary"
      />
      <ActionButtons
        label="Admin and IT"
        icon={<Description sx={{ fontSize: loggedInUser?.status?.id! > 2 ? 20 : 25 }} />}
        onClick={() => {
          handleOpen(Action.Admin);
        }}
        color="primary"
      />
      {loggedInUser?.status?.id! > 2 && (
        <Fab
          sx={{ backgroundColor: theme.palette.success.main, color: "white" }}
          size="small"
          onClick={() => {
            handleOpen(Action.Return);
          }}
        >
          <Tooltip title={<Typography variant="button">Return</Typography>}>
            <ReplayRounded sx={{ fontSize: 20 }} />
          </Tooltip>
        </Fab>
      )}
    </Box>
  );
};

export default UserActionMenu;
