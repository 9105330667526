import React, { useEffect } from "react";
import { Box } from "@mui/material";
import AnnouncementCard from "../views/AnnouncementCard";
import { useDispatch, useSelector } from "../../store";
import { fetchAnnouncements } from "../../slices/announcement";

const AnnouncementHistory: React.FC = () => {
  const dispatch = useDispatch();
  const { announcements } = useSelector((store) => store.announcements);

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Box sx={{ margin: 3, pt: 1, flexDirection: "column-reverse" }}>
        {(announcements || []).map((announcement: any) => (
          <Box key={announcement.id} sx={{ marginY: "20px" }}>
            <AnnouncementCard
              history
              id={announcement.id}
              key={announcement.id}
              title={announcement.title}
              body={announcement.body}
              created={announcement.created}
              createdByUser={announcement.createdByUser}
            />
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default AnnouncementHistory;
