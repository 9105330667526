import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import { getStatusOptions } from "../../slices/irStudy";
import * as Yup from "yup";
import Option from "../../types/option";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";

interface Props {
  status: Option;
  handleClose: () => void;
  handleSubmit: (value: Option) => void;
}

const ChangeStudyStatus: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { statuses, status } = useSelector((store) => store.irStudy);

  useEffect(() => {
    dispatch(getStatusOptions());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    status: Yup.object().nullable().required("Please select a new status"),
  });

  const initialValue = {
    status: props.status,
  };

  const handleSubmit = (value: Option) => {
    props.handleSubmit(value);
  };

  return (
    <Box>
      <Grid margin={1}>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values.status)}
        >
          <Form noValidate>
            <Field name="status" label="Status" required component={Inputs.Dropdown} options={statuses} />
            <Grid container justifyContent="center" alignItems="center" spacing={2} mt={2}>
              <Grid item xs={6}>
                <Box mt={2}>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={props.handleClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Inputs.Submit text={"Save"} isSubmitting={status === "loading"} />
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Grid>
    </Box>
  );
};

export default ChangeStudyStatus;
