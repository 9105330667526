import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  Grid,
  Paper,
  Divider,
  InputAdornment,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import { createComment, fetchQaCases, updateQaCase } from "../../slices/qa";
import { Download, Edit, Message, Search } from "@mui/icons-material";
import Option from "../../types/option";
import AddEditQa from "./AddEditQa";
import AddComment from "./AddComment";
import Modal from "../base/Modal";
import Case from "../../types/case";
import Comment from "../../types/comment";
import QACaseReport from "../base/QACaseReport";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import theme from "../../theme";
import { getReport } from "../../slices/report";
import Report from "../../types/report";
import { fetchQaUsers } from "../../slices/users";
import StyledTable from "../base/StyledTable";

type FilterOptions = "All" | "Challenged" | "Resolved" | "Rejected" | "Submitted";

interface FilterForm {
  name: string;
  filter: Option<FilterOptions>;
}

const filterOptions: Option<FilterOptions>[] = [
  {
    id: 1,
    value: "All",
  },
  {
    id: 4,
    value: "Submitted",
  },
  {
    id: 5,
    value: "Rejected",
  },
  {
    id: 6,
    value: "Challenged",
  },
  {
    id: 7,
    value: "Resolved",
  },
];

const CompletedCases: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { qaCases } = useSelector((store) => store.qa);
  const { settings } = useSelector((store) => store.settings);
  const { reportStatus } = useSelector((store) => store.reports);
  const { newCase } = useSelector((store) => store.cases);
  const [values] = useState<Report>();
  const [reportModal, setReportModal] = React.useState({
    caseId: 0,
    visible: false,
  });
  const reference = React.useRef<any>(null);
  const [editModal, setEditModal] = React.useState(false);
  const [commentModal, setCommentModal] = React.useState(false);
  const [downloadReportModel, setDownloadReportModel] = React.useState<Case>(newCase);
  const [filterForm, setFilterForm] = React.useState<FilterForm>({
    name: "",
    filter: {
      id: 1,
      value: "All",
    },
  });

  const md = useMediaQuery(theme.breakpoints.down("md"));

  const filteredQaCases = (qaCases || []).filter((c) => {
    if (filterForm.name !== "") {
      const { firstName, lastName } = c;
      return filterForm.filter.id !== 1
        ? `${firstName.toLowerCase()}${lastName.toLowerCase()}`.includes(filterForm.name.toLowerCase()) &&
            c.status.id === filterForm.filter.id
        : `${firstName.toLowerCase()}${lastName.toLowerCase()}`.includes(filterForm.name.toLowerCase());
    }
    if (filterForm.filter.id === 1) return true;
    return c.status.id === filterForm.filter.id;
  });

  const handleRefresh = useCallback(() => {
    dispatch(fetchQaCases());
  }, [dispatch]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const handleOpenEditModal = (caseId: number) => {
    dispatch(fetchQaUsers());
    reference.current = { ...qaCases.find((c) => c.id === caseId) };
    setEditModal(true);
  };

  const handleOpenCommentModal = (caseId: number) => {
    reference.current = { ...qaCases.find((c) => c.id === caseId) };
    setCommentModal(true);
  };

  const handleComment = async (t: any, comment: Comment) => {
    await dispatch(createComment(comment));
    handleRefresh();
  };

  const handleEdit = async (model: Case) => {
    await dispatch(updateQaCase(model));
    handleRefresh();
  };

  const handleClose = () => {
    reference.current = null;
    setEditModal(false);
    setCommentModal(false);
  };

  const handleDownload = async (model: Case) => {
    try {
      const doc = new jsPDF();

      const foundElement = document.getElementById("caseReportId") || document.createElement("div");
      const documentCanvas = await html2canvas(foundElement, { allowTaint: true, useCORS: true });

      doc.addImage(documentCanvas, "PNG", 0, 0, doc.internal.pageSize.getWidth(), documentCanvas.offsetHeight / 4);

      doc.save(
        `Discrepancy Report for ${model.firstName || ""} ${model.lastName || ""}, ${
          model.modality?.name || ""
        } - ${new Date().toDateString()}`
      );
    } catch (e) {
      // console.log(e);
    }
  };

  const handleOpenReportModal = (id: number) =>
    setReportModal({
      caseId: id,
      visible: true,
    });
  const handleCloseReportModal = () =>
    setReportModal({
      caseId: 0,
      visible: false,
    });

  const handleDownloadReport = (values: Report) => {
    dispatch(getReport({ reportUrl: `reports/qa`, model: { ...values, statusId: filterForm.filter.id } }));
  };

  const headers = ["ID", "Case", "Opened By", "Assigned To", "State", "Created", "Actions", ""];

  const rows =
    filteredQaCases.map((c) => {
      return {
        id: <Typography>{c.id}</Typography>,
        case: (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>
              {c.lastName}, {c.firstName}
            </Typography>
            <Typography variant="overline">Patient ID: {c.patientId}</Typography>
            {c.comments?.length! > 0 && <Typography variant="overline">{c.comments?.length} Comments</Typography>}
          </Box>
        ),
        openedBy: (
          <Typography>
            {c.openedByUser?.lastName}, {c.openedByUser?.firstName}
          </Typography>
        ),
        assignedTo: (
          <Typography>
            {c.assignedToUser?.lastName}, {c.assignedToUser?.firstName}
          </Typography>
        ),
        state: <Typography>{c.status.value}</Typography>,
        created: <Typography>{new Date(c.created || "").toDateString()}</Typography>,
        actions: (
          <>
            <IconButton onClick={() => handleOpenEditModal(c.id!)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleOpenCommentModal(c.id!)} sx={{ marginLeft: 1 }}>
              <Message />
            </IconButton>
          </>
        ),
        report: (
          <>
            {!md && (
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "white" }}
                startIcon={<Download />}
                onClick={() => {
                  handleOpenReportModal(c.id!);
                  setDownloadReportModel(c);
                }}
              >
                Report
              </Button>
            )}
          </>
        ),
      };
    }) || [];

  return (
    <React.Fragment>
      <Modal open={editModal || commentModal} handleClose={handleClose} title="Edit QA Case">
        <React.Fragment>
          {!!editModal && (
            <AddEditQa
              case={reference.current}
              caseId={reference.current?.id}
              onClose={handleClose}
              onSave={handleEdit}
            />
          )}
          {!!commentModal && (
            <AddComment
              caseId={reference.current?.id}
              onSave={handleComment}
              description="Add a comment to this case"
              onClose={handleClose}
              title=""
              type="comment"
            />
          )}
        </React.Fragment>
      </Modal>
      <Box>
        <Paper>
          <Grid container padding="15px">
            <Grid item xs={11} padding={1}>
              <Typography variant={"h6"}>Qa Cases</Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={3} alignItems="center" py={3} px={3}>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search Cases"
                variant="outlined"
                value={filterForm.name}
                onChange={(e) =>
                  setFilterForm((prevState) => {
                    return { ...prevState, name: e.target.value };
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Autocomplete
                disableClearable
                fullWidth
                disablePortal
                // @ts-ignore
                value={{ label: filterForm.filter.value, value: { ...filterForm.filter } }}
                onChange={(label, value) => {
                  if (!value)
                    return setFilterForm((prevState) => {
                      return {
                        ...prevState,
                        filter: {
                          id: 1,
                          value: "All",
                        },
                      };
                    });
                  setFilterForm((prevState) => {
                    return {
                      ...prevState,
                      filter: {
                        // @ts-ignore
                        id: value.id,
                        // @ts-ignore
                        value: value.label,
                      },
                    };
                  });
                }}
                options={filterOptions.map((filter) => {
                  return { id: filter.id, label: filter.value };
                })}
                renderInput={(params) => <TextField label="Filter By State" {...params} />}
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                color="info"
                variant="contained"
                onClick={() => {
                  handleDownloadReport(values!);
                }}
                startIcon={
                  reportStatus === "loading" ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Download fontSize="small" />
                  )
                }
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                }}
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <Box>
            <StyledTable headers={headers} rows={rows} useObjects />
          </Box>
        </Paper>
      </Box>
      <Modal
        open={reportModal.visible && reportModal.caseId === downloadReportModel?.id}
        handleClose={handleCloseReportModal}
        title="Case Report"
        size={800}
      >
        <React.Fragment>
          <Box sx={{ borderRadius: 2, overflow: "hidden" }}>
            <QACaseReport
              image={`data:image/png;base64,${settings?.siteLogos.reportLogoFile}`}
              width={800 - 30}
              model={downloadReportModel!}
            />
          </Box>
          <br />
          <Grid container spacing={3}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => handleDownload(downloadReportModel!)}>
                Download
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={handleCloseReportModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    </React.Fragment>
  );
};

export default CompletedCases;
