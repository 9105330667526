import theme from "../theme";

const getColorByStatus = (statusId: number): string => {
  switch (statusId) {
    case 1: {
      return theme.palette.success.main; // Online / Green
    }
    case 2: {
      return theme.palette.text.secondary; // Offline / Gray
    }
    case 3: {
      return theme.palette.error.main; // Break / Red
    }
    case 4: {
      return theme.palette.secondary.main; // Meeting / Orange
    }
    case 5: {
      return theme.palette.warning.main; // Call / Yellow
    }
    case 6: {
      return theme.palette.quarternary.main; // Procedure / Blue
    }
    case 7: {
      return theme.palette.info.main; // Consult / Purple
    }
    case 8: {
      return theme.palette.tertiary.main; // Flex / Pink
    }
    case 9: {
      return theme.palette.primary.main; // Admin and IT / Aqua
    }
    default: {
      return theme.palette.text.secondary; // Light Gray
    }
  }
};

export default getColorByStatus;
