import React, { useRef } from "react";
import Picker from "emoji-picker-react";
// import styled from "styled-components";

interface Props {
  onClick: (emojiObject: any) => void;
  emojiOpen: boolean;
  setEmojiOpen: React.Dispatch<React.SetStateAction<boolean>>;
  announcement: boolean;
}

const Emojis: React.FC<Props> = (props) => {
  const picker = useRef<HTMLDivElement>(null);

  const dismissPicker = (event: any, emojiObject: any) => {
    props.setEmojiOpen(false);
    props.onClick(emojiObject);
  };

  // const Dialog = styled.div`
  //   position: fixed;
  //   bottom: 210px;
  //   right: ${props.announcement ? "70px" : "325px"};
  //   zindex: 5000 !important;
  // `;

  return (
    <div ref={picker}>
      {/* <Dialog> */}
      {props.emojiOpen && <Picker native={true} onEmojiClick={dismissPicker} disableAutoFocus={true} />}
      {/* </Dialog> */}
    </div>
  );
};

export default Emojis;
