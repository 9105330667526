import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import TeamDashboard from "../views/TeamDashboard";

interface OwnProps {}

type Props = OwnProps;

const Dashboard: React.FC<Props> = () => {
  return (
    <Box py={3} sx={{ p: "20px" }}>
      <Box>
        <Typography color="textPrimary" variant="h5">
          Team Dashboard
        </Typography>
      </Box>
      <Divider />
      <br />

      <TeamDashboard />
    </Box>
  );
};

export default Dashboard;
