import React from "react";
import { Button } from "@mui/material";

interface FormikSubmitButtonProps {
  icon: React.ReactElement;
}

const FormikIconSubmit: React.FC<FormikSubmitButtonProps> = (props) => {
  const { icon } = props;

  return (
    <Button color="inherit" fullWidth size="large" type="submit" sx={{ mt: 2 }}>
      {icon}
    </Button>
  );
};

export default FormikIconSubmit;
