import QGenda from "../types/qGenda";
import CurrentSchedule from "../types/currentSchedule";
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import User from "../types/user";
import Schedule from "../types/schedule";
import HospitalSummary from "../types/hospitalSummary";

interface ScheduleState {
  welcomeModalOpened: boolean;
  schedule: QGenda[] | null;
  currentSchedule: CurrentSchedule[] | null;
  latestSchedule: Schedule[];
  claimedSchedules?: Schedule[];
  status: "idle" | "loading" | "failed";
  lastCalled: Date;
  scheduledUsers: User[];
  scheduledTechUsers: User[];
  userHospital?: HospitalSummary;
  userScheduleStatus: "idle" | "loading" | "failed";
  syncStatus: "idle" | "loading" | "failed";
}

const initialState: ScheduleState = {
  welcomeModalOpened: false,
  schedule: null,
  currentSchedule: null,
  status: "idle",
  lastCalled: new Date(),
  scheduledUsers: [],
  scheduledTechUsers: [],
  latestSchedule: [],
  userScheduleStatus: "idle",
  syncStatus: "idle",
};

// Returns list of users with included schedules for the scheduled users list
export const fetchUserSchedule = createAsyncThunk<User[]>("scheduled-users/fetch", async () => {
  const response = await http.get<User[]>(`/schedule`);
  return response.data;
});

export const fetchChatList = createAsyncThunk<User[]>("tech-users/fetch", async () => {
  const response = await http.get<User[]>(`/schedule/tech`);
  return response.data;
});

// Returns the calendar for the QGenda page
export const fetchSchedule = createAsyncThunk<QGenda[]>("schedule/fetch", async () => {
  const response = await http.get("qgenda");
  return response.data;
});

// Called when app starts to see if the logged in user is on schedule
export const fetchLatestSchedule = createAsyncThunk<Schedule[]>("schedule-latest/fetch", async () => {
  const response = await http.get("schedule/current");
  return response.data;
});

export const claimSchedule = createAsyncThunk<Schedule[], number>("schedule/claim", async (id: number) => {
  const response = await http.put(`schedule/${id}`);
  return response.data;
});

export const syncSchedule = createAsyncThunk("schedule/sync", async () => {
  await http.get("tasks/schedule");
});

export const fetchClaimedSchedules = createAsyncThunk<Schedule[]>("schedule/claimed", async () => {
  const response = await http.get("schedule/claimed");
  return response.data;
});

export const removeClaimedSchedule = createAsyncThunk<null, number>("schedule/remove-claim", async (id: number) => {
  const response = await http.patch(`schedule/claimed/${id}`);
  return response.data;
});

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    setWelcomeModalOpened: (state, action) => {
      state.welcomeModalOpened = action.payload;
    },
    setUserHospital: (state, action) => {
      state.userHospital = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedule.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        state.status = "idle";
        state.schedule = action.payload;
        state.lastCalled = new Date();
      })
      .addCase(fetchSchedule.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchUserSchedule.pending, (state) => {
        state.userScheduleStatus = "loading";
      })
      .addCase(fetchUserSchedule.fulfilled, (state, action) => {
        state.userScheduleStatus = "idle";
        state.scheduledUsers = action.payload;
      })
      .addCase(fetchUserSchedule.rejected, (state) => {
        state.userScheduleStatus = "failed";
      })

      .addCase(fetchChatList.pending, (state) => {
        state.userScheduleStatus = "loading";
      })
      .addCase(fetchChatList.fulfilled, (state, action) => {
        state.userScheduleStatus = "idle";
        state.scheduledTechUsers = action.payload;
      })
      .addCase(fetchChatList.rejected, (state) => {
        state.userScheduleStatus = "failed";
      })

      .addCase(fetchLatestSchedule.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLatestSchedule.fulfilled, (state, action) => {
        state.status = "idle";
        state.latestSchedule = action.payload;
      })
      .addCase(fetchLatestSchedule.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(syncSchedule.pending, (state) => {
        state.syncStatus = "loading";
      })
      .addCase(syncSchedule.fulfilled, (state, action) => {
        state.syncStatus = "idle";
      })
      .addCase(syncSchedule.rejected, (state) => {
        state.syncStatus = "failed";
      })
      .addCase(fetchClaimedSchedules.pending, (state) => {
        state.syncStatus = "loading";
      })
      .addCase(fetchClaimedSchedules.fulfilled, (state, action) => {
        state.claimedSchedules = action.payload;
        state.syncStatus = "idle";
      })
      .addCase(fetchClaimedSchedules.rejected, (state) => {
        state.syncStatus = "failed";
      })
      .addCase(removeClaimedSchedule.pending, (state) => {
        state.syncStatus = "loading";
      })
      .addCase(removeClaimedSchedule.fulfilled, (state, action) => {
        state.syncStatus = "idle";
      })
      .addCase(removeClaimedSchedule.rejected, (state) => {
        state.syncStatus = "failed";
      });
  },
});

export default scheduleSlice.reducer;
export const { setWelcomeModalOpened, setUserHospital } = scheduleSlice.actions;
