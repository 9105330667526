import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Howl } from "howler";
import http from "../api/http";
import Conversation from "../types/conversation";
import Message from "../types/message";
import UserSimple from "../types/userSimple";

interface TeamChatsState {
  teamChats: UserSimple[] | null;
  status: "idle" | "loading" | "failed";
  currentUnreadTeam: number;
  currentTeamMessage?: Message;
  currentTeamConversation: Message[];
  teamConversations: Conversation[];
  teamChatOpen: boolean;
  pinnedTeamChat: boolean;
  teamChatVolume: number;
  teamChatSound: string;
}

const initialState: TeamChatsState = {
  teamChats: null,
  status: "idle",
  currentUnreadTeam: 0,
  currentTeamMessage: undefined,
  currentTeamConversation: [],
  teamConversations: [],
  teamChatOpen: false,
  pinnedTeamChat: false,
  teamChatVolume: 1,
  teamChatSound: "",
};

export const fetchTeamChat = createAsyncThunk<any>("team-chats/fetch", async () => {
  const response = await http.get<UserSimple>("/teams");
  return response.data;
});

export const addTeamUser = createAsyncThunk<UserSimple, number>("team-chats/add", async (model: number) => {
  const response = await http.put<UserSimple>("/teams/add", [model]);
  return response.data;
});

export const removeTeamUser = createAsyncThunk<any, number>("team-chats/remove", async (model: number) => {
  const response = await http.put<UserSimple>("/teams/remove", [model]);
  return response.data;
});

export const sendTeamChat = createAsyncThunk<Message, Message>(
  `team-chats/send`,
  async (chat: Message, { dispatch }) => {
    const response = await http.post<Message>(`chats`, chat);
    return response.data;
  }
);

export const getTeamMessages = createAsyncThunk<Message[]>(`team-chats/get-messages`, async () => {
  const response = await http.get<Message[]>(`chats/messages/0`);
  return response.data;
});

export const readTeamMessage = createAsyncThunk<null, number>(`team-chats/read`, async (id: number, { dispatch }) => {
  const response = await http.patch(`chats/${id}/read`);
  dispatch(getTeamConversations());
  return response.data;
});

export const getTeamConversations = createAsyncThunk<Conversation[]>(`team-chats/get-conversations`, async () => {
  const response = await http.get<Conversation[]>(`chats/conversations`);
  const result = response.data.filter((c: Conversation) => {
    return c.id === 0;
  });

  return result;
});

export const getTeamChatSoundEffect = createAsyncThunk<string>("settings/team-chat-sound-get", async () => {
  const response = await http.get("settings/team-sound");
  return response.data;
});

const teamSlice = createSlice({
  name: "teamchats",
  initialState,
  reducers: {
    toggleTeamChat(state, action) {
      state.teamChatOpen = action.payload;
    },
    clearTeamConversation(state) {
      state.currentTeamConversation = initialState.currentTeamConversation;
      state.teamChats = initialState.teamChats;
    },
    setPinnedTeamChat(state, action) {
      state.pinnedTeamChat = action.payload;
    },
    playTeamChatSound(state) {
      var teamChatSound = new Howl({
        src: ["data:audio/mp3;base64," + state.teamChatSound],
        volume: state.teamChatVolume,
      });
      teamChatSound.play();
    },
    updateTeamChatVolume(state, action) {
      state.teamChatVolume = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Team Chats
      .addCase(fetchTeamChat.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeamChat.fulfilled, (state, action) => {
        state.status = "idle";
        state.teamChats = action.payload;
      })
      .addCase(fetchTeamChat.rejected, (state) => {
        state.status = "failed";
      })

      // Add User
      .addCase(addTeamUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addTeamUser.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(addTeamUser.rejected, (state) => {
        state.status = "failed";
      })

      // Remove User
      .addCase(removeTeamUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removeTeamUser.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(removeTeamUser.rejected, (state) => {
        state.status = "failed";
      })

      // Send Chat
      .addCase(sendTeamChat.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendTeamChat.fulfilled, (state, action) => {
        state.currentTeamMessage = action.payload;
        let chat = state.currentTeamConversation;
        chat?.push(action.payload);
        state.currentTeamConversation = chat;
        state.status = "idle";
      })
      .addCase(sendTeamChat.rejected, (state) => {
        state.status = "failed";
      })

      // Get Messages
      .addCase(getTeamMessages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamMessages.fulfilled, (state, action) => {
        state.status = "idle";
        state.currentTeamConversation = action.payload;
      })
      .addCase(getTeamMessages.rejected, (state) => {
        state.status = "failed";
      })

      // Read Message
      .addCase(readTeamMessage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(readTeamMessage.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(readTeamMessage.rejected, (state) => {
        state.status = "failed";
      })

      // Get Conversations
      .addCase(getTeamConversations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamConversations.fulfilled, (state, action) => {
        state.status = "idle";
        state.teamConversations = action.payload;
        let newUnread = action.payload.reduce((a, b) => a + b.unread!, 0);
        state.currentUnreadTeam = newUnread;
      })
      .addCase(getTeamConversations.rejected, (state) => {
        state.status = "failed";
      })

      // Get Sound Effect
      .addCase(getTeamChatSoundEffect.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTeamChatSoundEffect.fulfilled, (state, action) => {
        state.status = "idle";
        state.teamChatSound = action.payload;
      })
      .addCase(getTeamChatSoundEffect.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default teamSlice.reducer;
export const { clearTeamConversation, toggleTeamChat, setPinnedTeamChat, playTeamChatSound, updateTeamChatVolume } =
  teamSlice.actions;
