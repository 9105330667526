import React from "react";
import LoginView from "../views/LoginView";

interface Props {}

const Login: React.FC<Props> = (props) => {
  return (
    <>
      <LoginView />
    </>
  );
};

export default Login;
