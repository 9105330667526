import { Box, Button, Divider, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../theme";
import { Add, Delete, EditRounded, Visibility } from "@mui/icons-material";
import {
  deleteIrStudy,
  getIrStudies,
  getIrStudyId,
  getUnreadStudies,
  postIrStudy,
  readIrStudy,
  resetIrStudy,
  updateIrStudy,
} from "../../slices/irStudy";
import Modal from "../base/Modal";
import AddIrStudy from "./AddIrStudy";
import { useDispatch, useSelector } from "../../store";
import StyledTable from "../base/StyledTable";
import SearchParams from "../../types/searchParams";
import IrStudy from "../../types/irStudy";
import Chip from "../base/Chip";
import DeleteConfirmation from "../base/DeleteConfirmation";
import ChangeStudyStatus from "./ChangeStudyStatus";
import Option from "../../types/option";

interface Props {
  id?: number;
}

const StudyTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const { studies, study } = useSelector((store) => store.irStudy);
  const [openStudy, setOpenStudy] = useState<number>(id || 0);
  const [filterForm, setFilterForm] = useState<SearchParams>({
    pageNumber: 1,
    pageSize: 10,
  });

  type ActiveModal = "none" | "view" | "add" | "edit" | "delete";
  const [modalOpen, setModalOpen] = useState<ActiveModal>("none");

  useEffect(() => {
    if (id) {
      handleOpenViewModal(id);
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getIrStudies(filterForm));
  }, [dispatch, filterForm]);

  const handleOpenViewModal = (id: number) => {
    dispatch(getIrStudyId(id)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setModalOpen("view");
      }
    });
  };

  const handleOpenAddModal = () => {
    setModalOpen("add");
    dispatch(resetIrStudy());
  };

  const handleOpenEditModal = async (id: number) => {
    setOpenStudy(id);
    await dispatch(getIrStudyId(id));
    setModalOpen("edit");
  };

  const handleOpenDeleteModal = (id: number) => {
    setOpenStudy(id);
    setModalOpen("delete");
  };

  const handleCloseModal = () => {
    dispatch(getIrStudies(filterForm));
    setModalOpen("none");
    setOpenStudy(0);
  };

  const headers = ["", "MR Number", "Hospital", "Modality", "Added By", "Status", "Actions"];

  const rows =
    studies?.results.map((study: IrStudy) => {
      return {
        read: study.read ? null : (
          <Chip key={study.id} type="notification" color={theme.palette.success.main} label="Unread" />
        ),
        mrNumber: study.mrNumber,
        hospital: study.hospital?.name,
        modality: study.modality?.name,
        addedBy: `${study.submittedUser?.firstName} ${study.submittedUser?.lastName}`,
        status: study.status?.value,
        actions: (
          <Box>
            <Tooltip title={<Typography variant="button">View</Typography>} placement="top">
              <IconButton onClick={() => handleOpenViewModal(study.id)} color="inherit">
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="button">Change Status</Typography>} placement="top">
              <IconButton onClick={() => handleOpenEditModal(study.id)} color="inherit">
                <EditRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="button">Delete</Typography>} placement="top">
              <IconButton onClick={() => handleOpenDeleteModal(study.id)} color="inherit">
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      };
    }) || [];

  const changePage = (pageNumber: number, pageSize: number) => {
    setFilterForm({ ...filterForm, pageNumber: pageNumber, pageSize: pageSize });
  };

  const handleSubmit = (model: IrStudy) => {
    dispatch(postIrStudy(model)).then((response: any) => {
      dispatch(readIrStudy(response.payload.id)).then(() => {
        dispatch(getIrStudies(filterForm));
        dispatch(getUnreadStudies());
      });
    });
    setModalOpen("none");
  };

  const handleChangeStudyStatus = async (status: Option) => {
    await dispatch(updateIrStudy({ ...study, status: status }));
    handleCloseModal();
  };

  const handleDelete = async () => {
    await dispatch(deleteIrStudy(openStudy));
    handleCloseModal();
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: "100%",
        py: 3,
        m: 3,
      }}
    >
      <Paper>
        <Grid container padding="15px" direction={"row"} alignItems="center" justifyContent={"space-between"}>
          <Grid item padding={1}>
            <Typography variant={"h6"}>IR Studies</Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleOpenAddModal}
              color="primary"
              startIcon={<Add fontSize="small" />}
              variant="contained"
            >
              New Study
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Box>
          <StyledTable
            headers={headers}
            rows={rows}
            paging={true}
            page={filterForm.pageNumber}
            rowsPerPage={filterForm.pageSize}
            totalRows={studies?.totalResults}
            changePage={changePage}
            useObjects
          />
        </Box>
      </Paper>
      <Modal
        open={modalOpen === "add" || modalOpen === "view"}
        handleClose={handleCloseModal}
        title={modalOpen === "view" ? "View IR Study" : "New IR Study"}
      >
        <AddIrStudy handleClose={handleCloseModal} onSubmit={handleSubmit} />
      </Modal>
      <Modal open={modalOpen === "delete"} handleClose={handleCloseModal} title={"Confirm Delete"}>
        <DeleteConfirmation name="this study" handleClose={handleCloseModal} handleDelete={handleDelete} />
      </Modal>

      <Modal open={modalOpen === "edit"} handleClose={handleCloseModal} title={"Change Status"}>
        <ChangeStudyStatus
          status={study.status!}
          handleClose={handleCloseModal}
          handleSubmit={handleChangeStudyStatus}
        />
      </Modal>
    </Box>
  );
};

export default StudyTable;
