import React from "react";
import { Typography } from "@mui/material";
import Clock from "react-live-clock";
import theme from "../../theme";

interface Props {}

const LiveClock: React.FC<Props> = (props) => {
  return (
    <>
      <div>
        <Typography color="textPrimary" variant={"h5"}>
          <Clock
            format={"HH:mm:ss"}
            ticking={true}
            style={{
              color: theme.palette.warning.main,
              fontSize: 26,
              fontWeight: "700",
            }}
          />
        </Typography>
      </div>
      <div>
        <Typography color="textPrimary">
          <Clock
            format={"MMMM DD, YYYY"}
            ticking={true}
            style={{
              color: theme.palette.warning.main,
              fontSize: 18,
              fontWeight: "600",
            }}
          />
        </Typography>
      </div>
    </>
  );
};

export default LiveClock;
