import React from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material/Autocomplete";

interface Props {
  options: any[];
  handleChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  multiple?: boolean;
  freeSolo?: boolean;
  onSelected?: (
    event: React.SyntheticEvent,
    value: any | Array<any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => void;
}

const FormikAutocomplete: React.FC<FieldProps & TextFieldProps & Props> = (props) => {
  const { error, required, options, handleChange, multiple, field, form, helperText, onSelected, ...rest } = props;
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const submitCount = getIn(props.form.submitCount, props.field.name);
  const { setFieldValue } = useFormikContext();

  const { value } = field;

  const onChange = (event: React.ChangeEvent<{}>, value: any) => {
    setFieldValue(field.name, value);

    if (handleChange) {
      handleChange(event, value);
    }
  };

  const handleSelected = (
    event: React.SyntheticEvent,
    value: any | Array<any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    setFieldValue(field.name, value);

    onSelected?.(event, value, reason, details);
  };

  return (
    <Autocomplete
      {...field}
      freeSolo={props.freeSolo === true}
      autoSelect
      multiple={props.multiple === true}
      value={value}
      options={options}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option?.id}>
            {option?.name || option?.value || option}
          </li>
        );
      }}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        } else {
          return option?.name || option?.value || "";
        }
      }}
      onChange={onSelected ? handleSelected : (e: React.ChangeEvent<{}>, value: any) => onChange(e, value)}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required ?? false}
          error={(!!isTouched || submitCount !== 0) && errorMessage}
          helperText={helperText ?? ((!!isTouched || submitCount !== 0) && errorMessage ? errorMessage : undefined)}
          inputProps={{ ...params.inputProps }}
          {...rest}
        />
      )}
    />
  );
};

export default FormikAutocomplete;
