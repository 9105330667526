import React from "react";
import { Alert, Box } from "@mui/material";

interface FormikGlobalErrorState {
  hasError: boolean;
  errorMessage?: string;
}

const FormikGlobalError: React.FC<FormikGlobalErrorState> = (props) => {
  const { hasError, errorMessage } = props;

  return (
    <>
      {hasError && (
        <Box sx={{ mt: 1 }}>
          <Alert severity="error" sx={{ backgroundColor: "inherit" }}>
            {errorMessage}
          </Alert>
        </Box>
      )}
    </>
  );
};

export default FormikGlobalError;
