import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Case from "../types/case";
import Comment from "../types/comment";
import Modality from "../types/modality";
import HospitalSummary from "../types/hospitalSummary";
import HospitalDepartment from "../types/hospitalDepartment";
import Option from "../types/option";
import User from "../types/user";
import http from "../api/http";

interface QaState {
  userAssignedQaCases: Case[];
  userCreatedQaCases: Case[];
  qaErrors: Option[];
  newCase: Case;
  judgeQuery: Option[];
  judgeResults: Option[];
  qaCases: Case[];
  status: "idle" | "loading" | "failed";
}

const initialState: QaState = {
  userAssignedQaCases: [],
  userCreatedQaCases: [],
  qaErrors: [],
  judgeQuery: [
    {
      id: 1,
      value: "Person who opened the QA issue",
    },
    {
      id: 2,
      value: "Person who the QA issue is assigned to",
    },
  ],
  judgeResults: [] as Option[],
  qaCases: [],
  newCase: {
    patientId: undefined,
    firstName: "",
    lastName: "",
    modality: {} as Modality,
    examTypes: [],
    hospital: {} as HospitalSummary,
    hospitalDepartment: {} as HospitalDepartment,
    urgency: {} as Option,
    criticalValue: {} as Option,
    comments: [] as Comment[],
    status: { id: 4, value: "" } as Option,
    openedByUser: {} as User,
    currentUser: {} as User,
    created: undefined,
    caseDate: undefined,
    isTrauma: false,
    assignedToUser: {} as User,
    qaError: {} as Option,
  },
  status: "idle",
};

export const fetchAssignedCases = createAsyncThunk<Case[]>("cases/fetch-assigned", async () => {
  const response = await http.get<Case[]>(`cases?assigned=true`);
  return response.data;
});

export const fetchCreatedCases = createAsyncThunk<Case[]>("cases/fetch-created", async () => {
  const response = await http.get<Case[]>(`cases?created=true`);
  return response.data;
});

export const createComment = createAsyncThunk<Comment, Comment>(
  `cases/comment`,
  async (comment: Comment, { dispatch }) => {
    await http.post(`comment`, comment);
    dispatch(fetchAssignedCases());
    dispatch(fetchCreatedCases());
    return comment;
  }
);

export const createQaCase = createAsyncThunk<Case, Case>(`cases/create`, async (vm: Case, { dispatch }) => {
  let qs = "";

  if (vm.sendEmail && vm.sendText) {
    qs = "?sendEmail=true&sendText=true";
  } else if (vm.sendText) {
    qs = "?sendText=true";
  } else if (vm.sendEmail) {
    qs = "?sendEmail=true";
  }

  const response = await http.post<Case>(`cases${qs}`, vm);
  dispatch(fetchCreatedCases());
  return response.data;
});

export const updateQaCase = createAsyncThunk<Case, Case>(`cases/update`, async (vm: Case, { dispatch }) => {
  let qs = "";

  if (vm.sendEmail && vm.sendText) {
    qs = "?sendEmail=true&sendText=true";
  } else if (vm.sendText) {
    qs = "?sendText=true";
  } else if (vm.sendEmail) {
    qs = "?sendEmail=true";
  }

  const response = await http.put<Case>(`cases/${vm.id}${qs}`, vm);
  dispatch(fetchCreatedCases());
  return response.data;
});

export const updateQaAction = createAsyncThunk<
  Case,
  {
    id: number;
    status: number;
    comment: Comment;
    user?: number;
    judgement?: number;
    sendEmail?: boolean;
    sendText?: boolean;
  }
>(`cases/qa-update`, async ({ id, status, comment, user, judgement, sendEmail, sendText }) => {
  let url = `cases/${id}/qa?statusId=${status}`;
  if (user) {
    url += `&userId=${user}`;
  }
  if (judgement) {
    url += `&judgementId=${judgement}`;
  }
  if (sendEmail) {
    url += `&sendEmail=${sendEmail}`;
  }
  if (sendText) {
    url += `&sendText=${sendText}`;
  }
  const response = await http.patch<Case>(url, comment);
  return response.data;
});

export const fetchQaCodes = createAsyncThunk<Option[]>("cases/error-codes", async () => {
  const response = await http.get<Option[]>(`cases/error-codes`);
  return response.data;
});

export const fetchJudgementOptions = createAsyncThunk<Option[]>("cases/judgement-options", async () => {
  const response = await http.get<Option[]>(`cases/qa-judgement`);
  return response.data;
});

export const fetchQaCases = createAsyncThunk<Case[]>("cases/qa", async () => {
  const response = await http.get<Case[]>("/cases?qa=true");
  return response.data;
});

const qaSlice = createSlice({
  name: "qa",
  initialState,
  reducers: {
    clearResults(state) {
      state.newCase = initialState.newCase;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignedCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAssignedCases.fulfilled, (state, action) => {
        state.status = "idle";
        state.userAssignedQaCases = action.payload;
      })
      .addCase(fetchAssignedCases.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchCreatedCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCreatedCases.fulfilled, (state, action) => {
        state.status = "idle";
        state.userCreatedQaCases = action.payload;
      })
      .addCase(fetchCreatedCases.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createComment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createComment.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(createComment.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createQaCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createQaCase.fulfilled, (state, action) => {
        state.status = "idle";
        state.newCase = initialState.newCase;
      })
      .addCase(createQaCase.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateQaCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQaCase.fulfilled, (state, action) => {
        state.status = "idle";
        state.newCase = initialState.newCase;
      })
      .addCase(updateQaCase.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchQaCodes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQaCodes.fulfilled, (state, action) => {
        state.status = "idle";
        state.qaErrors = action.payload;
      })
      .addCase(fetchQaCodes.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchJudgementOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJudgementOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.judgeResults = action.payload;
      })
      .addCase(fetchJudgementOptions.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchQaCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQaCases.fulfilled, (state, action) => {
        state.status = "idle";
        state.qaCases = action.payload;
      })
      .addCase(fetchQaCases.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default qaSlice.reducer;
export const { clearResults } = qaSlice.actions;
