import React from "react";
import ReactDOM from "react-dom";
import store from "./store";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://5c386f3612e546d58b535a739578e0c9@o1122606.ingest.sentry.io/6196722",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Amplify.configure({
  Auth: {
    identityPoolId: `${process.env.REACT_APP_IDENTITY_POOL_ID}`,
    region: `${process.env.REACT_APP_REGION}`, // REQUIRED - Amazon Cognito Region
    userPoolId: `${process.env.REACT_APP_USER_POOLS_ID}`, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: `${process.env.REACT_APP_WEB_CLIENT_ID}`, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: `${process.env.REACT_APP_BUCKET}`, //REQUIRED -  Amazon S3 bucket name
      region: `${process.env.REACT_APP_REGION}`, //OPTIONAL -  Amazon service region
      customPrefix: {
        public: "",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
