import React, { useState } from "react";
import { Box, Divider, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import UserSimple from "../../types/userSimple";
import theme from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { updateUserList } from "../../slices/users";
import { useDispatch } from "../../store";

interface Props {
  onSubmit: (userId?: number, qaUsers?: UserSimple[]) => void;
  statRadUser?: UserSimple;
  userOptions: UserSimple[];
  qaUsers: UserSimple[];
}

const StatRadUser: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<UserSimple>();

  const handleSubmit = (model: UserSimple) => {
    setLoading(true);
    props.onSubmit(model?.id!, props.qaUsers!);
    setLoading(false);
  };

  const handleRemove = (user: UserSimple) => {
    const filterUsers = props.qaUsers.filter((u) => u.id !== user.id);
    dispatch(updateUserList(filterUsers));
  };

  const handleAdd = () => {
    if (!!selectedUser) {
      const newUsers = [...props.qaUsers, selectedUser];
      dispatch(updateUserList(newUsers!));
    }
  };

  const initialValues = {
    statRadUser: props.statRadUser,
    qaUser: undefined,
  };

  const validationSchema = Yup.object().shape({
    statRadUser: Yup.object().nullable(),
    qaUser: Yup.object().nullable(),
  });

  return (
    <React.Fragment>
      <Box>
        <Formik
          initialValues={initialValues!}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            handleSubmit(values?.statRadUser!);
          }}
        >
          <Form noValidate style={{ color: "textSecondary" }}>
            <Box
              sx={{
                height: "300px",
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  mt: { xs: 10, md: 0 },
                  mb: { xs: "10px", md: 0 },
                }}
              >
                <Typography variant="body1" sx={{ marginTop: "10px", marginBottom: "10px" }}>
                  Select Statrad User
                </Typography>
                <Box sx={{ width: { md: "475px", xs: "100%" } }}>
                  <Field
                    name="statRadUser"
                    placeholder="Start typing to select"
                    label="Statrad User"
                    component={Inputs.Dropdown}
                    options={props.userOptions}
                  />
                </Box>
              </Box>
              {sm && <Divider />}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  alignItems: "center",
                  borderLeft: { md: `1px solid ${theme.palette.text.secondary}`, xs: undefined },
                }}
              >
                <Typography variant="body1" sx={{ marginTop: "10px" }}>
                  Select QA Users
                </Typography>
                <Box
                  sx={{
                    overflowY: "scroll",
                    fontSize: `${theme.typography.caption}`,
                    height: "255px",
                    width: { xs: "100%", md: "550px" },
                    pb: { xs: 5, md: 0 },
                  }}
                >
                  <ul style={{ listStyleType: "none", marginLeft: "-40px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                      <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                        <IconButton sx={{ color: theme.palette.secondary.main }} onClick={() => {}} color="inherit">
                          <FontAwesomeIcon icon={faPlusCircle} onClick={handleAdd} size="sm" />
                        </IconButton>
                      </div>
                      <li style={{ display: "flex", flexDirection: "column", width: "475px" }}>
                        <Field
                          name="qaUser"
                          placeholder="Start typing to select"
                          label="Add New QA User"
                          component={Inputs.Dropdown}
                          options={props.userOptions}
                          handleChange={(e: any, value: any) => {
                            setSelectedUser(value);
                          }}
                        />
                      </li>
                    </div>
                    {props.qaUsers && props.qaUsers.length > 0 ? (
                      props.qaUsers.map((user) => {
                        return (
                          <div key={user?.id} style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                            <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                              <IconButton
                                sx={{ color: theme.palette.secondary.main }}
                                onClick={() => {
                                  handleRemove(user);
                                }}
                                color="inherit"
                              >
                                <FontAwesomeIcon icon={faMinusCircle} size="sm" />
                              </IconButton>
                            </div>
                            <li key={user?.id} style={{ display: "flex", flexDirection: "column" }}>
                              <Typography sx={{ marginTop: "6px" }}>{user.value}</Typography>
                            </li>
                          </div>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Inputs.Submit text="Save" isSubmitting={loading} />
            </Box>
          </Form>
        </Formik>
      </Box>
      {/* <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          handleSubmit(values?.statRadUser!);
        }}
      >
        <Form noValidate>
          <Grid container spacing={3}>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <Field
                name="statRadUser"
                placeholder="Start typing to select"
                label="Statrad User"
                required
                component={Inputs.Dropdown}
                options={props.userOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <Inputs.Submit text="Save" isSubmitting={loading} />
            </Grid>
          </Grid>
        </Form>
      </Formik> */}
    </React.Fragment>
  );
};

export default StatRadUser;
