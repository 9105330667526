import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../theme";

interface Props {}

const AuthLogo: React.FC<Props> = (props) => {
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mb: "10px" }}>
        <img height={xs ? 110 : 160} src={"/static/images/rads.jpeg"} alt="logo" />
      </Box>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
        <Typography textAlign={"center"}>A collaboration and case management tool for radiologists</Typography>
      </Box>
    </>
  );
};

export default AuthLogo;
