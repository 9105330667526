import React from "react";
import { useParams } from "react-router-dom";
import InterestingCasesTable from "../views/InterestingCasesTable";

interface Props {}

const InterestingCases: React.FC<Props> = (props) => {
  const { id } = useParams();
  return (
    <>
      <InterestingCasesTable id={+id!} />
    </>
  );
};

export default InterestingCases;
