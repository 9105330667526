import React from "react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

interface Props {
  title: string;
  children: any;
}

const FormContainer: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <Card {...props}>
        <CardHeader title={props.title} />
        <Divider />
        <CardContent>{props.children}</CardContent>
      </Card>
    </React.Fragment>
  );
};

export default FormContainer;
