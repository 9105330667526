import React from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { FieldProps, getIn, useFormikContext } from "formik";
import { TextFieldProps, Typography } from "@mui/material";
import theme from "../../theme";
import styled from "styled-components";

// background-color: ${window.location.href.includes("profile")
//   ? `#red;`
//   : window.location.href.includes("settings")
//   ? `#404750;`
//   : `red;`};
const Root = styled.div`
  padding-top: 15px;

  .react-tel-input {
    .special-label {
      font-size: 0.75rem;
      left: 10px;
      top: -9px;
      color: #838c9a;
      background-color: #404750;
    }
    .form-control {
      margin-top: 1px;
      border-radius: 16px;
      border-color: theme.palette.primary.main;
      background: transparent;
    }
    .country-list {
      background-color: #252526;
      color: #34c1d1;
    }
    .country:hover {
      background-color: #1b1b1c;
      color: #e68627;
    }
    .country.highlight {
      background-color: #5a5a5d;
      color: #e68627;
    }
    .country {
      .dial-code {
        color: #ffffff;
      }
    }
  }
`;

interface Props {
  label?: string;
  disabled?: boolean;
  key?: number;
  color?: string;
}

const FormikPhoneField: React.FC<FieldProps & TextFieldProps & Props & PhoneInputProps> = (props) => {
  const { className, field, error, disabled, label, required, key, focused, ...rest } = props;
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const { setFieldValue } = useFormikContext();

  return (
    <Root>
      <PhoneInput
        key={key}
        inputProps={{
          required: required ?? false,
        }}
        disabled={disabled}
        countryCodeEditable={false}
        country={"us"}
        placeholder=""
        onlyCountries={["us"]}
        specialLabel={required ? label + "*" || "" : label || ""}
        {...field}
        {...rest}
        onChange={(e) => {
          setFieldValue(field.name, e);
        }}
        value={field.value || ""}
        inputStyle={{
          width: "100%",
          borderColor:
            !!isTouched && errorMessage
              ? theme.palette.error.main
              : focused
              ? theme.palette.primary.main
              : `${theme.palette.primary.contrastText}50`,
          color: theme.palette.primary.contrastText,
        }}
      />
      <Typography
        sx={{
          fontSize: ".75rem",
          letterSpacing: "0em",
          fontWeight: 400,
          textAlign: "left",
          ml: "14px",
          mt: "2px",
        }}
        color={theme.palette.error.main}
      >
        {!!isTouched && errorMessage ? errorMessage : ""}
      </Typography>
    </Root>
  );
};

export default FormikPhoneField;
