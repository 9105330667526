import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Popover,
  Typography,
  Link as MuiLink,
  Autocomplete,
  TextField,
  Grid,
  useMediaQuery,
} from "@mui/material";
import LiveClock from "../base/LiveClock";
import UserMenu from "./UserMenu";
import { useSelector, useDispatch } from "../../store";
import ActionButtons from "../base/ActionButtons";
import { Assignment, Notifications, MonitorRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { fetchLogo } from "../../slices/settings";
import CasesMenu from "./CasesMenu";
import MenuDrawer from "../views/MenuDrawer";
import ChatMenu from "../views/ChatMenu";
import AnnouncementMenu from "./AnnouncementMenu";
import getUserRoleId from "../../helpers/getUserRoleId";
import { setKiosk } from "../../slices/kiosk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import helpers from "../../helpers";
import Avatar from "../base/Avatar";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";
import { setSelectedHospital } from "../../slices/auth";
import Hospital from "../../types/hospital";
import Option from "../../types/option";
import IrMenu from "./IrMenu";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import S3Avatar from "../base/S3Avatar";
import LinkMenuDrawer from "./LinkMenuDrawer";
import { postIrStudy, resetIrStudy } from "../../slices/irStudy";
import Modal from "../base/Modal";
import AddIrStudy from "./AddIrStudy";
import IrStudy from "../../types/irStudy";
import { handleSuccessToastState } from "../../slices/toast";

interface CaseModal {
  visible: boolean;
  id: number | undefined;
}

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { loggedInUser, selectedHospital } = useSelector((store) => store.auth);
  const { pendingCases, inProgressCases, currentUserCases } = useSelector((store) => store.cases);
  const { conversations } = useSelector((state) => state.chat);
  const { teamConversations } = useSelector((state) => state.teamChats);
  const { logo } = useSelector((state) => state.settings);
  const { kiosk } = useSelector((store) => store.kiosk);
  const { unreadCases } = useSelector((store) => store.interestingCases);

  const [irModal, setIrModal] = useState<boolean>(false);
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const [hospital, setHospital] = useState(loggedInUser?.hospital);
  const [caseModal, setCaseModal] = React.useState<CaseModal>({
    visible: false,
    id: undefined, // Case ID
  });
  const [announcementModal, setAnnouncementModal] = React.useState<CaseModal>({
    visible: false,
    id: undefined, // Case ID
  });

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  let hospitalIds = loggedInUser?.hospitals?.map((h) => h.id);
  const pending =
    getUserRoleId(loggedInUser) === 3
      ? pendingCases?.filter((c) => hospitalIds?.includes(c.hospital?.id!))
      : pendingCases;
  const userProgress = inProgressCases?.filter((c) => c.currentUser?.id === loggedInUser?.id);
  const isJudge = !!loggedInUser?.roles?.find((role: Option) => role?.id === 6);
  const currentCases = !isJudge ? currentUserCases?.filter((c) => c.status.id !== 6) : currentUserCases;
  const roleId = getUserRoleId(loggedInUser);

  const handleEnableKiosk = () => dispatch(setKiosk(true));
  const handleDisableKiosk = () => dispatch(setKiosk(false));

  const handleSelectHospital = (value: any) => {
    const hospital = loggedInUser?.hospitals?.find((h) => h.id === value?.id!);
    if (!!hospital) {
      dispatch(setSelectedHospital(hospital));
    } else {
      dispatch(setSelectedHospital(undefined));
    }
  };

  const handleIrModal = () => {
    if (loggedInUser?.roles.find((role) => role.id === 7)) {
      navigate("/app/studies");
    } else {
      dispatch(resetIrStudy());
      setIrModal(true);
    }
  };

  useEffect(() => {
    dispatch(fetchLogo());
  }, [dispatch]);

  useEffect(() => {
    if (selectedHospital) {
      setHospital(selectedHospital);
    } else {
      setHospital(loggedInUser?.hospital);
    }
  }, [selectedHospital, loggedInUser?.hospital, loggedInUser?.hospital?.isOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (userMenuOpen && popoverRef.current && !popoverRef.current.contains(e.target)) {
        setUserMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [userMenuOpen]);

  const handleSubmit = async (model: IrStudy) => {
    dispatch(postIrStudy(model)).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(handleSuccessToastState(true));
      }
    });
    setIrModal(false);
  };

  const handleCloseModal = () => setIrModal(false);

  return (
    <AppBar
      elevation={0}
      sx={{
        zIndex: 1205,
        backgroundColor: "background.paper",
        color: "text.primary",
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ py: "4px" }}>
          <Link to="/">
            <img height={md ? 40 : 50} src={`data:image/png;base64,${logo}` || "/static/images/logo.png"} alt="logo" />
          </Link>
        </Box>
        {!md && (
          <Box sx={{ px: 3, alignItems: "center", display: "flex", flexDirection: "column" }}>
            <LiveClock />
          </Box>
        )}
        {getUserRoleId(loggedInUser) !== 3 && !md && (
          <React.Fragment>
            <Box
              sx={{
                flexGrow: 1,
                ml: 2,
              }}
            />
            <Box sx={{ py: "4px" }}>
              <MuiLink
                target="_blank"
                href="https://tptrads.sharepoint.com/sites/radsinc/team/notebook/SitePages/Home.aspx"
              >
                <img height={55} src={"/static/images/radio.jpeg"} alt="radio" />
              </MuiLink>
            </Box>
          </React.Fragment>
        )}
        {!!kiosk && !md && (
          <React.Fragment>
            <Box
              sx={{
                flexGrow: 1,
                ml: 2,
              }}
            />
            <Box sx={{ ml: 1, textAlign: "center" }}>
              <Typography variant="h5">{hospital?.name}</Typography>
              {(getUserRoleId(loggedInUser) === 3 || kiosk) && !(hospital?.isOpen || false) && (
                <Typography variant="h6" color="yellow">
                  {hospital?.closedMessage || "RADS is no longer accepting submissions for this location."}
                </Typography>
              )}
            </Box>
          </React.Fragment>
        )}
        {!md && getUserRoleId(loggedInUser) === 3 && !kiosk && !(hospital?.isOpen || false) && (
          <React.Fragment>
            <Box
              sx={{
                flexGrow: 1,
                ml: 2,
              }}
            />
            <Box sx={{ ml: 1, textAlign: "center" }}>
              <Typography variant="h6" color={theme.palette.warning.main}>
                {hospital ? hospital?.shortName + " - " : ""}
                {hospital?.closedMessage || "RADS is no longer accepting submissions for this location."}
              </Typography>
            </Box>
          </React.Fragment>
        )}
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {!md && getUserRoleId(loggedInUser) !== 3 && (
          <React.Fragment>
            <Box sx={{ display: "flex" }}>
              <Box sx={{}}>
                <LinkMenuDrawer />
              </Box>

              <Box sx={{ ml: 1 }}>
                <ActionButtons
                  label="Pending"
                  icon={<Notifications />}
                  badgeNumber={pending?.length}
                  onClick={() => navigate("/app/dashboard")}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <ActionButtons
                  label="My Cases"
                  icon={<Assignment />}
                  badgeNumber={userProgress?.length}
                  onClick={() => navigate("/app/dashboard")}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <ActionButtons
                  label="QA"
                  icon={<Icon type={IconType.Qa} size="1em" />}
                  badgeNumber={currentCases?.length}
                  onClick={() => navigate("/app/qa")}
                />
              </Box>
              {(loggedInUser?.roles.find((role) => role.id === 2) ||
                loggedInUser?.roles.find((role) => role.id === 1) ||
                loggedInUser?.roles.find((role) => role.id === 7)) && (
                <Box sx={{ ml: 1 }}>
                  <IrMenu setIrModal={handleIrModal} />
                </Box>
              )}
              {(loggedInUser?.roles.find((role) => role.id === 2) ||
                loggedInUser?.roles.find((role) => role.id === 1)) && (
                <Box sx={{ ml: 1 }}>
                  <Grid container>
                    <Grid item>
                      <ActionButtons
                        label={"Interesting Cases"}
                        icon={<FontAwesomeIcon icon={faLightbulb} size="1x" />}
                        badgeNumber={unreadCases}
                        onClick={() => navigate("/app/interesting-cases")}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </React.Fragment>
        )}
        {roleId === 3 && !kiosk && loggedInUser?.hospitals?.length! > 1 && (
          <Box sx={{ width: "200px", margin: "0px 25px" }}>
            <Autocomplete
              fullWidth
              onChange={(event: React.ChangeEvent<{}>, value: Hospital | null) => {
                handleSelectHospital(value);
              }}
              id="tags-standard"
              options={loggedInUser?.hospitals!}
              getOptionLabel={(option: Hospital) => option.name!}
              renderInput={(params) => (
                <TextField
                  label={"Select Hospital"}
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "0.75rem",
                      fontWeight: 500,
                    },
                    "& .MuiFormLabel-root": {
                      fontSize: "0.75rem",
                      fontWeight: 500,
                      zIndex: 5000,
                    },
                  }}
                  {...params}
                />
              )}
            />
          </Box>
        )}
        {roleId === 3 && !kiosk && !sm && (
          <Box sx={{ margin: "0px 25px" }}>
            <Box py={3}>
              <ActionButtons label="Kiosk Mode" icon={<MonitorRounded />} onClick={handleEnableKiosk} />
            </Box>
          </Box>
        )}
        {!kiosk && roleId !== 3 && (
          <React.Fragment>
            <Box>
              <AnnouncementMenu announcementModal={announcementModal} setAnnouncementModal={setAnnouncementModal} />
            </Box>
          </React.Fragment>
        )}
        <Box>
          <CasesMenu caseModal={caseModal} setCaseModal={setCaseModal} hospital={hospital!} />
        </Box>
        {!kiosk && roleId !== 3 && (
          <React.Fragment>
            <Box sx={{ margin: "0px 25px" }}>
              <MenuDrawer
                pending={pending!}
                userProgress={userProgress!}
                currentCases={currentCases!}
                handleIrClick={handleIrModal}
              />
            </Box>
          </React.Fragment>
        )}
        <Box
          onClick={() => setUserMenuOpen(!userMenuOpen)}
          ref={anchorRef}
          sx={{
            paddingRight: "0",
            marginLeft: "auto",
            zIndex: 1250,
          }}
        >
          {!kiosk ? (
            <Avatar
              roleId={getUserRoleId(loggedInUser)}
              sx={{
                color: "#fff",
                height: md ? "40px" : "50px",
                width: md ? "40px" : "50px",
                ":hover": { cursor: "pointer" },
              }}
              alt={
                loggedInUser?.firstName
                  ? `${loggedInUser?.firstName}`
                  : "" + loggedInUser?.lastName
                  ? `${loggedInUser?.lastName}`
                  : ""
              }
            >
              {!kiosk ? (
                <S3Avatar
                  firstName={loggedInUser?.firstName!}
                  lastName={loggedInUser?.lastName!}
                  imageId={loggedInUser?.id!}
                  height={md ? "40px" : "50px"}
                />
              ) : (
                <FontAwesomeIcon icon={helpers.getIconByRole(getUserRoleId(loggedInUser))} size="2x" />
              )}
            </Avatar>
          ) : (
            <Avatar
              roleId={getUserRoleId(loggedInUser)}
              sx={{
                border: "2px solid #fff",
                color: "#fff",
                height: md ? "40px" : "50px",
                width: md ? "40px" : "50px",
                ":hover": { cursor: "pointer" },
              }}
            >
              <FontAwesomeIcon icon={helpers.getIconByRole(getUserRoleId(loggedInUser))} size="2x" />
            </Avatar>
          )}
        </Box>
        <Box ref={popoverRef}>
          <Popover
            anchorEl={anchorRef.current}
            anchorOrigin={{
              horizontal: "center",
              vertical: "bottom",
            }}
            keepMounted
            open={userMenuOpen}
          >
            <UserMenu kiosk={kiosk} handleDisableKiosk={handleDisableKiosk} />
          </Popover>
        </Box>
        <ChatMenu conversations={conversations} teamConversations={teamConversations} loggedInUser={loggedInUser!} />
      </Toolbar>
      <Modal open={irModal} handleClose={handleCloseModal} title="New IR Study">
        <AddIrStudy handleClose={handleCloseModal} onSubmit={handleSubmit} />
      </Modal>
    </AppBar>
  );
};

export default Navbar;
