import React, { useEffect, useState } from "react";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import theme from "../../theme";
import moment from "moment";

export default React.memo((props: { created: Date; maxTime: number; timeBlocked: number }) => {
  const { created, maxTime, timeBlocked } = props;

  const color = theme.palette.warning.main;
  const [time, setTime] = useState(0);
  const [overdue, setOverdue] = useState(false);

  useEffect(() => {
    if (overdue) return;
    setTimeout(() => {
      const date = moment(new Date());
      const minDate = moment(new Date(created));

      const elapsedTime = date.diff(minDate, "seconds") - timeBlocked;

      const percentage = (elapsedTime * 100) / (maxTime * 60);

      if (percentage >= 100) {
        setOverdue(true);
      } else {
        setTime(percentage);
      }
    }, 1000);
  });

  return !overdue ? (
    <React.Fragment>
      <LinearProgress
        value={time}
        color="primary"
        variant="determinate"
        style={{
          height: 10,
          backgroundColor: theme.palette.quinary.main,
        }}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: `${color}`,
              maxHeight: 10,
            }}
          >
            <Typography color="#000" style={{ fontWeight: 800, fontSize: 10, lineHeight: 1 }}>
              Overdue!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
