import React, { FunctionComponent } from "react";
import { Autocomplete, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import User from "../../../types/user";
import UserSimple from "../../../types/userSimple";

interface OwnProps {
  users: UserSimple[];
  authUsers: User[];
  setAuthUsers: any;
}

type Props = OwnProps;

const HospitalAuthUsers: FunctionComponent<Props> = React.memo((props) => {
  const { users, authUsers, setAuthUsers } = props;

  const [newUser, setNewUser] = React.useState<any>({
    label: null,
    id: null,
  });

  const handleAddUser = () => {
    setAuthUsers([...authUsers, { ...(users.find((user) => user?.id === newUser?.id) || {}) }]);
    setNewUser({
      label: null,
      id: null,
    });
  };

  const handleDeleteUser = (userId: number) => {
    let temp = [...authUsers];
    temp.splice(
      temp.findIndex((user) => user.id === userId),
      1
    );
    setAuthUsers(temp);
  };

  const autoCompleteValue = (() => {
    const foundUser = users?.find((user) => user?.id === newUser?.id);
    if (!!foundUser)
      return {
        label: `${foundUser?.value || ""}`,
        value: foundUser?.id || 0,
      };
    return {
      label: "",
      value: 0,
    };
  })();

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                <Typography color="textPrimary" variant="h6">
                  Authorized Users
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={3} alignItems="center" justifyContent="flex-end">
                  <Grid item xs={5}>
                    <Autocomplete
                      fullWidth
                      disablePortal
                      value={autoCompleteValue}
                      onChange={(label, value) => {
                        if (!value) return setNewUser(null);
                        setNewUser({
                          label: value?.label,
                          id: value?.value,
                        });
                      }}
                      options={users
                        .filter((e) => !authUsers.find((user) => user.id === e?.id))
                        .map((user) => {
                          return { label: `${user.value}`, value: user.id };
                        })}
                      renderInput={(params) => <TextField label="User" {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <Button disabled={!newUser?.id} onClick={handleAddUser}>
                      Add User
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {authUsers?.map((user) => (
            <Grid key={user.id} marginBottom={0.5} display="flex" flexDirection="row" alignItems="center">
              <Typography marginRight={1} color="textPrimary" variant="body1">
                {user.value ? user.value : user.firstName + " " + user.lastName}
              </Typography>
              <Button onClick={() => handleDeleteUser(user.id)} type="button">
                Delete
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default HospitalAuthUsers;
