import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import CaseDurationsType from "../../types/caseDurations";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";

interface Props {
  onSubmit: (result: CaseDurationsType) => void;
  caseDurations?: CaseDurationsType;
}

const CaseDurations: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (model: CaseDurationsType) => {
    setLoading(true);
    props.onSubmit(model);
    setLoading(false);
  };

  const initialValues: CaseDurationsType = { stroke: "", critical: "", nonCritical: "" };

  const validationSchema = Yup.object().shape({
    stroke: Yup.string().required("Please enter a value for stroke"),
    critical: Yup.string().required("Please enter a value for critical"),
    trauma: Yup.string().required("Please enter a value for trauma"),
    nonCritical: Yup.string().required("Please enter a value for routine"),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={props.caseDurations || initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        <Form noValidate>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <Field name="stroke" label="Stroke" required component={Inputs.Text} />
            </Grid>
            <Grid item md={3} xs={6}>
              <Field name="critical" label="Critical" required component={Inputs.Text} />
            </Grid>
            <Grid item md={3} xs={6}>
              <Field name="trauma" label="Trauma Activation" required component={Inputs.Text} />
            </Grid>
            <Grid item md={3} xs={6}>
              <Field name="nonCritical" label="Routine" required component={Inputs.Text} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Inputs.Submit text="Save" isSubmitting={loading} />
          </Box>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default CaseDurations;
