import { Box, Container, Grid, Typography, Divider } from "@mui/material";
import React, { FunctionComponent } from "react";
import HospitalView from "../views/HospitalView/HospitalView";

interface OwnProps {}

type Props = OwnProps;

const Hospital: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Box
        sx={{
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Hospital Settings
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ mt: 3 }}>
            <HospitalView />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Hospital;
