import React from "react";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "../../store";
import { fetchSchedule, syncSchedule } from "../../slices/schedule";
import { Box, Button, CircularProgress, Container, Link } from "@mui/material";
import ScheduleTable from "../views/ScheduleTable";
import Moment from "react-moment";
import getUserRoleId from "../../helpers/getUserRoleId";

interface OwnProps {}
type Props = OwnProps;

const Schedule: FunctionComponent<Props> = (props) => {
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { schedule, syncStatus } = useSelector((store) => store.schedule);

  useEffect(() => {
    dispatch(fetchSchedule());
  }, [dispatch]);

  const handleSync = () => {
    dispatch(syncSchedule());
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100%",
          py: 8,
          marginTop: "5px",
        }}
      >
        <Container maxWidth={false}>
          <p>
            Manually sync with{" "}
            <Link
              color="primary"
              component="a"
              href="https://app.qgenda.com/link/view?linkKey=0d2354fb-7601-4784-bab5-d7a19a137b9c"
              target="_blank"
            >
              QGenda
            </Link>
          </p>
          {(getUserRoleId(loggedInUser) === 1 || getUserRoleId(loggedInUser) === 2) && (
            <Box sx={{ marginBottom: 2 }}>
              <Button
                disabled={syncStatus === "loading"}
                size="large"
                type="button"
                variant="contained"
                onClick={handleSync}
              >
                Sync
                {syncStatus === "loading" && <CircularProgress size={20} sx={{ ml: 2 }} />}
              </Button>
            </Box>
          )}
          <Box sx={{ width: "100%" }}>
            <ScheduleTable loggedInUser={loggedInUser} schedule={schedule} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Schedule;
