import React, { useRef } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import theme from "../../theme";
import BadgeIcon from "./BadgeIcon";

interface Props {
  onClick: () => void;
  icon: React.ReactElement;
  label: string;
  badgeNumber?: number;
  disabled?: boolean;
  badgeColor?: "warning" | "default" | "primary" | "secondary" | "error" | "info" | "success";
  color?: "warning" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "tertiary" | "quarternary";
}

const ActionButtons: React.FC<Props> = (props) => {
  const { onClick, icon, label, badgeNumber, disabled, badgeColor, color } = props;
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const getBadgeColor = (color: string) => {
    switch (color) {
      case "warning":
        return theme.palette.warning.main;
      case "primary":
        return theme.palette.primary.main;
      case "secondary":
        return theme.palette.secondary.main;
      case "error":
        return theme.palette.error.main;
      case "info":
        return theme.palette.info.main;
      case "success":
        return theme.palette.success.main;
      case "quarternary":
        return theme.palette.quarternary.main;
      case "tertiary":
        return theme.palette.tertiary.main;
      default:
        return "#fff";
    }
  };

  return (
    <Box>
      <Tooltip title={<Typography variant="button">{label}</Typography>}>
        <Box component={"span"}>
          <IconButton
            color="inherit"
            ref={anchorRef}
            disabled={disabled || false}
            onClick={onClick}
            sx={{ color: getBadgeColor(color!) }}
          >
            <BadgeIcon color={badgeColor || "error"} badgeContent={badgeNumber!} content={icon} />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ActionButtons;
