import { Badge } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  badgeContent: number;
  content?: any;
  color: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
}

const StyledBadge = styled(Badge)((props: Props) => ({
  "& .MuiBadge-badge": {
    fontSize: "1rem",
    height: "30px",
    width: "30px",
    borderRadius: "15px",
  },
}));

const BadgeIcon: React.FC<Props> = (props) => {
  return <StyledBadge {...props}>{props.content}</StyledBadge>;
};

export default BadgeIcon;
