import React, { useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Case from "../../types/case";
import Card from "../base/Card";
import theme from "../../theme";
import { useDispatch, useSelector } from "../../store";
import CaseView from "./CaseView";
import { updateCase, searchCases } from "../../slices/cases";
import getPillColorByStatus from "../../helpers/getPillColorByStatus";
import Modal from "../base/Modal";
import CaseReport from "../base/CaseReport";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Download } from "@mui/icons-material";

interface Props {
  case: Case;
  kiosk?: boolean | undefined;
}

const CaseCard: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const [reportModal, setReportModal] = useState(false);
  const [strokeModal, setStrokeModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { durations, settings } = useSelector((store) => store.settings);
  const { loggedInUser } = useSelector((store) => store.auth);

  const statusId = props.case?.status?.id!;
  const isCompleted = statusId === 3;
  const isBlocked = statusId === 8;

  const refreshCases = () => {
    if (window.location.href.includes("completed-cases")) {
      dispatch(
        searchCases({
          pageNumber: 1,
          pageSize: 10,
          filters: ["active:3"],
          orderDirection: "Descending",
        })
      );
    }
  };

  const maxTime = (() => {
    switch (props.case.urgency?.id) {
      case 1:
        return +durations?.stroke!;
      case 2:
        return +durations?.critical!;
      case 3:
        return +durations?.nonCritical!;
      case 4:
        return +durations?.trauma!;
      default:
        return 0;
    }
  })();

  const isAssigned = (() => {
    if (!(statusId === 2))
      return {
        isAssigned: false,
        toUser: false,
      };
    if (+(props.case.currentUser?.id || 0) === loggedInUser?.id)
      return {
        isAssigned: true,
        toUser: true,
      };
    return {
      isAssigned: true,
      toUser: false,
    };
  })();

  const completeCase = async () => {
    setIsEditing(true);
    if (strokeModal) {
      handleCloseStrokeModal();
    }
    await dispatch(
      updateCase({
        ...props.case,
        status: { id: 3, value: "" },
        currentUser: undefined,
      })
    );
    refreshCases();
  };

  const handleDownload = async () => {
    try {
      const doc = new jsPDF();

      const foundElement = document.getElementById("caseReportId") || document.createElement("div");
      const documentCanvas = await html2canvas(foundElement, { allowTaint: true, useCORS: true });

      doc.addImage(documentCanvas, "PNG", 0, 0, doc.internal.pageSize.getWidth(), foundElement.scrollHeight / 4);

      doc.save(
        `Preliminary Report for ${props.case.lastName || ""} ${props.case.firstName || ""}, ${
          props.case.modality?.name || ""
        } - ${new Date().toDateString()}`
      );
    } catch (e) {
      // console.log(e);
    }
  };

  const [buttonClicked, setButtonClicked] = useState(false);

  const handleOpenReportModal = () => setReportModal(true);
  const handleCloseReportModal = () => setReportModal(false);

  const handleCloseStrokeModal = () => {
    setStrokeModal(false);
    setButtonClicked(false);
  };

  return (
    <Box>
      <Modal open={strokeModal} handleClose={handleCloseStrokeModal} title="Complete Stroke Case">
        <Box sx={{ textAlign: "center" }}>
          <Typography sx={{ mb: 3 }}>
            This case is marked STROKE - please phone the on-call neurologist before completing this case.
          </Typography>
          <Divider />
          <Grid container>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="secondary"
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={handleCloseStrokeModal}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Box sx={{ mt: 2 }}>
                <Button color="primary" fullWidth size="large" type="button" variant="contained" onClick={completeCase}>
                  Already Done
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={reportModal} handleClose={handleCloseReportModal} title="Case Report" size={800}>
        <React.Fragment>
          <Box sx={{ borderRadius: 2, overflow: "hidden" }}>
            <CaseReport image={`${settings?.siteLogos?.reportLogoFile}`} width={800 - 30} model={props.case} />
          </Box>
          <br />
          <Grid container spacing={3}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleDownload} startIcon={<Download />}>
                Download
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={handleCloseReportModal}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
      <Box sx={{ marginTop: "-15px" }}>
        <Card color={!isBlocked ? getPillColorByStatus(props.case.urgency?.id) : theme.palette.background.paper}>
          <CaseView
            isAssigned={isAssigned}
            isCompleted={isCompleted}
            isBlocked={isBlocked}
            case={props.case}
            kiosk={props.kiosk}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            maxTime={maxTime}
            handleOpenReportModal={handleOpenReportModal}
            setStrokeModal={setStrokeModal}
            buttonClicked={buttonClicked}
            setButtonClicked={setButtonClicked}
            completeCase={completeCase}
          />
        </Card>
      </Box>
    </Box>
  );
};

export default CaseCard;
