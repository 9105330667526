import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../theme";

export default React.memo(() => {
  return (
    <React.Fragment>
      <Grid margin={1}>
        <Typography marginBottom={2} variant="h3">
          Security
        </Typography>
        <br />

        <Typography marginBottom={2} variant="h5" fontWeight="700">
          Radiology Diagnostic Services HIPAA Compliance Statement
        </Typography>

        <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
          The content of this document is the confidential information of Radiology Diagnostic Services Incorporated and
          its use and disclosure is subject to the terms of the agreement pursuant to which you obtained access to Rads
          Sync®.
        </Typography>

        <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
          Rads Sync® is a registered trademark of Radiology Diagnostic Services, Inc.
        </Typography>

        <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
          The Rads logo is a trademark of Radiology Diagnostic Services, Inc.
        </Typography>

        <Typography marginBottom={2} variant="h6" fontWeight="800">
          U.S. GOVERNMENT RESTRICTED RIGHTS
        </Typography>
        <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
          This product is a “Commercial Item” offered with “Restricted Rights.” The Government’s rights to use, modify,
          reproduce, release, perform, display or disclose this documentation are subject to the restrictions set forth
          in Federal Acquisition Regulation (“FAR”) 12.211 and 12.212 for civilian agencies and in DFARS 227.7202-3 for
          military agencies. Contractor is Radiology Diagnostic Services Incorporated.
        </Typography>

        <Typography marginBottom={2} variant="h6" fontWeight="800">
          INDICATIONS FOR USE: Rads Sync®
        </Typography>
        <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
          Rads Sync is a web based medical information management system, dashboard and collaboration tool that assists
          Radiologists with case tracking, telecommunications, intergroup consultation and time management. It assists
          in the tracking of clinical radiology work volume, administrative duties, consultative activities, and other
          non-clinical work (otherwise known as “added value”).
        </Typography>

        <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
          CAUTION: Federal law restricts the use of this program by or on the order of a physician.
        </Typography>

        <Typography marginBottom={2} variant="h5" fontWeight="700">
          1 Introduction
        </Typography>

        <Box sx={{ ml: 3 }}>
          <Typography marginBottom={2} fontWeight="700">
            1.1 Purpose
          </Typography>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            The purpose of this document is to provide general information in relationship to the technical security
            services referenced within the Technical Safeguards section of the Federal Register, Section G (164.312), as
            they pertain to Protected Health Information in electronic form. Rads Sync is designed to assure the privacy
            and security of electronically stored medical data. The regulations set standards for electronic
            transactions, the privacy of all medical records and all identifiable health information and the security of
            electronically stored information. Rads Sync is designed to assist Covered Entities (as defined under HIPAA)
            to comply with the HIPAA Regulations referenced in this document.
          </Typography>

          <Typography marginBottom={2} fontWeight="700">
            1.2 Scope
          </Typography>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            The scope of this document is focused solely on the five technical security services requirements with
            supporting implementation features: Access control; Audit Controls; Integrity; Person or Entity
            authentication; and Transmission Security. For details, reference Technical Safeguards section of the
            Federal Register, Section G (164.312). All other required privacy and security elements associated with the
            HIPAA regulations are the sole responsibility of the Covered Entities. Note: a complete description of HIPAA
            is beyond the scope of this document.
          </Typography>

          <Typography marginBottom={2} fontWeight="700">
            1.3 Related Information
          </Typography>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            The following are websites with related information:
          </Typography>

          <Grid container>
            <Grid item xs={6}>
              <Typography marginBottom={2} fontWeight="700">
                Website
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography marginBottom={2} fontWeight="700">
                Description
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ width: "100%", height: 2, my: 2, backgroundColor: theme.palette.text.secondary }} />
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ width: "80%" }}>
                <a href="https://www.hhs.gov/hipaa/index.html">https://www.hhs.gov/hipaa/index.html</a>
                <br />
                <a href="https://www.hhs.gov/hipaa/for-professionals/privacy/index.html">
                  https://www.hhs.gov/hipaa/for-professionals/privacy/index.html
                </a>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
                United States Department of Health and Human Services
              </Typography>

              <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
                Office for Civil Rights - HIPAA
              </Typography>

              <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
                Medical Privacy – National Standards To Protect the Privacy of Personal Health Information
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ width: "100%", height: 2, my: 2, backgroundColor: theme.palette.text.secondary }} />
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ width: "80%" }}>
                <a href="http://edocket.access.gpo.gov/2009/pdf/E9-20169.pdf">
                  http://edocket.access.gpo.gov/2009/pdf/E9-20169.pdf
                </a>
                <br />
                <a href="https://www.ecfr.gov/cgi-bin/ECFR?page=browse">
                  https://www.ecfr.gov/cgi-bin/ECFR?page=browse
                </a>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
                Federal Register – Part II, Department of Health and Human Services, 45 CFR Parts 160, 162, and 164
                Health Insurance Reform: Security Standards; Final Rule. Section G. Technical Safeguards – 164.312.
              </Typography>
            </Grid>
          </Grid>

          <Typography marginBottom={2} fontWeight="700">
            1.4 Glossary
          </Typography>

          <Typography marginBottom={1} fontWeight="700">
            Business Associate
          </Typography>
          <Typography marginBottom={2} marginLeft={3} variant="subtitle1" color="wheatsmoke">
            Under HIPAA, “Business Associate” is a person or entity that performs certain functions or activities that
            involve the use or disclosure of protected health information, on behalf of, or provides service to, covered
            entity.
          </Typography>

          <Typography marginBottom={1} fontWeight="700">
            Covered Entity (CE)
          </Typography>
          <Typography marginBottom={2} marginLeft={3} variant="subtitle1" color="wheatsmoke">
            Under HIPAA, “Covered Entity” is a health plan, a health care clearinghouse, or a health care provider who
            transmits any health information in electronic form in connection with a HIPAA transaction. Also see Part
            II, 45 CFR 160.103.
          </Typography>

          <Typography marginBottom={1} fontWeight="700">
            HIPAA (Health Insurance Portability and Accountability Act)
          </Typography>
          <Typography marginBottom={2} marginLeft={3} variant="subtitle1" color="wheatsmoke">
            The Health Insurance Portability and Accountability Act (HIPAA) of 1996 mandates legal and regulatory
            environments governing the provision of health benefits, the delivery and payment of healthcare services,
            and the security and confidentiality of individually identifiable, protected health information.
          </Typography>

          <Typography marginBottom={1} fontWeight="700">
            Protected Health Information (PHI)
          </Typography>
          <Typography marginBottom={2} marginLeft={3} variant="subtitle1" color="wheatsmoke">
            PHI is individually identifiable health information that is transmitted by, or maintained in, electronic
            media or any other form or medium. This information must relate to 1) the past, present, or future physical
            or mental health, or condition of an individual; 2) provision of health care to an individual; or 3) payment
            for the provision of health care to an individual. If the information identifies or provides a reasonable
            basis to believe it can be used to identify an individual, it is considered individually identifiable health
            information. See Part II, 45 CFR 164.501
          </Typography>
        </Box>

        <Typography marginBottom={2} variant="h5" fontWeight="700">
          2 Responsibility for compliance
        </Typography>
        <Typography marginBottom={2} marginLeft={3} variant="subtitle1" color="wheatsmoke">
          Compliance with state and federal regulations regarding patient confidentiality, security, and privacy is the
          responsibility of all covered entities and business associates. This includes, but is not limited to,
          compliance with the Health Information Portability and Accountability Act (HIPAA) of 1996.
        </Typography>

        <Typography marginBottom={2} variant="h5" fontWeight="700">
          3 How Rads Sync supports compliance internally
        </Typography>

        <Box sx={{ ml: 3 }}>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            Rads Sync supports compliance with regulatory and ethical requirements in many ways:
          </Typography>

          <ul>
            <li>
              Confidentiality agreement. All Radiology Diagnostic Services employees are required to execute a
              confidentiality agreement, because employees may gain access to confidential medical information as a
              result of their work with patients. A copy of Radiology Diagnostic Services internal confidentiality
              agreement can be provided upon request.
            </li>

            <li>
              Background checks before hiring. A background check is performed on all new hires to Radiology Diagnostic
              Services.
            </li>

            <li>
              Requirement for creating anonymized patient records. Radiology Diagnostic Services internal policies
              prohibit the inappropriate transmission or duplication of patient records, if the patient records include
              identifying information.
            </li>
          </ul>
        </Box>

        <Typography marginBottom={2} variant="h5" fontWeight="700">
          4 How Rads Sync supports compliance for its users
        </Typography>

        <Box sx={{ ml: 3 }}>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            In the Technical Safeguards section of the Federal Register, Section G (164.312), the following security
            services features are discussed:
          </Typography>
          <ul>
            <li>Access Controls</li>
            <li>Audit Controls</li>
            <li>Integrity</li>
            <li>Authentication</li>
            <li>Transmission Security</li>
            <li>See the following sections for details on how Rads Sync addresses these features.</li>
          </ul>

          <Typography marginBottom={2} variant="subtitle1" fontWeight="700">
            4.1 Access controls
          </Typography>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            Requirements for unique user identification and other methods of access control:
          </Typography>

          <ul>
            <li>
              Unique user identification. Rads Sync supports a username/password system for gaining access to Rads Sync
              user applications. We strongly discourage any user from sharing or disclosing this personal password or
              user name.
            </li>
            <li>
              Restrictions based on user rights. User Security Administration enables certain Administrators to tailor
              the rights assigned to any given user, including limiting and suspending user rights as appropriate.
            </li>
            <li>
              Automated log-outs. Rads Sync supports an automatic logout mechanism. The system requires the user to log
              on again, if the workstation is not used for a selected period of time.
            </li>
            <li>
              Placement of equipment in secure areas. Rads Sync participates with customers in planning implementation
              so that confidentiality and security is promoted. This includes placing computer technology in
              appropriately secured and confidential areas when applicable as well as locking down equipment
              mechanically.
            </li>
          </ul>

          <Typography marginBottom={2} variant="subtitle1" fontWeight="700">
            4.2 Audit controls
          </Typography>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            Audit control procedures to record and examine system activity:
          </Typography>

          <ul>
            <li>
              <strong>Audit trail.</strong> Rads Sync provides an audit trail that records access to any patient’s
              medical record within Rads Sync products. The event log can be queried and sorted, and can also be copied
              to external databases such as Microsoft Excel.
            </li>
          </ul>

          <Typography marginBottom={2} variant="subtitle1" fontWeight="700">
            4.3 Integrity
          </Typography>
          <Typography marginBottom={2} variant="subtitle1" color="wheatsmoke">
            Integrity is maintained through the following methods:
          </Typography>

          <ul>
            <li>
              <strong>Archiving and backup methods.</strong> Rads Sync provides a multi-tiered archive to ensure
              security of imaging and related records.
            </li>
            <li>
              <strong>Disaster planning support.</strong> Rads Sync supplies fault-tolerant servers and other related
              technologies to support disaster planning when necessary via Amazon Hosting services.
            </li>
          </ul>

          <Typography marginBottom={2} variant="subtitle1" fontWeight="700">
            4.4 Authentication
          </Typography>

          <ul>
            <li>
              <strong>User Authentication.</strong> Username/password system.
            </li>
            <li>
              <strong>Approval and authentication of reports.</strong> User names and passwords are required to
              generate, edit, revise, or correct reports and appropriate audit trails are retained.
            </li>
          </ul>

          <Typography marginBottom={2} variant="subtitle1" fontWeight="700">
            4.5 Transmission security
          </Typography>

          <ul>
            <li>
              <strong>Data encryption.</strong> The system supports data encryption when information is transmitted via
              the Internet, using secure technology such as SSL, TLS, or VPN.
            </li>
            <li>
              <strong>Confidentiality warning.</strong> Appropriate confidentiality notices are displayed when records
              are queried over the Internet.
            </li>
          </ul>
        </Box>

        <Typography marginBottom={2} variant="h5" fontWeight="700">
          5 Agreement to Receive Notifications
        </Typography>
        <Typography marginBottom={2} marginLeft={3} variant="subtitle1" color="wheatsmoke">
          By accepting these terms, the user agrees to receive emails to the email address saved to their user profile.
          Please visit the profile page to opt-in to receiving text messsages. Text messages are disabled by default.
        </Typography>
      </Grid>
    </React.Fragment>
  );
});
