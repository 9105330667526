import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import usersReducer from "../slices/users";
import qaReducer from "../slices/qa";
import hospitalReducer from "../slices/hospital";
import casesReducer from "../slices/cases";
import addEditUserReducer from "../slices/addedituser";
import chatReducer from "../slices/chat";
import settingsReducer from "../slices/settings";
import scheduleReducer from "../slices/schedule";
import announcementsReducer from "../slices/announcement";
import teamChatsReducer from "../slices/teamchats";
import teamDashboardReducer from "../slices/teamdashboard";
import kioskReducer from "../slices/kiosk";
import toastReducer from "../slices/toast";
import newsReducer from "../slices/news";
import reportReducer from "../slices/report";
import hubReducer from "../slices/hub";
import userBreaksReducer from "../slices/userBreak";
import irStudyReducer from "../slices/irStudy";
import interestingCasesReducer from "../slices/interestingCases";
import linkReducer from "../slices/links";
import techChatReducer from "../slices/techChats";

const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  qa: qaReducer,
  hospital: hospitalReducer,
  cases: casesReducer,
  addEditUser: addEditUserReducer,
  chat: chatReducer,
  settings: settingsReducer,
  schedule: scheduleReducer,
  announcements: announcementsReducer,
  teamChats: teamChatsReducer,
  techChats: techChatReducer,
  teamDashboard: teamDashboardReducer,
  kiosk: kioskReducer,
  toast: toastReducer,
  news: newsReducer,
  reports: reportReducer,
  hub: hubReducer,
  userBreaks: userBreaksReducer,
  irStudy: irStudyReducer,
  interestingCases: interestingCasesReducer,
  links: linkReducer,
});

export default rootReducer;
