import { RouteObject, Navigate } from "react-router-dom";

import MainLayout from "./components/layouts/MainLayout";
import DefaultLayout from "./components/layouts/DefaultLayout";
import Dashboard from "./components/pages/Dashboard";
import LoginPage from "./components/pages/LoginPage";
import Settings from "./components/pages/Settings";
import Qa from "./components/pages/Qa";
import Profile from "./components/pages/Profile";
import Hospital from "./components/pages/Hospital";
import AnnouncementHistory from "./components/pages/AnnouncementHistory";
import Schedule from "./components/pages/Schedule";
import Reports from "./components/pages/Reports";
import TeamDashboard from "./components/pages/TeamDashboard";
import CompletedCases from "./components/pages/CompletedCases";
import User from "./types/user";
import ForgotPassword from "./components/views/ForgotPassword";
import ResetPassword from "./components/views/ResetPassword";
import ChangePassword from "./components/pages/ChangePassword";
import StudyPage from "./components/pages/StudyPage";
import InterestingCases from "./components/pages/InterestingCases";

const routes = (
  isLoggedIn: boolean,
  loggedInUser?: User,
  temporaryPassword?: boolean,
  passwordExpired?: boolean
): RouteObject[] => [
  {
    path: "/app",
    element:
      isLoggedIn && !!loggedInUser ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" state={{ redirectPath: window.location.pathname }} />
      ),
    children: [
      { path: "dashboard", element: <Dashboard /> },
      { path: "settings", element: <Settings /> },
      { path: "qa", element: <Qa /> },
      { path: "studies", element: <StudyPage /> },
      { path: "studies/:id", element: <StudyPage /> },
      { path: "interesting-cases", element: <InterestingCases /> },
      { path: "interesting-cases/:id", element: <InterestingCases /> },
      { path: "profile", element: <Profile /> },
      { path: "hospital", element: <Hospital /> },
      { path: "announcement-history", element: <AnnouncementHistory /> },
      { path: "schedule", element: <Schedule /> },
      { path: "reports", element: <Reports /> },
      { path: "team-dashboard", element: <TeamDashboard /> },
      { path: "completed-cases", element: <CompletedCases /> },
      { path: "", element: <Navigate to="/app/dashboard" /> },
    ],
  },
  {
    path: "/",
    element:
      !isLoggedIn || !loggedInUser ? (
        temporaryPassword || passwordExpired ? (
          <Navigate to="/password/change" />
        ) : (
          <DefaultLayout />
        )
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    children: [
      { path: "login", element: <LoginPage /> },
      { path: "forgot", element: <ForgotPassword /> },
      { path: "reset", element: <ResetPassword /> },
      { path: "", element: <Navigate to="/login" /> },
    ],
  },
  {
    path: "/password",
    element: temporaryPassword && <DefaultLayout />,
    children: [
      { path: "change", element: <ChangePassword /> },
      { path: "", element: <Navigate to="/password/change" /> },
    ],
  },
];

export default routes;
