import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormContainer from "../base/FormContainer";
import ContactInformation from "../views/ContactInformation";
import ContactInformationType from "../../types/contactInformation";
import { useSelector, useDispatch } from "../../store";
import {
  fetchSettings,
  updateContactInfo,
  updatePublicNumbers,
  updateSiteLogos,
  updateQGendaSettings,
  updateSoundFiles,
} from "../../slices/settings";
import PublicPhoneNumbers from "../views/PublicPhoneNumbers";
import PublicNumbers from "../../types/publicNumbers";
import SiteLogos from "../views/SiteLogos";
import SiteLogosType from "../../types/siteLogos";
import QGendaSettingsType from "../../types/qGendaSettings";
import QGendaSettings from "../views/QGendaSettings";
import SnackBarToast from "../base/SnackbarToast";
import { fetchBreakOptions, clearBreakOptions } from "../../slices/userBreak";
import SiteSounds from "./SiteSounds";
import SiteSoundsType from "../../types/siteSounds";
import { fetchLinks } from "../../slices/links";
import RadioLinks from "./RadioLinks";

interface Props {}

interface FormComponent {
  formName: string;
  component: React.ReactElement;
}

const SiteSettings: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store.settings);
  const { links } = useSelector((store) => store.links);

  const [complete, setComplete] = useState({
    contact: false,
    publicPhone: false,
    logos: false,
    qGenda: false,
    sounds: false,
    radioLinks: false,
  });

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchBreakOptions());
    dispatch(clearBreakOptions());
    dispatch(fetchLinks());
  }, [dispatch]);

  const handleContactInformationSubmit = (result: ContactInformationType) => {
    dispatch(updateContactInfo(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, contact: true });
      }
    });
  };

  const handlePublicPhoneNumbersSubmit = (result: PublicNumbers[]) => {
    dispatch(updatePublicNumbers(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, publicPhone: true });
      }
    });
  };

  const handleLogosSubmit = (result: SiteLogosType) => {
    dispatch(updateSiteLogos(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, logos: true });
      }
    });
  };

  const handleSoundSubmit = (result: SiteSoundsType) => {
    dispatch(updateSoundFiles(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, sounds: true });
      }
    });
  };

  const handleQGendaSubmit = (result: QGendaSettingsType) => {
    dispatch(updateQGendaSettings(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, qGenda: true });
      }
    });
  };

  const forms: FormComponent[] = [
    {
      formName: "Contact Information",
      component: (
        <ContactInformation
          onSubmit={handleContactInformationSubmit}
          contactInformation={settings?.contactInformation}
        />
      ),
    },
    {
      formName: "Public Phone Numbers",
      component: (
        <PublicPhoneNumbers onSubmit={handlePublicPhoneNumbersSubmit} publicNumbers={settings?.publicNumbers} />
      ),
    },
    {
      formName: "Quick Links",
      component: <RadioLinks />,
    },
    {
      formName: "Logos",
      component: <SiteLogos onSubmit={handleLogosSubmit} siteLogos={settings?.siteLogos} />,
    },
    {
      formName: "Sounds",
      component: <SiteSounds onSubmit={handleSoundSubmit} siteSounds={settings?.siteSounds} />,
    },
    {
      formName: "Integration with QGenda",
      component: <QGendaSettings onSubmit={handleQGendaSubmit} qGendaSettings={settings?.qGendaSettings} />,
    },
  ];

  const closeContactToast = () => {
    setComplete({ ...complete, contact: false });
  };
  const closePublicPhoneToast = () => {
    setComplete({ ...complete, publicPhone: false });
  };
  const closeLogosToast = () => {
    setComplete({ ...complete, logos: false });
  };
  const closeSoundToast = () => {
    setComplete({ ...complete, sounds: false });
  };
  const closeQGendaToast = () => {
    setComplete({ ...complete, qGenda: false });
  };

  return (
    <>
      <Grid container spacing={3} marginBottom={6}>
        {forms.map((form) => (
          <Grid item height="100%" xs={12} key={form.formName}>
            <FormContainer title={form.formName}>{form.component}</FormContainer>
          </Grid>
        ))}
      </Grid>
      <SnackBarToast
        message="Contact Information Changed Successfully"
        type="success"
        open={complete.contact}
        handleClose={closeContactToast}
      />
      <SnackBarToast
        message="Public Phone Numbers Changed Successfully"
        type="success"
        open={complete.publicPhone}
        handleClose={closePublicPhoneToast}
      />
      <SnackBarToast
        message="Logos Changed Successfully"
        type="success"
        open={complete.logos}
        handleClose={closeLogosToast}
      />
      <SnackBarToast
        message="Sounds Changed Successfully"
        type="success"
        open={complete.sounds}
        handleClose={closeSoundToast}
      />
      <SnackBarToast
        message="QGenda Integration Changed Successfully"
        type="success"
        open={complete.qGenda}
        handleClose={closeQGendaToast}
      />
    </>
  );
};

export default SiteSettings;
