import getColorByStatus from "./getColorByStatus";
import getColorByCaseStatus from "./getColorByCaseStatus";
import getIconByRole from "./getIconByRole";
import replaceCharactersWithDashes from "./hideCharacterDashes";
import formatPhoneNumber from "./formatPhoneNumber";
import getSearchQueryString from "./getSearchQueryString";

const helpers = {
  getColorByStatus: getColorByStatus,
  getColorByCaseStatus: getColorByCaseStatus,
  getIconByRole: getIconByRole,
  replaceCharactersWithDashes: replaceCharactersWithDashes,
  formatPhoneNumber: formatPhoneNumber,
  getSearchQueryString: getSearchQueryString,
};

export default helpers;
