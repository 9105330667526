import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { Add } from "@mui/icons-material";
import Modal from "../base/Modal";
import AddEditQa from "./AddEditQa";
import Case from "../../types/case";
import { createQaCase, fetchQaCodes } from "../../slices/qa";
import { useDispatch } from "../../store";
import { fetchHospitals } from "../../slices/hospital";
import { fetchQaUsers, fetchOnlyRads } from "../../slices/users";

interface Props {}

const QaMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    dispatch(fetchHospitals());
    dispatch(fetchQaUsers());
    dispatch(fetchQaCodes());
    setShowModal(true);
  };

  const handleSave = (model: Case) => {
    dispatch(createQaCase(model));
  };

  return (
    <Grid container paddingTop={3} paddingBottom={3}>
      <Grid item>
        <Button color="primary" startIcon={<Add fontSize="small" />} variant="contained" onClick={handleOpen}>
          Add New
        </Button>
        <Modal open={showModal} handleClose={() => setShowModal(false)} title="Create QA Case">
          <AddEditQa onSave={handleSave} onClose={() => setShowModal(false)} />
        </Modal>
      </Grid>
    </Grid>
  );
};

export default QaMenu;
