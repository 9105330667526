import React from "react";
import { Grid, Box, Button } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { fetchReturnReasons, returnToTech } from "../../slices/cases";
import { useDispatch, useSelector } from "../../store";
import { Inputs } from "../forms";
import * as Yup from "yup";

interface OwnProps {
  caseId: number;
  handleClose: () => void;
}

type Props = OwnProps;

const BlockedCasesModal: React.FC<Props> = (props) => {
  const { caseId, handleClose } = props;
  const dispatch = useDispatch();
  const { returnReasons } = useSelector((store) => store.cases);
  const [isWorking, setIsWorking] = React.useState(false);
  const initialValue = {
    reason: undefined,
    comment: "",
  };

  const handleReturn = async (values: any) => {
    setIsWorking(true);
    await dispatch(
      returnToTech({
        caseId: caseId,
        reasonId: values.reason.id,
        commentText: values.comment,
        userId: undefined,
      })
    ).then(() => {
      setIsWorking(false);
    });
    await handleClose();
  };

  const validationSchema = Yup.object().shape({
    reason: Yup.object().nullable().required("Please select a reason"),
    comment: Yup.string(),
  });

  React.useEffect(() => {
    dispatch(fetchReturnReasons());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleReturn(values);
        }}
      >
        {() => (
          <Form noValidate>
            <Grid container spacing={3} flexDirection="column">
              <Grid item xs={12}>
                <Field
                  name="reason"
                  label="Reason"
                  required
                  component={Inputs.Dropdown}
                  options={returnReasons || []}
                />
              </Grid>

              <Grid item xs={12}>
                <Field multiline name="comment" label="Comment" component={Inputs.Text} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
                <Box sx={{ mt: 2 }}>
                  <Button
                    fullWidth
                    color="secondary"
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
                <Box>
                  <Inputs.Submit text={"Return Case"} isSubmitting={isWorking} />
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default BlockedCasesModal;
