import React, { FunctionComponent } from "react";
import { Box, Button, Fade, Grid, Menu, Modal, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
import { SketchPicker } from "react-color";
import Hospital from "../../../types/hospital";
import { useDispatch } from "../../../store";
import { fetchSimilarColor } from "../../../slices/hospital";
import PhoneInput from "react-phone-input-2";
import theme from "../../../theme";
import styled from "styled-components";

const Root = styled.div`
  .react-tel-input {
    .special-label {
      font-size: 0.75rem;
      left: 10px;
      top: -9px;
      color: #838c9a;
      background-color: #252526;
    }
    .form-control {
      margin-top: 1px;
      border-radius: 16px;
      border-color: theme.palette.primary.main;
      background: transparent;
    }
    .country-list {
      background-color: #252526;
      color: #34c1d1;
    }
    .country:hover {
      background-color: #1b1b1c;
      color: #e68627;
    }
    .country.highlight {
      background-color: #5a5a5d;
      color: #e68627;
    }
    .country {
      .dial-code {
        color: #ffffff;
      }
    }
  }
`;

interface OwnProps {
  hospitalDetails: Hospital;
  setHospitalDetails: any;
}

interface WarningModal {
  visible: boolean;
  array:
    | {
        hospitalName: string;
        color: string;
        shortName: string;
      }[]
    | null;
}

type Props = OwnProps;

const HospitalDetails: FunctionComponent<Props> = React.memo((props) => {
  const dispatch = useDispatch();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { badgeColor, location, name, phone, shortName, id, closedMessage } = props.hospitalDetails;
  const { setHospitalDetails } = props;

  const [warningModal, setWarningModal] = React.useState<WarningModal>({
    visible: false,
    array: null,
  });
  const [colorPickerVisible, setColorPickerVisible] = React.useState<null | HTMLElement>(null);

  const handleClosePicker = async () => {
    setColorPickerVisible(null);
    const fixedColor = badgeColor.replace("#", "%23");
    const response = await dispatch(fetchSimilarColor(fixedColor));
    const json: any = response?.payload;
    if (!json) return;
    const filteredHospitals = json
      ?.map((hospital: { id: any; name: any; badgeColor: any; shortName: any }) => {
        return {
          id: hospital.id,
          hospitalName: hospital.name,
          color: hospital.badgeColor,
          shortName: hospital.shortName,
        };
      })
      .filter((hospital: { id: number }) => hospital.id !== id);
    if (filteredHospitals?.length > 0) handleOpenWarningModal(filteredHospitals);
  };

  const handleOpenWarningModal = (array: any) =>
    setWarningModal({
      visible: true,
      array: array,
    });

  const handleCloseWarningModal = () => {
    setWarningModal((prevState) => {
      return {
        ...prevState,
        visible: false,
      };
    });
    setTimeout(() => {
      setWarningModal((prevState) => {
        return {
          ...prevState,
          array: null,
        };
      });
    }, 250);
  };

  return (
    <React.Fragment>
      <Modal open={warningModal.visible} onClose={handleCloseWarningModal}>
        <Fade in={warningModal.visible}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: sm ? 350 : 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid margin={2}>
                <Typography>Warning: These hospitals have the same / similar badge color.</Typography>
                <Typography>Please consider using a different color:</Typography>
                {warningModal.array?.map((hospital, index) => (
                  <Grid key={index} marginTop={1} display="flex" flexDirection="row" alignItems="center">
                    <Typography>{hospital.hospitalName}</Typography>
                    <Box bgcolor={badgeColor} borderRadius={20} marginLeft={1}>
                      <Typography marginLeft={1} marginRight={1} marginTop={0.5} marginBottom={0.5}>
                        {hospital.shortName}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={handleCloseWarningModal}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Hospital"
          value={name}
          onChange={(event) =>
            setHospitalDetails((prevState: any) => {
              return { ...prevState, name: event.target.value };
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Short Name"
          value={shortName}
          onChange={(event) =>
            setHospitalDetails((prevState: any) => {
              return { ...prevState, shortName: event.target.value };
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Root>
          <PhoneInput
            specialLabel="Phone Number"
            countryCodeEditable={false}
            country={"us"}
            placeholder=""
            onlyCountries={["us"]}
            onChange={(event) =>
              setHospitalDetails((prevState: any) => {
                return { ...prevState, phone: event };
              })
            }
            value={phone}
            inputStyle={{
              width: "100%",
              borderColor: `${theme.palette.primary.contrastText}50`,
              color: theme.palette.primary.contrastText,
              borderRadius: "16px",
              backgroundColor: "inherit",
            }}
          />
        </Root>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Location"
          value={location}
          onChange={(event) =>
            setHospitalDetails((prevState: any) => {
              return { ...prevState, location: event.target.value };
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Closed Message"
          value={closedMessage}
          onChange={(event) =>
            setHospitalDetails((prevState: any) => {
              return { ...prevState, closedMessage: event.target.value };
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Box width="fit-content" display="flex" flexDirection="row">
          <Button aria-controls="basic-menu" onClick={(e) => setColorPickerVisible(e.currentTarget)}>
            Change Badge Color
          </Button>
          <Box bgcolor={badgeColor} borderRadius={20}>
            <Typography marginLeft={1} marginRight={1} marginTop={0.5} marginBottom={0.5}>
              {shortName}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={colorPickerVisible}
        open={!!colorPickerVisible}
        onClose={handleClosePicker}
        PaperProps={{
          style: {
            transform: "translateY(10%)",
          },
        }}
      >
        <SketchPicker
          color={badgeColor}
          onChange={({ hex }) =>
            setHospitalDetails((prevState: any) => {
              return { ...prevState, badgeColor: hex };
            })
          }
        />
      </Menu>
    </React.Fragment>
  );
});

export default HospitalDetails;
