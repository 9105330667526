import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Divider, Drawer, Grid, useMediaQuery } from "@mui/material";
import StatusPanel from "../views/StatusPanel";
import { fetchAllCases, getCaseSoundEffect, updateNewCaseVolume } from "../../slices/cases";
import { useDispatch, useSelector } from "../../store";
import Cases from "../views/Cases";
import { clearBreak, fetchScheduledBreak, getBreak, setUsersCollapsed } from "../../slices/users";
import LoggedInUserCard from "../views/LoggedInUserCard";
import { fetchUnreadAnnouncements } from "../../slices/announcement";
import getUserRoleId from "../../helpers/getUserRoleId";
import { fetchSettings } from "../../slices/settings";
import Modal from "../base/Modal";
import { claimSchedule, fetchLatestSchedule, setUserHospital, setWelcomeModalOpened } from "../../slices/schedule";
import WelcomeModal from "../views/WelcomeModal";
import TermsModal from "../views/TermsModal";
import { acceptTerms, setTermsOpened } from "../../slices/auth";
import { getChatSoundEffect, getConversations, updateChatSoundVolume } from "../../slices/chat";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { getTeamChatSoundEffect, getTeamConversations, updateTeamChatVolume } from "../../slices/teamchats";
import { getUnreadCases } from "../../slices/interestingCases";
import { getUnreadStudies } from "../../slices/irStudy";
import theme from "../../theme";
import { getTechConversations } from "../../slices/techChats";

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { latestSchedule, status, welcomeModalOpened } = useSelector((store) => store.schedule);
  const { loggedInUser, terms, openedTermsModal, status: authStatus } = useSelector((store) => store.auth);
  const { scheduledBreak, usersCollapsed } = useSelector((store) => store.users);
  const { kiosk } = useSelector((store) => store.kiosk);
  const [isAccepting, setIsAccepting] = useState(false);
  const [modal, setModal] = useState({
    visible: false,
    type: "Security Docs/Terms & Conditions",
  });
  const [windowWidth, setWindowWidth] = useState(0);

  const sm = useMediaQuery(theme.breakpoints.down("md"));

  React.useLayoutEffect(() => {
    const updateSize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (sm) {
      dispatch(setUsersCollapsed(true));
    }
  }, [dispatch, sm]);

  const handleWelcomeModal = useCallback(() => {
    const handleOpenWelcomeModal = () => {
      setModal({
        visible: true,
        type: "Welcome!",
      });
      dispatch(setWelcomeModalOpened(true));
    };

    if (!!loggedInUser?.roles.find((r) => r.id === 3) || welcomeModalOpened) return;
    const foundUser = latestSchedule?.find(
      (e) => e.email === loggedInUser?.email || e.claimedUser?.id === loggedInUser?.id
    );
    if (foundUser) {
      dispatch(setUserHospital(foundUser.hospital));
    } else {
      dispatch(setUserHospital(undefined));
    }
    if (latestSchedule && latestSchedule.length > 0 && !foundUser) {
      handleOpenWelcomeModal();
    }
  }, [dispatch, latestSchedule, loggedInUser?.email, loggedInUser?.id, loggedInUser?.roles, welcomeModalOpened]);

  useEffect(() => {
    if (terms === undefined || authStatus === "loading" || status === "loading") return;

    const handleOpenTermsModal = () => {
      setModal({
        visible: true,
        type: "Security Docs/Terms & Conditions",
      });
      dispatch(setTermsOpened(true));
    };

    const handleTermsModal = () => {
      if (terms) return handleWelcomeModal();
      if (!openedTermsModal) handleOpenTermsModal();
    };

    handleTermsModal();
  }, [dispatch, status, authStatus, terms, handleWelcomeModal, openedTermsModal]);

  useEffect(() => {
    const getSounds = () => {
      dispatch(getCaseSoundEffect());
      dispatch(getChatSoundEffect());
      dispatch(getTeamChatSoundEffect());
    };

    dispatch(fetchLatestSchedule());
    dispatch(fetchAllCases());
    dispatch(fetchSettings()).then((result: any) => {
      if (result && result.payload && result.payload.siteSounds && result.payload.siteSounds.newCaseVolume) {
        dispatch(updateNewCaseVolume(result.payload.siteSounds.newCaseVolume));
      }
      if (result && result.payload && result.payload.siteSounds && result.payload.siteSounds.teamChatVolume) {
        dispatch(updateTeamChatVolume(result.payload.siteSounds.teamChatVolume));
      }
      if (result && result.payload && result.payload.siteSounds && result.payload.siteSounds.chatSoundVolume) {
        dispatch(updateChatSoundVolume(result.payload.siteSounds.chatSoundVolume));
      }
    });
    getSounds();
  }, [dispatch]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.id) {
      if (getUserRoleId(loggedInUser) !== 3) {
        dispatch(getConversations());
        dispatch(getTeamConversations());
        dispatch(fetchUnreadAnnouncements());
        dispatch(fetchScheduledBreak(loggedInUser?.id));

        if (loggedInUser.status?.id! > 2) {
          dispatch(getBreak(loggedInUser?.id));
        } else {
          dispatch(clearBreak());
        }
      }

      dispatch(getTechConversations());
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    dispatch(getUnreadCases());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUnreadStudies());
  }, [dispatch]);

  const switchModal = () => {
    handleCloseWelcomeModal();
    setTimeout(handleWelcomeModal, 250);
  };

  const handleCloseWelcomeModal = () =>
    setModal((prevState) => {
      return {
        ...prevState,
        visible: false,
      };
    });

  const handleClaimShift = (id: number) => {
    handleCloseWelcomeModal();
    dispatch(claimSchedule(id));
  };

  const handleCloseFromTerms = async (accept: boolean) => {
    try {
      if (accept) {
        setIsAccepting(true);
        await dispatch(acceptTerms(loggedInUser?.id || 0));
        setIsAccepting(false);
      }
      switchModal();
    } catch (e) {}
  };

  return (
    <React.Fragment>
      <Modal open={modal.visible} handleClose={handleCloseWelcomeModal} title={modal.type}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ height: "50vh", width: "50px" }}>
            <SimpleBar style={{ height: "100%" }}>
              <Box sx={{ overflow: "hidden" }}>
                {modal.type === "Welcome!" && (
                  <WelcomeModal schedule={latestSchedule || []} handleClaimShift={handleClaimShift} />
                )}
                {modal.type === "Security Docs/Terms & Conditions" && <TermsModal />}
              </Box>
            </SimpleBar>
          </Grid>
          <Divider />
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            {/* {modal.type === "Security Docs/Terms & Conditions" && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleCloseFromTerms(false)}
                sx={{ marginRight: 2 }}
                disabled={isAccepting}
              >
                Dismiss
              </Button>
            )} */}
            <Button
              variant="contained"
              color="primary"
              onClick={modal.type === "Welcome!" ? handleCloseWelcomeModal : () => handleCloseFromTerms(true)}
              disabled={isAccepting}
            >
              {modal.type === "Welcome!" ? "No Thanks" : "Accept"}
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Box py={0}>
        {getUserRoleId(loggedInUser) !== 3 && (
          <Box sx={{ display: "flex", flexDirection: "row", pb: 2 }}>
            <Drawer
              anchor="left"
              variant="permanent"
              open={!usersCollapsed}
              sx={{
                width: usersCollapsed ? "110px" : "310px",
                whiteSpace: "nowrap",
                boxSizing: "border-box",
                "& .MuiDrawer-paper": {
                  height: usersCollapsed ? "100px" : "100%",
                  backgroundColor: "inherit",
                  borderStyle: "none",
                  marginLeft: "5px",
                  mt: "100px",
                },
              }}
            >
              <LoggedInUserCard collapsed={usersCollapsed} windowWidth={windowWidth} />
            </Drawer>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Drawer
            anchor="left"
            variant="permanent"
            open={!usersCollapsed}
            sx={{
              // width: usersCollapsed ? "110px" : "310px",
              whiteSpace: "nowrap",
              boxSizing: "border-box",
              "& .MuiDrawer-paper": {
                height: usersCollapsed && sm ? "100px" : "100%",
                backgroundColor: "inherit",
                borderStyle: "none",
                marginLeft: 0,
                mt:
                  getUserRoleId(loggedInUser) === 3
                    ? "98px"
                    : loggedInUser?.status?.id! > 2 && !usersCollapsed
                    ? "268px"
                    : !!scheduledBreak && !usersCollapsed
                    ? "268px"
                    : usersCollapsed
                    ? "187px"
                    : "268px",
              },
            }}
          >
            <StatusPanel kiosk={kiosk} windowWidth={windowWidth} />
          </Drawer>
          <Box
            component="main"
            sx={{ display: { xs: usersCollapsed ? "flex" : "none", md: "flex" }, flexDirection: "column", flexGrow: 1 }}
          >
            <Cases kiosk={kiosk} collapsed={usersCollapsed} windowWidth={windowWidth} />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
