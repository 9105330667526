import React, { useMemo } from "react";
import { FieldProps, useFormikContext } from "formik";
import { TextFieldProps } from "@mui/material/TextField";
import { useDropzone } from "react-dropzone";
import theme from "../../theme";

const baseStyle = {
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#fff",
  borderStyle: "dashed",
  backgroundColor: "inherit",
  color: theme.palette.quinary.main,
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const activeStyle = {
  borderColor: theme.palette.quarternary.main,
};

const acceptStyle = {
  borderColor: theme.palette.success.main,
};

const rejectStyle = {
  borderColor: theme.palette.tertiary.main,
};

const FormikAutocomplete: React.FC<FieldProps & TextFieldProps> = (props) => {
  const { field } = props;
  const { setFieldValue } = useFormikContext();

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, isDragAccept, isDragReject } = useDropzone({
    accept:
      "image/*, audio/*, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/ppt, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",

    onDrop: (acceptedFiles) => {
      setFieldValue(field.name, acceptedFiles);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>Drag and drop a file here, or click to select</p>
        </div>
      </div>
      <ul>{files}</ul>
    </>
  );
};

export default FormikAutocomplete;
