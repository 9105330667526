import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";

interface AnnouncementState {
  totalCases?: number;
  studies?: number;
  wus?: number;
  reading?: number;
  utilization?: number;
  status: "idle" | "loading" | "failed";
}

const initialState: AnnouncementState = {
  status: "idle",
};

export const fetchTotalCases = createAsyncThunk<number>("cases/fetch", async () => {
  const response = await http.get<number>("/dashboard/cases");
  return response.data;
});

export const fetchStudies = createAsyncThunk<number>("studies/fetch", async () => {
  const response = await http.get<number>("/dashboard/studies");
  return response.data;
});

export const fetchWus = createAsyncThunk<number>("wus/fetch", async () => {
  const response = await http.get<number>("/dashboard/wus");
  return response.data;
});

export const fetchReading = createAsyncThunk<number>("reading/fetch", async () => {
  const response = await http.get<number>("/dashboard/reading");
  return response.data;
});

export const fetchUtilization = createAsyncThunk<number>("utilization/fetch", async () => {
  const response = await http.get<number>("/dashboard/utilization");
  return response.data;
});

const casesSlice = createSlice({
  name: "teamdashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Total Cases
      .addCase(fetchTotalCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTotalCases.fulfilled, (state, action) => {
        state.status = "idle";
        state.totalCases = action.payload;
      })
      .addCase(fetchTotalCases.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch Studies
      .addCase(fetchStudies.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStudies.fulfilled, (state, action) => {
        state.status = "idle";
        state.studies = action.payload;
      })
      .addCase(fetchStudies.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch WUs
      .addCase(fetchWus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWus.fulfilled, (state, action) => {
        state.status = "idle";
        state.wus = action.payload;
      })
      .addCase(fetchWus.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch Reading
      .addCase(fetchReading.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReading.fulfilled, (state, action) => {
        state.status = "idle";
        state.reading = action.payload;
      })
      .addCase(fetchReading.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch Utilization
      .addCase(fetchUtilization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUtilization.fulfilled, (state, action) => {
        state.status = "idle";
        state.utilization = action.payload;
      })
      .addCase(fetchUtilization.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default casesSlice.reducer;
