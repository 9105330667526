import React from "react";
import { Grid, Fab, Tooltip, Typography, useMediaQuery } from "@mui/material";
import {
  FreeBreakfastRounded,
  GroupsRounded,
  CallRounded,
  MedicalServicesRounded,
  AssignmentRounded,
  TimerRounded,
  ReplayRounded,
  Description,
} from "@mui/icons-material";
import { Card } from "@mui/material";
import theme from "../../theme";
import { useDispatch, useSelector } from "../../store";
import helpers from "../../helpers";
import { changeState } from "../../slices/auth";
import { logBreak, setUsersCollapsed } from "../../slices/users";
import moment from "moment";

interface Props {}

const BreakCard: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { breakTime, temporaryOpen } = useSelector((store) => store.users);
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const getIcon = (typeId: number) => {
    switch (typeId) {
      case 1:
        return <FreeBreakfastRounded />;
      case 2:
        return <GroupsRounded />;
      case 3:
        return <CallRounded />;
      case 4:
        return <MedicalServicesRounded />;
      case 5:
        return <AssignmentRounded />;
      case 6:
        return <TimerRounded />;
      case 7:
        return <Description />;
      default:
        break;
    }
  };

  const handleReturn = () => {
    dispatch(changeState({ id: 1, value: "Online", return: 0 }));
    dispatch(
      logBreak({
        ...breakTime,
        userId: loggedInUser?.id,
        returnTime: moment().toDate(),
        watching: false,
        returned: true,
      })
    );
    if (temporaryOpen) {
      dispatch(setUsersCollapsed(true));
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: helpers.getColorByStatus(loggedInUser?.status?.id!),
        minHeight: { xs: "132px", md: undefined },
      }}
    >
      <Grid
        container
        direction={{ xs: "column", sm: "row" }}
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "space-between",
          height: "fit-content",
          padding: "15px",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Fab
            sx={{
              "&:hover": {
                cursor: "default",
              },
              backgroundColor: "#fff",
              color: helpers.getColorByStatus(loggedInUser?.status?.id!),
            }}
            size="small"
          >
            {getIcon(breakTime?.breakType?.id!)}
          </Fab>
          <Typography variant="h6" textAlign={{ xs: "center", sm: "left" }}>
            {breakTime?.breakType?.value}
          </Typography>
        </Grid>
        {!xs && (
          <>
            <Grid item sx={{}}>
              <Typography variant="h6">{breakTime?.hospital?.name}</Typography>
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Typography variant="h6">{breakTime?.details}</Typography>
            </Grid>
          </>
        )}
        <Grid item sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Fab sx={{ backgroundColor: theme.palette.success.main, color: "white" }} size="small" onClick={handleReturn}>
            <Tooltip title={<Typography variant="button">Return</Typography>}>
              <ReplayRounded sx={{ fontSize: 20 }} />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BreakCard;
