import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { deleteCase } from "../../slices/cases";
import { useDispatch } from "../../store";

interface Props {
  onClose: () => void;
  caseId: number;
}

const DeleteCase: React.FC<Props> = (props) => {
  const { onClose, caseId } = props;
  const dispatch = useDispatch();

  return (
    <Box>
      <Box>
        <Typography>Are you sure you want to close this case?</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-around", mt: "25px" }}>
        <Button color="secondary" type="button" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="button"
          variant="contained"
          onClick={async () => {
            await dispatch(deleteCase(caseId));
            onClose();
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteCase;
