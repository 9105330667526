import React, { ChangeEvent } from "react";
import { FieldProps, getIn } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface Props {
  onBlur?: () => void;
}

const FormikTextField: React.FC<FieldProps & TextFieldProps & Props> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, variant, margin, required, onBlur, onChange, ...rest } = props;

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    field.onChange(event);
    onChange?.(event);
  };

  return (
    <TextField
      multiline
      error={error ?? Boolean(isTouched && errorMessage)}
      fullWidth
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      margin={margin ?? "normal"}
      variant={variant ?? "outlined"}
      required={required ?? false}
      {...rest} // Material UI Props
      {...field} // Formik Props
      onBlur={props.onBlur ? props.onBlur : () => {}}
      onChange={handleChange}
      inputProps={{
        spellCheck: "true",
      }}
    />
  );
};

export default FormikTextField;
