import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Hospital from "../types/hospital";
import HospitalDepartment from "../types/hospitalDepartment";
import HospitalSummary from "../types/hospitalSummary";
import Modality from "../types/modality";
import http from "../api/http";
import ExamType from "../types/examType";

interface HospitalState {
  hospital?: Hospital | undefined;
  hospitals: HospitalSummary[] | [];
  hospitalDepartments: HospitalDepartment[] | [];
  hospitalModalities: Modality[] | [];
  examTypes: ExamType[];
  allModalities: Modality[];
  status: "idle" | "loading" | "failed";
}

const initialState: HospitalState = {
  hospitals: [],
  hospitalDepartments: [],
  hospitalModalities: [],
  examTypes: [],
  allModalities: [],
  status: "idle",
};

export const fetchHospital = createAsyncThunk<Hospital, number>("hospital/fetch", async (id: number) => {
  const response = await http.get<Hospital>(`hospitals/${id}`);
  return response.data;
});

export const fetchHospitals = createAsyncThunk<HospitalSummary[]>("hospitals/fetch", async () => {
  const response = await http.get<HospitalSummary[]>("hospitals");
  return response.data;
});

export const fetchHospitalOptions = createAsyncThunk<Hospital, number>("hospital/fetch-options", async (id: number) => {
  const response = await http.get<Hospital>(`hospitals/${id}/options`);
  return response.data;
});

export const fetchExamTypes = createAsyncThunk<ExamType[], { hospitalId: number; modalityId: number }>(
  "hospital/fetch-exams",
  async ({ hospitalId, modalityId }) => {
    const response = await http.get<ExamType[]>(`hospitals/${hospitalId}/modality-exams/${modalityId}`);
    return response.data;
  }
);

export const fetchAllModalities = createAsyncThunk<Modality[], undefined>("hospital/fetch-modalities", async () => {
  const response = await http.get<Modality[]>("hospitals/modality-exams");
  return response.data;
});

export const addHospital = createAsyncThunk<Modality[], Hospital>("hospital/add", async (model: Hospital) => {
  const response = await http.post<Modality[]>("hospitals", { ...model });
  return response.data;
});

export const updateHospital = createAsyncThunk<Modality[], Hospital>("hospital/update", async (model: Hospital) => {
  const response = await http.put<Modality[]>(`hospitals/${model?.id}`, { ...model });
  return response.data;
});

export const updateHospitalOrder = createAsyncThunk<HospitalSummary[], HospitalSummary[]>(
  "hospital/update-order",
  async (model: HospitalSummary[]) => {
    const response = await http({
      method: "patch",
      url: "hospitals/order",
      data: model,
      headers: { "Content-type": "application/json" },
    });

    return response.data;
  }
);

export const deleteHospital = createAsyncThunk<Modality[], number>("hospital/delete", async (hospitalId: number) => {
  const response = await http.delete<Modality[]>(`hospitals/${hospitalId}`);
  return response.data;
});

export const fetchSimilarColor = createAsyncThunk<Modality[], string>("hospital/delete", async (color: string) => {
  const response = await http.get<Modality[]>(`hospitals/similar-color?color=${color}`);
  return response.data;
});

export const closeHospital = createAsyncThunk<any, number>("hospital/close", async (hospitalId: number) => {
  const response = await http.patch<Modality[]>(`hospitals/${hospitalId}/closed`);
  return response.data;
});

export const openHospital = createAsyncThunk<any, number>("hospital/open", async (hospitalId: number) => {
  const response = await http.patch<Modality[]>(`hospitals/${hospitalId}/open`);
  return response.data;
});

const hospitalSlice = createSlice({
  name: "hospital",
  initialState,
  reducers: {
    updateHospitals(state, action: PayloadAction<HospitalSummary[]>) {
      let list = action.payload.map((h, index) => {
        return {
          ...h,
          order: index + 1,
        };
      });
      state.hospitals = list;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Hospital
      .addCase(fetchHospital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHospital.fulfilled, (state, action) => {
        state.status = "idle";
        state.hospital = action.payload;
      })
      .addCase(fetchHospital.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch Hospital List
      .addCase(fetchHospitals.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHospitals.fulfilled, (state, action) => {
        state.status = "idle";
        state.hospitals = action.payload;
      })
      .addCase(fetchHospitals.rejected, (state) => {
        state.status = "failed";
      })

      // Update Hospital Order
      .addCase(updateHospitalOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateHospitalOrder.fulfilled, (state, action) => {
        state.status = "idle";
        state.hospitals = action.payload;
      })
      .addCase(updateHospitalOrder.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch Hospital Options
      .addCase(fetchHospitalOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHospitalOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.hospitalDepartments = action.payload.hospitalDepartments!;
        state.hospitalModalities = action.payload.modalities!;
      })
      .addCase(fetchHospitalOptions.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch Exam Types
      .addCase(fetchExamTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExamTypes.fulfilled, (state, action) => {
        state.status = "idle";
        state.examTypes = action.payload;
      })
      .addCase(fetchExamTypes.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch All Modalities
      .addCase(fetchAllModalities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllModalities.fulfilled, (state, action) => {
        state.status = "idle";
        state.allModalities = action.payload;
      })
      .addCase(fetchAllModalities.rejected, (state) => {
        state.status = "failed";
      })

      // Add Hospital
      .addCase(addHospital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addHospital.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(addHospital.rejected, (state) => {
        state.status = "failed";
      })

      // Update Hospital
      .addCase(updateHospital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateHospital.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(updateHospital.rejected, (state) => {
        state.status = "failed";
      })

      // Close Hospital
      .addCase(closeHospital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(closeHospital.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(closeHospital.rejected, (state) => {
        state.status = "failed";
      })

      // Open Hospital
      .addCase(openHospital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(openHospital.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(openHospital.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { updateHospitals } = hospitalSlice.actions;

export default hospitalSlice.reducer;
