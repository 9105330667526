import React, { FunctionComponent } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { HighlightOff } from "@mui/icons-material";

interface OwnProps {
  schedule: {
    order: 0;
    dayOfWeek: number;
    openHour: number;
    openMinute: number;
    closeHour: number;
    closeMinute: number;
  }[];
  setSchedule: any;
}

type Days = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";

interface TimePickerInterface {
  anchor: null | HTMLElement;
  time: null | {
    type: "open" | "close";
    day: number;
  };
}

type Props = OwnProps;

const days: Days[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const HospitalOnOffTable: FunctionComponent<Props> = React.memo((props) => {
  const { schedule, setSchedule } = props;

  const [newTime, setNewTime] = React.useState<Date | null>(null);
  const [timePickerHook, setTimePickerHook] = React.useState<TimePickerInterface>({
    anchor: null,
    time: null,
  });

  const handleOpenTimePicker = (anchor: TimePickerInterface["anchor"], time: TimePickerInterface["time"]) =>
    setTimePickerHook({
      anchor: anchor,
      time: time,
    });

  const handleCloseTimePicker = () => {
    setTimePickerHook({
      anchor: null,
      time: null,
    });
    handleAddTime();
  };

  const handleAddTime = () =>
    setTimeout(() => {
      if (newTime === null) return;
      const { time }: any = timePickerHook;
      const foundScheduleIndex = schedule.findIndex((s) => s?.dayOfWeek === time.day);

      let temp = [...schedule];

      const prevSchedule = temp[foundScheduleIndex];
      const newHours = new Date(newTime).getHours();
      const newMinutes = new Date(newTime).getMinutes();
      temp[foundScheduleIndex] =
        time.type === "open"
          ? {
              ...prevSchedule,
              openHour: newHours,
              openMinute: newMinutes,
            }
          : {
              ...prevSchedule,
              closeHour: newHours,
              closeMinute: newMinutes,
            };
      setSchedule([...temp]);
      setNewTime(null);
    }, 250);

  const sortedDays = [...schedule].sort((a, b) => {
    if (a.dayOfWeek === b.dayOfWeek) return 0;
    return a.dayOfWeek > b.dayOfWeek ? 1 : -1;
  });

  const handleRemoveTime = (dayOfWeek: number, type: "open" | "close") => {
    const foundScheduleIndex = schedule.findIndex((s) => s?.dayOfWeek === dayOfWeek);
    let temp = [...schedule];
    const prevSchedule = temp[foundScheduleIndex];
    temp[foundScheduleIndex] =
      type === "open"
        ? {
            ...prevSchedule,
            openHour: 0,
            openMinute: 0,
          }
        : {
            ...prevSchedule,
            closeHour: 0,
            closeMinute: 0,
          };
    setSchedule([...temp]);
    setNewTime(null);
  };

  const fixDigits = (digit: number | string) => (`${digit}`.length === 1 ? `0${digit}` : `${digit}`);

  return (
    <React.Fragment>
      <Menu open={Boolean(timePickerHook.anchor)} onClose={handleCloseTimePicker} anchorEl={timePickerHook.anchor}>
        {/* @ts-ignore */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={3}>
            <Grid item margin={1}>
              <TimePicker
                clearable
                ampm={false}
                label="Time"
                minutesStep={5}
                value={newTime}
                onChange={(newValue: Date | null) => {
                  setNewTime(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Menu>

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h6">
              Automatic On/Off Times
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Day</TableCell>
                <TableCell>Auto On</TableCell>
                <TableCell>Auto Off</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedDays.map((schedule) => {
                const { openHour, openMinute, closeHour, closeMinute } = schedule;
                const hasOpenTime = (() => {
                  return !(
                    (openHour === null || openHour === undefined) &&
                    (openHour === null || openHour === undefined)
                  );
                })();
                const hasCloseTime = (() => {
                  return !(
                    (closeHour === null || closeHour === undefined) &&
                    (closeMinute === null || closeMinute === undefined)
                  );
                })();
                return (
                  <TableRow key={schedule.dayOfWeek}>
                    <TableCell>{days[schedule.dayOfWeek]}</TableCell>
                    <TableCell>
                      <Button
                        color="inherit"
                        onClick={(e: { currentTarget: HTMLElement | null }) =>
                          handleOpenTimePicker(e.currentTarget, {
                            day: schedule.dayOfWeek,
                            type: "open",
                          })
                        }
                      >
                        {hasOpenTime ? `${fixDigits(openHour)}:${fixDigits(openMinute)}` : "Enter a time"}
                      </Button>
                      {hasOpenTime && (
                        <IconButton onClick={() => handleRemoveTime(schedule.dayOfWeek, "open")}>
                          <HighlightOff />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        color="inherit"
                        onClick={(e: { currentTarget: HTMLElement | null }) =>
                          handleOpenTimePicker(e.currentTarget, {
                            day: schedule.dayOfWeek,
                            type: "close",
                          })
                        }
                      >
                        {hasCloseTime ? `${fixDigits(closeHour)}:${fixDigits(closeMinute)}` : "Enter a time"}
                      </Button>
                      {hasCloseTime && (
                        <IconButton onClick={() => handleRemoveTime(schedule.dayOfWeek, "close")}>
                          <HighlightOff />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
});

export default HospitalOnOffTable;
