import React, { ReactNode } from "react";
import { Paper } from "@mui/material";
import styled from "styled-components";
import theme from "../../theme";

const Root = styled.div<{ color?: string }>`
  .paper {
    width: 100%;
    border: 3px solid ${(props) => props.color || theme.palette.background.paper};
  }
`;

interface Props {
  children?: ReactNode;
  color?: string;
}

const Card: React.FC<Props> = (props) => {
  const { children, color } = props;

  return (
    <Root color={color!}>
      <Paper className="paper" elevation={10}>
        {children}
      </Paper>
    </Root>
  );
};

export default Card;
