import React, { useEffect } from "react";
import { Box, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import { fetchAssignedCases, fetchCreatedCases, fetchJudgementOptions, fetchQaCodes } from "../../slices/qa";
import { fetchHospitals } from "../../slices/hospital";
import Case from "../../types/case";
import QaCase from "../views/QaCase";
import theme from "../../theme";
import QaMenu from "../views/QaMenu";

interface Props {}

const Qa: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { userAssignedQaCases, userCreatedQaCases } = useSelector((store) => store.qa);
  const { loggedInUser } = useSelector((store) => store.auth);

  useEffect(() => {
    dispatch(fetchAssignedCases());
    dispatch(fetchCreatedCases());
    dispatch(fetchHospitals());
    dispatch(fetchQaCodes());
    dispatch(fetchJudgementOptions());
  }, [dispatch, loggedInUser]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Typography color="textPrimary" variant="h5" marginBottom="5px">
          My QA Cases
        </Typography>
        <Divider />
        <Grid container width="100%" marginLeft={3}>
          <QaMenu />
        </Grid>
        <Grid container width="100%" marginBottom={3} py={3}>
          <Box sx={{ marginTop: "10px", marginLeft: "15px" }}>
            <Box>Assigned to Me ({userAssignedQaCases?.length})</Box>
          </Box>
          {userAssignedQaCases?.map((assignedCase: Case) => (
            <Paper sx={{ width: "100%", margin: "20px" }} elevation={10} key={assignedCase.id}>
              <QaCase case={assignedCase} />
            </Paper>
          ))}
        </Grid>
        <Divider />
        <Grid container width="100%" marginBottom={3} py={3}>
          <Box sx={{ marginTop: "10px", marginLeft: "15px" }}>
            <Box>Opened by Me ({userCreatedQaCases?.length})</Box>
          </Box>
          {userCreatedQaCases?.map((createdCase: Case) => (
            <Paper sx={{ width: "100%", margin: "20px" }} elevation={10} key={createdCase.id}>
              <QaCase case={createdCase} />
            </Paper>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Qa;
