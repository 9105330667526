import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import Case from "../types/case";
import Option from "../types/option";
import { Howl } from "howler";
import SearchResults from "../types/searchResults";
import SearchParams from "../types/searchParams";
import helpers from "../helpers";

interface CaseState {
  newCase: any;
  allCases: Case[] | null;
  criticalValues: Option[] | null;
  urgencies: Option[] | null;
  pendingCases: Case[] | null;
  inProgressCases: Case[] | null;
  currentUserCases: Case[] | null;
  blockedCases: Case[] | null;
  returnReasons: Case[] | null;
  recentCases: Case[] | null;
  completedCases: Case[] | null;
  urgencyStatus: "idle" | "loading" | "failed";
  caseStatus: "idle" | "loading" | "failed";
  pendingStatus: "idle" | "loading" | "failed";
  userProgressStatus: "idle" | "loading" | "failed";
  teamProgressStatus: "idle" | "loading" | "failed";
  blockedStatus: "idle" | "loading" | "failed";
  returnReasonsStatus: "idle" | "loading" | "failed";
  recentCasesStatus: "idle" | "loading" | "failed";
  completedStatus: "idle" | "loading" | "failed";
  caseSearch?: SearchResults;
  newCaseVolume: number;
  newCaseSound: string;
}

const initialState: CaseState = {
  newCase: {
    patientId: undefined,
    firstName: "",
    lastName: "",
    modality: null,
    examTypes: [],
    hospital: null,
    hospitalDepartment: null,
    urgency: {
      id: 3,
      value: "Routine",
    },
    criticalValue: null,
    comments: [],
    status: { id: 1, value: "" },
    openedByUser: null,
    currentUser: null,
    created: undefined,
    caseDate: undefined,
    isTrauma: false,
    assignedToUser: null,
    qaError: null,
  },
  allCases: null,
  criticalValues: null,
  urgencies: null,
  pendingCases: null,
  inProgressCases: null,
  currentUserCases: null,
  blockedCases: null,
  returnReasons: null,
  recentCases: null,
  completedCases: null,
  urgencyStatus: "idle",
  caseStatus: "idle",
  pendingStatus: "idle",
  userProgressStatus: "idle",
  teamProgressStatus: "idle",
  blockedStatus: "idle",
  returnReasonsStatus: "idle",
  recentCasesStatus: "idle",
  completedStatus: "idle",
  newCaseVolume: 1,
  newCaseSound: "",
};

export const fetchCase = createAsyncThunk<Case, number>("case/fetch", async (caseId: number) => {
  const response = await http.get<Case>(`/cases/${caseId}`);
  return response.data;
});

export const fetchAllCases = createAsyncThunk<Case[] | null>("cases/fetch", async () => {
  const response = await http.get<Case[]>("/cases/dashboard");
  return response.data;
});

export const fetchUrgencies = createAsyncThunk<Option[] | null>("urgencies/fetch", async () => {
  const response = await http.get("/cases/urgency");
  return response.data;
});

export const fetchCriticalValues = createAsyncThunk<Option[] | null>("critical-values/fetch", async () => {
  const response = await http.get("/cases/critical-value");
  return response.data;
});

export const updateCriticalValues = createAsyncThunk<Option[], Option[]>(
  "cases/critical-update",
  async (values: Option[]) => {
    const response = await http.put("/cases/critical-value", values);
    return response.data;
  }
);

export const fetchCompletedCases = createAsyncThunk<Case[] | null>("completed-cases/fetch", async () => {
  const response = await http.get<Case[]>("/cases?completed=true");
  return response.data;
});

export const claimPendingCase = createAsyncThunk<Case>("claim/fetch", async () => {
  const response = await http.post("/cases");
  return response.data;
});

export const addCase = createAsyncThunk<Case, Case>("cases/add", async (model: Case) => {
  const response = await http.post("/cases", { ...model });
  return response.data;
});

export const updateCase = createAsyncThunk<Case, Case>("cases/edit", async (model: Case) => {
  const response = await http.put(`/cases/${model?.id}`, { ...model });
  return response.data;
});

export const fetchReturnReasons = createAsyncThunk("cases/return-reasons", async () => {
  const response = await http.get("cases/return-reasons");
  return response.data;
});

export const returnToTech = createAsyncThunk<any, any, any>("cases/return-to-tech", async (model: any) => {
  const { caseId, reasonId, commentText, userId } = model;
  const response = await http.patch(`/cases/${caseId}/return?reasonId=${reasonId || 0}`, {
    commentText: commentText,
    caseId: caseId,
    commentType: {
      id: 1,
      value: "",
    },
    user: {
      id: userId,
    },
  });
  return response.data;
});

export const deleteCase = createAsyncThunk<Case, number>("cases/delete", async (caseId: number) => {
  const response = await http.delete(`cases/${caseId}`);
  return response.data;
});

export const searchCases = createAsyncThunk<SearchResults, SearchParams>(
  "cases/search",
  async (model: SearchParams) => {
    let qs = helpers.getSearchQueryString(model);
    const response = await http.get<SearchResults>(`cases/search?${qs}`);
    return response.data;
  }
);

export const getCaseSoundEffect = createAsyncThunk<string>("settings/case-sound-get", async () => {
  const response = await http.get("settings/case-sound");
  return response.data;
});

const splitCases = (state: CaseState, cases: Case[]) => {
  if (cases && cases.length > 0) {
    let pending = cases?.filter((c: Case) => c.status?.id === 1)!;

    var caseSound = new Howl({
      src: ["data:audio/mp3;base64," + state.newCaseSound],
      volume: state.newCaseVolume,
    });

    if (pending?.length! > state.pendingCases?.length!) {
      caseSound.play();
    }

    state.pendingCases = pending;

    let blocked = cases?.filter((c: Case) => c.status.id === 8)!;
    state.blockedCases = blocked;

    let userProgress = cases?.filter((c: Case) => c.status.id === 2)!;
    state.inProgressCases = userProgress;

    const sort = (array: Case[]) => {
      const sorter = (a: Case, b: Case) => {
        return new Date(b.completed!).getTime() - new Date(a.completed!).getTime();
      };
      array.sort(sorter);
    };
    let recent = cases?.filter((c: Case) => c.status.id === 3)!;
    sort(recent);
    state.recentCases = recent;

    let qa = cases?.filter((c: Case) => c.status.id === 4 || c.status.id === 5 || c.status.id === 6)!;
    state.currentUserCases = qa;
  }
};

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    updateValuesList(state, action: PayloadAction<Option[]>) {
      state.criticalValues = action.payload;
    },
    clearValues(state) {
      state.criticalValues = [];
    },
    clearResults(state) {
      state.newCase = initialState.newCase;
    },
    replaceCase(state, action) {
      let cases = state.allCases;
      let editCase = cases && cases?.find((c) => c.id === action.payload?.id);

      if (!!editCase) {
        const index = state.allCases?.indexOf(editCase);
        if (index! > -1) {
          cases![index!] = action.payload;
        }
      } else {
        cases?.push(action.payload);
      }

      state.allCases = cases;
      splitCases(state, cases!);
      state.caseStatus = "idle";
    },
    removeCase(state, action) {
      let cases = state.allCases;
      let removedCase = cases?.find((c) => c.id === action.payload);

      if (!!removedCase) {
        const index = cases?.indexOf(removedCase);
        if (index! > -1) {
          cases?.splice(index!, 1);
        }
      }

      state.allCases = cases;
      splitCases(state, cases!);
      state.caseStatus = "idle";
    },
    updateNewCaseVolume(state, action) {
      state.newCaseVolume = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Case
      .addCase(fetchCase.pending, (state) => {
        state.caseStatus = "loading";
      })
      .addCase(fetchCase.fulfilled, (state, action) => {
        state.caseStatus = "idle";
        state.newCase = action.payload;
      })
      .addCase(fetchCase.rejected, (state) => {
        state.caseStatus = "failed";
      })

      // Fetch Cases
      .addCase(fetchAllCases.pending, (state) => {
        state.caseStatus = "loading";
      })
      .addCase(fetchAllCases.fulfilled, (state, action) => {
        state.allCases = action.payload;
        splitCases(state, action.payload!);
        state.caseStatus = "idle";
      })
      .addCase(fetchAllCases.rejected, (state) => {
        state.caseStatus = "failed";
      })

      // Fetch Urgencies
      .addCase(fetchUrgencies.pending, (state) => {
        state.urgencyStatus = "loading";
      })
      .addCase(fetchUrgencies.fulfilled, (state, action) => {
        state.urgencyStatus = "idle";
        state.urgencies = action.payload;
      })
      .addCase(fetchUrgencies.rejected, (state) => {
        state.urgencyStatus = "failed";
      })

      // Fetch Critical Values
      .addCase(fetchCriticalValues.pending, (state) => {
        state.caseStatus = "loading";
      })
      .addCase(fetchCriticalValues.fulfilled, (state, action) => {
        state.caseStatus = "idle";
        state.criticalValues = action.payload;
      })
      .addCase(fetchCriticalValues.rejected, (state) => {
        state.caseStatus = "failed";
      })

      // Update Critical Values
      .addCase(updateCriticalValues.pending, (state) => {
        state.caseStatus = "loading";
      })
      .addCase(updateCriticalValues.fulfilled, (state, action) => {
        state.caseStatus = "idle";
        state.criticalValues = action.payload;
      })
      .addCase(updateCriticalValues.rejected, (state) => {
        state.caseStatus = "failed";
      })

      // Fetch Return Reasons
      .addCase(fetchReturnReasons.pending, (state) => {
        state.returnReasonsStatus = "loading";
      })
      .addCase(fetchReturnReasons.fulfilled, (state, action) => {
        state.returnReasonsStatus = "idle";
        state.returnReasons = action.payload;
      })
      .addCase(fetchReturnReasons.rejected, (state) => {
        state.returnReasonsStatus = "failed";
      })

      // Fetch Completed Cases
      .addCase(fetchCompletedCases.pending, (state) => {
        state.completedStatus = "loading";
      })
      .addCase(fetchCompletedCases.fulfilled, (state, action) => {
        state.completedStatus = "idle";
        state.completedCases = action.payload;
      })
      .addCase(fetchCompletedCases.rejected, (state) => {
        state.completedStatus = "failed";
      })
      //Delete Cases
      .addCase(deleteCase.pending, (state) => {
        state.caseStatus = "loading";
      })
      .addCase(deleteCase.fulfilled, (state) => {
        state.caseStatus = "idle";
      })
      .addCase(deleteCase.rejected, (state) => {
        state.caseStatus = "failed";
      })
      // Search Cases
      .addCase(searchCases.pending, (state) => {
        state.caseStatus = "loading";
      })
      .addCase(searchCases.fulfilled, (state, action) => {
        state.caseStatus = "idle";
        state.caseSearch = action.payload;
      })
      .addCase(searchCases.rejected, (state, action) => {
        state.caseStatus = "failed";
      })
      // Get Case Sound Effect
      .addCase(getCaseSoundEffect.pending, (state) => {
        state.caseStatus = "loading";
      })
      .addCase(getCaseSoundEffect.fulfilled, (state, action) => {
        state.caseStatus = "idle";
        state.newCaseSound = action.payload;
      })
      .addCase(getCaseSoundEffect.rejected, (state, action) => {
        state.caseStatus = "failed";
      });
  },
});

export default casesSlice.reducer;
export const { clearResults, replaceCase, removeCase, updateNewCaseVolume, updateValuesList, clearValues } =
  casesSlice.actions;
