import React from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Typography, Divider, Button, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";
import { logout, setTermsOpened, setTerms } from "../../slices/auth";
import Card from "../base/Card";
import { setKiosk } from "../../slices/kiosk";
import { setWelcomeModalOpened } from "../../slices/schedule";

interface Props {
  kiosk: boolean;
  handleDisableKiosk: () => void;
}

const UserMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUser } = useSelector((store) => store.auth);

  const handleLogout = async () => {
    await dispatch(logout());
    dispatch(setWelcomeModalOpened(false));
    dispatch(setTermsOpened(false));
    dispatch(setTerms(undefined));
    dispatch(setKiosk(false));
  };

  return (
    <Box sx={{ width: "240px", zIndex: 50000 }}>
      <Card>
        <Box sx={{ p: "10px" }}>
          <Typography color="textSecondary" variant="subtitle2">
            {!props.kiosk ? `${loggedInUser?.firstName} ${loggedInUser?.lastName}` : "Kiosk Mode"}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ py: "10px" }}>
          {!props.kiosk && (
            <React.Fragment>
              <MenuItem
                onClick={() => navigate("/app/profile", { state: { id: loggedInUser?.id } })}
                sx={{ display: "flex", gap: "20px" }}
              >
                <FontAwesomeIcon icon={faUser} size="sm" />
                <Typography color="textPrimary" variant="subtitle2">
                  Profile
                </Typography>
              </MenuItem>
              {!!loggedInUser?.roles.find((role) => role.id === 1) && (
                <MenuItem component={Link} to="/app/settings" sx={{ display: "flex", gap: "20px" }}>
                  <FontAwesomeIcon icon={faCog} size="sm" />
                  <Typography color="textPrimary" variant="subtitle2">
                    Admin Settings
                  </Typography>
                </MenuItem>
              )}
            </React.Fragment>
          )}
        </Box>
        {!!props.kiosk && (
          <Box sx={{ p: "10px" }}>
            <Button color="secondary" fullWidth variant="contained" onClick={props.handleDisableKiosk}>
              Exit Kiosk Mode
            </Button>
          </Box>
        )}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "5px" }}>
          <Typography color="textPrimary" variant="subtitle2">
            Version 1.3.10.31.1
          </Typography>
        </Box>
        <Box sx={{ p: "10px" }}>
          <Button color="primary" fullWidth variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default UserMenu;
