import React from "react";
import { Button, CircularProgress } from "@mui/material";

interface FormikSubmitButtonProps {
  isSubmitting: boolean;
  text?: string;
  disabled?: boolean;
}

const FormikSubmitButton: React.FC<FormikSubmitButtonProps> = (props) => {
  const { isSubmitting, text } = props;

  return (
    <Button
      color="primary"
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      disabled={isSubmitting || props.disabled}
      sx={{ mt: 2 }}
    >
      {text ?? "Submit"}
      {isSubmitting && <CircularProgress size={20} sx={{ ml: 5 }} />}
    </Button>
  );
};

export default FormikSubmitButton;
