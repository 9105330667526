import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import Report from "../types/report";

interface ReportState {
  reportStatus: "idle" | "loading" | "failed";
}

const initialState: ReportState = {
  reportStatus: "idle",
};

export const getReport = createAsyncThunk<boolean, { reportUrl: string; model?: Report }>(
  `report/fetch`,
  async ({ reportUrl, model }) => {
    const reportName = reportUrl.split("/")[1].split("?")[0];
    const response = await http.post<Blob>(`${reportUrl}`, model, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${reportName}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();

    return true;
  }
);

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReport.pending, (state) => {
        state.reportStatus = "loading";
      })
      .addCase(getReport.fulfilled, (state, action) => {
        state.reportStatus = "idle";
      })
      .addCase(getReport.rejected, (state, action) => {
        state.reportStatus = "failed";
      });
  },
});

export default reportSlice.reducer;
