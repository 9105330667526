import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import PublicNumbers from "../../types/publicNumbers";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import { Inputs } from "../forms";

interface Props {
  onSubmit: (result: PublicNumbers[]) => void;
  publicNumbers?: PublicNumbers[];
}

const PublicPhoneNumbers: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<PublicNumbers[]>([{ name: "", phoneNumber: "" }]);

  useEffect(() => {
    if (props.publicNumbers) {
      setModel(props.publicNumbers);
    }
  }, [props.publicNumbers]);

  const handleSubmit = (model: PublicNumbers[]) => {
    setLoading(true);
    props.onSubmit(model);
    setLoading(false);
  };

  const validationSchema = Yup.array().of(
    Yup.object().shape({
      phoneNumber: Yup.string().required("Please enter a phone number"),
      name: Yup.string().required("Please enter a name"),
    })
  );

  const initialValues = {
    phoneNumbers: model,
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          handleSubmit(values.phoneNumbers);
        }}
      >
        <Form noValidate>
          <FieldArray name="phoneNumbers">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values } = form;
              const { phoneNumbers } = values;
              return (
                <div>
                  {phoneNumbers &&
                    phoneNumbers.map((number: PublicNumbers, index: number) => (
                      <Grid key={index} container spacing={{ xs: 1, md: 3 }} style={{ marginBottom: "25px" }}>
                        <Grid item md={5} xs={12}>
                          <Field name={`phoneNumbers[${index}].name`} label="Name" required component={Inputs.Text} />
                        </Grid>
                        <Grid item md={5} xs={9}>
                          <Field
                            name={`phoneNumbers[${index}].phoneNumber`}
                            label="Phone Number"
                            required
                            component={Inputs.Phone}
                          />
                        </Grid>
                        <Grid item md={5} xs={3}>
                          <Box sx={{ mt: 3 }}>
                            <Button color="inherit" variant="outlined" onClick={() => remove(index)}>
                              Delete
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                  <Grid container>
                    <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
                      <Box>
                        <Inputs.Submit text="Save" isSubmitting={loading} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="secondary"
                          fullWidth
                          size="large"
                          type="button"
                          variant="contained"
                          onClick={() => {
                            push({ name: "", phoneNumber: "" });
                          }}
                        >
                          Add New
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              );
            }}
          </FieldArray>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default PublicPhoneNumbers;
