import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  ListItemText,
  MenuItem,
  Typography,
  Link as MuiLink,
  useMediaQuery,
  Divider,
  List,
  ListItemIcon,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ActionButtons from "../base/ActionButtons";
import { Circle, FiberManualRecord, Menu, Star } from "@mui/icons-material";
import theme from "../../theme";
import Case from "../../types/case";
import BadgeIcon from "../base/BadgeIcon";
import { useDispatch, useSelector } from "../../store";
import { fetchLinks } from "../../slices/links";

interface Props {
  pending?: Case[];
  userProgress?: Case[];
  currentCases?: Case[];
  handleIrClick?: any;
}

const MenuDrawer: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const md = useMediaQuery(theme.breakpoints.down("md"));

  const { visibleLinks } = useSelector((store) => store.links);

  const [open, setOpen] = useState<boolean>(false);
  const [openLinks, setOpenLinks] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchLinks());
  }, [dispatch]);

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleQuickLinks = () => {
    setOpenLinks(!openLinks);
  };

  const handleIrStudies = () => {
    handleClose();
    props.handleIrClick();
  };

  return (
    <>
      <ActionButtons label="Menu" icon={<Menu />} onClick={() => setOpen(!open)} />
      <Drawer
        anchor="top"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            p: 2,
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            mt: 10,
            px: 1.5,
          }}
        >
          {!!md && (
            <>
              <MenuItem sx={{ justifyContent: "center", padding: 0 }}>
                <Box>
                  <MuiLink
                    target="_blank"
                    href="https://tptrads.sharepoint.com/sites/radsinc/team/notebook/SitePages/Home.aspx"
                  >
                    <img height={50} src={"/static/images/radio.jpeg"} alt="radio" />
                  </MuiLink>
                </Box>
              </MenuItem>
              <MenuItem onClick={handleQuickLinks}>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="subtitle2">
                      Quick Links
                    </Typography>
                  }
                />
              </MenuItem>
              {!!openLinks && (
                <List component="div" disablePadding>
                  {visibleLinks.map((radioLink) => {
                    return (
                      <MenuItem
                        sx={{ pl: 5 }}
                        key={radioLink.id}
                        onClick={handleClose}
                        component={"a"}
                        href={
                          radioLink.permalink.includes("//") ? `${radioLink.permalink}` : `//${radioLink.permalink}`
                        }
                        target="_blank"
                      >
                        <ListItemIcon>
                          <FiberManualRecord fontSize="inherit" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography color="textPrimary" variant="subtitle2">
                              {radioLink.name}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    );
                  })}
                </List>
              )}
              <MenuItem component={RouterLink} onClick={handleClose} to="/app/dashboard">
                <>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        Pending
                      </Typography>
                    }
                  />
                </>
                <Box sx={{ mr: 1.5 }}>
                  <BadgeIcon color="error" badgeContent={props.pending?.length!} />
                </Box>
              </MenuItem>

              <MenuItem component={RouterLink} onClick={handleClose} to="/app/dashboard">
                <>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        My Cases
                      </Typography>
                    }
                  />
                </>
                <Box sx={{ mr: 1.5 }}>
                  <BadgeIcon color="error" badgeContent={props.userProgress?.length!} />
                </Box>
              </MenuItem>

              <MenuItem component={RouterLink} onClick={handleClose} to="/app/qa">
                <>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        QA
                      </Typography>
                    }
                  />
                </>
                <Box sx={{ mr: 1.5 }}>
                  <BadgeIcon color="error" badgeContent={props.currentCases?.length!} />
                </Box>
              </MenuItem>

              <MenuItem onClick={handleIrStudies}>
                <>
                  <ListItemText
                    primary={
                      <Typography color="textPrimary" variant="subtitle2">
                        IR Studies
                      </Typography>
                    }
                  />
                </>
              </MenuItem>

              <MenuItem component={RouterLink} onClick={handleClose} to="/app/interesting-cases">
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="subtitle2">
                      Interesting Cases
                    </Typography>
                  }
                />
              </MenuItem>

              <Divider />
            </>
          )}
          <MenuItem component={RouterLink} onClick={handleClose} to="/app/dashboard">
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Home
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} onClick={handleClose} to="/app/team-dashboard">
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Team Dashboard
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} onClick={handleClose} to="/app/schedule">
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Schedule
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} onClick={handleClose} to="/app/completed-cases">
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Completed Cases
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} onClick={handleClose} to="/app/announcement-history">
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Announcement History
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} onClick={handleClose} to="/app/reports">
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Reports
                </Typography>
              }
            />
          </MenuItem>
        </Box>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
