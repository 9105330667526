import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { CheckCircleOutlineRounded, Delete } from "@mui/icons-material";
import { Card } from "@mui/material";
import theme from "../../theme";
import { useDispatch } from "../../store";
import {
  deleteAnnouncement,
  fetchAnnouncements,
  fetchUnreadAnnouncements,
  readAnnouncement,
} from "../../slices/announcement";
import User from "../../types/user";
import Moment from "react-moment";

interface Props {
  id: number;
  title: string;
  body: string;
  created: Date | undefined;
  createdByUser: User | undefined;
  history?: boolean | undefined;
}

const AnnouncementCard: React.FunctionComponent<Props> = React.memo((props) => {
  const { id, title, body, createdByUser, created, history } = props;
  const dispatch = useDispatch();
  const [working, setWorking] = React.useState(false);

  const handleDelete = async () => {
    setWorking(true);
    await dispatch(deleteAnnouncement(id));
    dispatch(fetchAnnouncements());
    dispatch(fetchUnreadAnnouncements());
  };

  const handleDismiss = async () => {
    setWorking(true);
    await dispatch(readAnnouncement(id));
    dispatch(fetchAnnouncements());
    dispatch(fetchUnreadAnnouncements());
  };

  return (
    <Card sx={{ border: `3px solid ${theme.palette.secondary.main}` }}>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          height: "fit-content",
        }}
      >
        <Grid item sm={2} xs={undefined}>
          {title !== "" && (
            <Grid
              style={{
                backgroundColor: theme.palette.error.main,
                border: `3px solid ${theme.palette.quinary.main}`,
                borderRadius: 20,
                width: "fit-content",
                minWidth: "40px",
                margin: "8px",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: "500", textAlign: "center" }} margin={0.5}>
                {title}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item sm={8} xs={10} sx={{ textAlign: "center" }}>
          <Typography sx={{ fontSize: "14px" }}>{body}</Typography>
          <Typography variant="caption">
            {createdByUser?.value || ""} <Moment format="MMM Do YY HH:mm">{created}</Moment>
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {!!history ? (
            <Tooltip placement="top" title={<Typography variant="button">Delete</Typography>}>
              <IconButton disabled={working} onClick={handleDelete}>
                <Delete style={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={<Typography variant="button">Dismiss</Typography>}>
              <IconButton disabled={working} onClick={handleDismiss}>
                <CheckCircleOutlineRounded style={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Card>
  );
});

export default AnnouncementCard;
