import React from "react";
import { FieldProps } from "formik";
import { Checkbox, CheckboxProps, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

interface Props {
  label: string;
  checked: boolean;
  tooltip?: string;
}

const FormikCheckbox: React.FC<FieldProps & CheckboxProps & Props> = (props) => {
  const { label, checked, tooltip, field, ...rest } = props;

  return (
    <>
      <label>
        <Checkbox checked={checked} {...field} {...rest} />
        <span className="text-sm">{label}</span>{" "}
        {tooltip && (
          <Tooltip title={tooltip}>
            <Info fontSize="small" />
          </Tooltip>
        )}
      </label>
    </>
  );
};

export default FormikCheckbox;
