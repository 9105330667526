import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Card from "../base/Card";
import Case from "../../types/case";
import helpers from "../../helpers";
import Moment from "react-moment";
import Chip from "../../components/base/Chip";
import CommentBubble from "../../components/base/CommentBubble";
import { CalendarToday, Grid3x3, LocationOnRounded, Person, Warning } from "@mui/icons-material";
import CaseActionMenu from "../../components/views/CaseActionMenu";
import { useSelector } from "../../store";

interface Props {
  case: Case;
}

const QaCase: React.FC<Props> = (props) => {
  const { loggedInUser } = useSelector((store) => store.auth);
  const { settings } = useSelector((store) => store.settings);
  const [isHoveringName, setIsHoveringName] = React.useState(false);
  const [isHoveringID, setIsHoveringID] = React.useState(false);

  const getNameDashes = (amount: number) =>
    Array.from(Array(amount).keys())
      .map(() => "-")
      .join()
      .replaceAll(",", "");

  return (
    <Box>
      <Card color={helpers.getColorByCaseStatus(props.case.status.id)}>
        <Grid container paddingX="5px" marginY="-5px" alignItems="center">
          <Grid
            item
            xs={12}
            sm={9}
            onMouseEnter={() => {
              setIsHoveringName(true);
            }}
            onMouseLeave={() => {
              setIsHoveringName(false);
            }}
          >
            <Typography variant="overline" color={helpers.getColorByCaseStatus(props.case.status?.id!)}>
              {props.case.status?.value}
            </Typography>
            {!isHoveringName && !loggedInUser?.allowSensitiveData && (
              <Typography variant="h5">
                {props.case.lastName[0]}
                {getNameDashes(props.case.lastName?.length! - 1)}, {props.case.firstName[0]!}
                {getNameDashes(props.case.firstName?.length! - 1)}
              </Typography>
            )}
            {(isHoveringName || loggedInUser?.allowSensitiveData) && (
              <Typography variant="h5">
                {props.case.lastName}, {props.case.firstName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
            <Typography>
              <Moment format="MMMM DD, YYYY HH:mm:ss">{props.case.created}</Moment>
            </Typography>
            <Typography>
              <Moment fromNow>{props.case.created}</Moment>
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid container padding="5px" marginY="-5px" alignItems="center">
          <Grid item xs={12} sm={9}>
            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap" gap="10px">
              <Chip type="modality" label={props.case.modality?.name!} />
              {props.case.examTypes &&
                props.case.examTypes?.map((exam) => (
                  <Chip
                    type="exam"
                    color={helpers.getColorByCaseStatus(props.case.status.id)}
                    key={exam.id}
                    label={exam.value}
                  />
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} display="flex" justifyContent="right">
            <Chip type="hospital" color={props.case.hospital?.badgeColor} label={props.case.hospital?.shortName!} />
          </Grid>
        </Grid>
        <Grid container padding="5px" marginY="-5px">
          <Grid item xs={12} sm={6}>
            <Box
              onMouseEnter={() => {
                setIsHoveringID(true);
              }}
              onMouseLeave={() => {
                setIsHoveringID(false);
              }}
            >
              <Typography>
                <Grid3x3 fontSize={"small"} style={{ paddingTop: "5px" }} />
                {isHoveringID || loggedInUser?.allowSensitiveData
                  ? `Patient ID: ${props.case.patientId}`
                  : `${getNameDashes(`${props.case?.patientId || ""}`.length)}`}
              </Typography>
              {props.case.examNumber && <Grid3x3 fontSize={"small"} style={{ paddingTop: "5px" }} />}
              {(isHoveringID || loggedInUser?.allowSensitiveData) && props.case.examNumber
                ? `Exam/Accession: ${props.case.examNumber}`
                : `${getNameDashes(`${props.case?.examNumber || ""}`.length)}`}
            </Box>
            <Typography>
              <LocationOnRounded fontSize={"small"} style={{ paddingTop: "5px" }} /> {props.case.hospital?.name}
            </Typography>
            <Typography>
              <CalendarToday fontSize={"small"} style={{ paddingTop: "5px" }} /> Case Date:{" "}
              {new Date(props.case.caseDate!)?.toDateString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {(loggedInUser?.id === props.case.assignedToUser?.id ||
              loggedInUser?.id === props.case.openedByUser?.id) && (
              <Typography>
                <Person fontSize={"small"} style={{ paddingTop: "5px" }} /> Assigned To:{" "}
                {props.case.assignedToUser?.id === settings?.statRadUser?.id && "Statrad / "}
                {props.case.assignedToUser?.firstName || loggedInUser?.firstName}{" "}
                {props.case.assignedToUser?.lastName || loggedInUser?.lastName}
              </Typography>
            )}
            {loggedInUser?.id === props.case.openedByUser?.id && (
              <Typography>
                <Person fontSize={"small"} style={{ paddingTop: "5px" }} /> Opened By:{" "}
                {props.case.openedByUser?.firstName || ""} {props.case.openedByUser?.lastName || ""}
              </Typography>
            )}
            <Typography>
              <Warning fontSize={"small"} style={{ paddingTop: "5px" }} /> Error Code: {props.case.qaError?.value}
            </Typography>
          </Grid>
        </Grid>
        {props.case.comments && props.case.comments.length > 0 && (
          <Grid container paddingX="15px" marginTop="-5px">
            <Box sx={{ marginBottom: "-10px" }}>
              <Typography variant={"overline"}>Comments</Typography>
            </Box>
            {props.case.comments.map((comment) => (
              <Grid key={comment.id} item xs={12}>
                <Box>
                  <CommentBubble
                    comment={comment}
                    type="qa"
                    loggedInUser={loggedInUser}
                    assignedToUser={props.case.assignedToUser}
                    openedByUser={props.case.openedByUser}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        <Divider />
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="right">
          <CaseActionMenu type="qa" case={props.case} />
        </Box>
      </Card>
    </Box>
  );
};

export default QaCase;
