import React, { useState } from "react";
import { Box } from "@mui/material";
import ActionButtons from "../base/ActionButtons";
import {
  Message,
  Help,
  Gavel,
  CheckCircle,
  Send,
  Edit,
  NoteAddOutlined,
  AssignmentReturn,
  PendingActions,
  Close,
} from "@mui/icons-material";
import Modal from "../base/Modal";
import AddComment from "./AddComment";
import Case from "../../types/case";
import Comment from "../../types/comment";
import { useDispatch, useSelector } from "../../store";
import { fetchQaCodes, updateQaAction, updateQaCase } from "../../slices/qa";
import { updateCase } from "../../slices/cases";
import { fetchHospitals } from "../../slices/hospital";
import AddEditQa from "./AddEditQa";
import { fetchQaUsers, fetchOnlyRads } from "../../slices/users";
import AddEditCase from "./AddEditCase";
import BlockedCasesModal from "./BlockedCasesModal";
import getUserRoleId from "../../helpers/getUserRoleId";
import DeleteCase from "./DeleteCase";
import QaAction from "./QaAction";

interface Props {
  case: Case;
  type: "qa" | "case";
  kiosk?: boolean | undefined;
  completed?: boolean | undefined;
}

enum Action {
  Edit,
  Comment,
  Query,
  Challenge,
  Resolve,
  Resubmit,
  JudgeQuery,
  Adjudicate,
  CaseEdit,
  Findings,
  Pending,
  Tech,
  Delete,
}

const CaseActionMenu: React.FC<Props> = (props) => {
  const { type, completed } = props;
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((store) => store.auth);
  const { caseStatus } = useSelector((store) => store.cases);
  const [showModal, setShowModal] = useState(false);
  const [currentComponent, setCurrentComponent] = useState<any>();
  const [title, setTitle] = useState("");
  const [pendingPressed, setPendingPressed] = useState(false);

  const handleSave = async (
    titleSwitch: string,
    comment: Comment,
    judgementId?: number,
    sendEmail?: boolean,
    sendText?: boolean
  ) => {
    switch (titleSwitch) {
      case "Edit":
        break;
      case "Comment":
        await dispatch(
          updateQaAction({
            id: props.case.id!,
            status: props.case?.status?.id!,
            comment: comment,
            user: props.case.currentUser?.id,
            sendEmail: sendEmail,
            sendText: sendText,
          })
        );
        break;
      case "Finding":
        await dispatch(
          updateQaAction({
            id: props.case?.id!,
            status: props.case?.status?.id!,
            comment: comment,
            user: props.case.currentUser?.id,
            sendEmail: sendEmail,
            sendText: sendText,
          })
        );
        break;
      case "Query":
        await dispatch(
          updateQaAction({
            id: props.case.id!,
            status: 5,
            comment: comment,
            user: props.case.openedByUser?.id,
            sendEmail: sendEmail,
            sendText: sendText,
          })
        );
        break;
      case "Challenge":
        await dispatch(
          updateQaAction({
            id: props.case.id!,
            status: 6,
            comment: comment,
            user: 0,
            sendEmail: sendEmail,
            sendText: sendText,
          })
        );
        break;
      case "Resolve":
        await dispatch(
          updateQaAction({
            id: props.case.id!,
            status: 7,
            comment: comment,
            user: 0,
            sendEmail: sendEmail,
            sendText: sendText,
          })
        );
        break;
      case "Resubmit":
        await dispatch(
          updateQaAction({
            id: props.case.id!,
            status: 4,
            comment: comment,
            user: props.case.assignedToUser?.id,
            sendEmail: sendEmail,
            sendText: sendText,
          })
        );
        break;
      case "Return QA Case":
        switch (judgementId) {
          case 1:
            await dispatch(
              updateQaAction({
                id: props.case.id!,
                status: 5,
                comment: comment,
                user: props.case.openedByUser?.id,
                sendEmail: sendEmail,
                sendText: sendText,
              })
            );
            break;
          case 2:
            await dispatch(
              updateQaAction({
                id: props.case.id!,
                status: 4,
                comment: comment,
                user: props.case.assignedToUser?.id,
                sendEmail: sendEmail,
                sendText: sendText,
              })
            );
            break;
          default:
            break;
        }
        break;
      case "Adjudicate":
        await dispatch(
          updateQaAction({
            id: props.case.id!,
            status: 7,
            comment: comment,
            judgement: judgementId,
            user: 0,
            sendEmail: sendEmail,
            sendText: sendText,
          })
        );
        break;
      default:
        break;
    }

    // refreshCases();
  };

  const handleEditSave = (model: Case) => {
    dispatch(updateQaCase(model));
  };

  const handleReturnToTech = async (model: Case) => {
    await dispatch(
      updateCase({
        ...model,
        status: {
          id: 1,
          value: "",
        },
        currentUser: model?.openedByUser,
      })
    );
    // await refreshCases();
  };

  const handleReturnToPending = async (model: Case) => {
    await dispatch(
      updateCase({
        ...model,
        status: {
          id: 1,
          value: "",
        },
        currentUser: undefined,
      })
    );
    // await refreshCases();
    await setPendingPressed(false);
  };

  const handleOpen = (action: Action) => {
    switch (action) {
      case Action.Edit:
        setTitle("Edit Case");
        dispatch(fetchHospitals());
        dispatch(fetchQaUsers());
        dispatch(fetchQaCodes());
        setCurrentComponent(
          <AddEditQa
            case={props.case}
            caseId={props.case?.id!}
            onSave={handleEditSave}
            onClose={() => setShowModal(false)}
          />
        );
        break;
      case Action.CaseEdit:
        setTitle("Edit Case");
        dispatch(fetchHospitals());
        dispatch(fetchOnlyRads());
        setCurrentComponent(
          <AddEditCase
            blocked={props.case.status.id === 8}
            id={props.case?.id}
            // refreshCases={refreshCases}
            handleCloseModal={() => setShowModal(false)}
          />
        );
        break;
      case Action.Comment:
        setTitle("Comment");
        setCurrentComponent(
          <AddComment
            caseId={props.case?.id!}
            onSave={handleSave}
            description={"Add a new comment to this case."}
            onClose={() => {
              setShowModal(false);
            }}
            // refreshCases={refreshCases}
            required
            title={"Comment"}
            type="comment"
          />
        );
        break;
      case Action.Tech:
        setTitle("Return Case");
        setCurrentComponent(<BlockedCasesModal caseId={props.case.id || 0} handleClose={() => setShowModal(false)} />);
        break;
      case Action.Findings:
        setTitle("Finding");
        setCurrentComponent(
          <AddComment
            caseId={props.case?.id!}
            onSave={handleSave}
            description={"Add findings to this case."}
            onClose={() => {
              setShowModal(false);
            }}
            required
            title={"Finding"}
            type="finding"
          />
        );
        break;
      case Action.Query:
        setTitle("Query");
        setCurrentComponent(
          <QaAction
            caseId={props.case?.id!}
            onSave={handleSave}
            description={
              "Send the QA case back to the person who opened it for clarification. Optionally add a comment."
            }
            onClose={() => setShowModal(false)}
            title={"Query"}
            type="comment"
          />
        );
        break;
      case Action.Challenge:
        setTitle("Challenge");
        setCurrentComponent(
          <QaAction
            caseId={props.case?.id!}
            onSave={handleSave}
            description={"Request a Judge to adjudicate this QA case. Optionally add a comment."}
            onClose={() => setShowModal(false)}
            title={"Challenge"}
            type="comment"
          />
        );
        break;
      case Action.Resolve:
        setTitle("Resolve");
        setCurrentComponent(
          <QaAction
            caseId={props.case?.id!}
            onSave={handleSave}
            description={"Close this QA Case, accepting the issue should be withdrawn. Optionally add a comment."}
            onClose={() => setShowModal(false)}
            title={"Resolve"}
            type="finding"
          />
        );
        break;
      case Action.Resubmit:
        setTitle("Resubmit");
        setCurrentComponent(
          <QaAction
            caseId={props.case?.id!}
            onSave={handleSave}
            description={"Submit this QA case again. Optionally add a comment."}
            onClose={() => setShowModal(false)}
            title={"Resubmit"}
            type="comment"
          />
        );
        break;
      case Action.JudgeQuery:
        setTitle("Return QA Case");
        setCurrentComponent(
          <QaAction
            type="comment"
            showAdjudicate={true}
            judge={false}
            caseId={props.case?.id!}
            onSave={handleSave}
            description={"Who do you want to send this case back to?"}
            onClose={() => setShowModal(false)}
            title={"Return QA Case"}
          />
        );
        break;
      case Action.Adjudicate:
        setTitle("Adjudicate");
        setCurrentComponent(
          <QaAction
            type="finding"
            showAdjudicate={true}
            judge={true}
            caseId={props.case?.id!}
            onSave={handleSave}
            description={"Submit your findings:"}
            onClose={() => setShowModal(false)}
            title={"Adjudicate"}
          />
        );
        break;
      case Action.Delete:
        setTitle("Close");
        setCurrentComponent(<DeleteCase onClose={() => setShowModal(false)} caseId={props.case?.id!} />);
        break;
      default:
        return "";
    }

    setShowModal(true);
  };

  const handleReturn = (action: Action) => {
    switch (action) {
      case Action.Pending:
        setPendingPressed(true);
        handleReturnToPending(props.case);
        break;
      case Action.Tech:
        handleReturnToTech(props.case);
        break;
      default:
        return "";
    }
  };

  //Blocked
  if (props.case?.status?.id === 8)
    return (
      <Box display="flex" alignItems="center">
        <ActionButtons label="Add Comment" icon={<Message />} onClick={() => handleOpen(Action.Comment)} />
        {type === "case" && getUserRoleId(loggedInUser) === 2 && (
          <ActionButtons label="Add Findings" icon={<NoteAddOutlined />} onClick={() => handleOpen(Action.Findings)} />
        )}
        <ActionButtons label="Edit" icon={<Edit />} onClick={() => handleOpen(Action.CaseEdit)} />
        <ActionButtons
          label="Return to Pending"
          disabled={pendingPressed || caseStatus === "loading"}
          icon={<PendingActions />}
          onClick={() => handleReturn(Action.Pending)}
        />
        <ActionButtons label="Close" icon={<Close />} onClick={() => handleOpen(Action.Delete)} />
        <Modal open={showModal} handleClose={() => setShowModal(false)} title={title}>
          {currentComponent}
        </Modal>
      </Box>
    );

  // if (getUserRoleId(loggedInUser) === 3)
  //   return getUserRoleId(loggedInUser) === 2 ? (
  //     <Box display="flex" alignItems="center">
  //       {props.case?.status?.id === 1 && (
  //         <ActionButtons label="Edit" icon={<Edit />} onClick={() => handleOpen(Action.Edit)} />
  //       )}
  //       {props.case?.status?.id === 6 && (
  //         <ActionButtons label="Resubmit" icon={<Send />} onClick={() => handleOpen(Action.Resubmit)} />
  //       )}
  //       <Modal open={showModal} handleClose={() => setShowModal(false)} title={title}>
  //         {currentComponent}
  //       </Modal>
  //     </Box>
  //   ) : (
  //     <></>
  //   );

  if (!!completed)
    return getUserRoleId(loggedInUser) === 2 ? (
      <Box display="flex" alignItems="center">
        {type === "case" && <ActionButtons label="Edit" icon={<Edit />} onClick={() => handleOpen(Action.CaseEdit)} />}
        <ActionButtons label="Add Comment" icon={<Message />} onClick={() => handleOpen(Action.Comment)} />
        {type === "case" && getUserRoleId(loggedInUser) === 2 && (
          <ActionButtons label="Add Findings" icon={<NoteAddOutlined />} onClick={() => handleOpen(Action.Findings)} />
        )}
        {type === "case" && (
          <ActionButtons label="Return to Tech" icon={<AssignmentReturn />} onClick={() => handleOpen(Action.Tech)} />
        )}
        <Modal open={showModal} handleClose={() => setShowModal(false)} title={title}>
          {currentComponent}
        </Modal>
      </Box>
    ) : (
      <></>
    );

  const openedAndAssignedToSameUser = props.case?.assignedToUser?.id === props.case?.openedByUser?.id;
  const openedByLoggedInUser = props.case?.openedByUser?.id === loggedInUser?.id;
  const assignedToLoggedInUser = props.case?.assignedToUser?.id === loggedInUser?.id;

  return (
    <Box display="flex" alignItems="center">
      {openedByLoggedInUser && type === "qa" && (
        <ActionButtons label="Edit" icon={<Edit />} onClick={() => handleOpen(Action.Edit)} />
      )}
      <ActionButtons label="Add Comment" icon={<Message />} onClick={() => handleOpen(Action.Comment)} />
      {type === "case" && getUserRoleId(loggedInUser) === 2 && (
        <ActionButtons label="Add Findings" icon={<NoteAddOutlined />} onClick={() => handleOpen(Action.Findings)} />
      )}
      {type === "case" && <ActionButtons label="Edit" icon={<Edit />} onClick={() => handleOpen(Action.CaseEdit)} />}
      {type === "case" && getUserRoleId(loggedInUser) !== 3 && (
        <ActionButtons label="Return to Tech" icon={<AssignmentReturn />} onClick={() => handleOpen(Action.Tech)} />
      )}
      {type === "case" && props.case?.status?.id === 2 && (
        <ActionButtons
          label="Return to Pending"
          disabled={pendingPressed || caseStatus === "loading"}
          icon={<PendingActions />}
          onClick={() => handleReturn(Action.Pending)}
        />
      )}
      {props.case?.status?.id === 4 && assignedToLoggedInUser && !openedAndAssignedToSameUser && (
        <ActionButtons label="Query" icon={<Help />} onClick={() => handleOpen(Action.Query)} />
      )}
      {((openedByLoggedInUser && props.case.status?.id === 5) ||
        (assignedToLoggedInUser && props.case.status?.id === 4)) &&
        !openedAndAssignedToSameUser && (
          <ActionButtons label="Challenge" icon={<Gavel />} onClick={() => handleOpen(Action.Challenge)} />
        )}
      {((openedByLoggedInUser && props.case.status?.id === 5) ||
        (assignedToLoggedInUser && props.case.status?.id === 4)) && (
        <ActionButtons label="Resolve" icon={<CheckCircle />} onClick={() => handleOpen(Action.Resolve)} />
      )}
      {openedByLoggedInUser && !openedAndAssignedToSameUser && props.case.status?.id === 5 && (
        <ActionButtons label="Resubmit" icon={<Send />} onClick={() => handleOpen(Action.Resubmit)} />
      )}
      {loggedInUser?.roles.find((role) => role.id === 6) && props.case.status?.id === 6 && (
        <ActionButtons label="Query" icon={<Help />} onClick={() => handleOpen(Action.JudgeQuery)} />
      )}
      {loggedInUser?.roles.find((role) => role.id === 6) &&
        props.case.status?.id === 6 &&
        !assignedToLoggedInUser &&
        !openedByLoggedInUser && (
          <ActionButtons label="Judge" icon={<Gavel />} onClick={() => handleOpen(Action.Adjudicate)} />
        )}
      <Modal open={showModal} handleClose={() => setShowModal(false)} title={title}>
        {currentComponent}
      </Modal>
    </Box>
  );
};

export default CaseActionMenu;
