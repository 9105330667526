import React, { useEffect } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import Card from "../base/Card";
import { fetchTotalCases, fetchReading, fetchStudies, fetchUtilization, fetchWus } from "../../slices/teamdashboard";

interface Props {}

const TeamDashboard: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { totalCases, studies, wus, reading, utilization } = useSelector((store) => store.teamDashboard);
  const { settings } = useSelector((store) => store.settings);

  useEffect(() => {
    dispatch(fetchTotalCases());
    dispatch(fetchStudies());
    dispatch(fetchWus());
    dispatch(fetchReading());
    dispatch(fetchUtilization());
  }, [dispatch]);

  return (
    <Card>
      <Box m={1}>
        <Grid container spacing={1.5}>
          {settings?.displayedStats.cases ? (
            <>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Total Cases</Typography>
                  <Typography>{totalCases && totalCases > 0 ? totalCases : "0"}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          ) : (
            <></>
          )}
          {settings?.displayedStats.studies ? (
            <>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Total Studies</Typography>
                  <Typography>{studies && studies > 0 ? studies : "0"}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          ) : (
            <></>
          )}
          {settings?.displayedStats.wus ? (
            <>
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Total WUs</Typography>
                  <Typography>{wus && wus > 0 ? wus : "0"}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Box
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography>Reading Time</Typography>
              <Typography>{reading && reading > 0 ? reading + " minutes" : "No data today"}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography>Staff Utilization</Typography>
              <Typography>{utilization && utilization > 0 ? utilization + "%" : "0%"}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default TeamDashboard;
