import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import Option from "../../types/option";

interface Props {
  label: string;
  sortOptions: Option[];
  handleChange: (value: Option) => void;
}

const FilterDropdown: React.FC<Props> = (props) => {
  const selectOption = (e: Option) => {
    props.handleChange(e);
  };

  return (
    <Box>
      <Autocomplete
        fullWidth
        onChange={(event: React.ChangeEvent<{}>, value: Option | null) => selectOption(value!)}
        id="tags-standard"
        options={props.sortOptions}
        getOptionLabel={(option: Option) => option.value!}
        renderInput={(params) => <TextField label={props.label} {...params} />}
      />
    </Box>
  );
};

export default FilterDropdown;
