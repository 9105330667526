import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import CaseDurations from "../types/caseDurations";
import Settings from "../types/settings";
import http from "../api/http";
import ContactInformation from "../types/contactInformation";
import PublicNumbers from "../types/publicNumbers";
import DisplayedStats from "../types/displayedStats";
import SiteLogos from "../types/siteLogos";
import QGendaSettings from "../types/qGendaSettings";
import ReportTemplates from "../types/reportTemplates";
import SiteSounds from "../types/siteSounds";
import HighCaseLoad from "../types/highCaseLoad";

interface SettingsState {
  settings: Settings | null;
  status: "idle" | "loading" | "failed";
  logo: string | null;
  durations?: CaseDurations;
}

const initialState: SettingsState = {
  settings: null,
  status: "idle",
  logo: null,
  durations: undefined,
};

export const fetchSettings = createAsyncThunk<Settings>("settings/fetch", async () => {
  const response = await http.get("settings");
  return response.data;
});

export const updateContactInfo = createAsyncThunk<Settings, ContactInformation>(
  "settings/update-contact",
  async (vm: ContactInformation) => {
    const response = await http.patch("settings/contact-info", vm);
    return response.data;
  }
);

export const updatePublicNumbers = createAsyncThunk<Settings, PublicNumbers[]>(
  "settings/update-numbers",
  async (vm: PublicNumbers[]) => {
    const response = await http.patch("settings/public-numbers", vm);
    return response.data;
  }
);

export const updateCaseDurations = createAsyncThunk<Settings, CaseDurations>(
  "settings/update-durations",
  async (info: CaseDurations) => {
    const response = await http.patch("settings/case-durations", info);
    return response.data;
  }
);

export const updateHighCaseLoadSettings = createAsyncThunk<Settings, HighCaseLoad>(
  "settings/update-high-case-load-settings",
  async (info: HighCaseLoad) => {
    const response = await http.patch("settings/high-case-load-settings", info);
    return response.data;
  }
);

export const updateDisplayedStats = createAsyncThunk<Settings, DisplayedStats>(
  "settings/update-stats",
  async (info: DisplayedStats) => {
    const response = await http.patch("settings/displayed-stats", info);
    return response.data;
  }
);

export const updateSiteLogos = createAsyncThunk<Settings, SiteLogos>(
  "settings/update-logos",
  async (info: SiteLogos) => {
    var form = new FormData();
    if (info?.siteLogo?.length! > 0) {
      form.append("siteLogo", info.siteLogo![0]);
    }
    if (info?.reportLogo?.length! > 0) {
      form.append("reportLogo", info.reportLogo![0]);
    }

    const response = await http({
      method: "patch",
      url: "settings/site-logos",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
);

export const updateQGendaSettings = createAsyncThunk<Settings, QGendaSettings>(
  "settings/update-qgenda",
  async (info: QGendaSettings) => {
    const response = await http.patch("settings/qgenda-integration", info);
    return response.data;
  }
);

export const updateReportTemplates = createAsyncThunk<Settings, ReportTemplates>(
  "settings/update-templates",
  async (info: ReportTemplates) => {
    var form = new FormData();
    if (info?.casesData?.length! > 0) {
      form.append("casesData", info.casesData![0]);
    }
    if (info?.qaCasesData?.length! > 0) {
      form.append("qaCasesData", info.qaCasesData![0]);
    }
    if (info?.awayState?.length! > 0) {
      form.append("awayState", info.awayState![0]);
    }

    const response = await http({
      method: "patch",
      url: "settings/report-templates",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
);

export const updateStatRadUser = createAsyncThunk<Settings, number>(
  "settings/update-statrad",
  async (userId: number) => {
    const response = await http.patch("settings/statrad", userId);
    return response.data;
  }
);

export const fetchLogo = createAsyncThunk<string>("settings/fetch-logo", async () => {
  const response = await http.get("settings/site-logo");
  return response.data;
});

export const updateSoundFiles = createAsyncThunk<Settings, SiteSounds>(
  "settings/update-sounds",
  async (info: SiteSounds) => {
    var form = new FormData();
    if (info?.chatSound?.length! > 0) {
      form.append("chatSound", info.chatSound![0]);
    }
    if (info?.teamChatSound?.length! > 0) {
      form.append("teamChatSound", info.teamChatSound![0]);
    }
    if (info?.newCaseSound?.length! > 0) {
      form.append("newCaseSound", info.newCaseSound![0]);
    }

    if (info.chatSoundVolume) {
      form.append("chatSoundVolume", info.chatSoundVolume.toString());
    }

    if (info.teamChatVolume) {
      form.append("teamChatVolume", info.teamChatVolume.toString());
    }

    if (info.newCaseVolume) {
      form.append("newCaseVolume", info.newCaseVolume.toString());
    }

    const response = await http({
      method: "patch",
      url: "settings/site-sounds",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
        state.durations = action.payload.caseDurations;
      })
      .addCase(fetchSettings.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchLogo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLogo.fulfilled, (state, action) => {
        state.status = "idle";
        state.logo = action.payload;
      })
      .addCase(fetchLogo.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateContactInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContactInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateContactInfo.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updatePublicNumbers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePublicNumbers.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updatePublicNumbers.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateCaseDurations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCaseDurations.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateCaseDurations.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateHighCaseLoadSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateHighCaseLoadSettings.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateHighCaseLoadSettings.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateDisplayedStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDisplayedStats.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateDisplayedStats.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateQGendaSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQGendaSettings.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateQGendaSettings.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateReportTemplates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateReportTemplates.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateReportTemplates.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateSiteLogos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSiteLogos.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateSiteLogos.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateSoundFiles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSoundFiles.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateSoundFiles.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateStatRadUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateStatRadUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.settings = action.payload;
      })
      .addCase(updateStatRadUser.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default settingsSlice.reducer;
