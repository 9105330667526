import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";
import { useDispatch, useSelector } from "../../store";
import getUserRoleId from "../../helpers/getUserRoleId";
import ActionButtons from "../base/ActionButtons";
import { setTechCollapsed } from "../../slices/users";
import { ArrowBackIos } from "@mui/icons-material";
import HospitalCard from "./HospitalCard";
import User from "../../types/user";
import Chip from "../base/Chip";

interface Props {
  windowWidth: number;
  techChatArray: [User, ...User[]][];
}

const TechChat: React.FC<Props> = (props) => {
  const { windowWidth, techChatArray } = props;
  const dispatch = useDispatch();
  const { techCollapsed, usersCollapsed } = useSelector((state) => state.users);
  const { loggedInUser } = useSelector((state) => state.auth);
  const { techConversations } = useSelector((state) => state.techChats);

  const isTech = !!loggedInUser?.roles.find((f) => f.id === 3);

  const getBadgeContent = () => {
    let found = null;

    const newChats = techConversations?.filter((f) => f.unread! > 0);

    if (newChats?.length > 0) {
      found = newChats.reduce((a, b) => +a + +b.unread!, 0);
    }

    if (found! > 9) {
      return "9+";
    } else {
      return found!.toString();
    }
  };

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 1, overflowY: "auto", overflowX: "hidden", height: "100%" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, gap: 1 }}>
          <div
            style={{
              display: "inline-block",
              cursor: !!isTech ? "auto" : "pointer",
            }}
            onClick={!!isTech ? () => {} : () => dispatch(setTechCollapsed(!techCollapsed))}
          >
            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "4px" }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: "15px",
                  border: `2px solid ${theme.palette.primary.main}`,
                  width: {
                    xs: usersCollapsed && getUserRoleId(loggedInUser) !== 3 ? "180px" : windowWidth - 10,
                    md: usersCollapsed ? "180px" : "345px",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    marginLeft: !!techConversations?.find((f) => f.unread! > 0) ? "5px" : "35px",
                    marginRight: "15px",
                    justifyContent: "center",
                    display: "flex",
                    flexGrow: 1,
                  }}
                >
                  {!!techConversations?.find((f) => f.unread! > 0) && (
                    <Chip type="hospital" color={theme.palette.error.main} label={getBadgeContent()} />
                  )}
                </Box>
                {!usersCollapsed ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <Typography variant="h6">{!!isTech ? "Rads Chat" : "Technologist Chats"}</Typography>
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    <Typography variant="h6">Techs</Typography>
                  </Box>
                )}
                {!!isTech ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexGrow: 1,
                      paddingLeft: "50px",
                      height: "38px",
                    }}
                  ></Box>
                ) : (
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                      display: "flex",
                      flexGrow: 1,
                    }}
                  >
                    <ActionButtons
                      onClick={() => {
                        dispatch(setTechCollapsed(!techCollapsed));
                      }}
                      label={techCollapsed ? "Expand" : "Hide"}
                      icon={
                        <ArrowBackIos
                          fontSize="large"
                          sx={{
                            paddingLeft: "10px",
                            marginY: "-6px",
                            transform: techCollapsed ? "rotate(-90deg)" : "rotate(90deg)",
                          }}
                        />
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
      {(!techCollapsed || !!isTech) &&
        techChatArray.map((h, index) => {
          return (
            <HospitalCard
              key={index}
              techConversations={techConversations}
              hospital={h[0]?.hospital}
              users={h}
              collapsed={usersCollapsed}
              showHospital={true}
              loggedInUser={loggedInUser!}
              techChat={true}
            />
          );
        })}
    </>
  );
};

export default TechChat;
