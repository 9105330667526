import User from "../types/user";

const getUserRoleId = (user?: User | undefined): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
  if (!user || user.roles === null || user.roles.length < 1) return 3;
  if (!!user?.roles?.find((role) => role?.id === 2)) return 2;
  if (!!user?.roles?.find((role) => role?.id === 3)) return 3;
  if (!!user?.roles?.find((role) => role?.id === 1)) return 1;
  if (!!user?.roles?.find((role) => role?.id === 4)) return 4;
  if (!!user?.roles?.find((role) => role?.id === 5)) return 5;
  if (!!user?.roles?.find((role) => role?.id === 6)) return 6;
  return 0;
};

export default getUserRoleId;
