import React, { useCallback, useEffect, useState } from "react";
import Message from "../../types/message";
import { useDispatch, useSelector } from "../../store";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import UserSimple from "../../types/userSimple";
import * as Yup from "yup";
import { getConversations, getMessages, readMessage, sendChat, toggleChat } from "../../slices/chat";
import {
  fetchTeamChat,
  getTeamConversations,
  getTeamMessages,
  readTeamMessage,
  sendTeamChat,
} from "../../slices/teamchats";
import ChatView from "./ChatView";
import {
  fetchTechChatList,
  getTechConversations,
  getTechMessages,
  readTechMessage,
  sendTechChat,
} from "../../slices/techChats";

interface Props {
  chatId: number;
  open: boolean;
  teamChat: boolean;
  techChat: boolean;
}

const ChatComponent: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { chatId, open, teamChat, techChat } = props;
  const { currentConversation, chatOpen } = useSelector((state) => state.chat);
  const { currentTeamConversation, teamChatOpen, teamChats } = useSelector((state) => state.teamChats);
  const { currentTechConversation, techChatHospitalId, techChats } = useSelector((state) => state.techChats);

  const { loggedInUser } = useSelector((state) => state.auth);
  const { users, simpleUser } = useSelector((state) => state.users);

  const [selectedUsers, setSelectedUsers] = useState<UserSimple[]>();

  useEffect(() => {
    if (teamChat) {
      dispatch(fetchTeamChat());
    }

    if (techChat) {
      dispatch(fetchTechChatList(chatId));
    }
  }, [dispatch, chatId, teamChat, chatOpen, techChat]);

  const handleSelectUser = useCallback(
    (user: UserSimple) => {
      if (techChat) {
        dispatch(getTechMessages(chatId));
      } else if (chatId === -1) {
        dispatch(toggleChat({ id: user?.id, title: user?.value, open: true, team: false }));
      } else if (chatId === 0) {
        dispatch(getTeamMessages());
      } else {
        dispatch(getMessages(user.id));
      }
      let toUsers = [user];
      setSelectedUsers(toUsers);
    },
    [chatId, dispatch, techChat]
  );

  useEffect(() => {
    let users: UserSimple[] = [];

    if (techChat && techChats?.length! > 0) {
      users = techChats!;
      let toUsers = users.filter((u) => u.id !== loggedInUser?.id);
      setSelectedUsers(toUsers);
    } else if (teamChat && teamChats?.length! > 0) {
      users = teamChats!;
      let toUsers = users.filter((u) => u.id !== loggedInUser?.id);
      setSelectedUsers(toUsers);
    } else {
      let conversation = teamChat ? currentTeamConversation : techChat ? currentTechConversation : currentConversation;

      if (conversation && conversation.length > 0) {
        let conversationUsers: UserSimple[] = [];
        conversationUsers.push(conversation[0].fromUser);
        let newUsers = conversationUsers.concat(conversation[0].toUsers);
        let users: UserSimple[] = [];

        if (chatId !== 0 && newUsers.find((u) => u.id !== loggedInUser?.id!)?.id === chatId) {
          users = newUsers;
        }

        let toUsers = users.filter((u) => u.id !== loggedInUser?.id);

        setSelectedUsers(toUsers);
      } else if (!!simpleUser && !teamChat && !techChat && simpleUser.id === chatId) {
        handleSelectUser(simpleUser!);
      }
    }
  }, [
    currentConversation,
    currentTeamConversation,
    currentTechConversation,
    dispatch,
    techChat,
    loggedInUser,
    simpleUser,
    teamChat,
    chatId,
    handleSelectUser,
    teamChats,
    techChats,
  ]);

  useEffect(() => {
    let conversation = teamChat ? currentTeamConversation : techChat ? currentTechConversation : currentConversation;
    if ((!conversation || conversation.length < 1) && !!users) {
      let user = users?.find((u) => u.id === chatId);
      if (user) {
        handleSelectUser(user);
      }
    }
  }, [
    currentConversation,
    currentTeamConversation,
    currentTechConversation,
    techChat,
    chatId,
    teamChat,
    users,
    handleSelectUser,
  ]);

  const validationSchema = Yup.object().shape({
    user: Yup.object(),
  });

  return (
    <>
      {open && (
        <Formik
          enableReinitialize
          initialValues={{ message: undefined }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {}}
        >
          {({ values }) => (
            <Form noValidate>
              {!techChat && !teamChat && chatId! < 1 && (
                <Field
                  name="user"
                  label="New User"
                  required
                  component={Inputs.Dropdown}
                  options={users || []}
                  handleChange={(event: React.ChangeEvent<{}>, value: UserSimple) => handleSelectUser(value)}
                />
              )}
              {teamChat ? (
                <ChatView
                  chatId={chatId}
                  open={teamChatOpen}
                  conversation={currentTeamConversation}
                  toUsers={selectedUsers!}
                  read={(id: number) => dispatch(readTeamMessage(id))}
                  getConversation={() => dispatch(getTeamConversations())}
                  send={(message: Message) => dispatch(sendTeamChat(message))}
                  // techChat={false}
                />
              ) : techChat ? (
                <ChatView
                  chatId={chatId}
                  open={!!chatOpen.tech && !!chatOpen.open && chatOpen.id === techChatHospitalId}
                  conversation={currentTechConversation}
                  toUsers={selectedUsers!}
                  read={(id: number) => dispatch(readTechMessage(id))}
                  getConversation={() => dispatch(getTechConversations())}
                  send={(message: Message) => dispatch(sendTechChat(message))}
                  // techChat={true}
                />
              ) : (
                <ChatView
                  chatId={chatId}
                  open={chatOpen.open}
                  conversation={currentConversation}
                  toUsers={selectedUsers!}
                  read={(id: number) => dispatch(readMessage(id))}
                  getConversation={() => dispatch(getConversations())}
                  send={(message: Message) => dispatch(sendChat(message))}
                  // techChat={false}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ChatComponent;
