import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

const Root = styled.div<Props>`
  width: ${(props) => props?.size || "25px"};
  height: ${(props) => props?.size || "25px"};

  path {
    fill: ${(props) => props?.color || "#fff"};
  }

  polygon {
    fill: ${(props) => props?.color || "#fff"};
  }
`;

export const enum IconType {
  Qa = "qa.svg",
}

interface Props {
  type: IconType;
  size?: string;
  color?: string;
}

const Icon: React.FC<Props> = (props) => {
  return (
    <Root {...props}>
      <ReactSVG style={{ height: "100%", fontSize: "1px" }} src={`/static/images/${props.type}`}></ReactSVG>
    </Root>
  );
};

export default Icon;
