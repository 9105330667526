import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import helpers from "../../helpers";
import UserActionMenu from "../views/UserActionMenu";
import UserBreakMenu from "../views/UserBreakMenu";
import { useDispatch, useSelector } from "../../store";
import Avatar from "../base/Avatar";
import getUserRoleId from "../../helpers/getUserRoleId";
import Moment from "react-moment";
import theme from "../../theme";
import Modal from "../base/Modal";
import moment from "moment";
import BreakModal from "./BreakModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { setTemporaryOpen } from "../../slices/users";
import S3Avatar from "../base/S3Avatar";

interface Props {
  collapsed: boolean;
  windowWidth: number;
}

const LoggedInUserCard: React.FC<Props> = (props) => {
  const { collapsed, windowWidth } = props;
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector((state) => state.auth);
  const { scheduledBreak, breakTime, usersCollapsed } = useSelector((state) => state.users);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      if (!!breakTime?.returnTime) {
        const now = moment(new Date());
        const returnDate = moment(new Date(breakTime?.returnTime));
        const difference = now.diff(returnDate, "minutes");

        if (difference >= 5) {
          setOpen(true);
        }
      }
    };

    const run = setInterval(checkTime, 30000);
    return () => clearInterval(run);
  }, [breakTime?.returnTime]);

  let status = loggedInUser?.status?.id! === 2 ? { id: 1, value: "Online" } : loggedInUser?.status;

  const handleShowBreakMenu = () => {
    dispatch(setTemporaryOpen(true));
  };

  const getTooltipInfo = () => {
    return (
      <Box sx={{ fontSize: 14 }}>
        <Box>
          <Box sx={{ textTransform: "capitalize" }}>
            {breakTime?.details ? breakTime?.breakType?.value + ": " + breakTime?.details : ""}
          </Box>
          <Box sx={{ textTransform: "capitalize" }}>{breakTime?.hospital ? breakTime?.hospital.name : ""}</Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1, overflowY: "auto", overflowX: "hidden" }}>
      <Box
        sx={{
          display: "inline-block",
          flexDirection: "column",
          width: {
            xs: collapsed ? "121px" : windowWidth - 10,
            md: collapsed ? "121px" : "345px",
          },
          backgroundColor: theme.palette.background.default,
          borderBottomLeftRadius: status?.id! > 1 ? "" : "15px",
          borderBottomRightRadius: status?.id! > 1 ? "" : "15px",
        }}
      >
        <Modal
          size={window.innerWidth - 100}
          border={`8px solid ${theme.palette.error.main}`}
          background={theme.palette.warning.main}
          largeTitle
          title="Return from break?"
          open={open}
          handleClose={() => {
            // setOpen(false);
          }}
        >
          <BreakModal
            loggedInUser={loggedInUser!}
            handleClose={() => {
              setOpen(false);
            }}
          />
        </Modal>
        <Box sx={{ display: "flex", flexDirection: "row", columnGap: "4px", height: "80px" }}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              background:
                breakTime != null && breakTime.watching
                  ? theme.palette.success.dark
                  : helpers.getColorByStatus(status?.id!),
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
              alignItems: "center",
              justifyContent: "center",
              width: collapsed ? "85px" : "fit-content",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <Avatar
                roleId={getUserRoleId(loggedInUser)}
                alt={
                  loggedInUser?.firstName
                    ? `${loggedInUser?.firstName}`
                    : "" + loggedInUser?.lastName
                    ? `${loggedInUser?.lastName}`
                    : ""
                }
                sx={{
                  width: 60,
                  height: 60,
                  mx: "5px",
                  alignItems: "left",
                }}
              >
                <S3Avatar
                  firstName={loggedInUser?.firstName!}
                  lastName={loggedInUser?.lastName!}
                  imageId={loggedInUser?.id!}
                  height="60px"
                />
              </Avatar>
            </Box>
            {!collapsed && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  mx: "4px",
                  flexGrow: 1,
                  width: "200px",
                }}
              >
                <Box>
                  <Typography
                    color="textPrimary"
                    sx={{ lineHeight: "20px", fontWeight: 500, fontSize: "18px", textTransform: "capitalize" }}
                  >
                    {loggedInUser?.firstName} {loggedInUser?.lastName}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    textAlign="left"
                    color="textPrimary"
                    sx={{
                      lineHeight: "14px",
                      fontSize: ".8rem",
                      textTransform: "capitalize",
                      maxWidth: "195px",
                      whiteSpace: "break-spaces",
                      fontWeight: 400,
                    }}
                  >
                    {loggedInUser?.specialty ? loggedInUser?.specialty : ""}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title={getTooltipInfo()} placement="right">
                    <Typography
                      textAlign="left"
                      color="textPrimary"
                      fontStyle="italic"
                      sx={{
                        lineHeight: "13px",
                        fontSize: ".8rem",
                        textTransform: "capitalize",
                        maxWidth: "195px",
                        whiteSpace: "break-spaces",
                        fontWeight: 500,
                        marginTop: "2px",
                      }}
                    >
                      {breakTime?.details
                        ? breakTime?.details.length > 60
                          ? breakTime?.details?.slice(0, 60) + "..."
                          : breakTime.details
                        : ""}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box>
                  <Typography
                    textAlign="left"
                    color="textPrimary"
                    fontStyle="italic"
                    sx={{
                      lineHeight: "13px",
                      fontSize: ".8rem",
                      textTransform: "capitalize",
                      maxWidth: "195px",
                      whiteSpace: "break-spaces",
                      fontWeight: 500,
                      marginTop: "2px",
                    }}
                  >
                    {breakTime?.hospital ? breakTime.hospital.name : ""}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: helpers.getColorByStatus(status?.id!),
              transform: "rotate(-180deg)",
              writingMode: "vertical-lr",
              textTransform: "uppercase",
              textAlign: "center",
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
            }}
          >
            <Typography variant="caption" component="div" sx={{ fontWeight: 700, ml: "10px", mr: "5px" }}>
              {status?.value === "Procedure" ? "Proc" : status?.id === 9 ? "Admin" : status?.value || "Online"}
            </Typography>
          </Box>
        </Box>
        {scheduledBreak && status?.id! < 3 && !usersCollapsed && (
          <Box sx={{ display: "flex", flexDirection: "row", columnGap: "4px" }}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.default,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                mx: "30px",
                pt: 1,
                flexGrow: 1,
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
            >
              <Typography sx={{ color: theme.palette.warning.main }}>
                Reminder: Meeting scheduled at <Moment format="HH:mm">{scheduledBreak.startTime}</Moment>!
              </Typography>
            </Box>
          </Box>
        )}
        {!collapsed && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              columnGap: "2px",
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.background.default,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flexGrow: 1,
                borderTopLeftRadius: !!scheduledBreak ? "" : "15px",
                borderTopRightRadius: !!scheduledBreak ? "" : "15px",
                borderBottomLeftRadius: status?.id! > 1 ? "" : "15px",
                borderBottomRightRadius: status?.id! > 1 ? "" : "15px",
              }}
            >
              <UserActionMenu user={loggedInUser!} />
            </Box>
          </Box>
        )}
        {status?.id! > 2 && !usersCollapsed && (
          <>
            <Box sx={{ display: "flex", flexDirection: "row", columnGap: "4px" }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.default,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  mx: "30px",
                  flexGrow: 1,
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <UserBreakMenu user={loggedInUser!} />
              </Box>
            </Box>
          </>
        )}
      </Box>
      {collapsed && (
        <Tooltip title="Show break menu" placement="top">
          <Box
            sx={{
              backgroundColor: helpers.getColorByStatus(status?.id!),
              textTransform: "uppercase",
              borderRadius: "15px;",
              width: "55px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="overline"
              sx={{ fontWeight: 700, fontSize: ".7rem", lineHeight: "20px", marginTop: "2px" }}
            >
              Break
            </Typography>
            <React.Fragment>
              <IconButton aria-label="Example" sx={{ color: theme.palette.text.primary }} onClick={handleShowBreakMenu}>
                <FontAwesomeIcon icon={faCaretDown} size="sm" />
              </IconButton>
            </React.Fragment>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default LoggedInUserCard;
