import { Auth as AmplifyAuth } from "aws-amplify";
import { AxiosRequestConfig } from "axios";

const axiosJwtInterceptor = async (config: AxiosRequestConfig) => {
  try {
    const session = await AmplifyAuth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if (token) {
      config!.headers!.Authorization = `Bearer ${token}`;
    }
  } catch (error) {}

  return config;
};

export default axiosJwtInterceptor;
