import React from "react";
import { Box, Typography } from "@mui/material";
import User from "../../types/user";
import Avatar from "../base/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical, faHandshake, faHospitalUser } from "@fortawesome/free-solid-svg-icons";
import getUserRoleId from "../../helpers/getUserRoleId";
import S3Avatar from "../base/S3Avatar";

interface Props {
  user: User;
  collapsed: string;
}

const UserAvatar: React.FC<Props> = (props) => {
  const { user, collapsed } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
      {
        // SMC Group Coverage
        user.id < 0 && user.hospital?.id === 7 ? (
          <Avatar
            roleId={0}
            alt={user.firstName ? `${user.firstName}` : "" + user.lastName ? `${user.lastName}` : ""}
            backgroundColor="#000"
            sx={{
              color: "#fff",
              width: 60,
              height: 60,
              padding: -10,
              border: "2px solid",
              marginTop: collapsed !== "true" ? "2px" : "-1px",
              marginRight: "8px",
              marginLeft: "4px",
              alignItems: "left",
            }}
          >
            <FontAwesomeIcon size="lg" icon={faHandshake} />
          </Avatar>
        ) : // Tech Chats
        user.id === 0 && !!user.hospital?.hospitalLocation ? (
          <Avatar
            roleId={0}
            alt={user.firstName ? `${user.firstName}` : "" + user.lastName ? `${user.lastName}` : ""}
            backgroundColor="#000"
            sx={{
              color: "#fff",
              width: 60,
              height: 60,
              padding: -10,
              border: "2px solid",
              marginTop: "2px",
              marginRight: "8px",
              marginLeft: "4px",
              alignItems: "left",
            }}
          >
            <FontAwesomeIcon size="lg" icon={faHospitalUser} />
          </Avatar>
        ) : // Locum (user not in system)
        user.lastName.toLowerCase() === "tenens" ? (
          <Avatar
            roleId={getUserRoleId(user)}
            alt={user.firstName ? `${user.firstName}` : "" + user.lastName ? `${user.lastName}` : ""}
            src={"/static/images/locum.jpeg"}
            sx={{
              width: 60,
              height: 60,
              marginTop: collapsed !== "true" ? "2px" : "-1px",
              marginRight: "8px",
              marginLeft: "4px",
              alignItems: "left",
            }}
          >
            {user.firstName ? `${user?.firstName[0]?.toUpperCase()}` : ""}
            {user.lastName ? `${user?.lastName[0]?.toUpperCase()}` : ""}
          </Avatar>
        ) : // BIC User with special Avatar
        user.firstName.toLowerCase() === "bic" ? (
          <Avatar
            roleId={getUserRoleId(user)}
            alt={user.firstName ? `${user.firstName}` : "" + user.lastName ? `${user.lastName}` : ""}
            src={"/static/images/bic.png"}
            sx={{
              width: 60,
              height: 60,
              marginTop: collapsed !== "true" ? "2px" : "-1px",
              marginRight: "8px",
              marginLeft: "4px",
              alignItems: "left",
            }}
          >
            {user.firstName ? `${user?.firstName[0]?.toUpperCase()}` : ""}
            {user.lastName ? `${user?.lastName[0]?.toUpperCase()}` : ""}
          </Avatar>
        ) : (
          <Avatar
            roleId={getUserRoleId(user)}
            alt={user.firstName ? `${user.firstName}` : "" + user.lastName ? `${user.lastName}` : ""}
            backgroundColor={user.lastName.toLowerCase() === "shift" ? "#757575" : ""}
            sx={{
              width: 60,
              height: 60,
              marginTop:
                collapsed !== "true"
                  ? "2px"
                  : user.lastName && user.lastName.toLowerCase() !== "shift"
                  ? "10px"
                  : "-1px",
              marginRight: "8px",
              marginLeft: "4px",
              alignItems: "left",
            }}
          >
            <S3Avatar firstName={user?.firstName!} lastName={user?.lastName!} imageId={user?.id!} height="60px" />
          </Avatar>
        )
      }
    </Box>
  );
};

export default UserAvatar;
