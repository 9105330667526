import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LoginType from "../types/login";
import { Auth as AmplifyAuth } from "aws-amplify";
import User from "../types/user";
import http from "../api/http";
import PasswordReset from "../types/passwordReset";
import Hospital from "../types/hospital";
import CognitoUser from "../types/cognitoUser";
import { hubActions } from "./hub";
import { toggleNews } from "./news";
import { RootState } from "../store";

interface AuthState {
  isLoggedIn: boolean;
  errorMessage: string;
  status: "idle" | "loading" | "failed";
  loggedInUser?: User;
  terms: boolean | undefined;
  openedTermsModal: boolean;
  selectedHospital?: Hospital;
  temporaryPassword?: boolean;
  passwordExpired?: boolean;
  awsUser?: CognitoUser;
}

const initialState: AuthState = {
  isLoggedIn: true,
  status: "idle",
  errorMessage: "",
  terms: undefined,
  openedTermsModal: false,
};

export const acceptTerms = createAsyncThunk<User, number>("auth/terms", async (userId) => {
  await AmplifyAuth.currentSession();
  const result = await http.patch<any>(`/users/${userId}/accept`);
  return result.data;
});

export const check = createAsyncThunk<User | undefined, boolean, { state: RootState }>(
  "auth/check",
  async (fromLogin, { getState }) => {
    let state = getState();
    if (state.auth?.passwordExpired || state.auth?.temporaryPassword) {
      console.log("expired");
    } else {
      await AmplifyAuth.currentSession();
      const result = await http.get<User>(`users/current`);
      if (result && result.data) {
        await http.post(`/qgenda/${result.data.id}`);

        if (fromLogin) {
          await http.patch(`/users/${result?.data?.id}/online`);
        }
      }
      return result.data;
    }
  }
);

export const login = createAsyncThunk<CognitoUser, LoginType>(
  "auth/login",
  async ({ username, password }, { dispatch }) => {
    const awsUser = await AmplifyAuth.signIn(username, password);
    if (!awsUser?.challengeName) {
      const attributes = await dispatch(getUserAttributes(awsUser));
      // this is to account for timezone issues between front and back end
      let expirationDate = new Date(attributes.payload as string);
      expirationDate.setDate(expirationDate.getDate() - 1);
      if (expirationDate <= new Date()) {
        return awsUser;
      }

      await dispatch(hubActions.startConnecting());
      await dispatch(toggleNews(false));
    }

    return awsUser;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const user = await http.get<User>(`users/current`);
  await http.patch(`/users/${user?.data?.id}/offline`);
  await AmplifyAuth.signOut();

  try {
    await AmplifyAuth.currentSession();
  } catch (error) {
    // console.log(error);
  }
});

export const forgotPassword = createAsyncThunk<null, string>("password/forgot", async (username: string) => {
  const response = await AmplifyAuth.forgotPassword(username);
  return response;
});

export const changePassword = createAsyncThunk<string, PasswordReset>(
  "password/change",
  async (reset: PasswordReset, { dispatch }) => {
    const result = await AmplifyAuth.completeNewPassword(reset.user, reset.password);
    await dispatch(login({ username: reset.username!, password: reset.password, rememberMe: false }));
    await http.put("/users/password-expiration");
    return result;
  }
);

export const resetPassword = createAsyncThunk<string, PasswordReset>(
  "password/reset",
  async (reset: PasswordReset, { dispatch }) => {
    const result = await AmplifyAuth.forgotPasswordSubmit(reset.username!, reset.code!, reset.password);
    await dispatch(login({ username: reset.username!, password: reset.password, rememberMe: false }));
    await http.put("/users/password-expiration");
    return result;
  }
);

export const adminChangePassword = createAsyncThunk<string, PasswordReset>(
  "password/admin-reset",
  async (reset: PasswordReset) => {
    const response = await http.put(`users/${reset.userId}/password?password=${reset.password}`);
    return response.data;
  }
);

export const getUserAttributes = createAsyncThunk<string, CognitoUser>("auth/user-attributes", async (user) => {
  const response = await AmplifyAuth.userAttributes(user);
  const expiration = response?.find((a) => a.Name === "custom:passwordExpiration")?.Value!;
  return expiration;
});

export const changeExpiredPassword = createAsyncThunk<any, LoginType>(
  "auth/expired-password",
  async (model: LoginType, { dispatch }) => {
    const response = await http.put(`users/own-password`, { password: model.password });
    await dispatch(login(model));
    return response.data;
  }
);

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    changeState(state, action) {
      if (state.loggedInUser) {
        state.loggedInUser.status = { id: action.payload?.id, value: action.payload?.value };
      }
    },
    setTermsOpened: (state, action) => {
      state.openedTermsModal = action.payload;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
    },
    setSelectedHospital(state, action) {
      state.selectedHospital = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(check.pending, (state) => {
        state.errorMessage = "";
        state.status = "loading";
      })
      .addCase(check.fulfilled, (state, action) => {
        state.status = "idle";
        if (!!action.payload) {
          state.isLoggedIn = state.passwordExpired ? false : true;
          state.loggedInUser = action.payload;
          state.loggedInUser.status = action.payload.status;
          state.terms = !!action.payload?.acceptedTerms;
          if (!!state.selectedHospital) {
            let hospital = state.loggedInUser?.hospitals?.find((h) => h.id === state.selectedHospital?.id);
            if (!!hospital) {
              state.selectedHospital = hospital;
            }
          }
        }
      })
      .addCase(check.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.status = "idle";
        state.isLoggedIn = false;
      })
      .addCase(login.pending, (state) => {
        state.errorMessage = "";
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "idle";
        state.awsUser = action.payload;

        if (!!action.payload && !!action.payload.challengeName) {
          state.temporaryPassword = true;
        } else {
          state.temporaryPassword = false;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.status = "failed";
      })
      .addCase(getUserAttributes.pending, (state) => {
        state.errorMessage = "";
        state.status = "loading";
      })
      .addCase(getUserAttributes.fulfilled, (state, action) => {
        state.status = "idle";
        let expirationDate = new Date(action.payload);
        expirationDate.setDate(expirationDate.getDate() - 1);
        if (!state.temporaryPassword && expirationDate <= new Date()) {
          state.passwordExpired = true;
          state.isLoggedIn = false;
          state.loggedInUser = initialState.loggedInUser;
        } else {
          state.passwordExpired = false;
        }
      })
      .addCase(getUserAttributes.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.status = "failed";
      })
      .addCase(logout.pending, (state) => {
        state.errorMessage = "";
        state.status = "loading";
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.status = "idle";
        state.isLoggedIn = false;
        state.loggedInUser = undefined;
      })
      .addCase(logout.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.status = "failed";
      })
      .addCase(adminChangePassword.pending, (state) => {
        state.errorMessage = "";
        state.status = "loading";
      })
      .addCase(adminChangePassword.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(adminChangePassword.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.status = "failed";
      })
      .addCase(changePassword.pending, (state) => {
        state.errorMessage = "";
        state.status = "loading";
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = "idle";
        state.temporaryPassword = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.status = "failed";
      })
      .addCase(changeExpiredPassword.pending, (state) => {
        state.errorMessage = "";
        state.status = "loading";
      })
      .addCase(changeExpiredPassword.fulfilled, (state, action) => {
        state.status = "idle";
        state.passwordExpired = false;
      })
      .addCase(changeExpiredPassword.rejected, (state, action) => {
        state.errorMessage = action.error.message || "";
        state.status = "failed";
      });
  },
});

export default authSlice.reducer;
export const { changeState, setTermsOpened, setTerms, setSelectedHospital } = authSlice.actions;
