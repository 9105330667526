import React from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Button, Card, CardContent, Container, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../forms";
import { forgotPassword } from "../../slices/auth";
import { useNavigate } from "react-router-dom";
import AuthLogo from "../base/AuthLogo";

interface Props {}

const ForgotPassword: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector((store) => store.auth);

  const initialValues = { username: "" };

  const validationSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Please enter your username"),
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: { xs: "100%", md: "100vh" },
        }}
      >
        <Container maxWidth="sm" sx={{ py: { md: "80px", xs: "0px" }, pb: { xs: "20px", md: "0px" } }}>
          <AuthLogo />
          <Card sx={{ mt: "15px" }}>
            <CardContent>
              <Box>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    await dispatch(forgotPassword(values.username)).then(() => {
                      navigate(`/reset?username=${values.username}`);
                    });
                  }}
                >
                  <Form noValidate style={{ color: "textSecondary" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                      <Typography variant="h6">Enter username to send a password reset email:</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                      <Field name="username" required label="Username" component={Inputs.Text} />
                    </Box>
                    <Box
                      sx={{
                        mt: "12px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Box>
                        <Button
                          variant="contained"
                          fullWidth
                          size="large"
                          sx={{ mt: 2 }}
                          color="secondary"
                          onClick={() => navigate("/login")}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Box>
                        <Inputs.Submit text={"Send"} isSubmitting={status === "loading"} />
                      </Box>
                    </Box>
                  </Form>
                </Formik>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
