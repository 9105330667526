import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import StyledTable from "../base/StyledTable";
import RadioLink from "../../types/radioLink";
import {
  createLink,
  deleteLink,
  fetchLinks,
  toggleVisible,
  updateLink,
  updateLinkOrder,
  updateLinks,
} from "../../slices/links";
import { useDispatch, useSelector } from "../../store";
import { EditRounded, Delete, Add, VisibilityOff, Visibility, Save } from "@mui/icons-material";
import AddEditLink from "./AddEditLink";
import Modal from "../base/Modal";
import DeleteConfirmation from "../base/DeleteConfirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { arrayMoveImmutable } from "array-move";
import theme from "../../theme";

interface Props {}

const RadioLinks: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { status, links } = useSelector((store) => store.links);

  type ActiveModal = "none" | "add" | "edit" | "delete" | "show" | "hide";
  const [modalOpen, setModalOpen] = useState<ActiveModal>("none");
  const [selectedLink, setSelectedLink] = useState<RadioLink>();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(fetchLinks());
  }, [dispatch]);

  const closeModal = () => {
    setModalOpen("none");
  };

  const handleOpenAdd = () => {
    setModalOpen("add");
  };

  const handleAdd = async (model: RadioLink) => {
    await dispatch(createLink(model));
    closeModal();
  };

  const handleOpenEdit = (openLink: RadioLink) => {
    setSelectedLink(openLink);
    setModalOpen("edit");
  };

  const handleEdit = async (model: RadioLink) => {
    await dispatch(updateLink(model));
    closeModal();
  };

  const handleOpenDelete = (openLink: RadioLink) => {
    setSelectedLink(openLink);
    setModalOpen("delete");
  };

  const handleDelete = async () => {
    await dispatch(deleteLink(selectedLink?.id!));
    closeModal();
  };

  const handleOpenVisible = (openLink: RadioLink, visible: boolean) => {
    setSelectedLink(openLink);
    setModalOpen(visible ? "show" : "hide");
  };

  const handleToggleVisible = async (visible: boolean) => {
    setSelectedLink({ ...selectedLink!, visible: visible });
    await dispatch(toggleVisible({ ...selectedLink!, visible: visible }));
    closeModal();
  };

  const saveOrdering = () => {
    dispatch(updateLinkOrder(links));
  };

  const headers = ["Name", "Link", "Visible", "Actions", "Display Order"];

  const rows =
    links?.map((link, index) => {
      return {
        name: (
          <Typography color="inherit" variant="subtitle1">
            {link.name}
          </Typography>
        ),
        link: (
          <Typography color="inherit" variant="subtitle1">
            {!sm ? link.permalink : "View on web"}
          </Typography>
        ),
        visible: (
          <Typography color="inherit" variant="subtitle1">
            {link.visible === true ? "Yes" : "No"}
          </Typography>
        ),
        actions: (
          <Box>
            <Tooltip title={<Typography variant="button">Edit</Typography>} placement="top">
              <IconButton color="inherit" onClick={() => handleOpenEdit(link)}>
                <EditRounded />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="button">Delete</Typography>} placement="top">
              <IconButton onClick={() => handleOpenDelete(link)} color="inherit">
                <Delete />
              </IconButton>
            </Tooltip>
            {link.visible ? (
              <Tooltip title={<Typography variant="button">Hide</Typography>} placement="top">
                <IconButton onClick={() => handleOpenVisible(link, false)} color="inherit">
                  <VisibilityOff />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={<Typography variant="button">Show</Typography>} placement="top">
                <IconButton onClick={() => handleOpenVisible(link, true)} color="inherit">
                  <Visibility />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
        order: (
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" gap={2}>
            {/* <Box>{link.order || index + 1}</Box> */}
            <Box sx={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faArrowUp}
                onClick={() => {
                  const newArray = arrayMoveImmutable(links, index, index - 1);
                  dispatch(updateLinks(newArray));
                }}
              />
            </Box>
            <Box sx={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faArrowDown}
                onClick={() => {
                  const newArray = arrayMoveImmutable(links, index, index + 1);
                  dispatch(updateLinks(newArray));
                }}
              />
            </Box>
          </Box>
        ),
      };
    }) || [];

  return (
    <React.Fragment>
      <Modal open={modalOpen === "add"} handleClose={closeModal} title="Add New Link">
        <AddEditLink handleClose={closeModal} isSubmitting={status === "loading"} onSubmit={handleAdd} />
      </Modal>
      <Modal open={modalOpen === "edit"} handleClose={closeModal} title="Add New Link">
        <AddEditLink
          editLink={selectedLink}
          handleClose={closeModal}
          isSubmitting={status === "loading"}
          onSubmit={handleEdit}
        />
      </Modal>
      <Modal open={modalOpen === "delete"} handleClose={closeModal} title={"Confirm Delete"}>
        <DeleteConfirmation name="this link" handleClose={closeModal} handleDelete={handleDelete} />
      </Modal>
      <Modal
        open={modalOpen === "show" || modalOpen === "hide"}
        handleClose={closeModal}
        title={modalOpen === "show" ? "Show Link" : "Hide Link"}
      >
        <Box>
          <Grid margin={1}>
            <Typography variant="h6" justifyContent="center" textAlign="center">
              Are you sure you want to{" "}
              {modalOpen === "show"
                ? "make this link visible?"
                : "hide this link? The link can be made visible again later."}
            </Typography>
            <Grid container justifyContent="center" alignItems="center" spacing={2} mt={2}>
              <Grid item xs={6}>
                <Box>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => handleToggleVisible(modalOpen === "show")}
                  >
                    {modalOpen === "show" ? "Show" : "Hide"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid container paddingBottom="10px" direction={"row"} alignItems="center" justifyContent={"space-between"}>
        <Grid item>
          <Button onClick={handleOpenAdd} color="primary" startIcon={<Add fontSize="small" />} variant="contained">
            New Link
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={saveOrdering}
            startIcon={
              status === "loading" ? <CircularProgress size={20} color="inherit" /> : <Save fontSize="small" />
            }
          >
            Save Order
          </Button>
        </Grid>
      </Grid>
      <Divider />

      {status === "loading" ? (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: 3 }}>
          <CircularProgress color="inherit" size={50} />
        </Box>
      ) : (
        <Box>
          <StyledTable headers={headers} rows={rows} paging={false} useObjects />
        </Box>
      )}
    </React.Fragment>
  );
};

export default RadioLinks;
