import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Case from "../../types/case";
import CaseCard from "./CaseCard";
import { useSelector } from "../../store";
import getUserRoleId from "../../helpers/getUserRoleId";
import theme from "../../theme";

export interface Props {
  kiosk?: boolean | undefined;
  columnHeader: string;

  loadingOne: boolean;
  caseArrayOne: Case[] | null;
  rowHeaderOne?: string;

  loadingTwo?: boolean | undefined;
  caseArrayTwo?: Case[] | null | undefined;
  rowHeaderTwo?: string | undefined;

  loadingThree?: boolean | undefined;
  caseArrayThree?: Case[] | null | undefined;
  rowHeaderThree?: string | undefined;

  userId: number;
}

const CaseColumn: React.FC<Props> = (props) => {
  const { loggedInUser } = useSelector((store) => store.auth);
  const borderRadius = 15;
  return (
    <React.Fragment>
      <Box
        id="pending-cases"
        style={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: borderRadius,
          width: "100%",
          border: `2px solid ${theme.palette.primary.main}`,
          display: "flex",
          justifyContent: "center",
          marginTop: getUserRoleId(loggedInUser) === 3 ? 0 : "7px",
          marginBottom: 24,
        }}
      >
        <Typography variant="h6" sx={{ marginTop: "4px", marginBottom: "4px" }}>{`${props?.columnHeader}`}</Typography>
      </Box>
      <Box
        style={{
          marginLeft: "2.5%",
          width: "95%",
          // backgroundColor: theme.palette.background.paper,
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: borderRadius,
          marginBottom: 12,
        }}
      >
        {!!props.caseArrayOne && (
          <Box style={{ marginLeft: 2, marginRight: 2, paddingBottom: 12 }}>
            <Grid container spacing={3}>
              {props.caseArrayOne?.map((cse) => (
                <React.Fragment key={cse?.id}>
                  <Grid item xs={12}>
                    <CaseCard kiosk={!!props.kiosk} case={cse} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
      {!!props.caseArrayTwo && (
        <>
          <Box
            style={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: borderRadius,
              width: "100%",
              border: `2px solid ${theme.palette.primary.main}`,
              display: "flex",
              justifyContent: "center",
              marginBottom: 24,
            }}
          >
            <Typography variant="h6" sx={{ marginTop: "4px", marginBottom: "4px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>{props?.rowHeaderTwo}</Box>
            </Typography>
          </Box>

          <Box
            style={{
              marginLeft: "2.5%",
              width: "95%",
              // backgroundColor: theme.palette.background.paper,
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              marginBottom: 12,
            }}
          >
            <Box style={{ marginLeft: 2, marginRight: 2, paddingBottom: 12 }}>
              <Grid container spacing={3}>
                {props.caseArrayTwo?.map((cse) => (
                  <React.Fragment key={cse?.id}>
                    <Grid item xs={12}>
                      <CaseCard kiosk={!!props.kiosk} case={cse} />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </>
      )}

      {!!props.caseArrayThree && (
        <>
          <Box
            style={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: borderRadius,
              width: "100%",
              border: `2px solid ${theme.palette.primary.main}`,
              display: "flex",
              justifyContent: "center",
              marginBottom: 24,
            }}
          >
            <Typography variant="h6" sx={{ marginTop: "4px", marginBottom: "4px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>{props?.rowHeaderThree}</Box>
            </Typography>
          </Box>

          <Box
            style={{
              marginLeft: "2.5%",
              width: "95%",
              // backgroundColor: theme.palette.background.paper,
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
              marginBottom: 12,
            }}
          >
            <Box style={{ marginLeft: 2, marginRight: 2, paddingBottom: 12 }}>
              <Grid container spacing={3}>
                {props.caseArrayThree?.map((cse) => (
                  <React.Fragment key={cse?.id}>
                    <Grid item xs={12}>
                      <CaseCard kiosk={!!props.kiosk} case={cse} />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </React.Fragment>
  );
};

export default CaseColumn;
