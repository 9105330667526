import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";

interface Props {}

const Root = styled.div`
  background-color: ${theme.palette.background.default};
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: 64px;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

const Content = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

const DefaultLayout: React.FC<Props> = (props) => {
  return (
    <Root>
      <Wrapper>
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Wrapper>
    </Root>
  );
};

export default DefaultLayout;
