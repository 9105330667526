import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Box, CardActionArea, Divider, Grid, Typography, Avatar, Tooltip, useMediaQuery } from "@mui/material";
import Case from "../../types/case";
import Moment from "react-moment";
import theme from "../../theme";
import getPillColorByStatus from "../../helpers/getPillColorByStatus";
import replaceCharactersWithDashes from "../../helpers/hideCharacterDashes";
import getUserRoleId from "../../helpers/getUserRoleId";
import CardTimer from "./CardTimer";
import Chip from "../base/Chip";
import { AccountCircleRounded, Grid3x3, LocationOnRounded, PhoneRounded } from "@mui/icons-material";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import CommentBubble from "../base/CommentBubble";
import CaseActionsView from "./CaseActionsView";
import { useDispatch, useSelector } from "../../store";
import { changeState } from "../../slices/auth";
import { logBreak } from "../../slices/users";
import moment from "moment";
import S3Avatar from "../base/S3Avatar";

interface Props {
  case: Case;
  kiosk?: boolean | undefined;
  isAssigned: { toUser: boolean };
  isCompleted: boolean;
  isBlocked: boolean;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  maxTime: number;
  handleOpenReportModal: () => void;
  setStrokeModal: Dispatch<SetStateAction<boolean>>;
  buttonClicked: boolean;
  setButtonClicked: Dispatch<SetStateAction<boolean>>;
  completeCase: () => void;
}

const CaseView: React.FC<Props> = (props) => {
  const {
    kiosk,
    isAssigned,
    isCompleted,
    isBlocked,
    isEditing,
    setIsEditing,
    maxTime,
    handleOpenReportModal,
    setStrokeModal,
    buttonClicked,
    setButtonClicked,
    completeCase,
  } = props;
  const [isHoveringName, setIsHoveringName] = React.useState(false);
  const [isHoveringID, setIsHoveringID] = React.useState(false);
  const sm = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(isAssigned.toUser);
  const { loggedInUser } = useSelector((state) => state.auth);

  const handleToggleOpen = () => setOpen(!open);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sm) {
      setOpen(false);
    }
  }, [sm]);

  const handleCallBreak = (caseType: Case) => {
    if (getUserRoleId(loggedInUser) !== 3) {
      dispatch(changeState({ id: 5, value: "Call", return: 10 }));
      dispatch(
        logBreak({
          userId: loggedInUser?.id,
          breakType: { id: 3, value: "" },
          returnTime: moment().add(10, "minutes").toDate(),
          watching: false,
          hospital: caseType?.hospital,
          details:
            caseType?.hospitalDepartment?.name +
            " " +
            caseType?.hospitalDepartment?.phoneNumber +
            " " +
            caseType?.modality?.name +
            ":" +
            caseType?.examTypes?.map((examType) => {
              return " " + examType.value;
            }),
        })
      );
    }
  };

  return (
    <Box style={{ backgroundColor: !!isBlocked ? theme.palette.error.main : "transparent", borderRadius: 12 }}>
      <CardActionArea
        onClick={handleToggleOpen}
        onMouseEnter={() => setIsHoveringName(true)}
        onMouseLeave={() => setIsHoveringName(false)}
      >
        {/* urgency and date */}
        <Grid container paddingX="5px" marginY="-5px" justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="overline" color={theme.palette.quinary.main}>
              {props.case.urgency?.value} {props.case.urgency?.id === 2 ? `- ${props.case.criticalValue?.value}` : ""}
            </Typography>
          </Grid>
          {!!isBlocked && getUserRoleId(loggedInUser) === 3 && (
            <Grid item>
              <Typography variant="subtitle2" paddingTop="5px">
                Action Required!
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant="overline">
              <Moment format="MM/DD/YYYY">{props.case.created}</Moment> (<Moment fromNow>{props.case.created}</Moment>)
            </Typography>
          </Grid>
        </Grid>
        {/* Name and hospital badge */}
        <Grid container paddingX="5px" justifyContent={"space-between"}>
          <Grid
            item
            onMouseEnter={() => {
              setIsHoveringName(true);
            }}
            onMouseLeave={() => {
              setIsHoveringName(false);
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {!isHoveringName && !loggedInUser?.allowSensitiveData && (
                <Typography variant="h6">
                  {props.case.lastName[0]}
                  {replaceCharactersWithDashes(props.case.lastName?.length! - 1)}, {props.case.firstName[0]!}
                  {replaceCharactersWithDashes(props.case.firstName?.length! - 1)}
                </Typography>
              )}
              {(isHoveringName || loggedInUser?.allowSensitiveData) && (
                <Typography variant="h6">
                  {props.case.lastName}, {props.case.firstName}
                </Typography>
              )}
            </Box>
          </Grid>
          {!!isBlocked &&
            (getUserRoleId(loggedInUser) === 3 ? (
              <Grid item style={{ textAlign: "center" }}>
                {getUserRoleId(loggedInUser) !== 0 && (
                  <Typography variant="h6" color={theme.palette.warning.main}>
                    {props.case.returnReason?.value}
                  </Typography>
                )}
              </Grid>
            ) : (
              <Grid item style={{ textAlign: "center" }}>
                {getUserRoleId(loggedInUser) !== 0 && (
                  <Typography variant="subtitle2" color={theme.palette.warning.main}>
                    {props.case.returnReason?.value}
                  </Typography>
                )}
              </Grid>
            ))}
          <Grid item>
            <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
              <Chip type="hospital" color={props.case.hospital?.badgeColor} label={props.case.hospital?.shortName!} />
            </Box>
          </Grid>
        </Grid>
      </CardActionArea>
      <Divider />
      {getUserRoleId(loggedInUser) !== 3 && !isBlocked && props.case?.status?.id! !== 3 && (
        <Grid>
          <Box>
            <CardTimer maxTime={maxTime ?? 0} created={props.case.created!} timeBlocked={props.case.timeBlocked!} />
          </Box>
        </Grid>
      )}
      {getUserRoleId(loggedInUser) !== 3 && !isBlocked && props.case?.status?.id! === 3 && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography sx={{ fontSize: 10 }}>
            <Moment duration={props.case.assigned} date={props.case.completed} format="mm" /> minutes to complete
          </Typography>
        </Box>
      )}
      {/* exam type and assigned user */}
      {props.case.currentUser && props.case.currentUser.id !== loggedInUser?.id! ? (
        <Grid container padding={"5px"} direction={"row"} display="flex" alignItems={"center"} sx={{ marginY: "-5px" }}>
          <Grid item xs={10} display="flex" flexDirection="column" justifyContent="flex-start">
            <Grid container direction="row" alignContent={"center"} alignItems={"center"} justifyContent={"flex-start"}>
              <Grid item margin={0.2}>
                <Chip type="modality" label={props.case.modality?.name!} />
              </Grid>
              {props.case.examTypes &&
                props.case.examTypes?.map((exam) => (
                  <Grid item margin={0.2} key={exam.id}>
                    <Chip
                      type="exam"
                      color={getPillColorByStatus(props.case.urgency?.id)}
                      key={exam.id}
                      label={exam.value}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={2} display="flex" flexDirection="column" justifyContent="flex-end" alignItems="end">
            <Tooltip
              title={props.case.currentUser?.firstName + " " + props.case.currentUser?.lastName}
              placement="left"
            >
              <Avatar alt={`${props.case.currentUser?.firstName} ${props.case.currentUser?.lastName}`}>
                <S3Avatar
                  firstName={props.case.currentUser?.firstName}
                  lastName={props.case.currentUser?.lastName}
                  imageId={props.case.currentUser?.id}
                  height="40px"
                />
              </Avatar>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            padding={"5px"}
            direction={"row"}
            display="flex"
            alignItems={"center"}
            sx={{ marginY: "-5px" }}
          >
            <Grid item xs={12} display="flex" flexDirection="column" justifyContent="flex-start">
              <Grid
                container
                direction="row"
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Grid item margin={0.2}>
                  <Chip type="modality" label={props.case.modality?.name!} />
                </Grid>
                {props.case.examTypes &&
                  props.case.examTypes?.map((exam) => (
                    <Grid item margin={0.2} key={exam.id}>
                      <Chip
                        type="exam"
                        color={getPillColorByStatus(props.case.urgency?.id)}
                        key={exam.id}
                        label={exam.value}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {isBlocked && getUserRoleId(loggedInUser) === 3 && (
        <Box px={1}>
          <Box display="flex" flexDirection="column">
            <Typography variant="overline">
              Please click edit then RESUBMIT once the missing information has been provided.
            </Typography>
            <Typography variant="overline">Enter additional comments, if any when submitting changes.</Typography>
          </Box>
        </Box>
      )}
      {open && (
        <Box>
          <Grid container paddingX="5px">
            <Grid item xs={12} sm={6} marginTop="-5px">
              <Box
                onMouseEnter={() => {
                  setIsHoveringID(true);
                }}
                onMouseLeave={() => {
                  setIsHoveringID(false);
                }}
                sx={{ marginBottom: "-5px" }}
              >
                <Typography>
                  <Grid3x3 fontSize={"small"} style={{ paddingTop: "5px" }} />
                  {isHoveringID || loggedInUser?.allowSensitiveData
                    ? props.case.patientId
                    : `${replaceCharactersWithDashes(`${props.case?.patientId || ""}`.length)}`}
                </Typography>
              </Box>
              <Typography>
                <AccountCircleRounded fontSize={"small"} style={{ paddingTop: "5px" }} />
                {props.case.openedByUser?.lastName || loggedInUser?.lastName},{" "}
                {props.case.openedByUser?.firstName || loggedInUser?.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} marginTop="-5px">
              <Box sx={{ marginBottom: "-5px" }}>
                <Typography>
                  <LocationOnRounded fontSize={"small"} style={{ paddingTop: "5px" }} />
                  {props.case.hospitalDepartment?.name}
                </Typography>
              </Box>
              <Typography
                onClick={() => handleCallBreak(props.case)}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
              >
                <PhoneRounded fontSize={"small"} style={{ paddingTop: "5px" }} />
                {formatPhoneNumber(props.case.hospitalDepartment?.phoneNumber!)}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          {/* Comments */}
          {props.case.comments &&
            props.case.comments.length > 0 &&
            props.case.comments.find((c) => c.commentType?.id === 1) && (
              <Grid container paddingX="15px" marginTop="-5px">
                <Box sx={{ marginBottom: "-10px" }}>
                  <Typography variant="overline">Comments</Typography>
                </Box>
                {props.case.comments
                  .filter((c) => c.commentType?.id === 1)
                  .map((comment) => (
                    <Grid key={comment.id} item xs={12}>
                      <Box>
                        <CommentBubble comment={comment} type="case" loggedInUser={loggedInUser} />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            )}
          {/* Findings */}

          {props.case.comments &&
            props.case.comments.length > 0 &&
            props.case.comments.find((c) => c.commentType?.id === 2) && (
              <Grid container paddingX="15px" marginTop="-5px">
                <Box sx={{ marginBottom: "-10px" }}>
                  <Typography variant="overline">Findings</Typography>
                </Box>
                {props.case.comments
                  .filter((c) => c.commentType?.id === 2)
                  .map((comment) => (
                    <Grid key={comment.id} item xs={12}>
                      <Box>
                        <CommentBubble comment={comment} type="case" loggedInUser={loggedInUser} />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            )}
        </Box>
      )}
      <CaseActionsView
        isAssigned={isAssigned}
        isCompleted={isCompleted}
        isBlocked={isBlocked}
        case={props.case}
        kiosk={kiosk}
        handleOpenReportModal={handleOpenReportModal}
        open={open}
        setStrokeModal={setStrokeModal}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        completeCase={completeCase}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </Box>
  );
};

export default CaseView;
