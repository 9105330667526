import React, { FunctionComponent } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  Typography,
  Modal,
  Backdrop,
  Fade,
  TextField,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Modality, { ModalType } from "../../../types/modality";
import ExamType from "../../../types/examType";
import theme from "../../../theme";

interface Props {
  modalTypes: Modality[]; // for the typeahead options
  modalities: Modality[];
  setModalities: any;
}

interface ModalInterface {
  visible: boolean;
  type: ModalType;
}

interface NewModalInterface {
  exam: {
    id: number;
    name: string;
    modalityId: number;
  } | null;
  wu: string;
}

const HospitalAuthUsers: FunctionComponent<Props> = React.memo((props) => {
  const { modalTypes, modalities, setModalities } = props;

  // need to map a new array for the Autocomplete options
  const UltraSoundExamTypes = modalTypes
    ?.find((modal) => modal.name === "Ultrasound")
    ?.examTypes.map((exam) => {
      return { id: exam.id, modalityId: exam.modalityId, label: exam.value };
    });
  const XRayExamTypes = modalTypes
    ?.find((modal) => modal.name === "X-Ray")
    ?.examTypes.map((exam) => {
      return { id: exam.id, modalityId: exam.modalityId, label: exam.value };
    });
  const MRIExamTypes = modalTypes
    ?.find((modal) => modal.name === "MRI")
    ?.examTypes.map((exam) => {
      return { id: exam.id, modalityId: exam.modalityId, label: exam.value };
    });
  const NuclearMedicineExamTypes = modalTypes
    ?.find((modal) => modal.name === "Nuclear Medicine")
    ?.examTypes.map((exam) => {
      return { id: exam.id, modalityId: exam.modalityId, label: exam.value };
    });
  const CTExamTypes = modalTypes
    ?.find((modal) => modal.name === "CT")
    ?.examTypes.map((exam) => {
      return { id: exam.id, modalityId: exam.modalityId, label: exam.value };
    });

  const UltraSoundArray = modalities?.find((modal) => modal.name === "Ultrasound")?.examTypes;
  const XRayArray = modalities?.find((modal) => modal.name === "X-Ray")?.examTypes;
  const MRIArray = modalities?.find((modal) => modal.name === "MRI")?.examTypes;
  const NuclearMedicineArray = modalities?.find((modal) => modal.name === "Nuclear Medicine")?.examTypes;
  const CTArray = modalities?.find((modal) => modal.name === "CT")?.examTypes;

  const [newExamType, setNewExamType] = React.useState<NewModalInterface>({
    exam: null,
    wu: "",
  });

  // Using a single modal in order to save memory
  const [muiModal, setMuiModal] = React.useState<ModalInterface>({
    visible: false,
    type: "CT",
  });

  const handleOpenModal = (type: ModalType) =>
    setMuiModal({
      visible: true,
      type: type,
    });

  const handleCloseModal = () => {
    setMuiModal({
      visible: false,
      type: "CT",
    });
    setTimeout(
      () =>
        setNewExamType({
          exam: null,
          wu: "",
        }),
      250
    );
  };

  const handleAddExam = () => {
    const { exam, wu } = newExamType;
    if (!exam) return alert("Please Enter the name");
    if (wu === "") return alert("Please Enter the wu");
    const { type } = muiModal;
    const payload: ExamType = {
      id: exam.id,
      value: exam.name,
      modalityId: exam.modalityId,
      workUnit: +wu,
    };

    setModalities((prevState: { modalities: any }) => {
      const prevModalities = prevState.modalities;
      const modalityIndex = prevModalities.findIndex((modal: { name: string }) => modal.name === type);
      let newModalities = [...prevModalities];
      if (prevState.modalities[payload.modalityId - 1].examTypes.some((exam: ExamType) => exam.id === payload.id)) {
        newModalities[modalityIndex] = {
          ...newModalities[modalityIndex],
          examTypes: [...newModalities[modalityIndex].examTypes],
        };
        return { ...prevState, modalities: [...newModalities] };
      } else {
        newModalities[modalityIndex] = {
          ...newModalities[modalityIndex],
          examTypes: [...newModalities[modalityIndex].examTypes, { ...payload }],
        };
        return { ...prevState, modalities: [...newModalities] };
      }
    });
    handleCloseModal();
  };

  const handleRemoveExam = (modalType: ModalType, id: number) => {
    setModalities((prevState: { modalities: any }) => {
      const prevModalities = prevState.modalities;
      const modalityIndex = prevModalities.findIndex((modal: { name: string }) => modal.name === modalType);
      let prevExamTypes = [...prevModalities[modalityIndex].examTypes];
      prevExamTypes.splice(
        prevExamTypes.findIndex((modal: { id: number }) => modal.id === id),
        1
      );
      let newModalities = [...prevModalities];
      newModalities[modalityIndex] = { ...newModalities[modalityIndex], examTypes: [...prevExamTypes] };
      return { ...prevState, modalities: [...newModalities] };
    });
  };

  return (
    <React.Fragment>
      {/*  */}
      <Modal
        open={muiModal.visible}
        onClose={handleCloseModal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={muiModal.visible}>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 650,
            }}
          >
            <Paper style={{ overflow: "hidden" }}>
              <Grid
                item
                xs={12}
                bgcolor={theme.palette.quarternary.main}
                height={75}
                display="flex"
                alignItems="center"
              >
                <Grid
                  marginLeft={3}
                  marginRight={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Grid item>
                    <Typography color="textPrimary" variant="h6">
                      Add Exam Type
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton type="button" onClick={() => handleCloseModal()}>
                      <CloseRounded />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid margin={3}>
                <Grid marginBottom={3} item xs={12}>
                  <Autocomplete
                    disablePortal
                    // value={newExamType.exam?.name}
                    onChange={(ignore, value) => {
                      // @ts-ignore
                      setNewExamType((prevState) => {
                        return { ...prevState, exam: { ...value, name: value?.label } };
                      });
                    }}
                    options={(() => {
                      if (muiModal.type === "Ultrasound") return UltraSoundExamTypes || [];
                      if (muiModal.type === "X-Ray") return XRayExamTypes || [];
                      if (muiModal.type === "MRI") return MRIExamTypes || [];
                      if (muiModal.type === "Nuclear Medicine") return NuclearMedicineExamTypes || [];
                      if (muiModal.type === "CT") return CTExamTypes || [];
                      return [];
                    })()}
                    renderInput={(params) => <TextField label="Name *" {...params} />}
                  />
                </Grid>
                <Grid marginBottom={6} item xs={12}>
                  <TextField
                    type="number"
                    fullWidth
                    label="WU *"
                    value={newExamType.wu}
                    onChange={(e) =>
                      setNewExamType((prevState) => {
                        return { ...prevState, wu: e.target.value };
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" alignItems="flex-end">
                    <Button onClick={() => handleCloseModal()}>Cancel</Button>
                    <Button onClick={() => handleAddExam()}>Add</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
      {/*  */}

      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h6">
              Modalities & Exam Types
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>

      {/* Ultrasound */}
      <Grid item xs={12}>
        <Table component={Paper}>
          <Box margin={2}>
            <Grid container justifyContent="space-between" alignItems="flex-end" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  Ultrasound
                </Typography>
              </Grid>
              <Button onClick={() => handleOpenModal("Ultrasound")}>Add</Button>
            </Grid>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {UltraSoundArray?.map((modal) => (
                <Box
                  key={modal.id}
                  marginRight={1}
                  marginBottom={1}
                  bgcolor={theme.palette.text.primary}
                  borderRadius={20}
                >
                  <Box display="flex" flexDirection="row" alignItems="center" marginRight={1.5} marginLeft={1.5}>
                    <Typography marginRight={1} variant="subtitle1" color="black">
                      {modal.value}
                    </Typography>
                    <Typography marginRight={1} variant="subtitle2" color="black">
                      WU: {modal.workUnit}
                    </Typography>
                    <IconButton
                      style={{ width: 30, height: 30 }}
                      onClick={() => handleRemoveExam("Ultrasound", modal.id)}
                    >
                      <CloseRounded color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Table>
      </Grid>

      {/* X-Ray */}
      <Grid item xs={12}>
        <Table component={Paper}>
          <Box margin={2}>
            <Grid container justifyContent="space-between" alignItems="flex-end" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  X-Ray
                </Typography>
              </Grid>
              <Button onClick={() => handleOpenModal("X-Ray")}>Add</Button>
            </Grid>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {XRayArray?.map((modal) => (
                <Box
                  key={modal.id}
                  marginRight={1}
                  marginBottom={1}
                  bgcolor={theme.palette.text.primary}
                  borderRadius={20}
                >
                  <Box display="flex" flexDirection="row" alignItems="center" marginRight={1.5} marginLeft={1.5}>
                    <Typography marginRight={1} variant="subtitle1" color="black">
                      {modal.value}
                    </Typography>
                    <Typography marginRight={1} variant="subtitle2" color="black">
                      WU: {modal.workUnit}
                    </Typography>
                    <IconButton style={{ width: 30, height: 30 }} onClick={() => handleRemoveExam("X-Ray", modal.id)}>
                      <CloseRounded color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Table>
      </Grid>

      {/* MRI */}
      <Grid item xs={12}>
        <Table component={Paper}>
          <Box margin={2}>
            <Grid container justifyContent="space-between" alignItems="flex-end" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  MRI
                </Typography>
              </Grid>
              <Button onClick={() => handleOpenModal("MRI")}>Add</Button>
            </Grid>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {MRIArray?.map((modal) => (
                <Box
                  key={modal.id}
                  marginRight={1}
                  marginBottom={1}
                  bgcolor={theme.palette.text.primary}
                  borderRadius={20}
                >
                  <Box display="flex" flexDirection="row" alignItems="center" marginRight={1.5} marginLeft={1.5}>
                    <Typography marginRight={1} variant="subtitle1" color="black">
                      {modal.value}
                    </Typography>
                    <Typography marginRight={1} variant="subtitle2" color="black">
                      WU: {modal.workUnit}
                    </Typography>
                    <IconButton style={{ width: 30, height: 30 }} onClick={() => handleRemoveExam("MRI", modal.id)}>
                      <CloseRounded color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Table>
      </Grid>

      {/* Nuclear Medecine */}
      <Grid item xs={12}>
        <Table component={Paper}>
          <Box margin={2}>
            <Grid container justifyContent="space-between" alignItems="flex-end" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  Nuclear Medicine
                </Typography>
              </Grid>
              <Button onClick={() => handleOpenModal("Nuclear Medicine")}>Add</Button>
            </Grid>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {NuclearMedicineArray?.map((modal) => (
                <Box
                  key={modal.id}
                  marginRight={1}
                  marginBottom={1}
                  bgcolor={theme.palette.text.primary}
                  borderRadius={20}
                >
                  <Box display="flex" flexDirection="row" alignItems="center" marginRight={1.5} marginLeft={1.5}>
                    <Typography marginRight={1} variant="subtitle1" color="black">
                      {modal.value}
                    </Typography>
                    <Typography marginRight={1} variant="subtitle2" color="black">
                      WU: {modal.workUnit}
                    </Typography>
                    <IconButton
                      style={{ width: 30, height: 30 }}
                      onClick={() => handleRemoveExam("Nuclear Medicine", modal.id)}
                    >
                      <CloseRounded color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Table>
      </Grid>

      {/* CT */}
      <Grid item xs={12}>
        <Table component={Paper}>
          <Box margin={2}>
            <Grid container justifyContent="space-between" alignItems="flex-end" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h6">
                  CT
                </Typography>
              </Grid>
              <Button onClick={() => handleOpenModal("CT")}>Add</Button>
            </Grid>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {CTArray?.map((modal) => (
                <Box
                  key={modal.id}
                  marginRight={1}
                  marginBottom={1}
                  bgcolor={theme.palette.text.primary}
                  borderRadius={20}
                >
                  <Box display="flex" flexDirection="row" alignItems="center" marginRight={1.5} marginLeft={1.5}>
                    <Typography marginRight={1} variant="subtitle1" color="black">
                      {modal.value}
                    </Typography>
                    <Typography marginRight={1} variant="subtitle2" color="black">
                      WU: {modal.workUnit}
                    </Typography>
                    <IconButton style={{ width: 30, height: 30 }} onClick={() => handleRemoveExam("CT", modal.id)}>
                      <CloseRounded color="primary" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Table>
      </Grid>
    </React.Fragment>
  );
});

export default HospitalAuthUsers;
