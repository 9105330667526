import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Comment from "../../types/comment";
import User from "../../types/user";
import Moment from "react-moment";
import theme from "../../theme";

interface Props {
  comment: Comment;
  type: "qa" | "case";
  loggedInUser?: User;
  assignedToUser?: User;
  openedByUser?: User;
}

const CommentBubble: React.FC<Props> = (props) => {
  const getUserString = () => {
    switch (props.type) {
      case "qa":
        switch (props.comment.user?.id) {
          case props.loggedInUser?.id:
            return "Me";
          case props.assignedToUser?.id:
            return "Assigned User";
          case props.openedByUser?.id:
            return "Opened By User";
          default:
            return "Other User";
        }
      case "case":
        return `${props.comment.user?.firstName} ${props.comment.user?.lastName}`;
      default:
        return "";
    }
  };

  return (
    <Grid display="flex" alignItems="center" container>
      <Grid item xs={12}>
        <Box
          bgcolor={
            props.comment.user?.id !== props.loggedInUser?.id
              ? theme.palette.text.secondary
              : theme.palette.success.main
          }
          borderRadius={3}
          width="100%"
        >
          {/* avatar if posted by not logged in user */}
          <Box display="flex" flexDirection="row" alignItems="center" margin="5px">
            <Grid
              style={{ textAlign: "center" }}
              color={props.comment.user?.id !== props.loggedInUser?.id ? "#000" : "#fff"}
              container
            >
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ lineHeight: "20px" }}>
                  {props.comment.commentText}
                </Typography>
              </Grid>
              {props.comment.user?.id !== props.loggedInUser?.id ? (
                <Grid container direction={"row"} alignItems={"center"}>
                  <Grid item xs={12}>
                    <Typography fontSize={11}>
                      Posted <Moment fromNow>{props.comment.created}</Moment> by <strong>{getUserString()}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction={"row"} alignItems={"center"}>
                  <Grid item xs={12}>
                    <Typography fontSize={11}>
                      Posted <Moment fromNow>{props.comment.created}</Moment> by <strong>{getUserString()}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Grid>
      {/* Avatar if posted by logged in user */}
    </Grid>
  );
};

export default CommentBubble;
