import React, { useEffect, useState } from "react";
import { Badge, Box, styled, Tooltip, Typography } from "@mui/material";
import helpers from "../../helpers";
import User from "../../types/user";
import { useDispatch, useSelector } from "../../store";
import { toggleChat, getMessages, clearConversation } from "../../slices/chat";
import getUserRoleId from "../../helpers/getUserRoleId";
import Moment from "react-moment";
import theme from "../../theme";
import { VisibilityOutlined } from "@mui/icons-material";
import Conversation from "../../types/conversation";
import { fetchSimpleUser } from "../../slices/users";
import UserAvatar from "./UserAvatar";
import { clearTechConversation, getTechMessages, toggleTechChatHospital } from "../../slices/techChats";

interface Props {
  user: User;
  collapsed: string;
  newMessages?: Conversation | undefined;
}

const MessageBadge = styled(Badge)((props: Props) => ({
  "& .MuiBadge-badge": {
    fontSize: "1rem",
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    border: "1px solid #000",
  },
}));

const WatchingBadge = styled(Badge)((props: Props) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.success.dark,
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
}));

const UserCard: React.FC<Props> = (props) => {
  const { user, collapsed, newMessages } = props;
  const { loggedInUser } = useSelector((store) => store.auth);
  const { conversations } = useSelector((state) => state.chat);
  const { techConversations } = useSelector((state) => state.techChats);
  const [shortBreak, setShortBreak] = useState("");

  const dispatch = useDispatch();

  const openChat = () => {
    dispatch(clearConversation());
    dispatch(clearTechConversation());

    if (!!user.techChat) {
      // get tech user list

      // get conversation for this hospital
      dispatch(getTechMessages(user.hospital?.id!));

      dispatch(toggleTechChatHospital(user?.hospital?.id));
      dispatch(
        toggleChat({
          id: user?.hospital?.id,
          title: user?.firstName + " " + user?.lastName,
          open: true,
          team: false,
          tech: true,
        })
      );
    } else if (user?.id >= 0 && !user?.roles.find((f) => f.id === 3)) {
      dispatch(fetchSimpleUser(user?.id));
      dispatch(getMessages(user?.id));
      dispatch(toggleChat({ id: user?.id, title: user?.firstName + " " + user?.lastName, open: true, team: false }));
    }
  };

  useEffect(() => {
    if (user.break?.details) {
      var length = user.break.details.length;
      if (length > 35) {
        setShortBreak(user.break?.details.slice(0, 35) + "...");
      } else {
        setShortBreak(user.break?.details);
      }
    }
  }, [user]);

  const getTooltipInfo = () => {
    return (
      <Box sx={{ fontSize: 14 }}>
        {user.id < 0 && user.hospital?.id === 7 ? (
          <Box>Group Coverage</Box>
        ) : user.id < 0 ? (
          <Box>No Shift</Box>
        ) : user.lastName.toLowerCase() === "tenens" ? (
          <>
            <Box>Locum Tenens</Box>
            {user.startTime && user.endTime ? (
              <Box>
                <Moment format="HH:mm">{user.startTime}</Moment> - <Moment format="HH:mm">{user.endTime}</Moment>
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box>
            <Box>
              {user.phone &&
                "(" +
                  user.phone.substring(1, 4) +
                  ")-" +
                  user.phone.substring(4, 7) +
                  "-" +
                  user.phone.substring(7, user.phone.length)}
            </Box>
            <Box>{user.email && user.email}</Box>
            {!user.hospital?.hospitalLocation &&
              user.hospital?.shortName.split(" ")?.filter((h) => h.length! > 5)?.length! > 0 && (
                <Box>{user.hospital?.name}</Box>
              )}
            {user.startTime && user.endTime ? (
              <Box>
                <Moment format="HH:mm">{user.startTime}</Moment> - <Moment format="HH:mm">{user.endTime}</Moment>
              </Box>
            ) : (
              <></>
            )}
            <Box sx={{ textTransform: "capitalize" }}>
              {user.break?.details ? user.status?.value + ": " + user.break?.details : ""}
            </Box>
            <Box sx={{ textTransform: "capitalize" }}>{user.break?.hospital ? user.break.hospital.name : ""}</Box>
          </Box>
        )}
      </Box>
    );
  };

  let contactOnCard = user;
  let loggedInUserIsRad = loggedInUser?.roles?.find((f) => f.id === 2);
  let loggedInUserIsAdmin = loggedInUser?.roles?.find((f) => f.id === 1);
  let loggedInUserIsTechForContactsHospital =
    loggedInUser?.roles?.find((f) => f.id === 3) &&
    loggedInUser.hospitals?.find((h) => h.id === contactOnCard.hospital?.id);

  let techChatAvailable = contactOnCard.techChat && (loggedInUserIsRad || loggedInUserIsTechForContactsHospital);
  let individualChatAvailable =
    !contactOnCard.techChat &&
    contactOnCard.isDbUser &&
    contactOnCard.id !== loggedInUser?.id &&
    (loggedInUserIsAdmin || loggedInUserIsRad);

  const getBadgeContent = () => {
    let found = null;
    if (newMessages?.unread! > 0) {
      if (!!user.techChat && !!newMessages?.isTech) {
        found = techConversations.find((c) => !!c.isTech && c.hospitalId === user.hospital?.id);
      } else if (!user.techChat) {
        found = conversations.find((c) => c.name === `${user.firstName} ${user.lastName}`);
      }
    }

    if (found?.unread! > 9) {
      return "9+";
    } else {
      return found?.unread!;
    }
  };

  return (
    <div
      style={{
        display: "inline-block",
        cursor: (!!user.techChat ? techChatAvailable : individualChatAvailable) ? "pointer" : "default",
      }}
      onClick={(!!user.techChat ? techChatAvailable : individualChatAvailable) ? openChat : undefined}
    >
      <Box sx={{ display: "flex", flexDirection: "row", columnGap: "4px", height: "75px" }}>
        <Tooltip title={getTooltipInfo()} placement="right">
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: collapsed === "true" && !user.techChat ? "column" : "row",
              background: !!user.techChat
                ? user.hospital?.badgeColor
                : user.break != null && user.break.watching
                ? theme.palette.success.dark
                : helpers.getColorByStatus(user?.status?.id!),
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
              alignItems: "center",
              justifyContent: "center",
              width: collapsed === "true" ? "85px" : "fit-content",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              {user.break && user.break.watching ? (
                <WatchingBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={<VisibilityOutlined />}
                  {...props}
                  sx={{ fontSize: "5px" }}
                >
                  <UserAvatar user={user} collapsed={collapsed} />
                </WatchingBadge>
              ) : !!newMessages ? (
                <MessageBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  color="error"
                  badgeContent={getBadgeContent()}
                  sx={{ fontSize: "50px" }}
                  {...props}
                >
                  <UserAvatar user={user} collapsed={collapsed} />
                </MessageBadge>
              ) : (
                <UserAvatar user={user} collapsed={collapsed} />
              )}
            </Box>
            {collapsed === "true" &&
            user.lastName &&
            !user.techChat &&
            !user.lastName.toLowerCase().includes("shift") &&
            user.lastName.toLowerCase() !== "tenens" &&
            user.lastName.toLowerCase() !== "coverage" &&
            user.firstName.toLowerCase() !== "bic" ? (
              <Typography
                variant="caption"
                sx={{
                  marginTop: "-3px",
                  marginBottom: "1px",
                }}
              >
                {user.lastName}
              </Typography>
            ) : (
              <></>
            )}
            {collapsed === "true" && !!user.techChat && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "5px",
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant={"body1"}
                    sx={{ lineHeight: "20px", fontWeight: 500, fontSize: "16px", textTransform: "capitalize" }}
                  >
                    {user?.firstName}
                  </Typography>
                </Box>
              </>
            )}
            {collapsed !== "true" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant={"body1"}
                    sx={{ lineHeight: "20px", fontWeight: 500, fontSize: "16px", textTransform: "capitalize" }}
                  >
                    {user?.firstName} {user.lastName.toLowerCase() !== "tenens" ? user?.lastName : ""}
                  </Typography>
                  {getUserRoleId(user) === 2 ? (
                    <Typography
                      textAlign="left"
                      color="textPrimary"
                      variant="body2"
                      sx={{
                        lineHeight: "14px",
                        fontSize: ".8rem",
                        textTransform: "capitalize",
                        maxWidth: "150px",
                        whiteSpace: "break-spaces",
                        fontWeight: 400,
                      }}
                    >
                      {user?.specialty ? user?.specialty : ""}
                    </Typography>
                  ) : (
                    <Typography
                      textAlign="left"
                      color="textPrimary"
                      variant="body2"
                      sx={{
                        lineHeight: "14px",
                        fontSize: ".8rem",
                        textTransform: "capitalize",
                        maxWidth: "150px",
                        whiteSpace: "break-spaces",
                        fontWeight: 400,
                      }}
                    >
                      {user?.specialty
                        ? user?.specialty
                        : getUserRoleId(user) === 3
                        ? "Technologist"
                        : getUserRoleId(user) === 1
                        ? "Admin"
                        : getUserRoleId(user) === 4
                        ? "Hospital Admin"
                        : getUserRoleId(user) === 5
                        ? "Office Manager"
                        : getUserRoleId(user) === 6
                        ? "Judge"
                        : ""}
                    </Typography>
                  )}
                  {user.status?.id! > 2 && !!user.online && (
                    <>
                      <Typography
                        fontStyle="italic"
                        textAlign="left"
                        color="textPrimary"
                        variant="body2"
                        sx={{
                          lineHeight: "14px",
                          fontSize: "10px",
                          textTransform: "capitalize",
                          maxWidth: "150px",
                          // whiteSpace: "break-spaces",
                          fontWeight: 500,
                        }}
                      >
                        {shortBreak}
                      </Typography>
                      <Typography
                        fontStyle="italic"
                        textAlign="left"
                        color="textPrimary"
                        variant="body2"
                        sx={{
                          lineHeight: "14px",
                          fontSize: "10px",
                          textTransform: "capitalize",
                          maxWidth: "150px",
                          whiteSpace: "break-spaces",
                          fontWeight: 500,
                        }}
                      >
                        Left <Moment fromNow>{user.break?.startTime}</Moment>
                      </Typography>
                      <Typography
                        fontStyle="italic"
                        textAlign="left"
                        color="textPrimary"
                        variant="body2"
                        sx={{
                          lineHeight: "14px",
                          fontSize: "10px",
                          textTransform: "capitalize",
                          maxWidth: "150px",
                          whiteSpace: "break-spaces",
                          fontWeight: 500,
                        }}
                      >
                        Returning <Moment fromNow>{user.break?.returnTime}</Moment>
                      </Typography>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Tooltip>
        <Box
          sx={{
            backgroundColor: !!user.techChat ? user.hospital?.badgeColor : helpers.getColorByStatus(user?.status?.id!),
            transform: "rotate(-180deg)",
            writingMode: "vertical-lr",
            textTransform: "uppercase",
            textAlign: "center",
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
          }}
        >
          <Typography variant="caption" sx={{ fontWeight: 700, ml: "10px", mr: "5px" }}>
            {!!user.techChat
              ? "Chat"
              : user?.status?.value === "Procedure"
              ? "Proc"
              : user?.status?.id === 9
              ? "Admin"
              : user?.status?.value || "Offline"}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default UserCard;
