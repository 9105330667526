import React, { useEffect } from "react";
import { Box, Divider, Typography, CircularProgress, Paper } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import AddEditUserView from "../views/AddEditUser";
import { useDispatch, useSelector } from "../../store";
import { addUser, updateUser, fetchUser } from "../../slices/addedituser";
import User from "../../types/user";
import { check } from "../../slices/auth";

interface Props {}

const defaultUser: User = {
  id: 0,
  email: "",
  firstName: "",
  lastName: "",
  roles: [],
  avatar: "",
  firstSession: new Date(),
  isJudge: false,
  lastSession: new Date(),
  phone: "",
  status: { id: 0, value: "" },
};

const AddEditUser: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const isEdit = !!location.state?.id;
  const { user } = useSelector((store) => store.addEditUser);
  const [loading, setLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const fromSettings = !!location.state?.fromSettings;

  const handleAddUser = async (model: User) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    await dispatch(addUser({ ...model, avatarForm: user?.avatarForm }));
    await dispatch(check(false));
    handleReturn();
  };

  const handleUpdateUser = async (model: User) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    await dispatch(updateUser({ ...model, avatarForm: user?.avatarForm }));
    await dispatch(check(false));
    handleReturn();
  };

  const handleReturn = () => {
    if (fromSettings)
      return navigate("/app/settings", {
        replace: true,
        state: {
          tab: "users",
        },
      });
    navigate(-1);
  };

  useEffect(() => {
    (async () => {
      if (isEdit) await dispatch(fetchUser(location.state?.id || 0));
      setLoading(false);
    })();
  }, [dispatch, isEdit, location.state?.id]);

  return !!loading ? (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ p: "10px" }}>
      <Paper sx={{ marginTop: "25px", marginBottom: "50px", padding: "25px" }}>
        <Box sx={{ marginTop: "-15px" }}>
          <Typography color="textPrimary" variant="h5">
            {isEdit ? "Edit" : "Add"} User
          </Typography>
        </Box>
        <Divider />
        <br />
        {!isEdit ? ( // for adding
          <AddEditUserView
            cancel={handleReturn}
            navigate={navigate}
            isSubmitting={isSubmitting}
            user={defaultUser}
            onSubmit={handleAddUser}
          />
        ) : !!user ? ( // async dispatch for editing
          <AddEditUserView
            cancel={handleReturn}
            navigate={navigate}
            isSubmitting={isSubmitting}
            user={user}
            onSubmit={handleUpdateUser}
          />
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
            <CircularProgress />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default AddEditUser;
