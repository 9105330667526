import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "styled-components";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { useDispatch, useSelector } from "./store";
import theme from "./theme";
import { check } from "./slices/auth";
import SnackBarToast from "./components/base/SnackbarToast";
import { handleErrorToastState } from "./slices/toast";
import { offlineUser } from "./slices/users";
import { hubActions } from "./slices/hub";

interface Props {}

const App: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { errorToastOpen, errorMessage } = useSelector((state) => state.toast);
  const { isLoggedIn, loggedInUser, temporaryPassword, passwordExpired } = useSelector((state) => state.auth);

  const content = useRoutes(routes(isLoggedIn, loggedInUser, temporaryPassword, passwordExpired));

  const closeToast = () => {
    dispatch(handleErrorToastState(false));
  };

  useEffect(() => {
    const handleLogout = (event: Event) => {
      event.preventDefault();
      return dispatch(offlineUser(loggedInUser?.id!));
    };

    window.addEventListener("beforeunload", handleLogout);

    return () => {
      window.removeEventListener("beforeunload", handleLogout);
    };
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    const getUser = async () => {
      const user = await dispatch(check(false));
      if (!!user.payload && !passwordExpired) {
        dispatch(hubActions.startConnecting());
      }
    };

    getUser();
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackBarToast open={errorToastOpen} message={errorMessage} type="error" handleClose={closeToast} />
      {isLoggedIn !== undefined && content}
    </ThemeProvider>
  );
};

export default App;
