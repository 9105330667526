import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import SearchResults from "../types/searchResults";
import SearchParams from "../types/searchParams";
import helpers from "../helpers";
import InterestingCase from "../types/interestingCase";

interface InterestingCasesState {
  status: "idle" | "loading" | "failed";
  unreadCases?: number;
  cases?: SearchResults;
  interestingCase: InterestingCase;
}

const initialState: InterestingCasesState = {
  status: "idle",
  interestingCase: {
    id: 0,
    mrNumber: undefined,
    hospital: undefined,
    modality: undefined,
    comments: "",
  },
};

export const searchInterestingCases = createAsyncThunk<SearchResults, SearchParams>(
  "interesting/search",
  async (model: SearchParams) => {
    let qs = helpers.getSearchQueryString(model);
    const response = await http.get<SearchResults>(`/interesting/search?${qs}`);
    return response.data;
  }
);

export const getInterestingCase = createAsyncThunk<InterestingCase, number>("interesting/get", async (id: number) => {
  const response = await http.get<InterestingCase>(`/interesting/${id}`);
  return response.data;
});

export const getUnreadCases = createAsyncThunk<number>("interesting/unread", async () => {
  const response = await http.get(`/interesting/unread`);
  return response.data;
});

export const saveInterestingCase = createAsyncThunk<InterestingCase, InterestingCase>(
  "interesting/post",
  async (model: InterestingCase) => {
    const response = await http.post("/interesting", model);
    return response.data;
  }
);

export const editInterestingCase = createAsyncThunk<InterestingCase, InterestingCase>(
  "interesting/put",
  async (model: InterestingCase) => {
    const response = await http.put(`/interesting/${model.id}`, model);
    return response.data;
  }
);

export const readCase = createAsyncThunk<null, number>("interesting/read", async (id: number) => {
  const response = await http.patch(`/interesting/${id}`);
  return response.data;
});

export const deleteInterestingCase = createAsyncThunk<null, number>("interesting/delete", async (id: number) => {
  const response = await http.delete(`/interesting/${id}`);
  return response.data;
});

const interestingCasesSlice = createSlice({
  name: "interestingCases",
  initialState,
  reducers: {
    resetInterestingCase(state) {
      state.interestingCase = { ...initialState.interestingCase };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchInterestingCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchInterestingCases.fulfilled, (state, action) => {
        state.status = "idle";
        state.cases = action.payload;
      })
      .addCase(searchInterestingCases.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getInterestingCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInterestingCase.fulfilled, (state, action) => {
        state.status = "idle";
        state.interestingCase = action.payload;
      })
      .addCase(getInterestingCase.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUnreadCases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUnreadCases.fulfilled, (state, action) => {
        state.status = "idle";
        state.unreadCases = action.payload;
      })
      .addCase(getUnreadCases.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(saveInterestingCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveInterestingCase.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(saveInterestingCase.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editInterestingCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editInterestingCase.fulfilled, (state, action) => {
        state.status = "idle";
        state.interestingCase = action.payload;
      })
      .addCase(editInterestingCase.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { resetInterestingCase } = interestingCasesSlice.actions;

export default interestingCasesSlice.reducer;
