import React, { useEffect, useRef } from "react";
import { Box, Fab, IconButton, Tooltip, Typography } from "@mui/material";
import User from "../../types/user";
import { useDispatch, useSelector } from "../../store";
import Moment from "react-moment";
import { logBreak } from "../../slices/users";
import moment from "moment";
import { changeState } from "../../slices/auth";
import theme from "../../theme";
import { ReplayRounded } from "@mui/icons-material";

interface Props {
  loggedInUser: User;
  handleClose: () => void;
}

const BreakModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { breakTime } = useSelector((store) => store.users);

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleClick = (minutes: number) => {
    const now = moment(new Date());
    const returnDate = moment(new Date(breakTime?.returnTime!));
    const difference = now.diff(returnDate, "minutes");
    const newTime = moment(breakTime?.returnTime)
      .utc()
      .add(difference + minutes, "minutes")
      .toDate();

    dispatch(
      logBreak({
        ...breakTime,
        userId: props.loggedInUser?.id,
        returnTime: newTime,
        watching: false,
      })
    );
    props.handleClose();
  };

  const handleReturn = () => {
    dispatch(changeState({ id: 1, value: "Online", return: 0 }));
    dispatch(
      logBreak({
        ...breakTime,
        userId: props.loggedInUser?.id,
        returnTime: moment().toDate(),
        // details: undefined,
        watching: false,
        returned: true,
      })
    );
    props.handleClose();
  };

  return (
    <Box>
      <Box>
        <Typography align="center" marginY="20px" variant="h5" color="#000">
          Your expected return time was <Moment format="HH:mm">{breakTime?.returnTime}</Moment>. You can return now or
          extend your time away.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginY: "20px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", gap: 5 }}>
          <Box>
            <Typography variant="h5" align="center" color="#000" marginBottom="5px">
              Return
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1, marginTop: "10px" }}>
              <Fab
                sx={{
                  backgroundColor: theme.palette.success.main,
                  color: "white",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                size="medium"
                onClick={handleReturn}
              >
                <Tooltip title={<Typography variant="button">Return</Typography>}>
                  <ReplayRounded sx={{ fontSize: 30 }} />
                </Tooltip>
              </Fab>
            </Box>
          </Box>
          <Box>
            <Typography variant="h5" color="#000" align="center" marginBottom="5px">
              Extend
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 1,
                marginTop: "10px",
                color: "#000",
              }}
            >
              <Fab
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  color: "white",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                size="medium"
              >
                <IconButton size="medium" color="inherit" ref={anchorRef} onClick={() => handleClick(1)}>
                  +1
                </IconButton>
              </Fab>
              <Fab
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  color: "white",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                size="medium"
              >
                <IconButton size="medium" color="inherit" ref={anchorRef} onClick={() => handleClick(5)}>
                  +5
                </IconButton>
              </Fab>
              <Fab
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  color: "white",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                size="medium"
              >
                <IconButton size="medium" color="inherit" ref={anchorRef} onClick={() => handleClick(30)}>
                  +30
                </IconButton>
              </Fab>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BreakModal;
