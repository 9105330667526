import React, { useState } from "react";
import {
  AutocompleteChangeReason,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import { Inputs } from "../forms";
import Option from "../../types/option";
import { useDispatch, useSelector } from "../../store";
import { clearBreakOptions, fetchBreakDetails, updateDetailList } from "../../slices/userBreak";
import theme from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import BreakDetails from "../../types/breakDetails";
import BreakOption from "../../types/breakOption";

interface Props {
  onSubmit: (breakTypeId: number, result: BreakDetails) => void;
  breakOptions?: Option[];
}

const BreakSettings: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { breakDetails } = useSelector((store) => store.userBreaks);

  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<Option>();
  const [addNew, setAddNew] = useState<boolean>(false);

  const handleSelect = (breakTypeId: number) => {
    dispatch(fetchBreakDetails(breakTypeId));
  };

  const handleRemove = (details: Option) => {
    const filterDetails = breakDetails.options.filter((breakDetail) => breakDetail.value !== details.value);
    dispatch(updateDetailList(filterDetails));
  };

  const handleOpenAdd = () => {
    setAddNew(true);
  };

  const handleAdd = (details: BreakOption) => {
    const filterDetails = [details, ...breakDetails.options];
    dispatch(updateDetailList(filterDetails));
    setAddNew(false);
  };

  const handleSubmit = (values: BreakDetails) => {
    setLoading(true);
    props.onSubmit(type?.id!, values);
    setLoading(false);
  };

  const initialValues = {
    breakType: type,
    breakTime: breakDetails.breakTime,
    options: breakDetails.options,
  };

  const validationSchema = Yup.object().shape({
    breakType: Yup.object().nullable().required("Please select a break type"),
    breakTime: Yup.string().nullable(),
  });

  const modalValidationSchema = Yup.object().shape({
    value: Yup.string().nullable().required("Please enter new details"),
  });

  return (
    <React.Fragment>
      <Modal open={addNew} onClose={() => setAddNew(false)}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: sm ? 350 : 650,
          }}
        >
          <Paper style={{ overflow: "hidden", padding: 10 }}>
            <Formik
              enableReinitialize
              initialValues={{ id: 0, value: "", customTime: breakDetails.breakTime }}
              validationSchema={modalValidationSchema}
              onSubmit={handleAdd}
            >
              <Form noValidate style={{ color: "textSecondary" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mx: 1, pb: 0 }}>
                  <Field name="value" required label="Add New Break Detail" component={Inputs.Text} />
                  <Field name="customTime" label="Break Time" component={Inputs.Number} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="secondary"
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={() => setAddNew(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <Inputs.Submit text={"Add"} isSubmitting={false} />
                  </Box>
                </Box>
              </Form>
            </Formik>
          </Paper>
        </Box>
      </Modal>
      <Box>
        <Formik
          initialValues={initialValues!}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Form noValidate style={{ color: "textSecondary" }}>
            <Box
              sx={{
                height: "300px",
                display: "flex",
                flexDirection: { md: "row", xs: "column" },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  mt: { xs: 10, md: 0 },
                  mb: { xs: "10px", md: 0 },
                }}
              >
                <Typography variant="body1" sx={{ marginTop: "10px", marginBottom: "10px" }}>
                  Select Break Type
                </Typography>
                <Box sx={{ width: { md: "475px", xs: "100%" } }}>
                  <Field
                    name="breakType"
                    placeholder="Start typing to select"
                    label="Break Type"
                    onSelected={(event: React.SyntheticEvent, type: Option, reason: AutocompleteChangeReason) => {
                      if (reason === "clear") {
                        dispatch(clearBreakOptions());
                        setType(undefined);
                      } else {
                        setType(type);
                        handleSelect(type.id);
                      }
                    }}
                    component={Inputs.Dropdown}
                    options={props.breakOptions}
                  />
                </Box>
                {/* <Box sx={{ width: { md: "475px", xs: "100%" } }}>
                  <Field
                    name="breakTime"
                    label="Default Time"
                    component={Inputs.Text}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      if (regex.test(e.currentTarget.value)) {
                        dispatch(updateBreakTime(parseInt(e.currentTarget.value)));
                      }
                    }}
                  />
                </Box> */}
              </Box>

              {sm && <Divider />}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  borderLeft: { md: `1px solid ${theme.palette.text.secondary}`, xs: undefined },
                }}
              >
                <Typography variant="body1" sx={{ marginTop: "10px" }}>
                  Available Break Details
                </Typography>
                <Box
                  sx={{
                    overflowY: "auto",
                    fontSize: `${theme.typography.caption}`,
                    height: "255px",
                    width: { xs: "100%", md: "550px" },
                    pb: { xs: 5, md: 0 },
                  }}
                >
                  <ul style={{ listStyleType: "none", marginLeft: "-40px" }}>
                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                      <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                        <IconButton
                          disabled={!type}
                          sx={{ color: theme.palette.secondary.main }}
                          onClick={() => {}}
                          color="inherit"
                        >
                          <FontAwesomeIcon icon={faPlusCircle} onClick={handleOpenAdd} size="sm" />
                        </IconButton>
                      </div>
                      <li style={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ marginTop: "6px" }}>Add New</Typography>
                      </li>
                    </div>
                    <FieldArray name="details">
                      {() => (
                        <>
                          {breakDetails.options && breakDetails.options && breakDetails.options.length > 0 ? (
                            breakDetails.options.map((details, index) => {
                              return (
                                <div key={details?.id} style={{ display: "flex", flexDirection: "row" }}>
                                  <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
                                    <IconButton
                                      sx={{ color: theme.palette.secondary.main }}
                                      onClick={() => {
                                        handleRemove(details);
                                      }}
                                      color="inherit"
                                    >
                                      <FontAwesomeIcon icon={faMinusCircle} size="sm" />
                                    </IconButton>
                                  </div>
                                  <li style={{ display: "flex", flexDirection: "column", width: "400px" }}>
                                    <Box display="flex" justifyContent="space-between">
                                      <Box sx={{ marginTop: "28px" }}>
                                        <Typography>{details.value}</Typography>
                                      </Box>
                                      <Box>
                                        <Field
                                          name={`options[${index}].customTime`}
                                          label="Away Time"
                                          component={Inputs.Number}
                                        />
                                      </Box>
                                    </Box>
                                    {/* <Grid container direction={"row"} alignItems={"center"} gap={2}>
                                      <Grid item>
                                        <Typography>{details.value}</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Field
                                          name={`options[${index}].customTime`}
                                          label="Break Time"
                                          component={Inputs.Number}
                                        />
                                      </Grid>
                                    </Grid> */}
                                  </li>
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </ul>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Inputs.Submit text="Save" isSubmitting={loading} />
            </Box>
          </Form>
        </Formik>
      </Box>
    </React.Fragment>
  );
};

export default BreakSettings;
