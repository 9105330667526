import { Box, Button, CircularProgress, Grid } from "@mui/material";
import React, { FormEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "../../../store";
import { fetchOnlyRads } from "../../../slices/users";
import { addHospital, fetchAllModalities, fetchHospital, updateHospital } from "../../../slices/hospital";

import HospitalDetails from "./HospitalDetails";
import HospitalOnOffTimes from "./HospitalOnOffTimes";
import HospitalAuthUsers from "./HospitalAuthUsers";
import HospitalModalitiesExams from "./HospitalModalitiesExams";
import HospitalDapartmentsLocations from "./HospitalDepartmentsLocations";

const defaultHospital = {
  id: 0,
  name: "",
  isOpen: true,
  location: "",
  shortName: "",
  phone: "",
  closedMessage: "",
  badgeColor: "",
  schedule: [
    {
      order: null,
      dayOfWeek: 6,
      openHour: 0,
      openMinute: 0,
      closeHour: 0,
      closeMinute: 0,
    },
    {
      order: null,
      dayOfWeek: 5,
      openHour: 0,
      openMinute: 0,
      closeHour: 0,
      closeMinute: 0,
    },
    {
      order: null,
      dayOfWeek: 4,
      openHour: 0,
      openMinute: 0,
      closeHour: 0,
      closeMinute: 0,
    },
    {
      order: null,
      dayOfWeek: 3,
      openHour: 0,
      openMinute: 0,
      closeHour: 0,
      closeMinute: 0,
    },
    {
      order: null,
      dayOfWeek: 2,
      openHour: 0,
      openMinute: 0,
      closeHour: 0,
      closeMinute: 0,
    },
    {
      order: null,
      dayOfWeek: 1,
      openHour: 0,
      openMinute: 0,
      closeHour: 0,
      closeMinute: 0,
    },
    {
      order: null,
      dayOfWeek: 0,
      openHour: 0,
      openMinute: 0,
      closeHour: 0,
      closeMinute: 0,
    },
  ],
  users: [],
  hospitalDepartments: [],
  modalities: [
    {
      id: 1,
      name: "Ultrasound",
      examTypes: [],
    },
    {
      id: 2,
      name: "X-Ray",
      examTypes: [],
    },
    {
      id: 3,
      name: "MRI",
      examTypes: [],
    },
    {
      id: 4,
      name: "Nuclear Medicine",
      examTypes: [],
    },
    {
      id: 5,
      name: "CT",
      examTypes: [],
    },
  ],
};

interface Props {}

const HospitalView: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { radUsers: userOptions } = useSelector((store) => store.users);
  const { allModalities: allExamTypes } = useSelector((store) => store.hospital);
  const dispatch = useDispatch();

  const isAdd = !location.state?.id;
  const [loading, setLoading] = React.useState(true);
  const [hospitalDetails, setHospitalDetails] = React.useState<any>(() => null);
  const [isSaving, setIsSaving] = React.useState(false);

  const handleSave = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSaving(true);
    if (!isAdd) {
      const response = await dispatch(updateHospital(hospitalDetails));
      if (response.meta.requestStatus === "rejected") return alert("an error occurred");
    } else {
      const response = await dispatch(addHospital(hospitalDetails));
      if (response.meta.requestStatus === "rejected") return alert("an error occurred");
    }
    navigate("/app/settings", {
      state: {
        tab: "hospitals",
      },
    });
  };

  useEffect(() => {
    (async () => {
      dispatch(fetchOnlyRads());
      await dispatch(fetchAllModalities());
      if (isAdd) {
        setHospitalDetails({ ...defaultHospital });
        setLoading(false);
        return;
      }
      const response: any = await dispatch(fetchHospital(location?.state.id || 0));
      const schedule = (() => {
        try {
          const { payload } = response;
          if ((payload?.schedule || []).length !== 7) {
            const fixedArray = defaultHospital.schedule.filter(
              (s) => !payload?.schedule.find((s2: any) => s2.dayOfWeek === s.dayOfWeek)
            );
            return [...fixedArray, ...(payload?.schedule || [])];
          }
          return payload?.schedule;
        } catch (e) {
          return defaultHospital.schedule;
        }
      })();
      setHospitalDetails({
        // @ts-ignore
        ...response?.payload,
        schedule: schedule,
      });
      setLoading(false);
    })();
  }, [dispatch, isAdd, location.state.id]);

  return !loading ? (
    <>
      <br />
      <form onSubmit={handleSave}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <HospitalDetails hospitalDetails={hospitalDetails} setHospitalDetails={setHospitalDetails} />
          {/*  */}
          <HospitalOnOffTimes
            schedule={hospitalDetails?.schedule}
            setSchedule={(e: any) =>
              setHospitalDetails((prevState: any) => {
                return { ...prevState, schedule: [...e] };
              })
            }
          />
          {/*  */}
          <HospitalAuthUsers
            users={userOptions || []}
            authUsers={hospitalDetails?.users || []}
            setAuthUsers={(e: any) =>
              setHospitalDetails((prevState: any) => {
                return { ...prevState, users: [...e] };
              })
            }
          />
          {/*  */}
          <HospitalDapartmentsLocations
            hospitalDepartments={hospitalDetails?.hospitalDepartments || []}
            setHospitalDepartments={(e: any) =>
              setHospitalDetails((prevState: any) => {
                return { ...prevState, hospitalDepartments: [...e] };
              })
            }
          />
          {/*  */}
          <HospitalModalitiesExams
            modalTypes={allExamTypes}
            modalities={hospitalDetails?.modalities || []}
            setModalities={setHospitalDetails}
          />
          {/*  */}
          <Grid item xs={6}>
            <Box sx={{ mt: 2 }}>
              <Button
                color="secondary"
                fullWidth
                size="large"
                type="button"
                variant="contained"
                onClick={() =>
                  navigate("/app/settings", {
                    state: {
                      tab: "hospitals",
                    },
                  })
                }
              >
                Cancel
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ mt: 2 }}>
              <Button disabled={isSaving} color="primary" fullWidth size="large" type="submit" variant="contained">
                {isSaving ? <CircularProgress color="inherit" size={28} /> : <>{isAdd ? "Create" : "Save"}</>}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
      <CircularProgress />
    </Box>
  );
};

export default HospitalView;
