import React from "react";
import { Avatar as MUIAvatar, AvatarProps as MUIAvatarProps } from "@mui/material";
import theme from "../../theme";

interface AvatarProps extends MUIAvatarProps {
  roleId?: number;
  backgroundColor?: string;
}

type Props = AvatarProps;

const Avatar: React.FC<Props> = React.memo((props) => {
  const capturedProps = { ...props };
  const { roleId, backgroundColor } = capturedProps;
  delete capturedProps.roleId;

  const roleColor = (() => {
    const { palette } = theme;
    if (!roleId) return "#fff";
    if (roleId === 5) return palette.info.main;
    if (roleId === 4) return palette.warning.main;
    if (roleId === 2) return palette.primary.main;
    if (roleId === 3) return palette.secondary.main;
    if (roleId === 1) return palette.error.main;
    return "#fff";
  })();

  return (
    <MUIAvatar
      {...capturedProps}
      sx={{
        ...props.sx,
        borderColor: roleColor,
        background: backgroundColor ? "" : "transparent",
        backgroundColor: backgroundColor,
      }}
    >
      <React.Fragment>{capturedProps.children}</React.Fragment>
    </MUIAvatar>
  );
});

export default Avatar;
