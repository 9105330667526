import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import http from "../api/http";
import UserStatus from "../types/userStatus";
import UserSimple from "../types/userSimple";
import UserBreak from "../types/userBreak";
import SearchResults from "../types/searchResults";
import SearchParams from "../types/searchParams";
import helpers from "../helpers";

interface UsersState {
  users: UserSimple[];
  searchResults?: SearchResults;
  radUsers: UserSimple[];
  qaUsers: UserSimple[];
  userStatuses: UserStatus[];
  status: "idle" | "loading" | "failed";
  breakTime?: UserBreak;
  scheduledBreak?: UserBreak;
  usersCollapsed: boolean;
  techCollapsed: boolean;
  simpleUser?: UserSimple;
  temporaryOpen: boolean;
}

const initialState: UsersState = {
  users: [],
  radUsers: [],
  qaUsers: [],
  userStatuses: [],
  status: "idle",
  usersCollapsed: false,
  techCollapsed: true,
  temporaryOpen: false,
};

export const fetchSimpleUser = createAsyncThunk<UserSimple, number>("users/fetch-simple", async (userId) => {
  const response = await http.get<UserSimple>(`/users/${userId}/simple`);
  return response.data;
});

export const fetchUsers = createAsyncThunk<UserSimple[], { includeTechs: boolean; includeAdmins: boolean }>(
  "users/fetch",
  async ({ includeTechs, includeAdmins }) => {
    const response = await http.get<UserSimple[]>(`/users?includeTechs=${includeTechs}&includeAdmins=${includeAdmins}`);
    return response.data;
  }
);

export const fetchOnlyRads = createAsyncThunk<UserSimple[]>("users/fetch-options", async () => {
  const response = await http.get<UserSimple[]>("/users/options");
  return response.data;
});

export const fetchQaUsers = createAsyncThunk<UserSimple[]>("users/fetch-qa", async () => {
  const response = await http.get<UserSimple[]>("/users/qa");
  return response.data;
});

export const updateQaUsers = createAsyncThunk<UserSimple[], UserSimple[]>(
  "users/update-qa",
  async (qaUsers: UserSimple[]) => {
    const response = await http.post<UserSimple[]>("/users/qa", qaUsers);
    return response.data;
  }
);

export const deleteUser = createAsyncThunk<UserSimple[], number>("users/delete", async (userId: number) => {
  const response = await http.delete<UserSimple[]>(`/users/${userId}`);
  return response.data;
});

export const toggleActive = createAsyncThunk<null, { id: number; active: boolean }>(
  "users/toggle-active",
  async ({ id, active }) => {
    const response = await http.patch(`/users/${id}`, active);
    return response.data;
  }
);

export const onlineUser = createAsyncThunk<{}, number>("users/online", async (userId: number) => {
  await http.patch(`users/${userId}/online`);
});

export const offlineUser = createAsyncThunk<{}, number>("users/offline", async (userId: number) => {
  const response = await http.patch(`users/${userId}/offline`);
  return response.data;
});

export const forceLogout = createAsyncThunk<{}, number>("users/logout", async (userId: number) => {
  await http.patch(`users/${userId}/logout`);
});

export const logBreak = createAsyncThunk<UserBreak, UserBreak>("users/log-break", async (userBreak: UserBreak) => {
  const response = await http.post(`users/${userBreak.userId}/break`, userBreak);
  return response.data;
});

export const toggleWatching = createAsyncThunk<UserBreak, number>("users/toggle-watching", async (userId: number) => {
  const response = await http.get(`users/${userId}/watching`);
  return response.data;
});

export const getBreak = createAsyncThunk<UserBreak, number>("users/get-break", async (userId: number) => {
  const response = await http.get(`users/${userId}/break`);
  return response.data;
});

export const fetchScheduledBreak = createAsyncThunk<UserBreak, number>(
  "users/scheduled-break",
  async (userId: number) => {
    const response = await http.get(`users/${userId}/scheduled-break`);
    return response.data;
  }
);

export const searchUsers = createAsyncThunk<SearchResults, SearchParams>(
  "cases/search",
  async (model: SearchParams) => {
    let qs = helpers.getSearchQueryString(model);
    const response = await http.get<SearchResults>(`users/search?${qs}`);
    return response.data;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersCollapsed(state, action) {
      state.usersCollapsed = action.payload;
      state.temporaryOpen = false;
    },
    setTechCollapsed(state, action) {
      state.techCollapsed = action.payload;
    },
    setTemporaryOpen(state, action) {
      state.temporaryOpen = action.payload;
      state.usersCollapsed = false;
    },
    clearBreak(state) {
      state.breakTime = undefined;
    },
    updateUserList(state, action: PayloadAction<UserSimple[]>) {
      state.qaUsers = action.payload;
    },
    clearUsers(state) {
      state.qaUsers = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSimpleUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSimpleUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.simpleUser = action.payload;
      })
      .addCase(fetchSimpleUser.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.status = "idle";
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchOnlyRads.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOnlyRads.fulfilled, (state, action) => {
        state.radUsers = action.payload;
        state.status = "idle";
      })
      .addCase(fetchOnlyRads.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.radUsers = action.payload;
        state.status = "idle";
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(logBreak.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logBreak.fulfilled, (state, action) => {
        state.breakTime = action.payload;
        state.status = "idle";
      })
      .addCase(logBreak.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getBreak.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBreak.fulfilled, (state, action) => {
        state.breakTime = action.payload;
        state.status = "idle";
      })
      .addCase(getBreak.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchScheduledBreak.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchScheduledBreak.fulfilled, (state, action) => {
        state.scheduledBreak = action.payload;
        state.status = "idle";
      })
      .addCase(fetchScheduledBreak.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(toggleActive.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleActive.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(toggleActive.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(onlineUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(onlineUser.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(onlineUser.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(offlineUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(offlineUser.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(offlineUser.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(forceLogout.pending, (state) => {
        state.status = "loading";
      })
      .addCase(forceLogout.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(forceLogout.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(toggleWatching.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleWatching.fulfilled, (state, action) => {
        state.breakTime = action.payload;
        state.status = "idle";
      })
      .addCase(toggleWatching.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(searchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.status = "idle";
        state.searchResults = action.payload;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchQaUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchQaUsers.fulfilled, (state, action) => {
        state.status = "idle";
        state.qaUsers = action.payload;
      })
      .addCase(fetchQaUsers.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateQaUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQaUsers.fulfilled, (state, action) => {
        state.status = "idle";
        state.qaUsers = action.payload;
      })
      .addCase(updateQaUsers.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { setUsersCollapsed, setTechCollapsed, setTemporaryOpen, clearBreak, updateUserList, clearUsers } =
  usersSlice.actions;

export default usersSlice.reducer;
