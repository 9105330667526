import FormikTextField from "./FormikTextField";
import FormikEmailField from "./FormikPasswordField";
import FormikSubmitButton from "./FormikSubmitButton";
import FormikGlobalErrorState from "./FormikGlobalErrorState";
import FormikAutocomplete from "./FormikAutocomplete";
import FormikDatepicker from "./FormikDatepicker";
import FormikCheckbox from "./FormikCheckbox";
import FormikDropzone from "./FormikDropzone";
import FormikIconSubmit from "./FormikIconSubmit";
import FormikPhoneField from "./FormikPhoneField";
import FormikRadio from "./FormikRadio";
import FormikRememberMe from "./FormikRememberMe";
import FormikNumberField from "./FormikNumberField";

export const Inputs = {
  Text: FormikTextField,
  Password: FormikEmailField,
  Submit: FormikSubmitButton,
  Error: FormikGlobalErrorState,
  Dropdown: FormikAutocomplete,
  Datepicker: FormikDatepicker,
  Checkbox: FormikCheckbox,
  RememberMe: FormikRememberMe,
  Dropzone: FormikDropzone,
  IconSubmit: FormikIconSubmit,
  Phone: FormikPhoneField,
  Radio: FormikRadio,
  Number: FormikNumberField
};
