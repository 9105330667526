import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "../../store";
import { getNews, toggleNews } from "../../slices/news";
import News from "./News";
import theme from "../../theme";
import ActionButtons from "../base/ActionButtons";
import { Close } from "@mui/icons-material";

interface Props {}

const SharepointCard: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { news } = useSelector((store) => store.news);

  useEffect(() => {
    dispatch(getNews());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Card sx={{ border: `2px solid ${theme.palette.primary.main}`, mt: "4px" }}>
        <Box style={{ margin: 12 }}>
          <Grid
            container
            spacing={1}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight="100px"
          >
            <Grid
              container
              display={{ xs: "none", md: "flex" }}
              flexDirection="row"
              justifyContent="right"
              marginBottom="-40px"
            >
              <ActionButtons onClick={() => dispatch(toggleNews(true))} label="Dismiss" icon={<Close />} />
            </Grid>
            {news?.map((newsItem) => {
              return (
                <Grid item md={4} xs={undefined} key={newsItem.id}>
                  <News newsItem={newsItem} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default SharepointCard;
