import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import User from "../types/user";

interface AddEditState {
  user: User;
  status: "idle" | "loading" | "failed";
}

const initialState: AddEditState = {
  user: {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    roles: [],
    avatar: undefined,
    firstSession: new Date(),
    isJudge: false,
    lastSession: new Date(),
    phone: "",
    status: { id: 0, value: "" },
    avatarForm: undefined,
  },
  status: "idle",
};

export const fetchUser = createAsyncThunk<User, number>("user/fetch", async (userId: number) => {
  const response = await http.get<User>(`/users/${userId}`);
  return response.data;
});

function buildFormData(formData: any, data: any, parentKey?: any) {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null || data === undefined ? null : data;

    if (!!data) {
      formData.append(parentKey, value);
    }
  }

  return formData;
}

function jsonToFormData(data: any) {
  const formData = new FormData();
  const newData = buildFormData(formData, data);
  return newData;
}

export const addUser = createAsyncThunk<User, User>("user-add/fetch", async (model: User) => {
  model.firstSession = undefined;
  model.lastSession = undefined;
  var form = jsonToFormData(model);
  if (model.avatarForm?.length! > 0) {
    form.append("avatarForm", model.avatarForm![0]);
  }

  const response = await http({
    method: "post",
    url: "/users",
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
});

export const updateUser = createAsyncThunk<User, User>("user-edit/fetch", async (model: User) => {
  var form = jsonToFormData(model);

  if (model.avatarForm?.length! > 0) {
    form.append("avatarForm", model.avatarForm![0]);
  }

  const response = await http({
    method: "put",
    url: `/users/${model?.id}`,
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
});

const addEditUserSlice = createSlice({
  name: "addEditUserSlice",
  initialState,
  reducers: {
    setAvatarForm(state, action) {
      state.user!.avatarForm = action.payload;
    },
    resetUser(state) {
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch User
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = "failed";
      })

      // Add User
      .addCase(addUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUser.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(addUser.rejected, (state) => {
        state.status = "failed";
      })

      // Edit User
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateUser.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setAvatarForm, resetUser } = addEditUserSlice.actions;

export default addEditUserSlice.reducer;
