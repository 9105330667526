import { Box, Button, Grid } from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Inputs } from "../forms";
import * as Yup from "yup";
import HospitalSummary from "../../types/hospitalSummary";
import { fetchExamTypes, fetchHospitalOptions } from "../../slices/hospital";
import Case from "../../types/case";
import Modality from "../../types/modality";
import { fetchQaCodes } from "../../slices/qa";
import ExamType from "../../types/examType";
import Option from "../../types/option";
import UserSimple from "../../types/userSimple";

interface Props {
  caseId?: number;
  case?: Case;
  onClose: () => void;
  onSave: (model: Case) => void;
}

const AddEditQa: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { qaErrors, newCase } = useSelector((store) => store.qa);
  const { hospitalModalities, hospitals } = useSelector((store) => store.hospital);
  const { qaUsers } = useSelector((store) => store.users);
  const { loggedInUser } = useSelector((store) => store.auth);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchQaCodes());
    if (props.case && props.case.hospital && props.case.hospital.id > 0) {
      dispatch(fetchHospitalOptions(props.case?.hospital?.id!));
    }

    if (props.case && props.case.modality && props.case.modality.id > 0) {
      dispatch(fetchExamTypes({ hospitalId: props.case?.hospital?.id!, modalityId: props.case?.modality?.id! }));
    }
  }, [dispatch, props.case]);

  const handleSelectHospital = (e: any, value: HospitalSummary) => {
    dispatch(fetchHospitalOptions(value?.id));
  };

  const handleSelectModality = (
    event: React.ChangeEvent<{}>,
    modality: Modality,
    values: Case,
    setFieldValue: (field: string, value: any) => void
  ) => {
    dispatch(fetchExamTypes({ hospitalId: values.hospital?.id!, modalityId: modality?.id }));
    if (modality?.id !== props.case?.modality?.id) {
      setFieldValue("examTypes", []);
    }
  };

  const handleSave = (values: Case) => {
    setLoading(true);
    props.onSave(values);
    setLoading(false);
    props.onClose();
  };

  const validationSchema = Yup.object().shape(
    {
      hospital: Yup.object().nullable().required("Please select a hospital"),
      hospitalDepartment: Yup.object().nullable().required("Please select a department"),
      lastName: Yup.string().required("Please enter patient last name"),
      firstName: Yup.string().required("Please enter patient first name"),
      patientId: Yup.string().required("Please enter patient ID"),
      caseDate: Yup.date().required("Please enter case date"),
      modality: Yup.object().nullable().required("Please select a modality"),
      examTypes: Yup.array()
        .nullable()
        .when("modality", {
          is: (val: Option) => !!val && val.id > 0,
          then: Yup.array().nullable().min(1, "Please select an exam type"),
        })
        .min(1, "Please select an exam type"),
      assignedToUser: Yup.object().nullable().required("Please select a user"),
      qaError: Yup.object().nullable().required("Please select an error code"),
    },
    [["modality", "examTypes"]]
  );

  const filteredModalities = hospitalModalities.filter((modal) => modal.examTypes.length > 0);

  let userOptions: UserSimple[] = [...qaUsers, { id: -1, value: "Statrad", avatar: "" }];

  return (
    <div>
      <Grid>
        <Formik
          initialValues={props.case || newCase}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            handleSave(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate>
              <Grid container spacing={3} flexDirection="column">
                <Grid item xs={12}>
                  <Field
                    name="hospital"
                    label="Hospital"
                    required
                    component={Inputs.Dropdown}
                    options={hospitals}
                    handleChange={handleSelectHospital}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Field name="lastName" label="Patient Last Name" required component={Inputs.Text} margin="none" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="firstName"
                        label="Patient First Name"
                        required
                        component={Inputs.Text}
                        margin="none"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field name="patientId" label="Patient ID" required component={Inputs.Text} margin="none" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={4}>
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Field name="caseDate" label="Case Date" required component={Inputs.Datepicker} />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          ml: -1,
                        }}
                      >
                        <Field name="isTrauma" label="Is Trauma" component={Inputs.Checkbox} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field name="examNumber" label="Exam/Accession #" component={Inputs.Text} margin="none" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="modality"
                    label="Exam Modality"
                    required
                    component={Inputs.Dropdown}
                    options={filteredModalities}
                    handleChange={(event: React.ChangeEvent<{}>, value: Modality) => {
                      handleSelectModality(event, value, values, setFieldValue);
                    }}
                  />
                </Grid>
                {values?.modality?.examTypes?.length! > 0 && (
                  <FieldArray
                    name="examTypes"
                    render={(arrayHelpers) => (
                      <Grid item xs={12}>
                        {values.modality?.examTypes.map((exam: ExamType) => (
                          <Field
                            key={exam.id}
                            name="examTypes"
                            value={exam}
                            checked={
                              !!values.examTypes?.find(
                                (exam2: ExamType) =>
                                  +exam2?.id === +exam?.id && +exam2.modalityId === +(values.modality?.id || 0)
                              )
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e.target.checked) return arrayHelpers.push(exam);
                              const index = values.examTypes.findIndex((exam2: ExamType) => exam2.id === exam?.id);
                              arrayHelpers.remove(index);
                            }}
                            component={Inputs.Checkbox}
                            label={exam.value}
                          />
                        ))}
                      </Grid>
                    )}
                  />
                )}
                <Grid item xs={12}>
                  <Field
                    name="assignedToUser"
                    label="Assigned To"
                    required
                    component={Inputs.Dropdown}
                    options={userOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field name="qaError" label="Error Code" required component={Inputs.Dropdown} options={qaErrors} />
                </Grid>
                <Grid item xs={12}>
                  <Field name="comment" label="Comments" component={Inputs.Text} margin="none"></Field>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
                    <Field name="sendEmail" label="Send Urgent Email" required component={Inputs.Checkbox} />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
                    <Field name="sendText" label="Send Urgent Text" required component={Inputs.Checkbox} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} paddingX={1} style={{ textAlign: "center" }}>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color="secondary"
                      fullWidth
                      size="large"
                      type="button"
                      variant="contained"
                      onClick={props.onClose}
                    >
                      Close
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} paddingX={1} style={{ textAlign: "center" }}>
                  <Box>
                    <Inputs.Submit text="Save" isSubmitting={loading} />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </div>
  );
};

export default AddEditQa;
