import { Box, FormControl, Grid, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import SiteSoundsType from "../../types/siteSounds";
import { Inputs } from "../forms";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch } from "../../store";
import { updateNewCaseVolume } from "../../slices/cases";
import { updateChatSoundVolume } from "../../slices/chat";
import { updateTeamChatVolume } from "../../slices/teamchats";

interface Props {
  onSubmit: (result: SiteSoundsType) => void;
  siteSounds?: SiteSoundsType;
}

const SiteSounds: React.FC<Props> = (props) => {
  const { onSubmit, siteSounds } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [model, setModel] = useState<SiteSoundsType>({
    chatSound: [],
    chatFile: "",
    chatSoundVolume: 1,
    teamChatSound: [],
    teamChatFile: "",
    teamChatVolume: 1,
    newCaseSound: [],
    newCaseFile: "",
    newCaseVolume: 1,
  });
  const [chatSoundVolume, setChatSoundVolume] = useState(siteSounds?.chatSoundVolume ? siteSounds.chatSoundVolume : 1);
  const [teamChatVolume, setTeamChatVolume] = useState(siteSounds?.teamChatVolume ? siteSounds.teamChatVolume : 1);
  const [newCaseVolume, setNewCaseVolume] = useState(siteSounds?.newCaseVolume ? siteSounds.newCaseVolume : 1);
  const dispatch = useDispatch();

  useEffect(() => {
    let chatFile = siteSounds?.chatFile;
    let teamChatFile = siteSounds?.teamChatFile;
    let newCaseFile = siteSounds?.newCaseFile;

    setModel({
      ...siteSounds,
      chatFile: chatFile,
      teamChatFile: teamChatFile,
      newCaseFile: newCaseFile,
      chatSoundVolume: chatSoundVolume,
      teamChatVolume: teamChatVolume,
      newCaseVolume: newCaseVolume,
    });
  }, [siteSounds, chatSoundVolume, teamChatVolume, newCaseVolume]);

  const handleSubmit = (model: SiteSoundsType) => {
    setLoading(true);
    onSubmit(model);
    setLoading(false);
    if (model.newCaseVolume) {
      dispatch(updateNewCaseVolume(model.newCaseVolume));
    }
    if (model.teamChatVolume) {
      dispatch(updateTeamChatVolume(model.teamChatVolume));
    }
    if (model.chatSoundVolume) {
      dispatch(updateChatSoundVolume(model.chatSoundVolume));
    }
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={model}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form noValidate>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12} sx={{ textAlign: "center" }}>
                <Typography sx={{ marginBottom: "5px" }}>Private Message</Typography>
                <FormControl>
                  <Field
                    name="chatSound"
                    label="Private Message"
                    setFieldValue={setFieldValue}
                    required
                    component={Inputs.Dropzone}
                  />
                </FormControl>
                {model?.chatFile && (
                  <ReactAudioPlayer
                    controls
                    src={`${model.chatFile}`}
                    onVolumeChanged={(e: any) => {
                      setChatSoundVolume(e.target.volume);
                    }}
                    volume={chatSoundVolume}
                  />
                )}
              </Grid>
              <Grid item md={4} xs={12} sx={{ textAlign: "center" }}>
                <Typography sx={{ marginBottom: "5px" }}>Reading Room</Typography>
                <FormControl>
                  <Field
                    name="teamChatSound"
                    label="Reading Room"
                    setFieldValue={setFieldValue}
                    required
                    component={Inputs.Dropzone}
                  />
                </FormControl>
                {model?.teamChatFile && (
                  <ReactAudioPlayer
                    controls
                    src={`${model.teamChatFile}`}
                    onVolumeChanged={(e: any) => {
                      setTeamChatVolume(e.target.volume);
                    }}
                    volume={teamChatVolume}
                  />
                )}
              </Grid>
              <Grid item md={4} xs={12} sx={{ textAlign: "center" }}>
                <Typography sx={{ marginBottom: "5px" }}>New Case</Typography>
                <FormControl>
                  <Field
                    name="newCaseSound"
                    label="New Case"
                    setFieldValue={setFieldValue}
                    required
                    component={Inputs.Dropzone}
                  />
                </FormControl>
                {model?.newCaseFile && (
                  <ReactAudioPlayer
                    controls
                    src={`${model.newCaseFile}`}
                    onVolumeChanged={(e: any) => {
                      setNewCaseVolume(e.target.volume);
                    }}
                    volume={newCaseVolume}
                  />
                )}
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Inputs.Submit text="Save" isSubmitting={loading} />
            </Box>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default SiteSounds;
