import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import * as Yup from "yup";
import { useDispatch, useSelector } from "../../store";
import IrStudy from "../../types/irStudy";
import { fetchAllModalities, fetchHospitals } from "../../slices/hospital";
import { getFindings, getUnreadStudies, readIrStudy } from "../../slices/irStudy";

interface Props {
  onSubmit: (model: IrStudy) => void;
  handleClose: () => void;
}

const AddIrStudy: React.FC<Props> = (props) => {
  const { onSubmit, handleClose } = props;
  const dispatch = useDispatch();
  const { hospitals, allModalities } = useSelector((store) => store.hospital);
  const { study, status, findings } = useSelector((store) => store.irStudy);

  useEffect(() => {
    dispatch(fetchHospitals());
    if (!allModalities.length) {
      dispatch(fetchAllModalities());
    }
    if (!findings.length) {
      dispatch(getFindings());
    }
  }, [dispatch]);

  useEffect(() => {
    if (study.id > 0) {
      dispatch(readIrStudy(study.id)).then(() => {
        dispatch(getUnreadStudies());
      });
    }
  }, [study.id]);

  const validationSchema = Yup.object().shape({
    mrNumber: Yup.string().required("Please enter an MR number"),
    hospital: Yup.object().nullable().required("Please select a hospital"),
    modality: Yup.object().nullable().required("Please select a modality"),
    findings: Yup.mixed().nullable().required("Please enter findings"),
    comments: Yup.string().nullable(),
  });

  return (
    <React.Fragment>
      <Formik enableReinitialize initialValues={study} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form noValidate>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Field name="mrNumber" label="MR Number" required component={Inputs.Text} disabled={study.id > 0} />
            </Grid>
            <Grid item xs={6}>
              <Field
                sx={{ mt: "8px" }}
                name="studyDate"
                label="Study Date"
                fullWidth
                component={Inputs.Datepicker}
                disabled={study.id > 0}
              />
            </Grid>
            <Grid item xs={12}>
              {study.id > 0 ? (
                <Field name="hospital.name" label="Hospital" required component={Inputs.Text} disabled />
              ) : (
                <Field name="hospital" label="Hospital" required component={Inputs.Dropdown} options={hospitals} />
              )}
            </Grid>
            <Grid item xs={12}>
              {study.id > 0 ? (
                <Field name="modality.name" label="Modality" required component={Inputs.Text} disabled />
              ) : (
                <Field name="modality" label="Modality" required component={Inputs.Dropdown} options={allModalities} />
              )}
            </Grid>
            <Grid item xs={12}>
              {study.id > 0 ? (
                <Field name="findings" label="Findings" required component={Inputs.Text} disabled />
              ) : (
                <Field
                  name="findings"
                  required
                  label="Findings"
                  component={Inputs.Dropdown}
                  options={findings}
                  freeSolo={true}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Field name="comments" label="Additional comments" component={Inputs.Text} disabled={study.id > 0} />
            </Grid>
            <Grid item xs={study.id > 0 ? 12 : 6} justifySelf={"center"}>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="secondary"
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={handleClose}
                >
                  {study.id > 0 ? "Close" : "Cancel"}
                </Button>
              </Box>
            </Grid>
            {study.id === 0 && (
              <Grid item xs={6}>
                <Inputs.Submit text={"Add"} isSubmitting={status === "loading"} />
              </Grid>
            )}
          </Grid>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default AddIrStudy;
