import React from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Inputs } from "../forms";
import { useNavigate, useSearchParams } from "react-router-dom";
import { check, resetPassword } from "../../slices/auth";
import SnackBarToast from "../base/SnackbarToast";
import { handleSuccessToastState } from "../../slices/toast";
import AuthLogo from "../base/AuthLogo";

interface Props {}

const ResetPassword: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector((store) => store.auth);
  const { successToastOpen } = useSelector((store) => store.toast);
  const [queryParams] = useSearchParams();
  const username = queryParams.get("username");

  const closeToast = () => {
    dispatch(handleSuccessToastState(false));
  };

  const initialValues = { username: username || "", code: "", password: "", confirmPassword: "" };

  const validationSchema = Yup.object().shape(
    {
      username: Yup.string().email("Invalid email").required("Please enter your email"),
      code: Yup.string().required("Please enter your verification code"),
      password: Yup.string()
        .min(8)
        .max(128)
        .required("Please enter your new password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, and One Number"
        ),
      confirmPassword: Yup.string().when("password", {
        is: (val: string) => val && val.length > 0,
        then: Yup.string()
          .required("Please confirm password")
          .oneOf([Yup.ref("password")], "Passwords must match"),
      }),
    },
    [["password", "confirmPassword"]]
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: { xs: "100%", md: "100vh" },
        }}
      >
        <Container maxWidth="sm" sx={{ py: { md: "80px", xs: "0px" }, pb: { xs: "20px", md: "0px" } }}>
          <AuthLogo />
          <Card sx={{ mt: "15px" }}>
            <CardContent>
              <Box>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (values, actions) => {
                    let newValues = {
                      username: values.username,
                      code: values.code,
                      password: values.password,
                    };
                    await dispatch(resetPassword(newValues)).then((result) => {
                      dispatch(check(true)).then((response) => {
                        if (response && response.payload) {
                          navigate("/app/dashboard");
                        }
                      });
                      if (result.meta.requestStatus === "fulfilled") {
                        dispatch(handleSuccessToastState(true));
                      }
                    });
                  }}
                >
                  {({ values, setFieldValue }) => {
                    return (
                      <Form noValidate style={{ color: "textSecondary" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          <Typography variant="h6" align="center">
                            Please enter the verification code that was sent to your email, along with your new password
                            below. Password must be a minimum of 8 characters and contain one uppercase letter, one
                            lowercase letter, and one number.
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          <Field name="username" required label="Email" component={Inputs.Text} />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          <Field name="code" required label="Verification Code" component={Inputs.Password} />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          <Field name="password" required label="New Password" component={Inputs.Password} />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mx: 1, pb: 0 }}>
                          <Field name="confirmPassword" required label="Confirm Password" component={Inputs.Password} />
                        </Box>
                        <Box
                          sx={{
                            mt: "12px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            <Inputs.Submit text={"Reset"} isSubmitting={status === "loading"} />
                          </Box>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <SnackBarToast
        message="Password Changed Successfully"
        type="success"
        open={successToastOpen}
        handleClose={closeToast}
      />
    </Box>
  );
};

export default ResetPassword;
