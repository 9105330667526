import { AxiosError } from "axios";
import { handleErrorToastState, setErrorMessage } from "../slices/toast";
import store from "../store";
import { logout } from "../slices/auth";

const axiosErrorInterceptor = async (error: AxiosError) => {
  const { dispatch } = store;

  if (error.response?.status === 403) {
    dispatch(logout());
  } else if (error.response?.status && error.response?.data && error.response?.data?.message) {
    dispatch(setErrorMessage("Error: " + error.response.data.message));
    dispatch(handleErrorToastState(true));
  }
};

export default axiosErrorInterceptor;
