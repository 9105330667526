import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import ContactInformationType from "../../types/contactInformation";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";

interface Props {
  onSubmit: (result: ContactInformationType) => void;
  contactInformation?: ContactInformationType;
}

const ContactInformation: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (model: ContactInformationType) => {
    setLoading(true);
    props.onSubmit(model);
    setLoading(false);
  };

  const initialValues: ContactInformationType = {
    phoneNumber: props.contactInformation?.phoneNumber,
    email: props.contactInformation?.email,
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().nullable().required("Please enter a phone number"),
    email: Yup.string().nullable().email("Invalid email").required("Please enter email"),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={props.contactInformation || initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          handleSubmit(values);
        }}
      >
        <Form noValidate>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Field name="phoneNumber" label="Phone Number" required component={Inputs.Phone} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field name="email" label="Email" required component={Inputs.Text} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Inputs.Submit text="Save" isSubmitting={loading} />
          </Box>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default ContactInformation;
