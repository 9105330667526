import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

interface Props {
  page?: number;
  rowsPerPage?: number;
  changePage?: (page: number, pageSize: number) => void;
  totalRows?: number;
  headers?: string[];
  rows: any[];
  paging?: boolean;
  defaultPaging?: boolean;
  useObjects?: boolean;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const tablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
      }}
    >
      <Box>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Box>
        <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

const StyledTable: React.FC<Props> = (props) => {
  const [page, setPage] = useState(props.page ? props.page - 1 : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.defaultPaging ? 5 : props.rowsPerPage ? props.rowsPerPage : 10
  );

  useEffect(() => {
    if (props.page) {
      setPage(props.page! - 1);
    }
  }, [props.page]);

  useEffect(() => {
    if (props.rowsPerPage) {
      setRowsPerPage(props.rowsPerPage!);
    }
  }, [props.rowsPerPage]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.changePage) {
      props.changePage(newPage + 1, rowsPerPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.changePage) {
      props.changePage(1, parseInt(event.target.value, 10));
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }
  };

  return (
    <Box
      sx={{
        // m: 1,
        maxWidth: "100%",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {props.headers &&
                props.headers.length > 0 &&
                props.headers.map((header, index) => (
                  <TableCell key={index} align="center" sx={{}}>
                    <Typography>{header}</Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0 && props.rows?.length > 0 && !props.changePage
              ? props.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : props.rows
            )?.map((row, key) => (
              <TableRow key={row + key}>
                {props.useObjects ? (
                  Object.values(row).map((value: any, key) => (
                    <TableCell key={key} align="center">
                      <Typography component={"span"}>{value}</Typography>
                    </TableCell>
                  ))
                ) : (
                  <TableCell align="center">{row && row}</TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {props.paging && (
          <TablePagination
            component="div"
            count={props.totalRows || (props.rows && props.rows.length)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 50, 100, { label: "All", value: props.totalRows! || -1 }]}
            ActionsComponent={tablePaginationActions}
          />
        )}
      </TableContainer>
    </Box>
  );
};

export default StyledTable;
