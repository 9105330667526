import { Auth as AmplifyAuth } from "aws-amplify";

const getToken = async (): Promise<string> => {
  try {
    const session = await AmplifyAuth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    return "";
  }
};

export default getToken;
