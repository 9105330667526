import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import DisplayedStatsType from "../../types/displayedStats";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import { useSelector } from "../../store";

interface Props {
  onSubmit: (result: DisplayedStatsType) => void;
  displayedStats?: DisplayedStatsType;
}

const DisplayedStats: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { settings } = useSelector((store) => store.settings);
  const [state, setState] = useState({
    cases: settings?.displayedStats.cases,
    studies: settings?.displayedStats.studies,
    wus: settings?.displayedStats.wus,
  });

  const { cases, studies, wus } = state;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const handleSubmit = (model: DisplayedStatsType) => {
    setLoading(true);
    props.onSubmit(model);
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    cases: Yup.boolean(),
    studies: Yup.boolean(),
    wus: Yup.boolean(),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={state}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        <Form noValidate>
          <Grid container spacing={{ xs: 0, md: 3 }}>
            <Grid item md={4} xs={12}>
              <Field
                name="cases"
                label="Cases"
                required
                component={Inputs.Checkbox}
                checked={cases}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field
                name="studies"
                label="Studies"
                required
                component={Inputs.Checkbox}
                checked={studies}
                onChange={handleChange}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field
                name="wus"
                label="Work Units"
                required
                component={Inputs.Checkbox}
                checked={wus}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Inputs.Submit text="Save" isSubmitting={loading} />
          </Box>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default DisplayedStats;
