import React, { useState, ChangeEvent } from "react";
import SiteSettings from "../views/SiteSettings";
import { Box, Tabs, Tab, Divider, Typography } from "@mui/material";
import { useLocation } from "react-router";

import HospitalTable from "../views/HospitalTable";
import TeamChatsTable from "../views/TeamChatsTable";
import QaCasesTable from "../views/QaCasesTable";
import Users from "../views/Users";
import SiteConfiguration from "../views/SiteConfiguration";

const Settings: React.FC = () => {
  const tabs = [
    { label: "Settings", value: "settings" },
    { label: "Configuration", value: "configuration" },
    { label: "Users", value: "users" },
    { label: "Hospitals", value: "hospitals" },
    { label: "Reading Room", value: "team-chats" },
    { label: "QA Cases", value: "qa-cases" },
  ];

  const location: any = useLocation();

  const [currentTab, setCurrentTab] = useState<string>(() =>
    !!location.state?.tab ? location.state?.tab : "settings"
  );

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <Box sx={{ p: "20px" }}>
      <Box>
        <Typography color="textPrimary" variant="h5">
          Admin Settings
        </Typography>
      </Box>
      <Box>
        <Tabs
          indicatorColor="secondary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="inherit"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <Box sx={{ my: 4 }}>
        {currentTab === "settings" && <SiteSettings />}
        {currentTab === "configuration" && <SiteConfiguration />}
        {currentTab === "users" && <Users />}
        {currentTab === "hospitals" && <HospitalTable />}
        {currentTab === "team-chats" && <TeamChatsTable />}
        {currentTab === "qa-cases" && <QaCasesTable />}
      </Box>
    </Box>
  );
};

export default Settings;
