import type { FC } from "react";

import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import ScheduleType from "../../types/qGenda";
import UserSchedule from "../../types/userSchedule";
import Chip from "../base/Chip";
import Moment from "react-moment";
import User from "../../types/user";
import theme from "../../theme";

interface Props {
  schedule: ScheduleType[] | null;
  loggedInUser: User | undefined;
}

const ScheduleTable: FC<Props> = (props) => {
  const { loggedInUser, schedule } = props;

  return (
    <>
      <Table style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell>Yesterday</TableCell>
            <TableCell style={{ backgroundColor: theme.palette.background.paper }}>Today</TableCell>
            <TableCell>Tomorrow</TableCell>
            <TableCell>+2 Days</TableCell>
            <TableCell>+3 Days</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule &&
            schedule.map((location: ScheduleType, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>
                    {!!location.hospital ? <Chip color={location.hospitalBadge} type="hospital" label="H" /> : ""}{" "}
                    {location.location}
                  </TableCell>
                  <TableCell>
                    <Box>
                      {location &&
                        location.yesterday &&
                        location.yesterday.map((person: UserSchedule, index) => (
                          <Box key={index}>
                            {person.task && (
                              <Typography color="primary" style={{ padding: "5px" }}>
                                {person.task}
                              </Typography>
                            )}
                            <Typography
                              color={person.id === loggedInUser?.id ? theme.palette.secondary.main : "textPrimary"}
                              variant="body2"
                              style={{ padding: "5px" }}
                            >
                              {person.name} (<Moment format="HH:mm">{new Date(person.start)}</Moment> -{" "}
                              <Moment format="HH:mm">{new Date(person.end)}</Moment>)
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell style={{ backgroundColor: theme.palette.background.paper }}>
                    <Box>
                      {location &&
                        location.today &&
                        location.today.map((person: UserSchedule, index) => (
                          <Box key={index}>
                            {person.task && (
                              <Typography color="primary" style={{ padding: "5px" }}>
                                {person.task}
                              </Typography>
                            )}
                            <Typography
                              color={person.id === loggedInUser?.id ? theme.palette.secondary.main : "textPrimary"}
                              variant="body2"
                              style={{ padding: "5px" }}
                            >
                              {person.name} (<Moment format="HH:mm">{new Date(person.start)}</Moment> -{" "}
                              <Moment format="HH:mm">{new Date(person.end)}</Moment>)
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      {location &&
                        location.tomorrow &&
                        location.tomorrow.map((person: UserSchedule, index) => (
                          <Box key={index}>
                            {person.task && (
                              <Typography color="primary" style={{ padding: "5px" }}>
                                {person.task}
                              </Typography>
                            )}
                            <Typography
                              color={person.id === loggedInUser?.id ? theme.palette.secondary.main : "textPrimary"}
                              variant="body2"
                              style={{ padding: "5px" }}
                            >
                              {person.name} (<Moment format="HH:mm">{new Date(person.start)}</Moment> -{" "}
                              <Moment format="HH:mm">{new Date(person.end)}</Moment>)
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      {location &&
                        location.plusTwo &&
                        location.plusTwo.map((person: UserSchedule, index) => (
                          <Box key={index}>
                            {person.task && (
                              <Typography color="primary" style={{ padding: "5px" }}>
                                {person.task}
                              </Typography>
                            )}
                            <Typography
                              color={person.id === loggedInUser?.id ? theme.palette.secondary.main : "textPrimary"}
                              variant="body2"
                              style={{ padding: "5px" }}
                            >
                              {person.name} (<Moment format="HH:mm">{new Date(person.start)}</Moment> -{" "}
                              <Moment format="HH:mm">{new Date(person.end)}</Moment>)
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      {location &&
                        location.plusThree &&
                        location.plusThree.map((person: UserSchedule, index) => (
                          <Box key={index}>
                            {person.task && (
                              <Typography color="primary" style={{ padding: "5px" }}>
                                {person.task}
                              </Typography>
                            )}
                            <Typography
                              color={person.id === loggedInUser?.id ? theme.palette.secondary.main : "textPrimary"}
                              variant="body2"
                              style={{ padding: "5px" }}
                            >
                              {person.name} (<Moment format="HH:mm">{new Date(person.start)}</Moment> -{" "}
                              <Moment format="HH:mm">{new Date(person.end)}</Moment>)
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </>
  );
};

export default ScheduleTable;
