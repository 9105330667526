import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import RadioLink from "../types/radioLink";

interface LinkState {
  links: RadioLink[];
  visibleLinks: RadioLink[];
  status: "idle" | "loading" | "failed";
}

const initialState: LinkState = {
  links: [],
  visibleLinks: [],
  status: "idle",
};

export const fetchLinks = createAsyncThunk<RadioLink[]>("links/fetch", async () => {
  const response = await http.get<RadioLink[]>("/links");
  return response.data;
});

export const createLink = createAsyncThunk<RadioLink[], RadioLink>("links/create", async (vm: RadioLink) => {
  const response = await http.post<RadioLink[]>("/links", vm);
  return response.data;
});

export const updateLink = createAsyncThunk<RadioLink[], RadioLink>("links/update", async (vm: RadioLink) => {
  const response = await http.put<RadioLink[]>(`/links/${vm.id}`, vm);
  return response.data;
});

export const deleteLink = createAsyncThunk<RadioLink[], number>("links/delete", async (id: number) => {
  const response = await http.delete<RadioLink[]>(`/links/${id}`);
  return response.data;
});

export const toggleVisible = createAsyncThunk<RadioLink[], RadioLink>("links/visible", async (vm: RadioLink) => {
  const response = await http.patch(`/links/${vm.id}?visible=${vm.visible}`);
  return response.data;
});

export const updateLinkOrder = createAsyncThunk<RadioLink[], RadioLink[]>(
  "links/update-order",
  async (model: RadioLink[]) => {
    const response = await http({
      method: "patch",
      url: "links/order",
      data: model,
      headers: { "Content-type": "application/json" },
    });

    return response.data;
  }
);

const linkSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    updateLinks(state, action: PayloadAction<RadioLink[]>) {
      let list = action.payload.map((h, index) => {
        return {
          ...h,
          order: index + 1,
        };
      });
      state.links = list;
      state.visibleLinks = list.filter((f) => f.visible === true);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLinks.fulfilled, (state, action) => {
        state.status = "idle";
        state.links = action.payload;
        state.visibleLinks = action.payload.filter((f) => f.visible === true);
      })
      .addCase(fetchLinks.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createLink.fulfilled, (state, action) => {
        state.status = "idle";
        state.links = action.payload;
        state.visibleLinks = action.payload.filter((f) => f.visible === true);
      })
      .addCase(createLink.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateLink.fulfilled, (state, action) => {
        state.status = "idle";
        state.links = action.payload;
        state.visibleLinks = action.payload.filter((f) => f.visible === true);
      })
      .addCase(updateLink.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteLink.fulfilled, (state, action) => {
        state.status = "idle";
        state.links = action.payload;
        state.visibleLinks = action.payload.filter((f) => f.visible === true);
      })
      .addCase(deleteLink.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(toggleVisible.pending, (state) => {
        state.status = "loading";
      })
      .addCase(toggleVisible.fulfilled, (state, action) => {
        state.status = "idle";
        state.links = action.payload;
        state.visibleLinks = action.payload.filter((f) => f.visible === true);
      })
      .addCase(toggleVisible.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateLinkOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateLinkOrder.fulfilled, (state, action) => {
        state.status = "idle";
        state.links = action.payload;
        state.visibleLinks = action.payload.filter((f) => f.visible === true);
      })
      .addCase(updateLinkOrder.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { updateLinks } = linkSlice.actions;

export default linkSlice.reducer;
