import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "../../store";
import { Inputs } from "../forms";
import * as Yup from "yup";
import { fetchHospitalOptions } from "../../slices/hospital";
import Case from "../../types/case";
import ExamType from "../../types/examType";
import { addCase, fetchCase, fetchCriticalValues, fetchUrgencies, updateCase } from "../../slices/cases";
import Option from "../../types/option";
import { fetchUsers } from "../../slices/users";
import getUserRoleId from "../../helpers/getUserRoleId";
import Hospital from "../../types/hospital";
import styled from "styled-components";
import theme from "../../theme";

const Root = styled.div`
  .error {
    color: ${theme.palette.error.main};
    font-size: 0.75rem;
  }
`;

interface Props {
  id?: number | undefined;
  handleCloseModal: () => void;
  kiosk?: boolean | undefined;
  blocked?: boolean | undefined;
  hospital?: Hospital;
}

const AddEditCase: React.FC<Props> = (props) => {
  const { hospital, id, handleCloseModal, kiosk, blocked } = props;

  const isEdit = !!props?.id;
  const modelRef = React.useRef<any>(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const { newCase, criticalValues, urgencies } = useSelector((store) => store.cases);
  const { hospitals, hospitalDepartments, hospitalModalities } = useSelector((store) => store.hospital);
  const { users } = useSelector((store) => store.users);
  const { loggedInUser } = useSelector((store) => store.auth);

  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    dispatch(fetchUsers({ includeTechs: true, includeAdmins: false }));
    dispatch(fetchUrgencies());
  }, [dispatch]);

  useEffect(() => {
    const handleEffect = async () => {
      await dispatch(fetchCriticalValues());

      if (isEdit) {
        const foundCase: any = await dispatch(fetchCase(id || 0));
        const json = foundCase?.payload;
        const hospitalOptions: any = await dispatch(fetchHospitalOptions(json.hospital?.id || 0));
        const json2 = hospitalOptions?.payload;
        modelRef.current =
          {
            ...json,
            modality: json2?.modalities?.find((modal: any) => modal.id === json?.modality.id || 0) || {},
          } || newCase;
        setIsLoading(false);
        return;
      }

      if (!!loggedInUser?.roles.find((role) => role.id === 3) && hospital) {
        dispatch(fetchHospitalOptions(hospital?.id));
        modelRef.current = {
          ...newCase,
          hospital: { ...hospital },
        };
      } else {
        dispatch(fetchHospitalOptions(loggedInUser?.hospital?.id!));
      }

      setIsLoading(false);
    };

    handleEffect();
    // const effectInterval = setInterval(handleEffect, 15000);
    // return () => clearInterval(effectInterval);
  }, [isEdit, dispatch, hospital, id, loggedInUser?.hospital?.id, loggedInUser?.roles]);

  const handleSelectHospital = (e: any, value: any) => {
    dispatch(fetchHospitalOptions(value?.id || 0));
  };

  const handleSelectUser = (value: any) => {
    const foundUser = users.find((user) => user.id === value.id);
    return {
      id: foundUser?.id,
      name: foundUser?.value,
    };
  };

  const handleSave = async (values: Case) => {
    setIsSaving(true);
    const payload = { ...values };
    if (!isEdit) {
      const response = await dispatch(addCase(payload));
      if (response.meta.requestStatus !== "fulfilled") {
        setIsSaving(false);
      }
      await handleCloseModal();
    }
    if (!!blocked) {
      await dispatch(
        updateCase({
          ...payload,
          status: {
            id: 1,
            value: "Pending",
          },
        })
      );
      await handleCloseModal();
      return;
    } else {
      await dispatch(updateCase(payload));
    }
    await handleCloseModal();
  };

  const validationSchema = Yup.object().shape(
    {
      hospital: Yup.object().nullable().required("Please select a hospital"),
      hospitalDepartment: Yup.object().nullable().required("Please select a department"),
      lastName: Yup.string().required("Please enter patient last name"),
      firstName: Yup.string().required("Please enter patient first name"),
      patientId: Yup.string().required("Please enter patient ID"),
      modality: Yup.object().nullable().required("Please select a modality"),
      examTypes: Yup.array()
        .nullable()
        .when("modality", {
          is: (val: Option) => !!val && val.id > 0,
          then: Yup.array().nullable().min(1, "Please select an exam type"),
        })
        .min(1, "Please select an exam type"),
      urgency: Yup.object().nullable().required("Please select an urgency"),
      criticalValue: Yup.object()
        .nullable()
        .when("urgency", {
          is: (urgency: Option) => urgency && urgency?.id! === 2,
          then: Yup.object().nullable().required("Please select a critical value"),
        }),
    },
    [
      ["urgency", "criticalValue"],
      ["modality", "examTypes"],
    ]
  );

  const filteredModalities = hospitalModalities.filter((modal) => modal.examTypes.length > 0);
  const getUserOptions = (hospitalId: number) =>
    users
      ? users
          .filter((user) => !!user?.hospitalIds?.find((h) => h === hospitalId))
          .map((user) => {
            return { id: user.id, name: user.value };
          })
      : [];

  let hospitalIds = loggedInUser?.hospitals?.map((h) => h.id);

  const filteredHospitals =
    getUserRoleId(loggedInUser) === 3
      ? [hospitals.find((h) => h.id === hospital?.id)]
      : hospitals.filter((h) => hospitalIds?.includes(h.id) && h.isOpen);

  return !isLoading ? (
    <Root>
      <Grid>
        <Formik initialValues={modelRef?.current || newCase} validationSchema={validationSchema} onSubmit={handleSave}>
          {({ values, setFieldValue, isValid }) => {
            const isCritical = values.urgency?.id === 2;
            return (
              <Form noValidate>
                <Grid container spacing={3} flexDirection="column">
                  {!!kiosk && (
                    <Grid item xs={12}>
                      <Field
                        name={isEdit ? "editedByUser" : "openedByUser"}
                        label="User"
                        required
                        component={Inputs.Dropdown}
                        options={getUserOptions(values?.hospital?.id!)}
                        handleChange={(e: any, value: any) => {
                          setFieldValue(isEdit ? "editedByUser" : "openedByUser", handleSelectUser(value));
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Field
                      disabled={!!kiosk}
                      name="hospital"
                      label="Hospital"
                      required
                      component={Inputs.Dropdown}
                      options={filteredHospitals || []}
                      handleChange={handleSelectHospital}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="hospitalDepartment"
                      label="Hospital Department"
                      required
                      component={Inputs.Dropdown}
                      options={hospitalDepartments}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={isCritical ? 6 : 12}>
                        <Field
                          name="urgency"
                          label="Urgency"
                          required
                          component={Inputs.Dropdown}
                          options={urgencies || []}
                        />
                      </Grid>
                      {isCritical && (
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="criticalValue"
                            label="Critical Value"
                            required
                            component={Inputs.Dropdown}
                            options={criticalValues || []}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <Field
                          required
                          name="lastName"
                          label="Patient Last Name"
                          component={Inputs.Text}
                          margin="none"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          required
                          name="firstName"
                          label="Patient First Name"
                          component={Inputs.Text}
                          margin="none"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field required name="patientId" label="Patient ID" component={Inputs.Text} margin="none" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="comment" label="Comments" component={Inputs.Text} margin="none"></Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="modality"
                      label="Exam Modality"
                      required
                      component={Inputs.Dropdown}
                      options={filteredModalities}
                      handleChange={() => {
                        setFieldValue("examTypes", []);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {values?.modality && values?.modality?.examTypes && values?.modality?.examTypes?.length! > 0 && (
                      <FieldArray
                        name="examTypes"
                        render={(arrayHelpers) => (
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" flexWrap="wrap">
                              {values.modality?.examTypes.map((exam: ExamType) => (
                                <div>
                                  <Field
                                    key={exam.id}
                                    name="examTypes"
                                    value={exam}
                                    checked={
                                      !!values.examTypes?.find(
                                        (exam2: ExamType) =>
                                          +exam2?.id === +exam?.id && +exam2.modalityId === +(values.modality?.id || 0)
                                      )
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e.target.checked) return arrayHelpers.push(exam);
                                      const index = values.examTypes.findIndex(
                                        (exam2: ExamType) => exam2.id === exam?.id
                                      );
                                      arrayHelpers.remove(index);
                                    }}
                                    component={Inputs.Checkbox}
                                    label={exam.value}
                                  />
                                </div>
                              ))}
                            </Box>
                          </Grid>
                        )}
                      />
                    )}
                    <ErrorMessage name="examTypes" component="div" className="error" />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6} paddingX={1} style={{ textAlign: "center" }}>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        fullWidth
                        color="secondary"
                        size="large"
                        type="button"
                        variant="contained"
                        onClick={handleCloseModal}
                      >
                        Close
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} paddingX={1} style={{ textAlign: "center" }}>
                    <Box>
                      <Inputs.Submit text={!!blocked ? "Resubmit" : isEdit ? "Save" : "Add"} isSubmitting={isSaving} />
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Root>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="inherit" />
    </Box>
  );
};

export default AddEditCase;
