import React from "react";
import { Box, Snackbar, Typography } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface Props {
  message: string;
  open: boolean;
  type: "success" | "error" | "info";
  handleClose: () => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBarToast: React.FC<Props> = (props) => {
  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.open}
        autoHideDuration={2000}
        onClose={props.handleClose}
        sx={{
          zIndex: 5500,
        }}
      >
        <Alert onClose={props.handleClose} severity={props.type} sx={{ width: "100%" }}>
          <Typography variant="body2">{props.message}</Typography>
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SnackBarToast;
