import React, { useState } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import QGendaSettingsType from "../../types/qGendaSettings";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";

interface Props {
  onSubmit: (result: QGendaSettingsType) => void;
  qGendaSettings?: QGendaSettingsType;
}

const QGendaSettings: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (model: QGendaSettingsType) => {
    setLoading(true);
    props.onSubmit(model);
    setLoading(false);
  };

  const initialValues: QGendaSettingsType = {};

  const validationSchema = Yup.object().shape({});

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={props.qGendaSettings || initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        <Form noValidate>
          <Typography>
            Sign in to QGenda:{" "}
            <Link color="primary" component="a" href="https://app.qgenda.com/login" target="_blank">
              open QGenda app
            </Link>
          </Typography>
          <Typography>
            QGenda API documentation:{" "}
            <Link
              color="primary"
              component="a"
              href="https://docs.google.com/a/qgenda.com/document/d/1qBgLBrHy_docaonT9pKi0FWDLgmMBFGHsdF6lVWQi1w/pub"
              target="_blank"
            >
              open Google doc
            </Link>
          </Typography>
          <Grid container spacing={{ xs: 0, md: 3 }}>
            <Grid item md={4} xs={12} paddingTop={0} marginTop="15px">
              <Field name="email" label="Email" required component={Inputs.Text} />
            </Grid>
            <Grid item md={4} xs={12} paddingTop={0} marginTop="15px">
              <Field name="password" label="Password" required component={Inputs.Password} />
            </Grid>
            <Grid item md={4} xs={12} paddingTop={0} marginTop="15px">
              <Field name="companyKey" label="Company Key" required component={Inputs.Password} />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Inputs.Submit text="Save" isSubmitting={loading} />
          </Box>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default QGendaSettings;
