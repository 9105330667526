import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import FormContainer from "../base/FormContainer";
import { useSelector, useDispatch } from "../../store";
import {
  fetchSettings,
  updateCaseDurations,
  updateDisplayedStats,
  updateHighCaseLoadSettings,
  updateStatRadUser,
} from "../../slices/settings";
import CaseDurationsType from "../../types/caseDurations";
import CaseDurations from "../views/CaseDurations";
import DisplayedStatsType from "../../types/displayedStats";
import DisplayedStats from "../views/DisplayedStats";
import SnackBarToast from "../base/SnackbarToast";
import BreakSettings from "./BreakSettings";
import Option from "../../types/option";
import { updateBreakDetails, fetchBreakOptions, clearBreakOptions } from "../../slices/userBreak";
import CriticalValue from "./CriticalValueConfiguration";
import { updateCriticalValues } from "../../slices/cases";
import StatRadUser from "./StatRadUser";
import { fetchQaUsers, fetchUsers, updateQaUsers } from "../../slices/users";
import UserSimple from "../../types/userSimple";
import HighCaseLoad from "./HighCaseLoad";
import HighCaseLoadType from "../../types/highCaseLoad";
import ClaimedSchedule from "./ClaimedScheduleSettings";
import BreakDetails from "../../types/breakDetails";

interface Props {}

interface FormComponent {
  formName: string;
  component: React.ReactElement;
}

const SiteConfiguration: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { settings } = useSelector((store) => store.settings);
  const { breakTypes } = useSelector((store) => store.userBreaks);
  const { users, qaUsers } = useSelector((store) => store.users);

  const [complete, setComplete] = useState({
    caseDuration: false,
    highCaseLoad: false,
    displayedStats: false,
    criticalValues: false,
    breakDetails: false,
    statRadUser: false,
  });

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchBreakOptions());
    dispatch(clearBreakOptions());
    dispatch(fetchUsers({ includeTechs: false, includeAdmins: true }));
    dispatch(fetchQaUsers());
  }, [dispatch]);

  const handleCaseDurationsSubmit = (result: CaseDurationsType) => {
    dispatch(updateCaseDurations(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, caseDuration: true });
      }
    });
  };

  const handleHighCaseLoadSubmit = (result: HighCaseLoadType) => {
    dispatch(updateHighCaseLoadSettings(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, caseDuration: true });
      }
    });
  };

  const handleDisplayedStatsSubmit = (result: DisplayedStatsType) => {
    dispatch(updateDisplayedStats(result)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, displayedStats: true });
      }
    });
  };

  const handleBreakSettingsSubmit = (breakTypeId: number, result: BreakDetails) => {
    dispatch(updateBreakDetails({ typeId: breakTypeId, details: result.options })).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, breakDetails: true });
      }
    });
  };

  const handleCriticalValueSubmit = (values: Option[]) => {
    dispatch(updateCriticalValues(values)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        setComplete({ ...complete, breakDetails: true });
      }
    });
  };

  const handleStatradUserSubmit = (userId?: number, qaUsers?: UserSimple[]) => {
    if (!!userId) {
      dispatch(updateStatRadUser(userId)).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setComplete({ ...complete, statRadUser: true });
        }
      });
    }

    if (!!qaUsers) {
      dispatch(updateQaUsers(qaUsers)).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          setComplete({ ...complete, statRadUser: true });
        }
      });
    }
  };

  const forms: FormComponent[] = [
    {
      formName: "Case Durations (Minutes)",
      component: <CaseDurations onSubmit={handleCaseDurationsSubmit} caseDurations={settings?.caseDurations} />,
    },

    {
      formName: "High Case Load",
      component: (
        <HighCaseLoad onSubmit={handleHighCaseLoadSubmit} highCaseLoadSettings={settings?.highCaseLoadSettings} />
      ),
    },

    {
      formName: "Displayed Stats",
      component: <DisplayedStats onSubmit={handleDisplayedStatsSubmit} displayedStats={settings?.displayedStats} />,
    },
    {
      formName: "QA Options",
      component: (
        <StatRadUser
          onSubmit={handleStatradUserSubmit}
          statRadUser={settings?.statRadUser}
          userOptions={users}
          qaUsers={qaUsers}
        />
      ),
    },
    {
      formName: "Break Details",
      component: <BreakSettings onSubmit={handleBreakSettingsSubmit} breakOptions={breakTypes} />,
    },
    {
      formName: "Critical Values",
      component: <CriticalValue onSubmit={handleCriticalValueSubmit} />,
    },
    {
      formName: "Claimed Schedules",
      component: <ClaimedSchedule />,
    },
  ];

  const closeCaseDurationToast = () => {
    setComplete({ ...complete, caseDuration: false });
  };
  const closeHighCaseLoadToast = () => {
    setComplete({ ...complete, highCaseLoad: false });
  };
  const closeDisplayedStatsToast = () => {
    setComplete({ ...complete, displayedStats: false });
  };
  const closeDetailsToast = () => {
    setComplete({ ...complete, breakDetails: false });
  };
  const closeCriticalValuesToast = () => {
    setComplete({ ...complete, criticalValues: false });
  };
  const closeQaSettingsToast = () => {
    setComplete({ ...complete, statRadUser: false });
  };

  return (
    <>
      <Grid container spacing={3} marginBottom={6}>
        {forms.map((form) => (
          <Grid item height="100%" md={12} sm={12} xs={12} key={form.formName}>
            <FormContainer title={form.formName}>{form.component}</FormContainer>
          </Grid>
        ))}
      </Grid>
      <SnackBarToast
        message="Case Durations Changed Successfully"
        type="success"
        open={complete.caseDuration}
        handleClose={closeCaseDurationToast}
      />
      <SnackBarToast
        message="High Case Load Settings Changed Successfully"
        type="success"
        open={complete.highCaseLoad}
        handleClose={closeHighCaseLoadToast}
      />
      <SnackBarToast
        message="Displayed Stats Changed Successfully"
        type="success"
        open={complete.displayedStats}
        handleClose={closeDisplayedStatsToast}
      />
      <SnackBarToast
        message="Break Details Changed Successfully"
        type="success"
        open={complete.breakDetails}
        handleClose={closeDetailsToast}
      />
      <SnackBarToast
        message="Critical Valyes Changed Successfully"
        type="success"
        open={complete.criticalValues}
        handleClose={closeCriticalValuesToast}
      />
      <SnackBarToast
        message="QA Settings Changed Successfully"
        type="success"
        open={complete.statRadUser}
        handleClose={closeQaSettingsToast}
      />
    </>
  );
};

export default SiteConfiguration;
