import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "../api/http";
import Announcement from "../types/announcement";

interface AnnouncementState {
  announcements: Announcement[];
  unreadAnnouncements: Announcement[];
  status: "idle" | "loading" | "failed";
}

const initialState: AnnouncementState = {
  announcements: [],
  unreadAnnouncements: [],
  status: "idle",
};

export const fetchAnnouncements = createAsyncThunk<Announcement[]>("announcement/fetch", async () => {
  const response = await http.get<Announcement[]>("/announcements");
  return response.data;
});

export const fetchUnreadAnnouncements = createAsyncThunk<Announcement[]>("announcement-unread/fetch", async () => {
  const response = await http.get<Announcement[]>("/announcements/unread");
  return response.data;
});

export const addAnnouncement = createAsyncThunk<Announcement, Announcement>(
  "announcements/add",
  async (model: Announcement) => {
    let qs = "";

    if (model.sendEmail && model.sendText) {
      qs = "?sendEmail=true&sendText=true";
    } else if (model.sendText) {
      qs = "?sendText=true";
    } else if (model.sendEmail) {
      qs = "?sendEmail=true";
    }

    const response = await http.post(`/announcements${qs}`, model);
    return response.data;
  }
);

export const deleteAnnouncement = createAsyncThunk<Announcement, number>("announcements/delete", async (id: number) => {
  const response = await http.delete(`/announcements/${id}`);
  return response.data;
});

export const readAnnouncement = createAsyncThunk<Announcement, number>("announcements/read", async (id: number) => {
  const response = await http.patch(`/announcements/${id}`);
  return response.data;
});

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    newAnnouncement: (state, action) => {
      const existingAnnouncement = state.unreadAnnouncements.find((a) => a.id === action.payload.id);

      if (!existingAnnouncement) {
        state.unreadAnnouncements.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Announcements
      .addCase(fetchAnnouncements.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAnnouncements.fulfilled, (state, action) => {
        state.status = "idle";
        state.announcements = action.payload;
      })
      .addCase(fetchAnnouncements.rejected, (state) => {
        state.status = "failed";
      })

      // Fetch Unread Announcements
      .addCase(fetchUnreadAnnouncements.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUnreadAnnouncements.fulfilled, (state, action) => {
        state.status = "idle";
        state.unreadAnnouncements = action.payload;
      })
      .addCase(fetchUnreadAnnouncements.rejected, (state) => {
        state.status = "failed";
      })

      // Add Announcement
      .addCase(addAnnouncement.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAnnouncement.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(addAnnouncement.rejected, (state) => {
        state.status = "failed";
      })

      // Read Announcement
      .addCase(readAnnouncement.pending, (state) => {
        state.status = "loading";
      })
      .addCase(readAnnouncement.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(readAnnouncement.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default casesSlice.reducer;

export const { newAnnouncement } = casesSlice.actions;
