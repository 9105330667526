import React, { useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";
import * as Yup from "yup";
import { useDispatch, useSelector } from "../../store";
import { fetchAllModalities, fetchHospitals } from "../../slices/hospital";
import InterestingCase from "../../types/interestingCase";
import { getUnreadCases, readCase } from "../../slices/interestingCases";

interface Props {
  onSubmit: (model: InterestingCase) => void;
  handleClose: () => void;
}

const AddInterestingCase: React.FC<Props> = (props) => {
  const { onSubmit, handleClose } = props;
  const dispatch = useDispatch();
  const { hospitals, allModalities } = useSelector((store) => store.hospital);
  const { interestingCase, status } = useSelector((store) => store.interestingCases);

  useEffect(() => {
    dispatch(fetchHospitals());
    if (!allModalities.length) {
      dispatch(fetchAllModalities());
    }
  }, [dispatch]);

  useEffect(() => {
    if (interestingCase.id > 0) {
      dispatch(readCase(interestingCase.id)).then(() => {
        dispatch(getUnreadCases());
      });
    }
  }, [interestingCase.id]);

  const validationSchema = Yup.object().shape({
    mrNumber: Yup.string().required("Please enter an MR number"),
    hospital: Yup.object().nullable().required("Please select a hospital"),
    modality: Yup.object().nullable().required("Please select a modality"),
    comments: Yup.string().nullable(),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={interestingCase}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form noValidate>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Field
                name="mrNumber"
                label="MR Number"
                required
                component={Inputs.Text}
                disabled={interestingCase.id > 0}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                sx={{ mt: "8px" }}
                name="studyDate"
                label="Case Date"
                fullWidth
                component={Inputs.Datepicker}
                disabled={interestingCase.id > 0}
              />
            </Grid>
            <Grid item xs={12}>
              {interestingCase.id > 0 ? (
                <Field name="hospital.name" label="Hospital" required component={Inputs.Text} disabled />
              ) : (
                <Field name="hospital" label="Hospital" required component={Inputs.Dropdown} options={hospitals} />
              )}
            </Grid>
            <Grid item xs={12}>
              {interestingCase.id > 0 ? (
                <Field name="modality.name" label="Modality" required component={Inputs.Text} disabled />
              ) : (
                <Field name="modality" label="Modality" required component={Inputs.Dropdown} options={allModalities} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Field name="comments" label="Comments" component={Inputs.Text} disabled={interestingCase.id > 0} />
            </Grid>
            <Grid item xs={interestingCase.id > 0 ? 12 : 6} justifySelf={"center"}>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="secondary"
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={handleClose}
                >
                  {interestingCase.id > 0 ? "Close" : "Cancel"}
                </Button>
              </Box>
            </Grid>
            {interestingCase.id === 0 && (
              <Grid item xs={6}>
                <Inputs.Submit text={"Add"} isSubmitting={status === "loading"} disabled={status === "loading"} />
              </Grid>
            )}
          </Grid>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default AddInterestingCase;
