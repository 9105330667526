import React from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import { Box, Checkbox, CheckboxProps, Typography } from "@mui/material";
import theme from "../../theme";

interface Props {
  label: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
}

const FormikCheckbox: React.FC<FieldProps & CheckboxProps & Props> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const submitCount = getIn(props.form.submitCount, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { label, checked, field, onChange, ...rest } = props;

  const { setFieldValue } = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) {
      onChange(checked);
    } else {
      setFieldValue(field.name, checked);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Checkbox onChange={handleChange} checked={checked} disableFocusRipple disableRipple />
        <Typography variant={"caption"}>{label}</Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: ".75rem",
            letterSpacing: "0em",
            fontWeight: 400,
            textAlign: "left",
            ml: "14px",
            mt: "5px",
          }}
          color={theme.palette.error.main}
        >
          {(!!isTouched || submitCount !== 0) && errorMessage}
        </Typography>{" "}
      </Box>
    </>
  );
};

export default FormikCheckbox;
