import React from "react";
import { FieldProps, getIn, useFormikContext } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

const FormikDatepicker: React.FC<FieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, variant, margin, required, disabled, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DesktopDatePicker
        {...field}
        onChange={(date) => {
          setFieldValue(field.name, date);
        }}
        disabled={disabled}
        inputFormat="MM/DD/yyyy"
        renderInput={(inputProps) => (
          <TextField
            required={required}
            variant="outlined"
            {...inputProps}
            {...rest}
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default FormikDatepicker;
