import React, { ChangeEvent, useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

interface Props {
  value: string;
  variant: "caption" | "body2";
  handleChange: (value: string) => void;
}

const SearchBar: React.FC<Props> = (props) => {
  const [searchValue, setSearchValue] = useState<string>();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);
    props.handleChange(e.target.value);
  };

  return (
    <Box
      sx={
        {
          // marginTop: "8px",
          // marginX: "6px",
          // width: "100%",
        }
      }
    >
      <TextField
        fullWidth
        placeholder="Search"
        variant="outlined"
        value={searchValue || ""}
        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
        // sx={{
        //   "& .MuiInputBase-root": {
        //     borderRadius: "30px",
        //     color: theme.palette.info.main,
        //     fontStyle: "italic",
        //     height: "34px",
        //     border: "1px solid #000",
        //   },
        // }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
