import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Conversation from "../types/conversation";
import http from "../api/http";
import Message from "../types/message";
import { Howl } from "howler";

interface ChatState {
  currentUnread: number;
  currentMessage?: Message;
  currentConversation: Message[];
  conversations: Conversation[];
  status: "idle" | "loading" | "failed";
  textStatus: "idle" | "loading" | "failed";
  chatOpen: { id?: number; title?: string; open: boolean; team: boolean; tech: boolean };
  pinnedChat: boolean;
  chatSoundVolume: number;
  chatSound: string;
  chatDraft?: { id: number; message: string };
}

const initialState: ChatState = {
  currentUnread: 0,
  currentMessage: undefined,
  currentConversation: [],
  conversations: [],
  status: "idle",
  textStatus: "idle",
  chatOpen: { id: undefined, title: "", open: false, team: false, tech: false },
  pinnedChat: false,
  chatSoundVolume: 1,
  chatSound: "",
};

export const sendChat = createAsyncThunk<Message, Message>(`chats/send`, async (chat: Message, { dispatch }) => {
  const response = await http.post<Message>(`chats`, chat);
  return response.data;
});

export const getMessages = createAsyncThunk<Message[], number>(`chats/get-user-messages`, async (userId: number) => {
  const response = await http.get<Message[]>(`chats/messages/${userId}`);
  return response.data;
});

export const readMessage = createAsyncThunk<null, number>(`chats/read`, async (id: number, { dispatch }) => {
  const response = await http.patch(`chats/${id}/read`);
  dispatch(getConversations());
  return response.data;
});

export const sendAsText = createAsyncThunk<null, number>(`chats/text`, async (chatId: number, { dispatch }) => {
  const response = await http.patch(`chats/${chatId}/text`);
  // dispatch(getConversations());
  return response.data;
});

export const getConversations = createAsyncThunk<Conversation[]>(`chats/get-conversations`, async () => {
  const response = await http.get<Conversation[]>(`chats/conversations`);

  const result = response.data.filter((c: Conversation) => {
    return c.id !== 0;
  });

  return result;
});

export const getChatSoundEffect = createAsyncThunk<string>("settings/chat-sound-get", async () => {
  const response = await http.get("settings/chat-sound");
  return response.data;
});

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    toggleChat(state, action) {
      state.chatOpen = action.payload;
    },
    clearConversation(state) {
      state.currentConversation = initialState.currentConversation;
    },
    setPinnedChat(state, action) {
      state.pinnedChat = action.payload;
    },
    playChatSound(state) {
      var chatSound = new Howl({
        src: ["data:audio/mp3;base64," + state.chatSound],
        volume: state.chatSoundVolume,
      });
      chatSound.play();
    },
    updateChatSoundVolume(state, action) {
      state.chatSoundVolume = action.payload;
    },
    saveChatDraft(state, action) {
      state.chatDraft = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendChat.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendChat.fulfilled, (state, action) => {
        state.currentMessage = action.payload;

        let chat = state.currentConversation;
        chat?.push(action.payload);
        state.currentConversation = chat;
        state.status = "idle";
      })
      .addCase(sendChat.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getMessages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.status = "idle";
        if (state.chatOpen.id !== 0) {
          const fromUser = action.payload[0]?.fromUser?.id!;
          const toUser = action.payload[0]?.toUsers[0]?.id!;

          if (fromUser === state.chatOpen.id || toUser === state.chatOpen.id) {
            state.currentConversation = action.payload;
          }
        }
      })
      .addCase(getMessages.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(readMessage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(readMessage.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(readMessage.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(sendAsText.pending, (state) => {
        state.textStatus = "loading";
      })
      .addCase(sendAsText.fulfilled, (state, action) => {
        state.textStatus = "idle";
      })
      .addCase(sendAsText.rejected, (state) => {
        state.textStatus = "failed";
      })
      .addCase(getConversations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getConversations.fulfilled, (state, action) => {
        state.status = "idle";
        state.conversations = action.payload;
        let newUnread = action.payload.reduce((a, b) => a + b.unread!, 0);
        state.currentUnread = newUnread;
      })
      .addCase(getConversations.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getChatSoundEffect.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getChatSoundEffect.fulfilled, (state, action) => {
        state.status = "idle";
        state.chatSound = action.payload;
      })
      .addCase(getChatSoundEffect.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export default chatSlice.reducer;
export const { clearConversation, toggleChat, setPinnedChat, playChatSound, updateChatSoundVolume, saveChatDraft } =
  chatSlice.actions;
