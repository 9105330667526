import { Box, Button, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import React, { useState } from "react";
import { useSelector } from "../../store";
import { Inputs } from "../forms";
import * as Yup from "yup";
import Comment from "../../types/comment";

interface Props {
  caseId: number;
  onSave: (title: string, c: Comment) => void;
  description: string;
  onClose: () => void;
  required?: boolean;
  title: string;
  type: "comment" | "finding";
}

const AddComment: React.FC<Props> = (props) => {
  const { caseId, onSave, description, onClose, required, title, type } = props;
  const { loggedInUser } = useSelector((store) => store.auth);
  const [busy, setBusy] = useState(false);

  const initialValues: Comment = {
    caseId: caseId,
    user: loggedInUser,
    commentType: { id: type === "comment" ? 1 : 2, value: "" },
    commentText: "",
  };

  const handleSave = async (values: Comment) => {
    onSave(title, values);
    onClose();
    setBusy(false);
  };

  const validationSchema = Yup.object().shape({
    commentText: Yup.string().required("Please enter a comment"),
  });

  return (
    <div>
      <Box padding={1}>{description}</Box>
      <Formik
        initialValues={initialValues}
        validationSchema={required && validationSchema}
        onSubmit={async (values, actions) => {
          setBusy(true);
          handleSave(values);
        }}
      >
        <Form noValidate>
          <Field name="commentText" label="Add Comment" required={required} component={Inputs.Text} />
          <Grid container>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Box sx={{ mt: 2 }}>
                <Button color="secondary" fullWidth size="large" type="button" variant="contained" onClick={onClose}>
                  Close
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} padding={1} style={{ textAlign: "center" }}>
              <Box>
                <Inputs.Submit text="Save" isSubmitting={busy} />
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};

export default AddComment;
