import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import HighCaseLoadType from "../../types/highCaseLoad";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Inputs } from "../forms";

interface Props {
  onSubmit: (result: HighCaseLoadType) => void;
  highCaseLoadSettings?: HighCaseLoadType;
}

const HighCaseLoad: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (model: HighCaseLoadType) => {
    setLoading(true);
    props.onSubmit(model);
    setLoading(false);
  };

  const initialValues: HighCaseLoadType = {
    pendingLimit: "",
    pendingNotificationGrace: "",
    pendingNotificationReminderGrace: "",
  };

  const validationSchema = Yup.object().shape({
    pendingLimit: Yup.string().required("Please enter a value for pending cases threshold"),
    pendingNotificationGrace: Yup.string().required("Please enter a value in minutes for grace period"),
    pendingNotificationReminderGrace: Yup.string().required(
      "Please enter a value in minutes for reminder grace period"
    ),
  });

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={props.highCaseLoadSettings || initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        <Form noValidate>
          <Grid container spacing={{ md: 3, xs: 0 }}>
            <Grid item md={4} xs={12}>
              <Field name="pendingLimit" label="Pending Cases Threshold" required component={Inputs.Text} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field name="pendingNotificationGrace" label="Grace Period (Minutes)" required component={Inputs.Text} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Field
                name="pendingNotificationReminderGrace"
                label="Reminder Grace Period (Minutes)"
                required
                component={Inputs.Text}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Inputs.Submit text="Save" isSubmitting={loading} />
          </Box>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

export default HighCaseLoad;
